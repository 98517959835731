import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {
    ActionCommunityTypesAdd,
    ActionCommunityTypesDelete,
    ActionCommunityTypesEdit,
    ActionDescriptorsCommunity, ActionStatusDelete
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import CommunityTypePopup from "./CommnityTypePopup";
import ToolButtons from "../../../parts/ToolButtons";
import Search from "../../../parts/Search";
import Storege from "../../../storage/storage";
import RemoveButton from "../../../parts/removeBtn";
import CommonTab from "../../../parts/CommonTabClass";
import EditBtn from "../../../parts/EditBtn";

class CommunityDescriptorComponent extends CommonTab {

    orderFields = {
        "Place Community Type Name": "placecommunitytypename",
        "Place Community Type Name Eng": "placecommunitytypenameeng"
    };

    state = {
        communities: {},
        popup: false,
        popupType: undefined,
        order: null
    };

    constructor(props) {
        super(props);
        this.state.communities = props.communities;
    }

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    getId(pathName) {
        let path = (pathName).split('/');
        let id = path[path.length - 1];
        return id;
    }

    addContentType = (object) => {
        ActionCommunityTypesAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLACE COMMUNITY TYPE',
                item: data
            });
            this.changePopupState();
            this.props.history.push('community_types/' + data.placecommunitytypeid);
        });
    };

    editContentType = (object) => {
        ActionCommunityTypesEdit(object.placecommunitytypeid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLACE COMMUNITY TYPE',
                item: data,
                id: data.placecommunitytypeid
            });
            this.changePopupState();
            this.props.history.push(data.placecommunitytypeid);
        });
    };

    changeState = (name, e, id) => {
        let temp = this.props.communities;
        temp.forEach(function (item) {
            if (item.placecommunitytypeid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            communities: Object.assign({}, temp)
        });
    };

    search = (value) => {
        if (value.length) {
            let filteredArray = this.props.communities.filter(item => {
                return item.placecommunitytypename.toLowerCase().indexOf(value.toLowerCase()) != -1 || item.placecommunitytypenameeng.toLowerCase().indexOf(value.toLowerCase()) != -1;
            });
            this.setState({
                filtered: true,
                filteredItems: filteredArray
            });
        } else {
            this.setState({
                filtered: false
            });
        }
    };

    render() {
        if (!this.props.communities.length) {
            ActionDescriptorsCommunity();
        }
        let {communities} = this.props;
        let {filtered, filteredItems} = this.state;
        let list = filtered ? filteredItems : communities;
        if (!list) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        this.sortResults(list);

        return (
            <div>
                <div className="inline-toolbuttons">
                    <h3>Place Community Types:</h3>
                    <div className={'tool-bar'}>
                        <ToolButtons
                            actionType={this.changePopupType}
                            popupChange={this.changePopupState}
                            id={this.state.currentItemIndex}
                        />
                        <Search
                            change={this.search}
                        />
                    </div>
                </div>
                <table className='table table-striped'>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {(list ? list : []).map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                e => {
                                    e.preventDefault();
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.placecommunitytypeid
                                    });
                                }
                            } className={this.state.rowIndex == index ? 'active' : ''}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionCommunityTypesDelete}
                                        id={item.placecommunitytypeid}
                                        descriptor={'REMOVE PLACE COMMUNITY TYPE'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.placecommunitytypeid}
                                    />
                                </td>
                                <td>{item.placecommunitytypename} </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.placecommunitytypenameeng}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <CommunityTypePopup
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        communities: state.communityType
    }
};
const DescriptorCommunity = connect(mapStateToProps)(CommunityDescriptorComponent);

export default DescriptorCommunity;