import React from 'react';
import {connect} from 'react-redux';
import Popup from "../../parts/Popup";
import CommonTab from "../../parts/CommonTabClass";
import DatePicker from "react-datepicker";

class TimeRangeAddComponent extends CommonTab {
    state = {
        popupItem: {

        },
        errors: [],
        validateFields: [
            'timerangename',
            'startdate',
            'enddate',
        ]
    };

    handleChange = (date) => {
        let temp = this.state.popupItem;
        temp.startdate = date;
        this.setState({popupItem: temp});
    };

    handleChange2 = (date) => {
        let temp = this.state.popupItem;
        temp.enddate = date;
        this.setState({popupItem: temp});
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }


    placeChange = (value) => {
        let temp = this.state.popupItem;
        temp.popupItemid = value.target.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        const {popupItem, validateFields, errors} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('planningphaseid' in popupItem)) {
                            popupItem['planningphaseid'] = ''
                        }
                        if (!('remarks' in popupItem)) {
                            popupItem['remarks'] = ''
                        }
                        if (!('url' in popupItem)) {
                            popupItem['url'] = ''
                        }
                        this.formValidation(popupItem, validateFields);
                    }}
                >
                    <h3>{this.props.button + (this.props.button === 'Add ' ? 'New ' : '')} Cost</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                         <tr  className={errors.indexOf('timerangename') !== -1 ? 'error' : ''}>
                            <td>Name:</td>
                            <td>
                                <input
                                    type={'text'}
                                    value={popupItem.timerangename}
                                    onChange={e => {
                                        this.changeState('timerangename', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                         <tr  className={errors.indexOf('timerangenameeng') !== -1 ? 'error' : ''}>
                            <td>Name Eng:</td>
                            <td>
                                <input
                                    type={'text'}
                                    value={popupItem.timerangenameeng}
                                    onChange={e => {
                                        this.changeState('timerangenameeng', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr  className={errors.indexOf('startdate') !== -1 ? 'error' : ''}>
                            <td>Start Date:</td>
                            <td>
                                <DatePicker
                                    selected={popupItem.startdate ? new Date(popupItem.startdate) : ''}
                                    onChange={this.handleChange}
                                    //showTimeSelect
                                    timeFormat="HH:mm:s"
                                    timeIntervals={60}
                                    dateFormat="dd/MM/yyyy h:mm:s"
                                    timeCaption="time"
                                />
                            </td>
                        </tr>
                        <tr  className={errors.indexOf('enddate') !== -1 ? 'error' : ''}>
                            <td>End Date:</td>
                            <td>
                                <DatePicker
                                    selected={popupItem.enddate ? new Date(popupItem.enddate) : ''}
                                    onChange={this.handleChange2}
                                    //showTimeSelect
                                    timeFormat="HH:mm:s"
                                    timeIntervals={60}
                                    dateFormat="dd/MM/yyyy h:mm:s"
                                    timeCaption="time"
                                />
                            </td>
                        </tr>
                         <tr  className={errors.indexOf('remarks') !== -1 ? 'error' : ''}>
                            <td>Remarks:</td>
                            <td>
                                <input
                                    type={'text'}
                                    value={popupItem.remarks}
                                    onChange={e => {
                                        this.changeState('remarks', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state , ownProps) => {
    let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item.timerangeid == ownProps.id) {
            popupItem = item;
        }
    });
    return {
        popupItem : popupItem
    }
};

const TimeRangeAdd = connect(mapStateToProps)(TimeRangeAddComponent);
export default TimeRangeAdd;
