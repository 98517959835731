import React from 'react';
import {connect} from 'react-redux';
import {
    ActionDescriptorsPlanningPhases
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import TendersRequest from "../../tenders/TendersRequest";
import CommonTab from "../../../parts/CommonTabClass";
import getFullDateYear, {getItemData} from "../../../parts/functions";
import PlansRequest from "../../plans/PlansRequest";


class PlanPublicationsComponent extends CommonTab {

    orderFields = {
        "Publication Date": 'publicationdate',
        "Planning Phase": 'planningphaseid',
        "Source": 'sourceid',
        "Source Credibility": 'sourcecredibilityid',
        "Remarks": 'remarks',
        "URL": 'url'
    };

    state = {
        planningPhases: {},
        source: {},
        sourcecredibility: {},
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        uploaded: false,
    };

    constructor(props) {
        super(props);

        this.state.planningPhases = props.planningPhases;
        this.state.source = props.source;
        this.state.sourcecredibility = props.sourcecredibility;
        this.state.plan = props.plan
    }

    render() {

        if (!this.props.planningPhases.length) {
            ActionDescriptorsPlanningPhases();
        }

        if (!this.props.sourcecredibility.length) {
            TendersRequest('tenders');
            PlansRequest('plans');
        }

        let {items, planningPhases, source, sourcecredibility} = this.props;

        let publications = items ? items : [];

        this.sortResults(publications);
        return (
            <div>
                <div className={'custom-tool-bar'}>
                    <h3>Plan Publications</h3>
                </div>
                <div className="scrolled ">
                    <table className={"table table-striped"} valign={"middle"}>
                        <thead>
                        {this.orderPanel()}
                        </thead>
                        <tbody>
                        {publications.map((item, index) => {
                            return (
                                <tr key={index} onClick={
                                    () => {
                                        this.setState({
                                            rowIndex: index,
                                            currentItemIndex: item.planpublicationid
                                        });
                                    }
                                } className={'list-group-item' + (this.state.rowIndex == index ? ' active' : '')}>
                                    <td  className={'flex-row'}>
                                    </td>
                                    <td>
                                        {getFullDateYear(item.publicationdate)}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {getItemData(planningPhases ? planningPhases : [], 'planningphaseid', item.planningphaseid, 'planningphasename')}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {getItemData(source ? source : [], 'sourceid', item.sourceid, 'sourcename')}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {getItemData(sourcecredibility ? sourcecredibility : [], 'sourcecredibilityid', item.sourcecredibilityid, 'sourcecredibilityname')}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{item.remarks}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{item.url}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        source: state.source,
        planningPhases: state.planningPhases,
        sourcecredibility: state.sourcecredibility
    }
};

const PlanPublications = connect(mapStateToProps)(PlanPublicationsComponent);
export default PlanPublications;