import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {
    ActionStatusEdit,
    ActionStatusAdd,
    ActionStatusDelete,
    ActionDescriptorsStatusCategories,
    ActionDescriptorsStatus,
    ActionStatusContentEdit,
    ActionStatusContentAdd,
    ActionStatusContentDelete,
} from "../../../parts/actions";
import ToolButtons from "../../../parts/ToolButtons";
import Search from "../../../parts/Search";
import Storege from "../../../storage/storage";
import StatusCategoryPopup from "./StatusCategoryPopup";
import ColumnResizer from "react-column-resizer";
import api from "../../../api";
import StatusPopup from "./StatusPopup";
import RemoveButton from "../../../parts/removeBtn";
import CommonTab from "../../../parts/CommonTabClass";
import EditBtn from "../../../parts/EditBtn";
import {getItemData} from "../../../parts/functions";

class StatusDescriptorComponent extends CommonTab {
    state = {
        statusCategories: {},
        statuses: {},
        popupCategory: false,
        popupCategoryType: undefined,
        popupStatus: false,
        popupStatusType: undefined,
        searchValue: '',
        searchValueStatus: '',
        currentItemIndexStatusCat: -1,
        currentItemIndexStatus: -1,
        rowIndex: -1,
        rowIndex_2: -1
    };

    orderFields = {
        "Status Id":'statusid',
        "Status Name":'statusname',
        "Status Name Eng":'statusnameeng',
        "Description":'description',
        "Status Category": (a, b) => {
            const {statusCategories} = this.props;
            let result = 0;
            if (getItemData(statusCategories, 'statuscategoryid', a.statuscategoryid, 'statuscategoryname') < getItemData(statusCategories, 'statuscategoryid', b.statuscategoryid, 'statuscategoryname')) {
                result = -1;
            }

            if (getItemData(statusCategories, 'statuscategoryid', a.statuscategoryid, 'statuscategoryname') > getItemData(statusCategories, 'statuscategoryid', b.statuscategoryid, 'statuscategoryname')) {
                result = 1;
            }
            return result
        }
    };

    setCurrentIndex_2 = (id, popupName) => {
        console.log(id, popupName)
        this.setState({
            currentItemIndexStatus : id,
            popupType : 'edit',
            popupName: popupName
        });
    };

    constructor(props) {
        super(props);
        this.state.statusCategories = props.statusCategories;
    }

    changePopupType = (actionType) => {
        this.setState({
            popupCategoryType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popupCategory;
        this.setState(
            {
                popupCategory: !currentPopupState
            }
        )
    };

    changePopupTypeDef = (actionType) => {
        this.setState({
            popupStatusType: actionType
        })
    };

    changePopupStateDef = () => {
        let currentPopupState = this.state.popupStatus;
        this.setState(
            {
                popupStatus: !currentPopupState
            }
        )
    };

    changeStateCategory = (name, e, id) => {
        let temp = this.props.statusCategories;
        temp.forEach(function (item) {
            if (item.statuscategoryid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            statusCategories: Object.assign({}, temp)
        });
    };

    getId(pathName) {
        let path = (pathName).split('/');
        let id = path[path.length - 1];
        return id;
    }

    changeState = (name, e, id) => {
        let temp = this.props.statuses;
        temp.forEach(function (item) {
            if (item.statusid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            statuses: Object.assign({}, temp)
        });
    };

    changeCategory = (name, e) => {
        let temp = this.props.statuses;
        temp[name] = +e.value;
        this.setState({
            statuses: Object.assign({}, temp)
        });
    };

    addContentType = (object) => {
        ActionStatusAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLACE STATUSES CATEGORY',
                item: data
            });
            this.changePopupState();
            this.props.history.push('status_categories/' + data.statuscategoryid);
        });
    };

    editContentType = (object) => {
        ActionStatusEdit(object.statuscategoryid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLACE STATUSES CATEGORY',
                item: data,
                id: data.statuscategoryid
            });
            this.changePopupState();
            this.props.history.push(data.statuscategoryid);
        });
    };

    addContentType2 = (object) => {
        ActionStatusContentAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLACE STATUSES',
                item: data
            });
            this.changePopupStateDef();
            this.props.history.push('status_categories/' + data.statusid);
        });
    };

    editContentType2 = (object) => {
        ActionStatusContentEdit(object.statusid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLACE STATUSES',
                item: data,
                id: data.statusid
            });
            this.changePopupStateDef();
            this.props.history.push(data.statusid);
        });
    };

    search_2 = (value) => {
        this.setState({
            searchValueStatus: value
        });
    };



    render() {

        const {searchValueStatus, searchValue} = this.state;

        if (!this.props.statusCategories.length) {
            ActionDescriptorsStatusCategories();
        }
        if (!this.props.statuses.length || !this.props.statuses[0].statusname) {
            ActionDescriptorsStatus();
        }
        let {statusCategories, statuses} = this.props;
        if (!statusCategories && !statuses) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        if (searchValue.length) {
            statusCategories = statusCategories.filter((item) => {
                return (item.statuscategoryname ? item.statuscategoryname : '').toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 || (item.statuscategorynameeng ? item.statuscategorynameeng : '').toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
            });
        }

        if (searchValueStatus.length) {
            statuses = statuses.filter((item) => {
                return (item.statusname ? item.statusname : '').toLowerCase().indexOf(searchValueStatus.toLowerCase()) !== -1 || (item.statusnameeng ? item.statusnameeng : '').toLowerCase().indexOf(searchValueStatus.toLowerCase()) !== -1 || (item.description ? item.description : '').toLowerCase().indexOf(searchValueStatus.toLowerCase()) !== -1;
            });
        }
        this.sortResults(statuses);
        return (
            <div>
                <div className="inline-toolbuttons">
                    <h3>Status Categories</h3>
                    <div className={'tool-bar'}>
                        <ToolButtons
                            actionType={this.changePopupType}
                            popupChange={this.changePopupState}
                            redirect={this.redirectFunction}
                            viewPath={'descriptors/content_types'}
                            viewPathEdit={'descriptors/content_types/edit/'}
                            url={this.props.location.pathname}
                        />
                        <Search
                            change={this.search}
                            id={'top-status'}
                        />
                    </div>
                </div>
                <table className='table table-striped'>
                    <thead>
                    <tr>
                        <th></th>
                        <th>Status Category Name</th>
                        <th></th>
                        <th>Status Category Name Eng</th>
                    </tr>
                    </thead>
                    <tbody>
                    {statusCategories.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                () => {
                                    this.setState({
                                        rowIndex: index,
                                        rowIndex_2: -1,
                                        currentItemIndexStatusCat: item.statuscategoryid
                                    });
                                }
                            } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionStatusDelete}
                                        id={item.statuscategoryid}
                                        descriptor={'REMOVE DESCRIPTOR STATUSES CATEGORY'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.statuscategoryid}
                                    />
                                </td>
                                <td>
                                    <Link
                                        to={api.getRoute('descriptors/place_types/status_categories/' + item.statuscategoryid)}>
                                       {item.statuscategoryname}
                                    </Link>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    <Link
                                        to={api.getRoute('descriptors/place_types/status_categories/' + item.statuscategoryid)}>
                                       {item.statuscategorynameeng}
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popupCategory ? <StatusCategoryPopup
                    close={this.changePopupState}
                    action={this.state.popupCategoryType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupCategoryType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupCategoryType == 'edit' ? this.state.currentItemIndexStatusCat : -1}
                /> : ''}
                <div className="inline-toolbuttons">
                    <h3>Statuses</h3>
                    <div className={'tool-bar'}>
                        <ToolButtons
                            actionType={this.changePopupTypeDef}
                            popupChange={this.changePopupStateDef}
                            redirect={this.redirectFunction}
                            url={this.props.location.pathname}
                        />
                        <Search
                            change={this.search_2}
                            id={'bottom-status'}
                        />
                    </div>
                </div>
                <table className='table table-striped'>
                    <thead>
                        {this.orderPanel()}
                    </thead>
                    <tbody>
                    {statuses.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                () => {
                                    this.setState({
                                        rowIndex_2: index,
                                        rowIndex: -1,
                                        currentItemIndexStatus: item.statusid
                                    });
                                }
                            } className={'list-group-item clear' + (this.state.rowIndex_2 == index ? ' active' : '')}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionStatusContentDelete}
                                        id={item.statusid}
                                        descriptor={'REMOVE DESCRIPTOR STATUSES'}
                                    />

                                    <EditBtn
                                        actionType={this.changePopupTypeDef}
                                        popupChange={this.changePopupStateDef}
                                        change={this.setCurrentIndex_2}
                                        id={item.statusid}
                                    />
                                </td>
                                <td>
                                    <span>{item.statusid}</span>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.statusname}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.statusnameeng}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.description}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{getItemData(statusCategories, 'statuscategoryid', item.statuscategoryid, 'statuscategoryname')}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popupStatus ? <StatusPopup
                    close={this.changePopupStateDef}
                    action={this.state.popupStatusType == 'add' ? this.addContentType2 : this.editContentType2}
                    button={this.state.popupStatusType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupStatusType == 'edit' ? this.state.currentItemIndexStatus : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        statusCategories: state.statusCategories,
        statuses: state.statuses
    }
};
const DescriptorStatus = connect(mapStateToProps)(StatusDescriptorComponent);

export default DescriptorStatus;
