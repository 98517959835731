import React from 'react';
import ColumnResizer from "react-column-resizer";

class CommonTab extends React.Component {

    state = {
        order : null,
        searchValue: ''
    };

    constructor(props) {
        super(props);
    }

    componentDidUpdate (props){
        this.state.page = 1 ;
    }

    popupName = (name = '') => {
        this.setState(
            {
                popupName: name
            }
        )
    }

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        });
    };

     paginationChange = (number) => {
        this.setState({
            page: number
        });
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };



    setOrder = (type) => {
        let order = {
            type: type,
            diraction: true
        };
        if (this.state.order && this.state.order.type == type) {
            order.diraction = !this.state.order.diraction;
        }
        this.setState({
            order: order
        })
    };

    diractionSymbol(type) {
        if (this.state.order && this.state.order.type == type) {
            return (
                <span
                    className={this.state.order.diraction ? 'asc ' : 'desc'}>{this.state.order.diraction ? 'asc ' : 'desc'}</span>
            )
        }
        return null;
    }

    // sortResults = (items) => {
    //     const {order} = this.state;
    //     if (order) {
    //         const k = order.diraction ? 1 : -1;
    //         items.sort((a, b) => {
    //             let result = 0
    //             if (a[order.type] === null) {
    //                 a[order.type] = '';
    //             }
    //             if (b[order.type] === null) {
    //                 b[order.type] = '';
    //             }
    //             if (a[order.type] < b[order.type]) {
    //                 result = -1;
    //             }
    //
    //             if (a[order.type] > b[order.type]) {
    //                 result = 1;
    //             }
    //             return result * k;
    //         });
    //     }
    // };

    sortResults = (items) => {
        const {order} = this.state;
        if (order) {
            const k = order.diraction ? 1 : -1;
            if(order.type instanceof Function){
                 items.sort((a, b) => {
                     return order.type(a, b) * k;
                 });
            }
            else {
	            items.sort((a, b) => {
		            let result = 0
		            if (a[order.type] === null) {
			            a[order.type] = '';
		            }
		            if (b[order.type] === null) {
			            b[order.type] = '';
		            }
		            if (a[order.type] < b[order.type]) {
			            result = -1;
		            }

		            if (a[order.type] > b[order.type]) {
			            result = 1;
		            }
		            return result * k;
	            });
            }
        }
    };

    search = (value) => {
        this.setState({
            searchValue: value,
            page : 1
        });
    };

    formValidation = (objectForSending, requireFields) => {
        let counter = 0;
        let arr = [];
        requireFields.forEach(item => {
            if((objectForSending[item] || objectForSending[item]===0) && (objectForSending[item].length || (typeof objectForSending[item] && (new Date(objectForSending[item]))))) {
                counter++;
            } else {
                if(arr.indexOf(item) === -1) {
                    arr.push(item);
                    this.setState({
                        errors: arr
                    })
                }
            }
            if(counter === requireFields.length) {
                this.setState({
                    errors: []
                });
                this.props.action(objectForSending);
            }
        });
    };

    setCurrentIndex = (id, popupName) => {
        this.setState({
            currentItemIndex : id,
            popupType : 'edit',
            popupName: popupName
        });
    };

    orderPanel = () => {
        let fields = [];
        if (this.orderFields) {
            for (let label in this.orderFields) {
                fields.push((
                    <React.Fragment>
                        <th>
                            <a className="sort-items" onClick={() => {
                                this.setOrder(this.orderFields[label])
                            }}><h4>{label}</h4>{this.diractionSymbol(this.orderFields[label])}</a>
                        </th>
                        {Object.keys(this.orderFields).indexOf(label) !== Object.keys(this.orderFields).length - 1 ?
                            <ColumnResizer className="columnResizer" minWidth={5}/> : ''}
                    </React.Fragment>
                ))
            }
        }
        if (fields.length) {
            return (
                <tr className={'sortable-btn'}>
                    <th></th>
                    {fields}
                </tr>
            )
        }
        return null;
    };


    render() {
        return null;
    }

}

export default CommonTab;