import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import {createBrowserHistory} from 'history'
import Storege from './components/storage/storage'
import {Provider} from 'react-redux'
import Content from "./components/main/content";


const history = createBrowserHistory();

console.log(history,'history');
ReactDOM.render(
    (<Provider store={Storege}>
        <Router history={history}>
            <Content/>
        </Router>
    </Provider>),
    document.getElementById('app_red')
);
