import React from 'react';
import {connect} from 'react-redux';
import {ActionSingleInitiator} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";

class SingleInitiatorComponent extends React.Component {
    state = {
        initiator: {}
    }

    constructor(props) {
        super(props);
        this.state.initiator = props.initiator;
    }

    changeState = (name, e) => {
        let temp = this.props.initiator;
        temp[name] = e.value;
        this.setState({
            initiator: Object.assign({}, temp)
        });
    };

    render() {
        let initiatorId = this.props.id
        let initiator = this.props.initiator;
        if (initiator.length == 0 || (initiatorId != initiator.initiatorid)) {
            ActionSingleInitiator(initiatorId);
        }
        return (
            <div>
                <h3>Selected Initiator:</h3>
                <table className='table table-striped'>
                    <thead>
                    <tr>
                        <th>Initiator Name</th>
                        <th></th>
                        <th>Initiator Name Eng</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'initiator-name'}
                                       value={initiator.initiatorname}
                                       onChange={(e) => {
                                           this.changeState('initiatorname', e.target);
                                       }}
                                />
                            </td>
                            <ColumnResizer className="columnResizer" minWidth={5} />
                            <td>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'initiator-nameeng'}
                                       value={initiator.initiatornameeng}
                                       onChange={(e) => {
                                           this.changeState('initiatornameeng', e.target);
                                       }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        initiator: state.singleInitiator
    }
};
const SingleInitiator = connect(mapStateToProps)(SingleInitiatorComponent);

export default SingleInitiator;