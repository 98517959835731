import React from 'react';
import api from "../../api";
import Storege from "../../storage/storage";

const DescriptorsTypesRequest = (route) => {
    fetch(api.ajaxUrl + route, {
        method: "POST",
        headers: {
            "Content-type": "application/json",
            "X-CSRF-TOKEN": api.token
        }
    }).then((response) => response.json()).then(function (data) {
        console.log(data);
        Storege.dispatch({
            type: 'SET DESCRIPTORS PLACE TYPES',
            items: data.contentTypes
        });
        Storege.dispatch({
            type: 'SET PLACES',
            items: data.places
        });
    });
};

export default DescriptorsTypesRequest;