import React from 'react';
import {connect} from 'react-redux';
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class LandusAddComponent extends CommonTab{
    state = {
        popupItem: this.props.popupItem,
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        errors: [],
        validateFields: [
            'landusename',
            'landusenameeng'
        ]
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        const {popupItem, validateFields, errors} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('landusedescription' in popupItem)) {
                            popupItem['landusedescription'] = ''
                        }
                        this.formValidation(popupItem, validateFields);
                    }}
                >
                    <h3>{this.props.button + (this.props.button === 'Add' ? ' new' : '')} Landuse</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Landus Name:</td>
                            <td className={errors.indexOf('landusename') !== -1 ? 'error' : ''}>
                                <input
                                    className={'form-control'}
                                    type={'text'}
                                    value={popupItem.landusename ? popupItem.landusename : ''}
                                    onChange={(e) => {
                                        this.changeState('landusename', e.target);
                                    }}
                                />
                                {errors.indexOf('landusename') !== -1 ? <span className="errorText">Please set Landuse Name</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>Landus Name Eng:</td>
                            <td className={errors.indexOf('landusenameeng') !== -1 ? 'error' : ''}>
                                <input
                                    className={'form-control'}
                                    type={'text'}
                                    value={popupItem.landusenameeng ? popupItem.landusenameeng : ''}
                                    onChange={(e) => {
                                        this.changeState('landusenameeng', e.target);
                                    }}
                                />
                                {errors.indexOf('landusenameeng') !== -1 ? <span className="errorText">Please set Landuse Name Eng</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>Landuse Description:</td>
                            <td>
                                <input
                                    className={'form-control'}
                                    type={'text'} value={popupItem.landusedescription ? popupItem.landusedescription : ''}
                                    onChange={(e) => {
                                        this.changeState('landusedescription', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let popupItem = {};
    state.landuses.allLanduses.forEach(function (item) {
        if (item.landuseid == ownProps.id) {
            console.log(item);
            popupItem = item;
        }
    });

    return {
        popupItem: Object.assign({}, popupItem)
    }
};

const LandusAdd = connect(mapStateToProps)(LandusAddComponent);
export default LandusAdd;
