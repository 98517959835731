import React from 'react';
import api from "../../api";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Pagination from "../../parts/Pagination";
import GeneralRequest from "./GeneralRequest";
import {ActionGeneralCategoryAdd, ActionGeneralCategoryDelete, ActionGeneralCategoryEdit} from "../../parts/actions";
import ToolButtons from "../../parts/ToolButtons";
import Storege from "../../storage/storage";
import GeneralDataAdd from "./general_data_add";
import RemoveButton from "../../parts/removeBtn";
import Search from "../../parts/Search";
import getFullDateYear, {doFilter} from "../../parts/functions";
import CommonList from "../../parts/CommonListClass";
import EditBtn from "../../parts/EditBtn";

const perPega = 25;

class GeneralDataListComponent extends CommonList {

    orderFields = {
        "Name": 'generalcategoryname',
        "Updated Date": 'updated_at',
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            // page: this.getCurrentPage(),
            items: nextProps.items,
        });
    }

    addContentType = (object) => {
        ActionGeneralCategoryAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD GENERAL CATEGORY',
                item: data
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionGeneralCategoryEdit(object.generalcategoryid, object).then(data => {
            Storege.dispatch({
                type: 'EDIT GENERAL CATEGORY',
                item: data,
                id: data.generalcategoryid
            });
            this.changePopupState();
        });
    };

    render() {
        if (!this.props.items.length) {
            GeneralRequest('general');
        }
        const {valueRegionsFilter, valuePlaceTypesFilter, searchValue, items} = this.state;
        let outPutPlaces = items;
        outPutPlaces = doFilter(
            outPutPlaces,
            [
                {
                    name: 'valueRegionsFilter',
                    value: valueRegionsFilter,
                    searchId: 'regionid'
                },
                {
                    name: 'valuePlaceTypesFilter',
                    value: valuePlaceTypesFilter,
                    searchId: 'placetypeid'
                },
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'generalcategoryname'
                    ]
                }
            ]
        );

        this.sortResults(outPutPlaces);
        let maxPage = Math.ceil(items.length / perPega);
        outPutPlaces = outPutPlaces.slice((this.state.page - 1) * perPega, (this.state.page) * perPega);

        let pages = [];
        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }
        if (!items) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div className={'tool-bar'}>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                    <Search
                        change={this.search}
                    />
                </div>
                <ul className={'list-group'}>
                    {this.orderPanel()}
                    {outPutPlaces.map((item, index) => {
                        return (
                            <li key={index}>
                                <RemoveButton
                                    action={ActionGeneralCategoryDelete}
                                    id={item.generalcategoryid}
                                    descriptor={'REMOVE GENERAL DATA CATEGORY'}
                                />
                                <EditBtn
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    change={this.setCurrentIndex}
                                    id={item.generalcategoryid}
                                />

                                <Link
                                    to={api.getRoute('general/category/') + item.generalcategoryid} onClick={
                                    () => {
                                        this.setState({
                                            rowIndex: index,
                                            currentItemIndex: item.generalcategoryid
                                        });
                                    }
                                } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                    <div className={'col-sm-6'}>{item.generalcategoryname}</div>
                                    <div className={'col-sm-6'}>{item.updated_at ? getFullDateYear(item.updated_at) : '1/1/1900'}</div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>

                <Pagination currentPage={this.state.page} pages={pages} click={this.paginationChange} paginationCount={5}/>
                {this.state.popup ? <GeneralDataAdd
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        items: state.generalData
    }
};

const GeneralDataList = connect(mapStateToProps)(GeneralDataListComponent);
export default GeneralDataList;
