import React from 'react';
import {connect} from "react-redux";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class PlanPhasesCategoryPopupComponent extends CommonTab {
    state = {
        phaseCategory: this.props.phaseCategory,
        errors: [],
        validateFields: [
            'planningphasecategoryname',
            'planningphasecategorynameeng'
        ]
    };

    constructor(props) {
        super(props);
    }

    changeState(name, e) {
        let temp = this.state.phaseCategory;
        temp[name] = e.value;
        this.setState({phaseCategory: temp});
    }

    render() {
        let {phaseCategory, errors, validateFields} = this.state;
        return (
            <Popup openClose={this.props.openClose}>
                <div className={'popup-content-types'}>
                    <form onSubmit={event => {
                        event.preventDefault();
                        this.formValidation(phaseCategory, validateFields);
                    }}>
                        <h2>{this.props.button} Planning Phase Category</h2>
                        <table className={'table table-striped'} align={'right'}>
                            <tbody>
                            <tr>
                                <td>Planning Phase Category Name :</td>
                                <td className={errors.indexOf('planningphasecategoryname') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={phaseCategory.planningphasecategoryname}
                                        onChange={(e) => {
                                            this.changeState('planningphasecategoryname', e.target);
                                        }}
                                    />
                                    {errors.indexOf('planningphasecategoryname') !== -1 ? <span className="errorText">Please set Phase Category Name</span> : '' }
                                </td>
                            </tr>
                            <tr>
                                <td>Planning Phase Category Name Eng:</td>
                                <td className={errors.indexOf('planningphasecategorynameeng') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={phaseCategory.planningphasecategorynameeng}
                                        onChange={(e) => {
                                            this.changeState('planningphasecategorynameeng', e.target);
                                        }}
                                    />
                                    {errors.indexOf('planningphasecategorynameeng') !== -1 ? <span className="errorText">Please set Phase Category Name Eng</span> : '' }
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </form>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let content = {};
    state.planningPhasesCategory.map((item) => {
        if (item.planningphasecategoryid == ownProps.id) {
            content = item;
        }
    });
    return {
        phaseCategory: Object.assign({}, content)
    }
};

const PlanPhasesCategoryPopup = connect(mapStateToProps)(PlanPhasesCategoryPopupComponent);
export default PlanPhasesCategoryPopup;