import * as React from 'react';
import {connect} from 'react-redux';
import CommonTab from "../../../../parts/CommonTabClass";
import ToolButtons from "../../../../parts/ToolButtons";
import RemoveButton from "../../../../parts/removeBtn";
import {
	ActionDescriptorsTenderPublicationEvents,
	ActionTenderPublicationEventDelete,
	ActionTenderPublicationEventAdd,
	ActionTenderPublicationEventEdit,
	ActionTenderPublications
} from "../../../../parts/actions";
import EditBtn from "../../../../parts/EditBtn";
import ColumnResizer from "react-column-resizer";
import AddEvent from './addEvent';
import getFullDateYear, {getItemData} from "../../../../parts/functions";

class EventsComponent extends CommonTab {

	orderFields = {
        "Plan Publication Event Type": 'tenderpublicationeventtypeid',
        "Event Date": 'eventdate'
    };


	addContentType = (object) => {
        ActionTenderPublicationEventAdd('', object).then(data => {
            this.changePopupState();
            ActionTenderPublications(this.props.tenderid);
        });
    };

    editContentType = (object) => {
        ActionTenderPublicationEventEdit(object.tenderpublicationeventdetailid, object).then(data => {
            this.changePopupState();
             ActionTenderPublications(this.props.tenderid);
        });
    };

    DeleteContentType = (id,descriptor) => {
        ActionTenderPublicationEventDelete(id,descriptor).then(()=>{
        	ActionTenderPublications(this.props.tenderid);
        });
    };


	render() {
		const {items,tenderPublicationEventsType,publication} = this.props
		 if (!tenderPublicationEventsType.length) {
            ActionDescriptorsTenderPublicationEvents();
        }

		this.sortResults(items);
		return (
			<div>
                <div className={'custom-tool-bar'}>
                    <h3>Event Details</h3>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <div className="scrolled ">
                    <table className={"table table-striped"} valign={"middle"}>
                        <thead>
                        {this.orderPanel()}
                        </thead>
	                    <tbody>
	                    {items.map((item, index) => {
                            return (
                                <tr key={index}  className={'list-group-item'}>
                                    <td  className={'flex-row'}>
                                        <RemoveButton
                                            descriptor={'REMOVE PLAN PUBLICATION'}
                                            action={this.DeleteContentType}
                                            id={item.tenderpublicationeventdetailid}
                                        />
                                        <EditBtn
                                            actionType={this.changePopupType}
                                            popupChange={this.changePopupState}
                                            change={this.setCurrentIndex}
                                            id={item.tenderpublicationeventdetailid}
                                        />
                                    </td>
                                    <td>
	                                    {getItemData(tenderPublicationEventsType ? tenderPublicationEventsType : [], 'tenderpublicationeventtypeid', item.tenderpublicationeventtypeid, 'tenderpublicationeventtypename')}
	                                    &nbsp;/&nbsp;
	                                    {getItemData(tenderPublicationEventsType ? tenderPublicationEventsType : [], 'tenderpublicationeventtypeid', item.tenderpublicationeventtypeid, 'tenderpublicationeventtypenameeng')}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
	                                    {item.eventdate ? getFullDateYear(item.eventdate) : ''}
                                    </td>
                                </tr>
                            )
                        })}
	                    </tbody>
                    </table>
                </div>
				{this.state.popup ? <AddEvent
                    close={this.changePopupState}
                    itemList={items}
                    publication = {publication}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
			</div>
		)
	}
}

const mapStateToProps = (state , ownProps) => {
    return {
        tenderPublicationEventsType: state.tenderPublicationEvents,
	    items : ownProps.items ? ownProps.items : []
    }
};
const Events = connect(mapStateToProps)(EventsComponent);

export default Events;