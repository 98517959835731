import React from 'react';
import {connect} from "react-redux";
import SelectObject from "../../../parts/SelectObject";
import {ActionPlanEdit} from "../../../parts/actions";
import Storege from "../../../storage/storage";

class PlanPopupComponent extends React.Component {
    state = {
        data: {}
    }

    constructor(props) {
        super(props);
        this.state.data = props.data
    }

    changePlanName = (name, e) => {
        let temp = this.props.data;
        temp[name] = e.value;
        this.setState({
            data: Object.assign({}, temp)
        });
    }

    changeSelect = (name, e) => {
        let temp = this.props.data;
        temp[name] = +e.value;
        this.setState({
            data: Object.assign({}, temp)
        });
    }

    editContentType = (object) => {
        ActionPlanEdit(object.planid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLAN',
                item: data,
                id: data.planid
            });
            this.changePopupState();
        });
    };

    render() {
        let {data,  plansPlaces, initiator, planTypes, ownerShipTypes} = this.props;
        let places = [];
        for (let key in plansPlaces) {
            places.push({id: +key, place: plansPlaces[key]});
        }
        if (!this.props.active) {
            return (
                <div></div>
            )
        }

        return (
            <div className="plan-popup modal fade" id="plan-popup">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{data.planname}</h5>
                            {/*<button type="button" className="close" data-dismiss="modal" aria-label="Close">*/}
                            {/*<span aria-hidden="true">&times;</span>*/}
                            {/*</button>*/}
                        </div>
                        <div className="modal-body">
                            <form onSubmit={
                                (event) => {
                                    event.preventDefault();
                                    this.editContentType(data);
                                }
                            }>
                                <label htmlFor="tender-name">Plan Name</label>
                                <input
                                    type="text"
                                    value={data.planname}
                                    name='plan-name'
                                    onChange={(e) => {
                                        this.changePlanName('planname', e.target)}
                                    }
                                    className='form-control'
                                />
                                <label htmlFor="tender-type">Place</label>
                                <select
                                    className='form-control'
                                    name={'place'}
                                    value={data.placeid}
                                    onChange={(e) => {
                                        this.changeSelect('placeid', e.target);
                                    }}
                                    id=''>
                                    <option value="-1"> </option>
                                    {places.map(function (item, index) {
                                        return (
                                            <option key={index} value={item.id}>{item.place}</option>
                                        )
                                    })}
                                </select>
                                <label htmlFor="initiator">Initiator</label>
                                <select
                                    className='form-control'
                                    name={'initiator'}
                                    value={data.initiatorid}
                                    onChange={(e) => {
                                        this.changeSelect('initiatorid', e.target);
                                    }}
                                    id=''>
                                    <option value="-1"> </option>
                                    {initiator.map(function (item, index) {
                                        return (
                                            <option key={index} value={index}>{item.initiatorname}</option>
                                        )
                                    })}
                                </select>
                                <label htmlFor="submitter">Submitter</label>
                                <select
                                    className='form-control'
                                    name={'submitter'}
                                    value={data.submitterid}
                                    onChange={(e) => {
                                        this.changeSelect('submitterid', e.target);
                                    }}
                                    id=''>
                                    <option value="-1"> </option>
                                    {initiator.map(function (item, index) {
                                        return (
                                            <option key={index} value={index}>{item.initiatorname}</option>
                                        )
                                    })}
                                </select>
                                <label htmlFor="ownershiptype">Ownership Type</label>
                                <select
                                    className='form-control'
                                    name={'ownershiptype'}
                                    value={data.ownershiptypeid}
                                    onChange={(e) => {
                                        this.changeSelect('ownershiptypeid', e.target);
                                    }}
                                    id=''>
                                    <option value="-1"> </option>
                                    {ownerShipTypes.map(function (item, index) {
                                        return (
                                            <option key={index} value={item.ownershiptypeid}>{item.ownershiptypename}</option>
                                        )
                                    })}
                                </select>
                                <label htmlFor="ownershipdescription">Ownership Description</label>
                                <input
                                    type="text"
                                    value={data.ownershipdescription}
                                    name='ownershipdescription'
                                    onChange={(e) => {
                                        this.changePlanName('ownershipdescription', e.target)}
                                    }
                                    className='form-control'
                                />
                                <label htmlFor="architect">Architect Name</label>
                                <input
                                    type="text"
                                    value={data.architectname}
                                    name='architect-name'
                                    onChange={(e) => {
                                        this.changePlanName('architectname', e.target)}
                                    }
                                    className='form-control'
                                />
                                <label htmlFor="address">Address</label>
                                <input
                                    type="text"
                                    value={data.address}
                                    name='address'
                                    onChange={(e) => {
                                        this.changePlanName('address', e.target)}
                                    }
                                    className='form-control'
                                />
                                <label htmlFor="objective">Objective</label>
                                <input
                                    type="text"
                                    value={data.objective}
                                    name='address'
                                    onChange={(e) => {
                                        this.changePlanName('objective', e.target)}
                                    }
                                    className='form-control'
                                />
                                <label htmlFor="mawqa">Mawqa</label>
                                <input
                                    type="text"
                                    value={data.mawqa}
                                    name='mawqa'
                                    onChange={(e) => {
                                        this.changePlanName('mawqa', e.target)}
                                    }
                                    className='form-control'
                                />
                                <label htmlFor="remarks">Remarks</label>
                                <input
                                    type="text"
                                    value={data.remarks}
                                    name='remarks'
                                    onChange={(e) => {
                                        this.changePlanName('remarks', e.target)}
                                    }
                                    className='form-control'
                                />
                                <label htmlFor="plantype">Plan Type</label>
                                <select
                                    className='form-control'
                                    name={'plantype'}
                                    value={(data)}
                                    onChange={(e) => {
                                        this.changeSelect('plantypeid', e.target);
                                    }}
                                    id=''>
                                    <option value="-1"> </option>
                                    {planTypes.map(function (item, index) {
                                        return (
                                            <option key={index} value={item.plantypeid}>{item.plantypename}</option>
                                        )
                                    })}
                                </select>

                                <label htmlFor="updatedate">Update Date</label>
                                <input
                                    type="date"
                                    value={data.updatedate}
                                    name='updatedate'
                                    onChange={(e) => {
                                        this.changePlanName('pdatedate', e.target)}
                                    }
                                    className='form-control'
                                />
                                <input type={"submit"} className={"btn btn-primary popup-submit"} value={'Save changes'}/>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



const mapStateToProps = (state, ownProps) => {
    return {
        plansPlaces: state.plansPlaces,
        initiator: state.initiator,
        planTypes: state.planType,
        ownerShipTypes: state.ownerShipType
    }
};

const PlanPopup = connect(mapStateToProps)(PlanPopupComponent);

export default PlanPopup;