import React from 'react';
import {ActionSetICBS} from "../actions";
import ColumnResizer from "react-column-resizer";

class ICBS extends React.Component {
    render() {
        const {place} = this.props;
        if (!place.ICBS) {
            ActionSetICBS(place.placeid);
        }
        let icbsDetails = place.ICBS ? place.ICBS : {};
        let settlement = icbsDetails.settlement ? icbsDetails.settlement : {};
        let municipality = icbsDetails.municipality ? icbsDetails.municipality : {};
        let district = icbsDetails.district ? icbsDetails.district : {};
        let settlementsHeaders = [],
            settlementsValue = [];
        for (var key in settlement) {
            settlementsHeaders.push(key);
            settlementsValue.push(settlement[key]);
        }
        return (
            <div>
                <h3>ICBS Details</h3>
                <h4>ICBS Settelments</h4>
                <div className={'scrolled'}>
                    <table className={'table table-striped'}>
                        <thead>
                        <tr>
                            {settlementsHeaders.map((item, index) => {
                                return (
                                    <th key={index}>{item}</th>
                                )
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            {settlementsValue.map((item, index) => {
                                return (
                                    <td key={index}>{item}</td>
                                )
                            })}
                        </tr>
                        </tbody>
                    </table>
                </div>
                <h4>ICBS Municipalities</h4>
                <table className={'table table-striped'}>
                    <thead>
                    <tr>
                        <th>Municipality Id</th>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <th>Municipality Name</th>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <th>Municipality Location</th>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <th>Sub District Id</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{municipality.municipalityid}</td>
                            <ColumnResizer className="columnResizer" minWidth={5}/>
                            <td>{municipality.municipalityname}</td>
                            <ColumnResizer className="columnResizer" minWidth={5}/>
                            <td>{municipality.municipalitylocation}</td>
                            <ColumnResizer className="columnResizer" minWidth={5}/>
                            <td>{municipality.subdistrictid}</td>
                        </tr>
                    </tbody>
                </table>

                <h4>ICBS Districts</h4>
                <table className={'table table-striped'}>
                    <thead>
                    <tr>
                        <th>District Id</th>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <th>District Name</th>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <th>District Code</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{district.districtid}</td>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <td>{district.districtname}</td>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <td>{district.districtcode}</td>
                    </tr>
                    </tbody>
                </table>

            </div>
        )
    }
}

export default ICBS;