import React from 'react';
import {connect} from 'react-redux';
import Popup from "../Popup";
import CommonTab from "../CommonTabClass";

class SynonymAddComponent extends CommonTab {

    state = {
        popupItem: {
            placeid: this.props.placeid
        },
        errors: [],
        validateFields: [
            'name',
            'nameeng'
        ]
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        const {popupItem, validateFields, errors} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.formValidation(popupItem, validateFields);
                    }}
                >
                    <h3>{this.props.button} Place Synonym</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>
                                Name:
                            </td>
                            <td className={errors.indexOf('name') !== -1 ? 'error' : ''}>
                                <input
                                    className={"form-control"}
                                    name={"name"}
                                    id={"name"}
                                    value={popupItem.name ? popupItem.name : ''}
                                    onChange={(e) => {
                                        this.changeState('name', e.target);
                                    }}
                                />
                                {errors.indexOf('name') !== -1 ?
                                    <span className="errorText">Please set Name</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Name eng:
                            </td>
                            <td className={errors.indexOf('nameeng') !== -1 ? 'error' : ''}>
                                <input type={"text"}
                                       className={"form-control"}
                                       name={"nameeng"}
                                       id={"nameeng"}
                                       value={popupItem.nameeng ? popupItem.nameeng : ''}
                                       onChange={(e) => {
                                           this.changeState('nameeng', e.target);
                                       }}
                                />
                                {errors.indexOf('nameeng') !== -1 ?
                                    <span className="errorText">Please set Name Eng</span> : ''}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item.placesynonymid == ownProps.id) {
            popupItem = item;
        }
    });

    return {
        popupItem: Object.assign({}, popupItem),
    }
};

const SynonymAdd = connect(mapStateToProps)(SynonymAddComponent);
export default SynonymAdd;
