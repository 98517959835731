import React from 'react';
import {connect} from "react-redux";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class TrackingTypePopupComponent extends CommonTab {
    state = {
        trackingType: {...this.props.trackingType, isrelativevalue: this.props.trackingType.isrelativevalue ? this.props.trackingType.isrelativevalue : false},
        errors: [],
        validateFields: [
            'trackingtypename',
            'trackingtypenameeng'
        ]
    };

    constructor(props) {
        super(props);
        // this.state = Object.assign({}, this.state, {trackingType: this.props.trackingType});
    }

    changeState(name, e) {
        let temp = this.state.trackingType;
        temp[name] = e.value;
        this.setState({trackingType: temp});
    }


    changeStateCheckbox(name, e) {
        let temp = this.state.trackingType;
        temp[name] = !e.value;
        this.setState({trackingType: temp});
    }

    render() {
        let {trackingType, validateFields, errors} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <div className={'popup-content-types'}>
                    <form onSubmit={event => {
                        event.preventDefault();
                        this.formValidation(trackingType, validateFields);
                        // this.props.action(trackingType);
                    }}>

                        <h2>{this.props.button + (this.props.button === 'Add' ? ' New' : '')} Tracking Type</h2>
                        <table className={'table table-striped'} align={'right'}>
                            <tbody>
                            <tr>
                                <td>Place Tracking Type Name :</td>
                                <td className={errors.indexOf('trackingtypename') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={trackingType.trackingtypename}
                                        onChange={(e) => {
                                            this.changeState('trackingtypename', e.target);
                                        }}
                                    />
                                    {errors.indexOf('trackingtypename') !== -1 ?
                                        <span className="errorText">Please set Tracking Type Name</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Place Tracking Type Name Eng:</td>
                                <td className={errors.indexOf('trackingtypenameeng') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={trackingType.trackingtypenameeng}
                                        onChange={(e) => {
                                            this.changeState('trackingtypenameeng', e.target);
                                        }}
                                    />
                                    {errors.indexOf('trackingtypenameeng') !== -1 ?
                                        <span className="errorText">Please set Tracking Type Name Eng</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Is Relative Value:</td>
                                <td>
                                    <input
                                        type={'checkbox'}
                                        value={1}
                                        checked={trackingType.isrelativevalue}
                                        onChange={(e) => {
                                            this.changeStateCheckbox('isrelativevalue', {value : !e.target.checked});
                                        }}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </form>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let content = {};
    state.trackingTypes.map((item) => {
        if (item.trackingtypeid == ownProps.id) {
            content = item;
        }
    });
    return {
        trackingType: Object.assign({}, content)
    }
};

const TrackingTypePopup = connect(mapStateToProps)(TrackingTypePopupComponent);

export default TrackingTypePopup;