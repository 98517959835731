import React from 'react';
import {connect} from 'react-redux';
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class SourceCategoryAddComponent extends CommonTab {
    state = {
        popupItem: this.props.popupItem,
        errors: [],
        validateFields: [
            'sourcecategoryname',
            'sourcecategorynameeng'
        ]
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    render() {
        const {popupItem, errors, validateFields} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.formValidation(popupItem, validateFields);
                    }}
                >
                    <h3>{this.props.button} Source Category</h3>
                    <table className={'table table-striped'}>
                        <tbody>
                        <tr>
                            <td>Source Category Name:</td>
                            <td className={errors.indexOf('sourcecategoryname') !== -1 ? 'error' : ''}>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'source-name'}
                                       value={popupItem.sourcecategoryname}
                                       onChange={(e) => {
                                           this.changeState('sourcecategoryname', e.target);
                                       }}
                                />
                                {errors.indexOf('sourcecategoryname') !== -1 ? <span className="errorText">Please set Source Category Name</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>Source Category Name Eng:</td>
                            <td className={errors.indexOf('sourcecategorynameeng') !== -1 ? 'error' : ''}>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'source-nameeng'}
                                       value={popupItem.sourcecategorynameeng}
                                       onChange={(e) => {
                                           this.changeState('sourcecategorynameeng', e.target);
                                       }}
                                />
                                {errors.indexOf('sourcecategorynameeng') !== -1 ? <span className="errorText">Please set Source Category Name Eng</span> : '' }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let newItem = {};
    (state.sources.sourceCategories ? state.sources.sourceCategories : []).forEach(item => {
        if (item.sourcecategoryid === ownProps.id) {
            newItem = item
        }
    });
    return {
        popupItem: Object.assign({}, newItem),
    }
};

const SourceCategoryAdd = connect(mapStateToProps)(SourceCategoryAddComponent);
export default SourceCategoryAdd;


