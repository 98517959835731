import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {
    ActionDescriptorsTracking,
    ActionTrackingTypesAdd,
    ActionTrackingTypesDelete,
    ActionTrackingTypesEdit
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import ToolButtons from "../../../parts/ToolButtons";
import Search from "../../../parts/Search";
import Storege from "../../../storage/storage";
import api from "../../../api";
import TrackingTypePopup from "./TrackingTypePopup";
import RemoveButton from "../../../parts/removeBtn";
import CommonTab from "../../../parts/CommonTabClass";
import EditBtn from "../../../parts/EditBtn";
import {doFilter} from "../../../parts/functions";

class TrackingDescriptorComponent extends CommonTab {

    orderFields = {
        "Tracking Type Id": "trackingtypeid",
        "Place Population Type Name": "trackingtypename",
        "Place Population Type Name Eng": "trackingtypenameeng",
        "Is Relative Value": "isrelativevalue"
    };

    state = {
        tracking: {},
        popup: false,
        popupType: undefined,
        order: null,
        searchValue: '',
        currentItemIndex: -1,
        rowIndex: -1
    };

    constructor(props) {
        super(props);
        this.state.tracking = props.tracking;
    }

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    getId(pathName) {
        let path = (pathName).split('/');
        let id = path[path.length - 1];
        return id;
    }

    addContentType = (object) => {
        ActionTrackingTypesAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLACE TRACKING TYPE',
                item: data
            });
            this.changePopupState();
            this.props.history.push('tracking_types/' + data.trackingtypeid);
        });
    };

    editContentType = (object) => {
        ActionTrackingTypesEdit(object.trackingtypeid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLACE TRACKING TYPE',
                item: data,
                id: data.trackingtypeid
            });
            this.changePopupState();
            this.props.history.push(data.trackingtypeid);
        });
    };


    changeState = (name, e, id) => {
        let temp = this.props.tracking;
        temp.forEach(function (item) {
            if (item.trackingtypeid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            tracking: Object.assign({}, temp)
        });
    };

    changeCheck = (name, e, id) => {
        let temp = this.props.tracking;
        temp.forEach(function (item) {
            if (item.trackingtypeid == id) {
                item[name] = e.checked;
            }
        });
        this.setState({
            tracking: Object.assign({}, temp)
        });
    };

    render() {
        if (!this.props.tracking.length) {
            ActionDescriptorsTracking();
        }
        let {tracking} = this.props;
        let {searchValue} = this.state;
        if (!tracking) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        tracking = doFilter(tracking, [
            {
                name: 'searchValue',
                value: searchValue,
                searchId: [
                    'trackingtypename',
                    'trackingtypenameeng'
                ]
            }
        ]);

        this.sortResults(tracking);
        return (
            <div>
                <div className="inline-toolbuttons">
                    <h3>Tracking Types:</h3>
                    <div className={'tool-bar'}>
                        <ToolButtons
                            actionType={this.changePopupType}
                            popupChange={this.changePopupState}
                            redirect={this.redirectFunction}
                            url={this.props.location.pathname}
                        />
                        <Search
                            change={this.search}
                        />
                    </div>
                </div>
                <table className='table table-striped'>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {tracking.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                () => {
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.trackingtypeid
                                    });
                                }
                            } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionTrackingTypesDelete}
                                        id={item.trackingtypeid}
                                        descriptor={'REMOVE PLACE TRACKING TYPE'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.trackingtypeid}
                                    />
                                </td>
                                <td>
                                    <span>{item.trackingtypeid}</span>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    <Link
                                        to={api.getRoute('descriptors/place_types/tracking_types/' + item.trackingtypeid)}>
                                        {item.trackingtypename}
                                    </Link>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    <Link
                                        to={api.getRoute('descriptors/place_types/tracking_types/' + item.trackingtypeid)}>
                                        {item.trackingtypenameeng}</Link>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    <input type="checkbox"
                                           className={'form-control'}
                                           name={'tracking-check'}
                                           disabled={'true'}
                                           checked={item.isrelativevalue}
                                           onChange={(e) => {
                                               this.changeCheck('isrelativevalue', e.target, item.trackingtypeid);
                                           }}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <TrackingTypePopup
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        tracking: state.trackingTypes
    }
};
const DescriptorTracking = connect(mapStateToProps)(TrackingDescriptorComponent);

export default DescriptorTracking;