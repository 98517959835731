import React from 'react';
import {connect} from 'react-redux';
import SelectObject from "../../parts/SelectObject";
import Select from "../../parts/select";
import Popup from "../../parts/Popup";
import CommonTab from "../../parts/CommonTabClass";


class PlanAddComponent extends CommonTab {
    state = {
        plan: {},
        errors: [],
        validateFields: [
            'planname',
            'placeid'
        ]
    };

    constructor(props) {
        super(props);
        this.state.plan = props.plan;
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if (oldProps.plan.planid !== newProps.plan.planid) {
            this.setState({plan: newProps.plan})
        }
    }

    placeChange = (name, e) => {
        let temp = this.props.plan;
        temp[name] = +e.value;
        this.setState({
            plan: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.props.plan;
        temp[name] = e.value;
        this.setState({
            plan: Object.assign({}, temp)
        });
    };
    relationChange = (name, arrayId, e, id) => {
        let temp = this.props.plan;
        temp.relationship[arrayId].map((item) => {
            if (item[name] == id) {
                item[name] = +e.value;
            }
        });
        this.setState({
            plan: Object.assign({}, temp)
        });
    };

    relationChangeInput = (name, arrayId, propertyName, e, id) => {
        let temp = this.props.plan;
        temp.relationship[arrayId].map((item) => {
            if (item[name] == id) {
                item[propertyName] = e.value;
            }
        });
        this.setState({
            plan: Object.assign({}, temp)
        });
    };

    render() {

        const {places, button} = this.props;
        let {plan, errors, validateFields} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <form onSubmit={event => {
                    event.preventDefault()
                    if (!('initiatorid' in plan)) {
                        plan['initiatorid'] = ''
                    }
                    if (!('submitterid' in plan)) {
                        plan['submitterid'] = ''
                    }
                    if (!('architectname' in plan)) {
                        plan['architectname'] = ''
                    }
                    if (!('objective' in plan)) {
                        plan['objective'] = ''
                    }
                    if (!('ownershiptypeid' in plan)) {
                        plan['ownershiptypeid'] = ''
                    }
                    if (!('ownershipdescription' in plan)) {
                        plan['ownershipdescription'] = ''
                    }
                    if (!('address' in plan)) {
                        plan['address'] = ''
                    }
                    if (!('mawqa' in plan)) {
                        plan['mawqa'] = ''
                    }
                    if (!('remarks' in plan)) {
                        plan['remarks'] = ''
                    }
                    if (!('plantypeid' in plan)) {
                        plan['plantypeid'] = ''
                    }
                    this.formValidation(plan, validateFields);
                    // this.props.action(this.state.plan);
                }}>
                    <h4>{button} plan</h4>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Plan Name:</td>
                            <td className={errors.indexOf('planname') !== -1 ? 'error' : ''}>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'plan_name'}
                                       value={plan.planname}
                                       onChange={(e) => {
                                           this.changeState('planname', e.target);
                                       }}
                                />
                                {errors.indexOf('planname') !== -1 ?
                                    <span className="errorText">Please set Plan Name</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Place:</td>

                            <td className={errors.indexOf('placeid') !== -1 ? 'error' : ''}>
                                <SelectObject

                                    name={'placeid'}
                                    value={plan.placeid}
                                    types={places}
                                    change={(e) => {
                                        this.placeChange('placeid', e.target);
                                    }}
                                />
                                {errors.indexOf('placeid') !== -1 ?
                                    <span className="errorText">Please set Place</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Initiator:</td>
                            <td>
                                <Select

                                    name={'initiatorid'}
                                    types={this.props.initiator}
                                    className={"form-control"}
                                    value={plan.initiatorid}
                                    optionValue={"initiatorid"}
                                    optionName={"initiatorname"}
                                    onChange={(e) => {
                                        this.placeChange('initiatorid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Submitter:</td>
                            <td>
                                <Select

                                    name={'submitterid'}
                                    types={this.props.initiator}
                                    className={"form-control"}
                                    value={plan.submitterid}
                                    optionValue={"initiatorid"}
                                    optionName={"initiatorname"}
                                    onChange={(e) => {
                                        this.placeChange('submitterid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Architect Name:</td>
                            <td>
                                <input
                                    type={'text'}
                                    className={'form-control'}
                                    name={'architectname'}
                                    value={plan.architectname ? plan.architectname : ''}
                                    onChange={(e) => {
                                        this.changeState('architectname', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Objective:</td>
                            <td>
                            <textarea
                                className={'form-control text-area'}
                                name={'objective'}
                                value={plan.objective ? plan.objective : ''}
                                onChange={(e) => {
                                    this.changeState('objective', e.target);
                                }}
                            > </textarea>
                            </td>
                        </tr>
                        <tr>
                            <td>Ownership Type:</td>
                            <td>
                                <Select

                                    name={'ownershiptypeid'}
                                    types={this.props.ownerShipType}
                                    className={"form-control"}
                                    value={plan.ownershiptypeid}
                                    optionValue={"ownershiptypeid"}
                                    optionName={"ownershiptypename"}
                                    onChange={(e) => {
                                        this.placeChange('ownershiptypeid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Ownership Description:</td>
                            <td>
                                <input
                                    type={'text'}
                                    className={'form-control'}
                                    name={'ownershipdescription'}
                                    value={plan.ownershipdescription ? plan.ownershipdescription : ''}
                                    onChange={(e) => {
                                        this.changeState('ownershipdescription', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Address:</td>
                            <td>
                            <textarea
                                className={'form-control text-area'}
                                name={'address'}
                                value={plan.address ? plan.address : ''}
                                onChange={(e) => {
                                    this.changeState('address', e.target);
                                }}
                            > </textarea>
                            </td>
                        </tr>
                        <tr>
                            <td>Mawqa:</td>
                            <td>
                            <textarea
                                className={'form-control text-area'}
                                name={'mawqa'}
                                value={plan.mawqa ? plan.mawqa : ''}
                                onChange={(e) => {
                                    this.changeState('mawqa', e.target);
                                }}
                            > </textarea>
                            </td>
                        </tr>
                        <tr>
                            <td>Remarks:</td>
                            <td>
                            <textarea
                                className={'form-control text-area'}
                                name={'remarks'}
                                value={plan.remarks ? plan.remarks : ''}
                                onChange={(e) => {
                                    this.changeState('remarks', e.target);
                                }}
                            > </textarea>
                            </td>
                        </tr>
                        <tr>
                            <td>Plan Type:</td>
                            <td>
                                <Select
                                 
                                    name={'plantypeid'}
                                    types={this.props.planType}
                                    className={"form-control"}
                                    value={plan.plantypeid}
                                    optionValue={"plantypeid"}
                                    optionName={"plantypename"}
                                    onChange={(e) => {
                                        this.placeChange('plantypeid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let plan = {};
    state.plans.forEach(function (item) {
        if (item.planid == ownProps.id) {
            plan = item;
        }
    });
    return {
        planType: state.planType,
        ownerShipType: state.ownerShipType,
        initiator: state.initiator,
        plan: Object.assign({}, plan),
        places: state.plansPlaces
    }
};

const PlanAdd = connect(mapStateToProps)(PlanAddComponent);

export default PlanAdd;