import React from 'react';
import {connect} from 'react-redux';
import {
    ActionPlanLanduseAdd, ActionPlanLanduseDelete, ActionPlanLanduseEdit,
    ActionPlanLanduses,
} from "../../../parts/actions";
import ToolButtons from "../../../parts/ToolButtons";
import Storege from "../../../storage/storage";
import PlanLandusAdd from "./PlanLandusAdd";
import RemoveButton from "../../../parts/removeBtn";
import {getItemData} from "../../../parts/functions";
import CommonTab from "../../../parts/CommonTabClass";
import EditBtn from "../../../parts/EditBtn";

class PlanLandusesComponent extends CommonTab {

    orderFields = {
        "Landuse": 'landuseid'
    };

    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            popupType: undefined,
            currentItemIndex: -1,
            rowIndex: -1,
            order: null
        }
    }

    addContentType = (object) => {
        ActionPlanLanduseAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLAN LANDUSE',
                item: data
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionPlanLanduseEdit(object.planlanduseid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLAN LANDUSE',
                item: data,
                id: data.planlanduseid
            });
            this.changePopupState();
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    render() {
        let {plan, allLanduses} = this.props;
        let landuses = plan.landuses ? plan.landuses : [];
        if (!plan.landuses) {
            ActionPlanLanduses(plan.planid);
        }
        if (!plan && !allLanduses) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        this.sortResults(landuses);
        return (
            <div>
                <div className={'inline-toolbuttons'}>
                    <h3>Plan Landuses</h3>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <table className={"table table-striped"} valign={"middle"}>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {landuses.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                () => {
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.planlanduseid
                                    });
                                }
                            } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionPlanLanduseDelete}
                                        id={item.planlanduseid}
                                        descriptor={'REMOVE PLAN LANDUSE'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.planlanduseid}
                                    />
                                </td>
                                <td>
                                    {getItemData(allLanduses, 'landuseid', item.landuseid, 'landusename')}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <PlanLandusAdd
                    planid={plan.planid}
                    close={this.changePopupState}
                    itemList={plan.landuses}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        allLanduses: state.allLanduses
    }
};

const PlanLanduses = connect(mapStateToProps)(PlanLandusesComponent);
export default PlanLanduses;