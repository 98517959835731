import React from 'react';
import {connect} from 'react-redux';
import {
    ActionDescRegionsAdd,
    ActionDescRegionsDelete,
    ActionDescRegionsEdit,
    ActionDescriptorsRegion
} from "../../../parts/actions";
import ToolButtons from "../../../parts/ToolButtons";
import Search from "../../../parts/Search";
import RegionsPopup from "./Regions popup";
import Storege from "../../../storage/storage";
import ColumnResizer from "react-column-resizer";
import RemoveButton from "../../../parts/removeBtn";
import CommonTab from "../../../parts/CommonTabClass";
import {doFilter} from "../../../parts/functions";
import EditBtn from "../../../parts/EditBtn";

class RegionsDescriptorComponent extends CommonTab {

    orderFields = {
        "Region Id": "regionid",
        "Region Name": "regionname",
        "Region Name Eng": "regionnameeng"
    };

    state = {
        regions: {},
        popup: false,
        popupType: undefined,
        order: null,
        searchValue: ''
    };

    constructor(props) {
        super(props);
        this.state.regions = props.regions;
    }

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    addContentType = (object) => {
        ActionDescRegionsAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD DESCRIPTOR REGION',
                item: data
            });
            this.changePopupState();
            this.props.history.push(data.regionid);
        });
    };

    editContentType = (object) => {
        ActionDescRegionsEdit(object.regionid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE DESCRIPTOR REGION',
                item: data,
                id: data.regionid
            });
            this.changePopupState();
            this.props.history.push(data.regionid);
        });
    };

    changeState = (name, e, id) => {
        let temp = this.props.regions;
        temp.forEach(function (item) {
            if (item.regionid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            regions: Object.assign({}, temp)
        });
    };

    // search = (value) => {
    //     if (value.length) {
    //         let filteredArray = this.props.placeTypes.filter(item => {
    //             return item.regionname.toLowerCase().indexOf(value.toLowerCase()) != -1 || item.regionnameeng.toLowerCase().indexOf(value.toLowerCase()) != -1;
    //         });
    //         this.setState({
    //             filtered: true,
    //             filteredItems: filteredArray
    //         });
    //     } else {
    //         this.setState({
    //             filtered: false
    //         });
    //     }
    // };


    render() {
        if (!this.props.regions.length) {
            ActionDescriptorsRegion();
        }
        let {regions} = this.props;
        let {searchValue} = this.state;
        let outPutPlaces = regions;
        outPutPlaces = doFilter(
            outPutPlaces,
            [
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'regionname',
                        'regionnameeng'
                    ]
                }
            ]
        );

        this.sortResults(outPutPlaces);
        if (!regions) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="inline-toolbuttons">
                    <h3>Regions:</h3>
                    <div className={'tool-bar'}>
                        <ToolButtons
                            actionType={this.changePopupType}
                            popupChange={this.changePopupState}
                            id={this.state.currentItemIndex}
                        />
                        <Search
                            change={this.search}
                        />
                    </div>
                </div>
                <table className='table table-striped'>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {outPutPlaces.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                e => {
                                    e.preventDefault();
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.regionid
                                    });
                                }
                            } className={this.state.rowIndex == index ? 'active' : ''}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionDescRegionsDelete}
                                        id={item.regionid}
                                        descriptor={'REMOVE DESCRIPTOR REGION'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.regionid}
                                    />
                                </td>
                                <td>
                                    <span>{item.regionid}</span>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.regionname}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.regionnameeng}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <RegionsPopup
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        regions: state.regions
    }
};
const DescriptorRegions = connect(mapStateToProps)(RegionsDescriptorComponent);

export default DescriptorRegions;