import React from 'react';
import {connect} from 'react-redux';
import SelectObject from "../SelectObject";
import DatePicker from "react-datepicker";
import CommonTab from "../CommonTabClass";
import Popup from "../Popup";

class StatusesAddComponent extends CommonTab {

    state = {
        status: {
            placeid: this.props.placeid
        },
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        errors: [],
        validateFields: [
            'statusdate',
            'statusid',
            // 'remarks'
        ]
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.status).length) {
            this.state = Object.assign({}, this.state, {status: this.props.status});
        }
    }

    placeChange = (value) => {
        let temp = this.state.status;
        temp.statusid = value.target.value;
        this.setState({
            status: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.state.status;
        temp[name] = e.value;
        this.setState({
            status: temp
        });
    };

    handleChange = (date) => {
        let temp = this.state.status;
        temp.statusdate = date;
        this.setState({status: temp});
    };

    render() {
        const {status, validateFields, errors} = this.state;
        if (!('statusdate' in status)) {
            status['statusdate'] = new Date();
        }
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('remarks' in status)) {
                            status['remarks'] = ''
                        }
                        if (!('statusdate' in status)) {
                            status['statusdate'] = new Date();
                        }
                        this.formValidation(status, validateFields);
                    }}
                >
                    <h3>{this.props.button} Place Status</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>
                                Status Date:
                            </td>
                            <td className={errors.indexOf('statusdate') !== -1 ? 'error' : ''}>
                                <DatePicker
                                    selected={status.statusdate ? new Date(status.statusdate) : ''}
                                    onChange={this.handleChange}
                                    //showTimeSelect
                                    timeFormat="HH:mm:s"
                                    timeIntervals={60}
                                    dateFormat="dd/MM/yyyy h:mm:s"
                                    timeCaption="time"
                                />
                                {errors.indexOf('statusdate') !== -1 ? <span className="errorText">Please set Date</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Status:
                            </td>
                            <td className={errors.indexOf('statusid') !== -1 ? 'error' : ''}>
                                <SelectObject
                                    name={'status_id'}
                                    value={status.statusid}
                                    types={this.props.statuses}
                                    change={this.placeChange}
                                />
                                {errors.indexOf('statusid') !== -1 ? <span className="errorText">Please set Status</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Remarks:
                            </td>
                            <td className={errors.indexOf('remarks') !== -1 ? 'error' : ''}>
                                <input type={"text"}
                                       className={"form-control"}
                                       name={"name"}
                                       id={"name"}
                                       value={status.remarks ? status.remarks : ''}
                                       onChange={(e) => {
                                           this.changeState('remarks', e.target);
                                       }}
                                />
                                {errors.indexOf('remarks') !== -1 ? <span className="errorText">Please set Remarks</span> : '' }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let status = {};
    ownProps.itemList.forEach(function (item) {
        if (item.placestatusid == ownProps.id) {
            status = item;
        }
    });

    return {
        status: Object.assign({}, status),
        statuses: state.statuses
    }
};

const StatusesAdd = connect(mapStateToProps)(StatusesAddComponent);
export default StatusesAdd;
