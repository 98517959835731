import React from 'react';

let Plans = function (store = [], event) {
    if (event.type == 'SET PLANS') {
        store = event.items;
    }
    if(event.type == 'UPDATE PLAN LANDUSES'){
        return store.map(function (item) {
            if (item.planid == event.id) {
                let item1 = Object.assign({}, item, {
                    landuses: event.items.planLanduses
                });
                return item1;
            }
            return item;
        });
    }
    if(event.type == 'UPDATE PLAN PUBLICATIONS'){
        console.log(event.items);
        return store.map(function (item) {
            if (item.planid == event.id) {
                let item1 = Object.assign({}, item, {
                    publications: event.items
                });
                return item1;
            }
            return item;
        });
    }

    if(event.type == 'UPDATE PLAN RELATIONSHIP'){
        return store.map(function (item) {
            if (item.planid == event.id) {
                let item1 = Object.assign({}, item, {
                    relationship: event.items
                });
                return item1;
            }
            return item;
        });
    }
    if(event.type == 'UPDATE PLAN TENDER PUBLICATION'){
        return store.map(function (item) {
            if (item.planid == event.id) {
                let item1 = Object.assign({}, item, {
                    tenderPublications: event.items
                });
                return item1;
            }
            return item;
        });
    }

    if (event.type == 'ADD PLAN') {
        store.push(event.item);
    }

    if (event.type == 'REMOVE PLAN') {
        store = store.filter(item => {
            return item.planid != event.id
        });
    }

    if (event.type == 'UPDATE PLAN') {
        return store.map(function (item) {
            if (item.planid == event.id) {
                let item1 = Object.assign({}, event.item);
                return item1;
            }
            return item;
        });
    }

    if (event.type === 'ADD PLAN PUBLICATION') {
        return store.map(function (item) {
            if (item.planid == event.item.planid) {
                console.log(item);
                item.publications.push(event.item);
            }
            return item;
        });
    }

    if (event.type === 'REMOVE PLAN PUBLICATION') {
        return store.map(function (item) {
            console.log(item);
            if (item.planid == event.id.planid) {
                item.publications = item.publications.filter(statusItem => {
                    return statusItem.planpublicationid !== event.id.planpublicationid;
                });
                console.log(item);
                let newItem = Object.assign({}, item);
                console.log(newItem);
                return newItem;
            }
            return item;
        });
    }

    if (event.type === 'UPDATE PLAN PUBLICATION') {
        return store.map(function (item) {
            if (item.planid == event.item.planid) {
                item.publications = item.publications.map(statusItem => {
                    if (statusItem.planpublicationid === event.item.planpublicationid) {
                        let editedItem = Object.assign({}, event.item);
                        return editedItem;
                    }
                    return statusItem;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }


    //////////////////

    if (event.type === 'ADD PLAN LANDUSE') {
        return store.map(function (item) {
            if (item.planid == event.item.planid) {
                item.landuses.push(event.item);
            }
            return item;
        });
    }

    if (event.type === 'REMOVE PLAN LANDUSE') {
        return store.map(function (item) {
            console.log(item);
            if (item.planid == event.id.planid) {
                item.landuses = item.landuses.filter(statusItem => {
                    return statusItem.planlanduseid !== event.id.planlanduseid;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    if (event.type === 'UPDATE PLAN LANDUSE') {
        return store.map(function (item) {
            if (item.planid == event.item.planid) {
                item.landuses = item.landuses.map(statusItem => {
                    if (statusItem.planlanduseid === event.item.planlanduseid) {
                        let editedItem = Object.assign({}, event.item);
                        return editedItem;
                    }
                    return statusItem;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    if (event.type === 'ADD TENDER PUBLICATION') {
        return store.map(function (item) {
            if (item.planid == event.item.planid) {
                item.tenderPublications = item.tenderPublications ? item.tenderPublications : []
                item.tenderPublications.push(event.item);
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    if (event.type === 'REMOVE TENDER PUBLICATION') {
        return store.map(function (item) {
            if (item.planid == event.id.planid) {
                item.tenderPublications = item.tenderPublications ? item.tenderPublications :[];
                item.tenderPublications = item.tenderPublications.filter(statusItem => {
                    return statusItem.tenderpublicationid !== event.id.tenderpublicationid;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    if (event.type === 'UPDATE TENDER PUBLICATION') {
        return store.map(function (item) {
            if (item.planid == event.item.planid) {
                item.tenderPublications = item.tenderPublications ? item.tenderPublications :[];
                item.tenderPublications = item.tenderPublications.map(statusItem => {
                    if (statusItem.tenderpublicationid === event.item.tenderpublicationid) {
                        let editedItem = Object.assign({}, event.item);
                        return editedItem;
                    }
                    return statusItem;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    if (event.type === 'ADD RELATIONSHIP') {
        return store.map(function (item) {
            if (item.planid == event.item.planid) {
                console.log(item);
                item.relationship[0].push(event.item);
            }
            return item;
        });
    }

    if (event.type === 'ADD RELATIONSHIP OVERLYING') {
        return store.map(function (item) {
            if (item.planid == event.item.underlyingplanid) {
                console.log(item);
                item.relationship[1].push(event.item);
            }
            return item;
        });
    }

    if (event.type === 'REMOVE RELATIONSHIP') {
        return store.map(function (item) {
            console.log(item);
            if (item.planid == event.id.planid) {
                item.relationship[0] = item.relationship[0].filter(statusItem => {
                    return statusItem.planrelationshipid !== event.id.planrelationshipid;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    if (event.type === 'UPDATE RELATIONSHIP') {
        return store.map(function (item) {
            if (item.planid == event.item.planid) {
                item.relationship[0] = item.relationship[0].map(statusItem => {
                    if (statusItem.planrelationshipid === event.item.planrelationshipid) {
                        let editedItem = Object.assign({}, event.item);
                        return editedItem;
                    }
                    return statusItem;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    if (event.type === 'REMOVE RELATIONSHIP OVERLYING') {
        return store.map(function (item) {
            console.log(item);
            if (item.planid == event.id.underlyingplanid) {
                item.relationship[1] = item.relationship[1].filter(statusItem => {
                    return statusItem.planrelationshipid !== event.id.planrelationshipid;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    if (event.type === 'UPDATE RELATIONSHIP OVERLYING') {
        return store.map(function (item) {
            if (item.planid == event.item.underlyingplanid) {
                item.relationship[1] = item.relationship[1].map(statusItem => {
                    if (statusItem.planrelationshipid === event.item.planrelationshipid) {
                        let editedItem = Object.assign({}, event.item);
                        return editedItem;
                    }
                    return statusItem;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    return store;
};

export default Plans;