import React from 'react';
import {connect} from 'react-redux';
import {
    ActionDescriptorsPlanningPhases,
    ActionDescriptorsPlanningPhasesCategory, ActionPlanningPhasesAdd,
    ActionPlanningPhasesCatAdd,
    ActionPlanningPhasesCatDelete,
    ActionPlanningPhasesCatEdit,
    ActionPlanningPhasesDelete, ActionPlanningPhasesEdit
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import ToolButtons from "../../../parts/ToolButtons";
import Storege from "../../../storage/storage";
import PlanPhasesCategoryPopup from "./PlanPhasesCategoryPopup";
import RemoveButton from "../../../parts/removeBtn";
import EditBtn from "../../../parts/EditBtn";
import CommonTab from "../../../parts/CommonTabClass";
import {getItemData} from "../../../parts/functions";
import PlanPhasesPopup from "./PlanPhasesPopup";

class PlanningPhasesDescriptorComponent extends CommonTab {
    state = {
        planningPhases: {},
        planningPhasesCategory: {},
        popup: false,
        popup_2: false,
        popupType: undefined,
        popupType_2: undefined,
        currentItemIndex: -1,
        currentItemIndex_2: -1,
        rowIndex: -1,
        rowIndex_2: -1
    };

    constructor(props) {
        super(props);
        this.state.planningPhases = props.planningPhases;
        this.state.planningPhasesCategory = props.planningPhasesCategory;
    }

    changeState = (name, e, id) => {
        let temp = this.props.planningPhases;
        temp.forEach(function (item) {
            if (item.planningphaseid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            planningPhases: Object.assign({}, temp)
        });
    };

    changeStateCategory = (name, e, id) => {
        let temp = this.props.planningPhasesCategory;
        temp.forEach(function (item) {
            if (item.planningphasecategoryid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            planningPhasesCategory: Object.assign({}, temp)
        });
    };

    changePopupType = (actionType) => {
        console.log(actionType)
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    changePopupState_2 = () => {
        let currentPopupState = this.state.popup_2;
        this.setState(
            {
                popup_2: !currentPopupState
            }
        )
    };

    addContentType = (object) => {
        ActionPlanningPhasesCatAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLAN PHASE CATEGORY',
                item: data
            });
            this.changePopupState();
        });
    };

    addContentType_2 = (object) => {
        ActionPlanningPhasesAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLAN PHASE',
                item: data
            });
            this.changePopupState_2();
        });
    };

    editContentType = (object) => {
        ActionPlanningPhasesCatEdit(object.planningphasecategoryid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLAN PHASE CATEGORY',
                item: data,
                id: data.planningphasecategoryid
            });
            this.changePopupState();
        });
    };

    editContentType_2 = (object) => {
        ActionPlanningPhasesEdit(object.planningphaseid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLAN PHASE',
                item: data,
                id: data.planningphaseid
            });
            this.changePopupState_2();
        });
    };

    render() {
        if (!this.props.planningPhases.length) {
            ActionDescriptorsPlanningPhases();
        }
        if (!this.props.planningPhasesCategory.length) {
            ActionDescriptorsPlanningPhasesCategory();
        }
        let {planningPhases, planningPhasesCategory} = this.props;
        if (!planningPhases && !planningPhasesCategory) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div className="inline-toolbuttons">
                    <h3>Planning Phase Categories</h3>
                    <div className={'tool-bar'}>
                        <ToolButtons
                            actionType={this.changePopupType}
                            popupChange={this.changePopupState}
                            id={this.state.currentItemIndex}
                        />
                    </div>
                </div>
                <table className='table table-striped planning-phase-category-table'>
                    <thead>
                    <th></th>
                    <th>Planning Phase Category Name</th>
                    <th></th>
                    <th>Planning Phase Category Name Eng</th>
                    </thead>
                    <tbody>
                    {planningPhasesCategory.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                e => {
                                    e.preventDefault();
                                    this.setState({
                                        rowIndex: index,
                                        rowIndex_2: -1,
                                        currentItemIndex: item.planningphasecategoryid
                                    });
                                }
                            } className={this.state.rowIndex == index ? 'active' : ''}>
                                <td className={'edit-buttons'}>
                                    <div className={'flex-row'}>
                                        <RemoveButton
                                            action={ActionPlanningPhasesCatDelete}
                                            id={item.planningphasecategoryid}
                                            descriptor={'REMOVE PLAN PHASE CATEGORY'}
                                        />
                                        <EditBtn
                                            actionType={this.changePopupType}
                                            popupChange={this.changePopupState}
                                            change={this.setCurrentIndex}
                                            id={item.planningphasecategoryid}
                                        />
                                    </div>
                                </td>
                                <td>{item.planningphasecategoryname}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.planningphasecategorynameeng}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <PlanPhasesCategoryPopup
                    openClose={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
                <div className="inline-toolbuttons">
                    <h3>Planning Phases</h3>
                    <div className={'tool-bar'}>
                        <ToolButtons
                            actionType={this.changePopupType}
                            popupChange={this.changePopupState_2}
                            id={this.state.currentItemIndex_2}
                        />
                    </div>
                </div>
                <table className='table table-striped'>
                    <thead>
                    <tr>
                        <th></th>
                        <th>Planning Phase Name</th>
                        <th></th>
                        <th>Planning Phase Name Eng</th>
                        <th></th>
                        <th>Description</th>
                        <th></th>
                        <th>Planning Phase Category</th>
                        <th></th>
                        <th>Order</th>
                    </tr>
                    </thead>
                    <tbody>
                    {planningPhases.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                e => {
                                    e.preventDefault();
                                    console.log(item)
                                    this.setState({
                                        rowIndex_2: index,
                                        rowIndex: -1,
                                        currentItemIndex_2: item.planningphaseid
                                    });
                                }
                            } className={this.state.rowIndex_2 == index ? 'active' : ''}>
                                <td className={'edit-buttons'}>
                                    <div className={'flex-row'}>
                                        <RemoveButton
                                            action={ActionPlanningPhasesDelete}
                                            id={item.planningphaseid}
                                            descriptor={'REMOVE PLAN PHASE'}
                                        />
                                        <EditBtn
                                            actionType={this.addContentType_2}
                                            popupChange={this.changePopupState_2}
                                            change={this.setCurrentIndex}
                                            id={item.planningphaseid}
                                        />
                                    </div>
                                </td>
                                <td>{item.planningphasename}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.planningphasenameeng}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.description}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{getItemData(planningPhasesCategory, 'planningphasecategoryid', item.planningphasecategoryid, 'planningphasecategoryname')}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.order}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
	            {this.state.popup_2 ? <PlanPhasesPopup
		            openClose={this.changePopupState_2}
		            action={this.state.popupType == 'add' ? this.addContentType_2 : this.editContentType_2}
		            button={this.state.popupType == 'add' ? 'Add' : 'Update'}
		            id={this.state.popupType == 'edit' ? this.state.currentItemIndex_2 : -1}
	            /> : ''}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        planningPhases: state.planningPhases,
        planningPhasesCategory: state.planningPhasesCategory
    }
};
const DescriptorPlanningPhases = connect(mapStateToProps)(PlanningPhasesDescriptorComponent);

export default DescriptorPlanningPhases;