import React from 'react';
import {connect} from 'react-redux';
import Select from "../../../parts/select";
import {
    ActionTenderRelationship,
    ActionTenderRelationshipAdd,
    ActionTenderRelationshipDelete,
    ActionTenderRelationshipEdit
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import Storege from "../../../storage/storage";
import RemoveButton from "../../../parts/removeBtn";
import TenderRelationshipAdd from "./TenderRelationshipsAdd";
import ToolButtons from "../../../parts/ToolButtons";
import {getItemData} from "../../../parts/functions";
import EditBtn from "../../../parts/EditBtn";
import CommonTab from "../../../parts/CommonTabClass";

class TenderRelationshipsComponent extends CommonTab {

    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            popupType: undefined,
            currentItemIndex: -1,
            rowIndex: -1,
            order: null
        }
    }

    addContentType = (object) => {
        ActionTenderRelationshipAdd('', object).then(data => {
            Storege.dispatch({
                type: this.state.popupName === 'underlying' ? 'ADD TENDER RELATIONSHIP' : 'ADD TENDER RELATIONSHIP OVERLYING',
                item: data
            });
            this.changePopupState();
        });
    };


    editContentType = (object) => {
        ActionTenderRelationshipEdit(object.tenderrelationshipid, object).then(data => {
            Storege.dispatch({
                type: this.state.popupName === 'underlying' ? 'UPDATE TENDER RELATIONSHIP' : 'UPDATE TENDER RELATIONSHIP OVERLYING',
                item: data,
                id: data.tenderrelationshipid
            });
            this.changePopupState();
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    overOrUnder = (name = '') => {
        this.setState(
            {
                popupName: name
            }
        )
    };

    render() {
        const {tender, change, tenders} = this.props;
        if (!tender.tenderRealationships) {
            ActionTenderRelationship(tender.tenderid);
        }

        let overliyng = [];
        let underling = [];

        if (tender.tenderRealationships) {
            overliyng = tender.tenderRealationships[1] ? tender.tenderRealationships[1] : [];
            underling = tender.tenderRealationships[0] ? tender.tenderRealationships[0] : [];
        }


        return (
            <div>
                <div className={'custom-tool-bar'}>
                    <h3>Overliying tender</h3>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                        popupName={this.overOrUnder}
                        name={'overlying'}
                    />
                </div>
                <table className={'table table-striped'}>
                    <thead>
                    <tr className={'sortable-btn'}>
                        <th></th>
                        <th>Overliying tender</th>
                        <th></th>
                        <th>Remarks</th>
                    </tr>
                    </thead>
                    <tbody>
                    {overliyng.map((item, index) => {
                        console.log(item);
                        return (
                            <tr key={index} onClick={
                                () => {
                                    // this.setState({
                                    //     rowIndex: index,
                                    //     currentItemIndex: item.tenderid
                                    // });
                                }
                            }
                                className={'list-group-item clear no-padding' + (this.state.rowIndex == index ? ' active' : '')}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionTenderRelationshipDelete}
                                        id={item.tenderrelationshipid}
                                        descriptor={'REMOVE TENDER RELATIONSHIP OVERLYING'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.tenderrelationshipid}
                                    />
                                </td>
                                <td>{getItemData(tenders, 'tenderid', item.tenderid, 'tendername')}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.remarks}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <div className={'custom-tool-bar'}>
                    <h3>Underliying tender</h3>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                        popupName={this.overOrUnder}
                        name={'underlying'}
                    />
                </div>
                <table className={'table table-striped'}>
                    <thead>
                    <tr className={'sortable-btn'}>
                        <th></th>
                        <th>Underliying tender</th>
                        <th></th>
                        <th>Remarks</th>
                    </tr>
                    </thead>
                    <tbody>
                    {underling.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                () => {
                                    // this.setState({
                                    //     rowIndex: index,
                                    //     currentItemIndex: item.tenderid
                                    // });
                                }
                            }
                                className={'list-group-item clear no-padding' + (this.state.rowIndex == index ? ' active' : '')}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionTenderRelationshipDelete}
                                        id={item.tenderrelationshipid}
                                        descriptor={'REMOVE TENDER RELATIONSHIP'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        name ={'underlying'}
                                        id={item.tenderrelationshipid}
                                    />
                                </td>
                                <td>{getItemData(tenders, 'tenderid', item.underlyingtenderid, 'tendername')}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.remarks}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

                {this.state.popup ? <TenderRelationshipAdd
                    tenderid={tender.tenderid}
                    close={this.changePopupState}
                    name={this.state.popupName}
                    // itemList={this.state.popupName === 'underlying' ? underling : overliyng}
                    itemList={underling.concat(overliyng)}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tenderRealationships: state.tenderRealationships,
        tenders: state.tenders
    }
};

const TenderRelationships = connect(mapStateToProps)(TenderRelationshipsComponent);

export default TenderRelationships;