import React from 'react';
import {connect} from 'react-redux';
import {Route} from "react-router-dom";
import Select from "../../parts/select";
import Statuses from "../../parts/tabs/Statuses";
import Synonyms from "../../parts/tabs/Synonyms";
import Hierarchies from "../../parts/tabs/Hierarchies";
import TabsRow from "../../parts/tabs-row";
import Geometry from "../../parts/tabs/Geometry";
import Sovereignties from "../../parts/tabs/Sovereignties";
import Tracking from "../../parts/tabs/Tracking";
import Links from "../../parts/tabs/Links";
import ICBS from "../../parts/tabs/ICBS";
import PlacesTabs from "./places_tabs";
import api from "../../api";
import {PlacesEdit} from "../../parts/actions";
import ColumnResizer from "react-column-resizer";
import {getItemData} from "../../parts/functions";

class SinglePlaceComponent extends React.Component {
    state = {
        place: {}
    };

    constructor(props) {
        super(props);
        this.state.place = props.place;
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if (oldProps.place.placeid !== newProps.place.placeid) {
            this.setState({place: newProps.place})
        }
    }

    changeState = (name, e) => {
        let temp = this.state.place;
        temp[name] = e.value;
        this.setState({place: temp});
    };

    placesChange = (name, typeName, e, id) => {
        let temp = this.props.place;
        temp.hierarchiesList[typeName].forEach((item) => {
            if (item[name] == id) {
                item[name] = +e.value;
            }
        });
        this.setState({
            place: Object.assign({}, temp)
        });
    };

    changeStateStatuses = (itemId, value, e) => {
        let temp = this.props.place;
        temp.statusList.forEach(function (item) {
            if (itemId == item.statusid) {
                item.placestatusid = +e.value;
            }
        });
        this.setState({
            place: Object.assign({}, temp)
        });
    };

    changeSynonyms = (name, e, id) => {
        let temp = this.props.place;
        temp.synonymsList.map((item) => {
            if (item.placesynonymid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            place: Object.assign({}, temp)
        });
    };

    changeLinks = (name, e, id) => {
        let temp = this.props.place;
        temp.links.map((item) => {
            if (item.placeurlid == id) {
                item[name] = +e.value;
            }
        });
        this.setState({
            place: Object.assign({}, temp)
        });
    };

    changeTracking = (name, e, id) => {
        let temp = this.props.place;
        temp.tracking.map((item) => {
            if (item.trackingid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            place: Object.assign({}, temp)
        });
    };

    changeTrackingDate = (name, e, id) => {
        let temp = this.props.place;
        temp.tracking.map((item) => {
            if (item.trackingid == id) {
                item[name] = e;
            }
        });
        this.setState({
            place: Object.assign({}, temp)
        });
    };

    render() {
        const {place} = this.props;
        if (!place && Object.keys(place).length) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    PlacesEdit(this.state.place.placeid);
                }}>
                    <div>
                        <div className={'place-top'}>
                            <h3>Selected place</h3>
                            <div className="left-id">
                                Place ID: {this.state.place.placeid}
                            </div>
                        </div>
                        <table className={"table table-striped place-top-table"} valign={"middle"}>
                            <tbody>
                            <tr>
                                <td>
                                    <label htmlFor={"name"}>Name: </label>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{this.state.place.name}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td><label htmlFor={"name_eng"}>Name Eng: </label></td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{this.state.place.nameeng}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    <label htmlFor={"place_type"}>Place Type: </label>
                                </td>

                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {getItemData(this.props.placeTypes, 'placetypeid', this.state.place.placetypeid, 'placetypename')}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className={"description-details"}>
                        <h3>Details</h3>
                        <table className={'place-detail'}>
                            <tbody>
                            <tr>
                                <td>
                                    <label htmlFor={"region"}>Region: </label>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {getItemData(this.props.regions, 'regionid', this.state.place.regionid, 'regionname')}
                                    {/*<Select*/}
                                    {/*id={"region"}*/}
                                    {/*className={"form-control"}*/}
                                    {/*name={"region"}*/}
                                    {/*value={this.state.place.regionid}*/}
                                    {/*types={this.props.regions}*/}
                                    {/*optionValue={"regionid"}*/}
                                    {/*optionName={"regionname"}*/}
                                    {/*onChange={(e) => {*/}
                                    {/*this.changeState("regionid", e.target);*/}
                                    {/*}*/}
                                    {/*}*/}
                                    {/*/>*/}
                                </td>
                                <td>
                                    <label htmlFor={"population_type"}>Population Type: </label>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {getItemData(this.props.populationType, 'placepopulationtypeid', this.state.place.placepopulationtypeid, 'placepopulationtypename')}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor={"community_type"}>Community Type: </label>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {getItemData(this.props.communityType, 'placecommunitytypeid', this.state.place.placecommunitytypeid, 'placecommunitytypename')}

                                    {/*<Select*/}
                                    {/*id={"community_type"}*/}
                                    {/*className={"form-control"}*/}
                                    {/*name={"community_type"}*/}
                                    {/*value={this.state.place.placecommunitytypeid}*/}
                                    {/*types={this.props.communityType}*/}
                                    {/*optionValue={"placecommunitytypeid"}*/}
                                    {/*optionName={"placecommunitytypename"}*/}
                                    {/*onChange={(e) => {*/}
                                    {/*this.changeState("placecommunitytypeid", e.target);*/}
                                    {/*}*/}
                                    {/*}*/}
                                    {/*/>*/}
                                </td>
                                <td>
                                    <label htmlFor={"yearestablisheddate"}>Year Established Date: </label>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {this.state.place.yearestablisheddate ? (new Date(this.state.place.yearestablisheddate)).getFullYear() : ''}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={"places-description"}>
                        <h3> Description </h3>
                        <table>
                            <tbody>
                            <tr>
                                <td><label
                                    htmlFor={"description"}>Description: </label>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{this.state.place.description ? this.state.place.description : ""}</td>
                            </tr>
                            <tr>
                                <td><label
                                    htmlFor={"URL"}>URL: </label>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{this.state.place.url ? this.state.place.url : ""}</td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                </form>
                <
                    TabsRow
                    tabs={PlacesTabs}
                    id={place.placeid
                    }
                    path={this.props.location.pathname
                    }
                />

                <Route
                    path={api.getRoute('places/statuses/:id')}
                    render={(props) => <Statuses
                        {...props}
                        place={place}
                        change={this.changeStateStatuses}
                    />}
                />
                < Route
                    path={api.getRoute('places/synonyms/:id')
                    }
                    render={(props) =>
                        <Synonyms
                            {...props}
                            place={place}
                            change={this.changeSynonyms}
                        />
                    }
                />
                <Route
                    path={api.getRoute('places/hierarchies/:id')}
                    render={(props) => <Hierarchies
                        {...props}
                        place={place}
                        change={this.placesChange}
                    />}
                />
                < Route
                    path={api.getRoute('places/geometry/:id')
                    }
                    render={(props) =>
                        <Geometry
                            {...props}
                            place={place}
                            change={this.placesChange}
                        />
                    }
                />
                <Route path={api.getRoute('places/sovereignties/place/:id')}
                       component={Sovereignties}
                       place={place}/>
                < Route
                    path={api.getRoute('places/tracking/:id')
                    }
                    render={(props) =>
                        <Tracking
                            {...props}
                            place={place}
                            change={this.changeTracking}
                            changeDate={this.changeTrackingDate}
                        />
                    }
                />
                <Route
                    path={api.getRoute('places/links/:id')}
                    render={(props) => <Links
                        {...props}
                        place={place}
                        change={this.changeLinks}
                    />}
                />
                < Route
                    path={api.getRoute('places/ibspdetails/:id')
                    }
                    render={(props) =>
                        <ICBS
                            {...props}
                            place={place}
                        />
                    }
                />
            </div>
    )
    }
    }


    const mapStateToProps = (state, ownProps) => {
        let place = {};
        state.places.forEach(function (el) {
        if (el.placeid == ownProps.match.params.id) {
        place = el;
    }
    });

        return {
        place: Object.assign({}, place),
        placeTypes: state.placeTypes,
        communityType: state.communityType,
        regions: state.regions,
        populationType: state.populationType
    }
    };

    const SinglePlace = connect(mapStateToProps)(SinglePlaceComponent);
    export default SinglePlace;

