import React from 'react';

const getFullDateYear = (date) => {
    let newDate = new Date(date);
    let Year = newDate.getFullYear();
    let Month = newDate.getMonth()+1;
    let Day = newDate.getDate();
    //let dateString = Year + '-' + (Month < 10 ? '0' + Month : Month) + '-' + (Day < 10 ? '0' + Day : Day);
    let dateString = (Day < 10 ? '0' + Day : Day) + '-' + (Month < 10 ? '0' + Month : Month) + '-' + Year   ;
    return dateString;
};

const getItemData = (collection, firstId, secondId, fieldName) => {
    let item = collection.filter(placeItem => {
        return placeItem[firstId] === secondId;
    });
    return item[0] ? item[0][fieldName] : '';
};

const doFilter = (collection, filtersArray) => {
    filtersArray.forEach(item => {
        if (item.name === 'searchValue') {
            if (item.value.length) {
                collection = collection.filter((collectionItem) => {
                    return (
                        collectionItem[item.searchId[0]] ? collectionItem[item.searchId[0]] : '').toLowerCase().indexOf(item.value.toLowerCase()) !== -1 ||
                        (collectionItem[item.searchId[1]] ? collectionItem[item.searchId[1]] : '').toLowerCase().indexOf(item.value.toLowerCase()) !== -1 ||
                        (item.additionalFilter ? item.additionalFilter(collectionItem,item.value.toLowerCase()) : false)
                        ;
                });
            }
        } else {
            if (item.value !== -1) {
                collection = collection.filter((collectionItem) => {
                    return collectionItem[item.searchId] === item.value;
                });
            }
        }
    });
    return collection;
};

const doPlaceFilter = (collection, filtersArray) => {
    filtersArray.forEach(item => {
        if (item.name === 'searchValue') {
            if (item.value.length) {
                collection = collection.filter((collectionItem) => {
                    return (collectionItem[item.searchId[0]] ? collectionItem[item.searchId[0]] : '').toLowerCase().indexOf(item.value.toLowerCase()) !== -1 || (collectionItem[item.searchId[1]] ? collectionItem[item.searchId[1]] : '').toLowerCase().indexOf(item.value.toLowerCase()) !== -1;
                });
            }
        } else {
            if (item.value !== -1) {
                collection = collection.filter((collectionItem) => {
                    return collectionItem[item.searchId] === item.value;
                });
            }
        }
    });
    return collection;
};

export default getFullDateYear;
export {doFilter, getItemData};
