import React from 'react';
import api from "../api";

class UploadExcel extends React.Component {
    state = {
        uploading: null,
        selectedFile: null,
        errors_file: [],
    };

    onFileChange = event => {
        this.setState({selectedFile: event.target.files[0]});
    };

    afterLoading = () => {
        if(this.props.afterLoading){
            this.props.afterLoading();
        }
    };

    onFileUpload = () => {
        this.setState({uploading: 'uploading'});
        let formData = new FormData();
        let file_ob = this.state.selectedFile;
        let file_name = this.state.selectedFile.name;
        formData.append(
            "myFile",
            file_ob,
            file_name,
        );
        let self = this;
        fetch(api.getRoute(this.props.route), {
            method: "POST",
            body: formData,
            headers: {
                'X-CSRF-TOKEN': api.token
            }
        }).then((response) => response.json()).then(function (data) {
            self.setState({uploading: null});
            self.setState({errors_file: data});
            self.afterLoading();
        });
    };

    render() {
        const errors_id = this.props.errors_id;
        let errors_items = this.state.errors_file.map((errors_item) =>
            errors_item == 'There are some errors:' ? <p style={{color: 'red'}}>{errors_item}</p> : <p>{errors_item}</p>
        );
        let spinner = '';
        if (this.state.uploading == 'uploading') {
            spinner = (<div className="overlay-loader">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>);
        }
        return (
            <div className={'upload-excel'}>
                <h4>{this.props.title}</h4>
                {spinner}
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <input type="file" onChange={this.onFileChange}/>
                    <input type="hidden" id={'file-hidden'}/>
                    <button onClick={this.onFileUpload} className={'btn btn-primary'}>
                        Upload Excel
                    </button>
                </form>
                <div>{errors_items}</div>
            </div>
        )
    }
}

export default UploadExcel;