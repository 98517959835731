import React from 'react';
import {connect} from 'react-redux';
import SelectObject from "../../parts/SelectObject";
import TabsRow from "../../parts/tabs-row";
import {Route} from "react-router-dom";
import TenderTabs from "./tenders_tabs";
import TenderDetails from "./tabs/TenderDetails";
import TenderLandus from "./tabs/TenderLandus";
import api from "../../api";
import TenderContactors from "./tabs/TenderContactors";
import TenderRelationships from "./tabs/TenderRelationships";
import TenderPublications from "./tabs/TenderPublications";

class TenderSingleComponent extends React.Component {
    state = {
        tender: {}
    }

    constructor(props) {
        super(props);
        this.state.tender = props.tender;
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if (oldProps.tender.tenderid !== newProps.tender.tenderid) {
            this.setState({tender: newProps.tender})
        }
    }

    tenderLanduses = (name, e, id) => {
        let temp = this.props.tender;
        console.log(temp);
        temp.landuses.map((item)=>{
            if(item.tenderlanduseid == id){
                item[name] = +e.value;
            }
        });
        this.setState({
            tender: Object.assign({}, temp)
        });
    };

    placeChange = (name, e) => {
        let temp = this.props.tender;
        temp[name] = +e.value;
        this.setState({
            tender: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.props.tender;
        temp[name] = e.value;
        this.setState({
            tender: Object.assign({}, temp)
        });
    };

    render() {
        const {places, tender} = this.props;
        const {pathname} = this.props.location;
        if (!places && !tender) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <form action="">
                    <div className={'form-top'}>
                        <h3>Selected Tender</h3>
                        <span>Tender Id: {this.state.tender.tenderid}</span>
                    </div>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Tender Name:</td>
                            <td>{this.state.tender.tendername}</td>
                            <td>Place:</td>
                            <td>{places[this.state.tender.placeid]}</td>
                        </tr>
                        </tbody>
                    </table>
                </form>
                <TabsRow
                    tabs={TenderTabs}
                    id={tender.tenderid}
                    path={pathname}
                />
                <Route
                    path={api.getRoute('tenders/tender-details/tender/:id')}
                    render={(props) => <TenderDetails
                        {...props}
                        tender={tender}
                        change={this.placeChange}
                    />}
                />
                <Route
                    path={api.getRoute('tenders/landuses/tender/:id')}
                    render={(props) => <TenderLandus
                        {...props}
                        tender={tender}
                        change={this.tenderLanduses}
                    />}
                />
                <Route
                    path={api.getRoute('tenders/contractors/tender/:id')}
                    render={(props) => <TenderContactors
                        {...props}
                        tender={tender}
                    />}
                />
                <Route
                    path={api.getRoute('tenders/relationship/tender/:id')}
                    render={(props) => <TenderRelationships
                        {...props}
                        tender={tender}
                    />}
                />
                <Route
                    path={api.getRoute('tenders/publications/tender/:id')}
                    render={(props) => <TenderPublications
                        {...props}
                        tender={tender}
                    />}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let tender = {};
    state.tenders.forEach(function (item) {
        if (item.tenderid == ownProps.match.params.id) {
            tender = item;
        }
    });
    return {
        tender: Object.assign({}, tender),
        places: state.tendersPlaces
    }
};

const TenderSingle = connect(mapStateToProps)(TenderSingleComponent);

export default TenderSingle;