 import React from 'react';
import Select from 'react-select'

export default class SelectObject extends React.Component {
    render() {
        let types = this.props.types ? this.props.types : [];
        let keyArray = [];
        let valArray = [];
        for(let key in types) {
            keyArray.push(key);
            valArray.push(types[key]);
        }
        let selected = undefined;
        const options = keyArray.map((item, index) => {
                    let obj = { value: item, label: valArray[index] };
                    if(this.props.value == item){
                        selected = obj
                    }
                    return obj;
                });
        return (
            <Select
                options = {options}
                onChange = {(value)=>{this.props.change({target : value})}}
                className = {'custom-select'}
                value = {selected}
            />
        );
        // return (
        //     <select
        //         className={this.props.className ? this.props.className : 'form-control'}
        //         name={this.props.name ? this.props.name : ''}
        //         value={this.props.value ? this.props.value : -1}
        //         onChange={this.props.change}
        //         id={this.props.id ? this.props.id : ''}>
        //         <option value="-1"> </option>
        //         {keyArray.map(function (item, index) {
        //             return (
        //                 <option key={index} value={item}>{valArray[index]}</option>
        //             )
        //         })}
        //     </select>
        // )
    }
}