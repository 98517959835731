import React from 'react';
import {Link, Route} from 'react-router-dom';
import api from "../../api";
import DescriptorPlanningPhases from "./Plans Descriptors/decriptor_planning_phases";
import DescriptorOwnership from "./Plans Descriptors/descriptors_ownership";
import DescriptorPlanPublication from "./Plans Descriptors/descriptors_plan_publication";
import DescriptorPlanTypes from "./Plans Descriptors/descriptor_plan_types";

export default class TabsPlansRow extends React.Component {
    render() {
        const PlansTabs = [
            {
                tabName: 'Planning Phases',
                tabEnd: 'planning_phases',
                tabUrl: api.getRoute('plan_descriptors/planning_phases')
            },
            {
                tabName: 'Ownership Types',
                tabEnd: 'plan_descriptors',
                tabUrl: api.getRoute('plan_descriptors/ownership_types')
            },
            {
                tabName: 'Plan Publication Unit Types',
                tabEnd: 'plan_publication_unit_types',
                tabUrl: api.getRoute('plan_descriptors/plan_publication_unit_types')
            },
            {
                tabName: 'Plan Types',
                tabEnd: 'plan_types',
                tabUrl: api.getRoute('plan_descriptors/plan_types')
            },
        ];
        let path=this.props.location.pathname;
        return(
            <div>
                <h2>Plans descriptors</h2>
                <div className="row">
                    <div className="col-sm-12">
                        <ul className={"nav navbar-nav place-tabs"}>
                            {PlansTabs.map((item, index)=>{
                                let link = item.tabUrl;
                                return(
                                    <li key={index}>
                                        <Link className={path.indexOf(link) != -1 ? 'active' : ''} to={link} onClick={(e)=>{
                                        }}>{item.tabName}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Route path={api.getRoute('plan_descriptors/planning_phases')} component={DescriptorPlanningPhases}/>
                        <Route path={api.getRoute('plan_descriptors/ownership_types')} component={DescriptorOwnership}/>
                        <Route path={api.getRoute('plan_descriptors/plan_publication_unit_types')} component={DescriptorPlanPublication}/>
                        <Route path={api.getRoute('plan_descriptors/plan_types')} component={DescriptorPlanTypes}/>
                    </div>
                </div>
            </div>
        )
    }
}
