import React from 'react';
import {connect} from 'react-redux';
import SelectObject from "../../parts/SelectObject";
import Select from "../../parts/select";
import {ActionTenderAdd, ActionTenderEdit} from "../../parts/actions";
import Storege from "../../storage/storage";

class TenderEditComponent extends React.Component {
    state = {
        tender: {}
    }

    constructor(props) {
        super(props);
        this.state.tender = props.tender;
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if (oldProps.tender.tenderid !== newProps.tender.tenderid) {
            this.setState({tender: newProps.tender})
        }
    }

    placeChange = (name, e) => {
        let temp = this.props.tender;
        temp[name] = +e.value;
        this.setState({
            tender: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.props.tender;
        temp[name] = e.value;
        this.setState({
            tender: Object.assign({}, temp)
        });
    };

    render() {
        const {places} = this.props;
        return (
            <div>
                <form onSubmit={e => {
                    e.preventDefault();
                    ActionTenderEdit(this.state.tender.tenderid, this.state.tender).then(data => {
                        Storege.dispatch({
                            type: 'UPDATE TENDER',
                            item: data,
                            id: data.tenderid
                        });
                        this.props.history.push('/tenders/tender-details/tender/' + data.tenderid);
                    });
                }}>
                    <h3>Edit Tender</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Tender Id:</td>
                            <td>{this.state.tender.tenderid}</td>
                            <td>Tender Name:</td>
                            <td>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'plan_name'}
                                       value={this.state.tender.tendername}
                                       onChange={(e) => {
                                           this.changeState('tendername', e.target);
                                       }}
                                />
                            </td>
                            <td>Place:</td>
                            <td>
                                <SelectObject
                                    name={'place_name'}
                                    value={this.state.tender.placeid}
                                    types={places}
                                    change={(e) => {
                                        this.placeChange('placeid', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table className={'table table-striped'}>
                        <tbody>
                        <tr>
                            <td>Tender Type: </td>
                            <td>
                                <Select
                                    name={'plan_name'}
                                    types={this.props.tendersTypes}
                                    className={"form-control"}
                                    value={this.state.tender.tendertypeid}
                                    optionValue={"tendertypeid"}
                                    optionName={"tendertypename"}
                                    onChange={(e) => {
                                        this.placeChange('tendertypeid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Tender Purpose: </td>
                            <td>
                                <Select
                                    name={'tenderpurposeid'}
                                    types={this.props.tendersPurposes}
                                    className={"form-control"}
                                    value={this.state.tender.tenderpurposeid}
                                    optionValue={"tenderpurposeid"}
                                    optionName={"tenderpurposename"}
                                    onChange={(e) => {
                                        this.placeChange('tenderpurposeid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Tender Initiator: </td>
                            <td>
                                <Select
                                    name={'plan_name'}
                                    types={this.props.initiator}
                                    className={"form-control"}
                                    value={this.state.tender.initiatorid}
                                    optionValue={"initiatorid"}
                                    optionName={"initiatorname"}
                                    onChange={(e) => {
                                        this.placeChange('initiatorid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={"Edit"}/>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let tender = {};
    state.tenders.forEach(function (item) {
        if (item.tenderid == ownProps.match.params.id) {
            tender = item;
        }
    });
    return {
        tender: Object.assign({}, tender),
        places: state.tendersPlaces,
        tendersTypes: state.tendersTypes,
        tendersPurposes: state.tendersPurposes,
        initiator: state.tenderInitiator
    }
};

const TenderEdit = connect(mapStateToProps)(TenderEditComponent);

export default TenderEdit;