import React from 'react';
import {connect} from 'react-redux';
import DescriptorRequest from "../descriptors_request";
import ToolButtons from "../../../parts/ToolButtons";
import {
    ActionDescRegionsDelete,
    ActionPlaceTypesAdd,
    ActionPlaceTypesDelete,
    ActionPlaceTypesEdit, ActionPlanningPhasesCatDelete
} from "../../../parts/actions";
import Search from "../../../parts/Search";
import Storege from "../../../storage/storage";
import PlaceTypesPopup from "./Place Types popup";
import ColumnResizer from "react-column-resizer";
import RemoveButton from "../../../parts/removeBtn";
import CommonTab from "../../../parts/CommonTabClass";
import EditBtn from "../../../parts/EditBtn";

class PlaceTypesDescriptorComponent extends CommonTab {
    orderFields = {
        "Place Type Id": "placetypeid",
        "Place Type Name": "placetypename",
        "Place Type Name Eng": "placetypenameeng"
    };

    state = {
        placeTypes: {},
        popup: false,
        popupType: undefined,
        order: null
    };

    constructor(props) {
        super(props);
        this.state.placeTypes = props.placeTypes;
    }

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    changeState = (name, e, id) => {
        let temp = this.props.placeTypes;
        temp.forEach(function (item) {
            if (item.placetypeid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            placeTypes: Object.assign({}, temp)
        });
    };

    addContentType = (object) => {
        ActionPlaceTypesAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD DESCRIPTOR CONTENT TYPE',
                item: data
            });
            this.changePopupState();
            this.setState({
                currentItemIndex: data.placetypeid
            });
        });
    };

    editContentType = (object) => {
        ActionPlaceTypesEdit(object.placetypeid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE DESCRIPTOR CONTENT TYPE',
                item: data,
                id: data.placetypeid
            });
            this.changePopupState();
            this.setState({
                currentItemIndex: data.placetypeid
            });
        });
    };

    search = (value) => {
        if (value.length) {
            let filteredArray = this.props.placeTypes.filter(item => {
                return item.placetypename.toLowerCase().indexOf(value.toLowerCase()) != -1 || item.placetypenameeng.toLowerCase().indexOf(value.toLowerCase()) != -1;
            });
            this.setState({
                filtered: true,
                filteredItems: filteredArray
            });
        } else {
            this.setState({
                filtered: false
            });
        }
    };

    render() {
        if (!this.props.placeTypes.length) {
            DescriptorRequest('descriptors/place_types');
        }
        let {placeTypes} = this.props;
        let {filtered, filteredItems} = this.state;
        let list = filtered ? filteredItems : placeTypes;
        if (!list) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        this.sortResults(list);

        return (
            <div>
                <div className="inline-toolbuttons">
                    <h3>Place Types:</h3>
                    <div className={'tool-bar'}>
                        <ToolButtons
                            actionType={this.changePopupType}
                            popupChange={this.changePopupState}
                            id={this.state.currentItemIndex}
                        />
                        <Search
                            change={this.search}
                        />
                    </div>
                </div>
                <table className='table table-striped'>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {(list ? list : []).map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                e => {
                                    e.preventDefault();
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.placetypeid
                                    });
                                }
                            } className={this.state.rowIndex == index ? 'active' : ''}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionPlaceTypesDelete}
                                        id={item.placetypeid}
                                        descriptor={'REMOVE DESCRIPTOR CONTENT TYPE'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.placetypeid}
                                    />
                                </td>
                                <td><span>{item.placetypeid}</span>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.placetypename}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.placetypenameeng}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

                {this.state.popup ? <PlaceTypesPopup
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        placeTypes: state.placeTypes
    }
};
const DescriptorPlaceTypes = connect(mapStateToProps)(PlaceTypesDescriptorComponent);

export default DescriptorPlaceTypes;