import React from 'react';
import {connect} from 'react-redux';
import {
    ActionSingleSource,
    ActionSingleSourceTender, ActionSourceTenderPublicationAdd,
    ActionSourceTenderPublicationDelete, ActionSourceTenderPublicationEdit
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import {getItemData} from "../../../parts/functions";
import CommonTab from "../../../parts/CommonTabClass";
import RemoveButton from "../../../parts/removeBtn";
import EditBtn from "../../../parts/EditBtn";
import TenderPublicationsAdd from "../../tenders/tabs/TenderPublicationsAdd";
import Storege from "../../../storage/storage";

class SingleSourceTenderComponent  extends CommonTab {

    orderFields = {
        "Source": 'sourceid',
        "Publication Date": 'publicationdate',
        "Tender": 'tenderid',
        "Tender Publication Purpose": 'tenderpublicationpurposeid',
        "Plan": "planid",
        "Lot": "lot",
        "Remarks": 'remarks',
        "URL": 'url'
    };

    constructor(props) {
        super(props);
        this.state.singleSource = props.singleSource;
    }


    addContentType = (object) => {
        ActionSourceTenderPublicationAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD TENDER PUBLICATION',
                item: data
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionSourceTenderPublicationEdit(object.tenderpublicationid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE TENDER PUBLICATION',
                item: data,
                id: data.tenderpublicationid
            });
            this.changePopupState();
        });
    };

    changeStateSource= (name, e, id) => {
        let temp = this.props.singleSource;
        temp.singleSourceTenders.forEach(function (item) {
            if (item.tenderpublicationid == id) {
                item[name] = e.value;
            }
        })
        this.setState({
            singleSource: Object.assign({}, temp)
        });
    };

    render() {
        let id = +this.props.id;
        let id_category = this.props.id_category;
        let singleSource = this.props.singleSource;
        if (!singleSource || (singleSource && id != singleSource.sourceid)) {
            ActionSingleSource(id_category, id);
        }
        if (singleSource && !singleSource.hasOwnProperty('singleSourceTenders')) {
            ActionSingleSourceTender(id_category, id);
        }
        let singleSourceTenders = this.props.singleSource.singleSourceTenders;
        let sourcesArr = [];
        let sources = this.props.sourcesArr;
        for (let key in sources) {
            sourcesArr.push({id: +key, sourceName: sources[key]});
        }
        let sourceTenders = [];
        let tenders = this.props.sourceTenders;
        for (let key in tenders) {
            sourceTenders.push({id: +key, tender: tenders[key]});
        }
        let tenderPublicationPurposes = [];
        let purposes = this.props.tenderPublicationPurposes;
        for (let key in purposes) {
            tenderPublicationPurposes.push({id: +key, tenderPurpose: purposes[key]});
        }
        let sourcePlans = [];
        let plans = this.props.sourcePlans;
        for (let key in plans) {
            sourcePlans.push({id: +key, plan: plans[key]});
        }
        // if (!singleSourceTenders) {
        //     return (
        //         <div className="overlay-loader">
        //             <div className="spinner-border" role="status">
        //                 <span className="sr-only">Loading...</span>
        //             </div>
        //         </div>
        //     );
        // }
        this.sortResults(singleSourceTenders);
        return (
            <div>
                <h3>Tender Publications</h3>
                <table className='table table-striped'>
                    <thead>
                     {this.orderPanel()}
                    </thead>
                    <tbody>
                {(singleSourceTenders ? singleSourceTenders : []).map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <div className="flex-row">
                                    <RemoveButton
                                        id={item.tenderpublicationid}
                                        action={ActionSourceTenderPublicationDelete}
                                        descriptor={'REMOVE TENDER PUBLICATION'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.tenderpublicationid}
                                    />
                                </div>
                            </td>
                            <td>
                                 {getItemData(sourcesArr ? sourcesArr : [], 'id', item.sourceid, 'sourceName')}
                            </td>
                            <ColumnResizer className="columnResizer" minWidth={5} />
                            <td>
                               {item.publicationdate}
                            </td>
                            <ColumnResizer className="columnResizer" minWidth={5} />
                            <td>
                                {(sourceTenders ? sourceTenders : []).map((unit) => {
                                    if (item.tenderid == unit.id) {
                                        return unit.tender;
                                    }
                                })}
                            </td>
                            <ColumnResizer className="columnResizer" minWidth={5} />
                            <td>
                                {(tenderPublicationPurposes ? tenderPublicationPurposes : []).map((unit) => {
                                    if (item.tenderpublicationpurposeid == unit.id) {
                                        return unit.tenderPurpose;
                                    }
                                })}
                            </td>
                            <ColumnResizer className="columnResizer" minWidth={5} />
                            <td>
                                {(item.planid ? (sourcePlans ? sourcePlans : []).map((unit) => {
                                    if (item.planid == unit.id) {
                                        return unit.plan;
                                    }
                                }) : '')}
                            </td>
                            <ColumnResizer className="columnResizer" minWidth={5} />
                            <td>
                                {item.lot ? item.lot : ''}
                            </td>
                            <ColumnResizer className="columnResizer" minWidth={5} />
                            <td>
                                {item.remarks}
                            </td>
                            <ColumnResizer className="columnResizer" minWidth={5} />
                            <td>
                                {item.url}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
                </table>
                    {this.state.popup ? <TenderPublicationsAdd
                        close={this.changePopupState}
                        itemList={singleSourceTenders}
                        action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                        button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                        id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                    /> : ''}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        singleSource: state.singleSource,
        sourcesArr: state.sources.sourcesArr,
        sourceTenders: state.sources.tenders,
        tenderPublicationPurposes: state.sources.tenderPublicationPurposesArr,
        sourcePlans: state.sources.plans
    }
};
const SingleSourceTender = connect(mapStateToProps)(SingleSourceTenderComponent);

export default SingleSourceTender;