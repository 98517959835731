import React from 'react';
import Select from "../../../parts/select";
import {connect} from 'react-redux';
import {getItemData} from "../../../parts/functions";

class PlanDetailsComponent extends React.Component {
    render() {
        let {plan, initiator, ownerShipType, planType} = this.props;
        if (!plan && !initiator && !ownerShipType && !planType) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <h3>Plan Details</h3>
                <table className={"table table-striped plan-details-table"} valign={"middle"}>
                    <tbody>
                    <tr>
                        <td>Initiator:</td>
                        <td>{getItemData(initiator, 'initiatorid', plan.initiatorid, 'initiatorname')}</td>
                    </tr>
                    <tr>
                        <td>Submitter:</td>
                        <td>{getItemData(initiator, 'initiatorid', plan.submitterid, 'initiatorname')}</td>
                    </tr>
                    <tr>
                        <td>Architect Name:</td>
                        <td>{plan.architectname ? plan.architectname : ''}</td>
                    </tr>
                    <tr>
                        <td>Objective:</td>
                        <td>{plan.objective ? plan.objective : ''}</td>
                    </tr>
                    <tr>
                        <td>Ownership Type:</td>
                        <td>{getItemData(ownerShipType, 'ownershiptypeid', plan.ownershiptypeid, 'ownershiptypename')}</td>
                    </tr>
                    <tr>
                        <td>Ownership Description:</td>
                        <td>{plan.ownershipdescription ? plan.ownershipdescription : ''}</td>
                    </tr>
                    <tr>
                        <td>Address:</td>
                        <td>{plan.address ? plan.address : ''}</td>
                    </tr>
                    <tr>
                        <td>Mawqa:</td>
                        <td>{plan.mawqa ? plan.mawqa : ''}</td>
                    </tr>
                    <tr>
                        <td>Remarks:</td>
                        <td>{plan.remarks ? plan.remarks : ''}</td>
                    </tr>
                    <tr>
                        <td>Plan Type:</td>
                        <td>{getItemData(planType, 'plantypeid', plan.plantypeid, 'plantypename')}</td>
                    </tr>
                    <tr>
                        <td>Update date:</td>
                        <td>
                            {plan.updatedate ? plan.updatedate : ''}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        initiator: state.initiator,
        ownerShipType: state.ownerShipType,
        planType: state.planType
    }
};

const PlanDetails = connect(mapStateToProps)(PlanDetailsComponent);

export default PlanDetails;