import {createStore, combineReducers} from 'redux';
import Place from "../reducers/place";
import Plans from "../reducers/plans";
import Tenders from "../reducers/tenders";


function getReducer(eventType) {
    return (store = [], event) => {
        if (event.type == eventType) {
            store = event.items;
        }
        return store;
    }
}

function allActionsReducer(commonEvent, addEvent, updateEvent, deleteEvent, valueName) {
    return (store = [], event) => {
        if (event.type == commonEvent) {
            store = event.items;
        }

        if (event.type == addEvent) {
            store.push(event.item);
        }

        if (event.type == deleteEvent) {
            store = store.filter(item => {
                return item[valueName] != event.id
            });
        }

        if (event.type == updateEvent) {
            return store.map(function (item) {
                if (item[valueName] == event.id) {
                    let item1 = Object.assign({}, event.item);
                    return item1;
                }
                return item;
            });
        }
        return store;
    }
}

const todoApp = combineReducers({
        // charts: getReducer('SET CHARTS'),
        charts: allActionsReducer('SET CHARTS', 'ADD CHART', 'UPDATE CHART', 'REMOVE CHART', 'chartid'),
        timeRanges: allActionsReducer('SET TIME RANGES', 'ADD TIME RANGE', 'UPDATE TIME RANGE', 'REMOVE TIME RANGE', 'timerangeid'),
        types: getReducer('SET TYPES'),
        styles: getReducer('SET STYLES'),
        pagination: getReducer('SET PAGINATION'),
        places: Place,
        synonyms: function (store = [], event) {
            if (event.type == 'SET PLACES SYNONYMS') {
                store = event.items;
            }
            return store;
        },

        placeTypes: function (store = [], event) {
            if (event.type == 'SET PLACE TYPES') {
                store = event.items;
            }

            if (event.type == 'ADD DESCRIPTOR CONTENT TYPE') {
                store.push(event.item);
            }

            if (event.type == 'REMOVE DESCRIPTOR CONTENT TYPE') {
                store = store.filter(item => {
                    return item.placetypeid != event.id
                });
            }

            if (event.type == 'UPDATE DESCRIPTOR CONTENT TYPE') {
                return store.map(function (item) {
                    if (item.placetypeid == event.id) {
                        let item1 = Object.assign({}, event.item);
                        return item1;
                    }
                    return item;
                });
            }
            return store;
        },
        regions: function (store = [], event) {
            if (event.type == 'SET PLACE REGIONS') {
                store = event.items;
            }

            if (event.type == 'ADD DESCRIPTOR REGION') {
                store.push(event.item);
            }

            if (event.type == 'REMOVE DESCRIPTOR REGION') {
                store = store.filter(item => {
                    return item.regionid != event.id
                });
            }

            if (event.type == 'UPDATE DESCRIPTOR REGION') {
                return store.map(function (item) {
                    if (item.regionid == event.id) {
                        let item1 = Object.assign({}, event.item);
                        return item1;
                    }
                    return item;
                });
            }

            return store;
        },
        communityType: function (store = [], event) {
            if (event.type == 'SET PLACE COMMUNITY TYPE') {
                store = event.items;
            }

            if (event.type == 'ADD PLACE COMMUNITY TYPE') {
                store.push(event.item);
            }

            if (event.type == 'REMOVE PLACE COMMUNITY TYPE') {
                store = store.filter(item => {
                    return item.placecommunitytypeid != event.id
                });
            }

            if (event.type == 'UPDATE PLACE COMMUNITY TYPE') {
                return store.map(function (item) {
                    if (item.placecommunitytypeid == event.id) {
                        let item1 = Object.assign({}, event.item);
                        return item1;
                    }
                    return item;
                });
            }
            return store;
        },
        populationType: function (store = [], event) {
            if (event.type == 'SET PLACE POPULATION TYPE') {
                store = event.items;
            }

            if (event.type == 'ADD PLACE POPULATION TYPE') {
                store.push(event.item);
            }

            if (event.type == 'REMOVE PLACE POPULATION TYPE') {
                store = store.filter(item => {
                    return item.placepopulationtypeid != event.id
                });
            }

            if (event.type == 'UPDATE PLACE POPULATION TYPE') {
                return store.map(function (item) {
                    if (item.placepopulationtypeid == event.id) {
                        let item1 = Object.assign({}, event.item);
                        return item1;
                    }
                    return item;
                });
            }
            return store;
        },
        statuses: function (store = [], event) {
            if (event.type == 'SET PLACE STATUSES') {
                store = event.items;
            }

            if (event.type == 'ADD PLACE STATUSES') {
                store.push(event.item);
            }

            if (event.type == 'REMOVE PLACE STATUSES') {
                store = store.filter(item => {
                    return item.statusid != event.id
                });
            }

            if (event.type == 'UPDATE PLACE STATUSES') {
                return store.map(function (item) {
                    if (item.statusid == event.id) {
                        let item1 = Object.assign({}, event.item);
                        return item1;
                    }
                    return item;
                });
            }

            if(event.type === 'REMOVE DESCRIPTOR STATUSES') {
                store = store.filter(item => {
                    return item.statusid != event.id
                });
            }

            return store;
        },
        territories: function (store = [], event) {
            if (event.type == 'SET TERRITORIES') {
                store = event.items;
            }

            if (event.type == 'UPDATE TENDERS') {
                store.push(event.item);
            }

            return store;

        },
        territoriesPlaces: function (store = [], event) {
            if (event.type == 'SET TERRITORY PLACES') {
                store = event.items;
            }

            return store;

        },
        sovereignties: function (store = [], event) {
            if (event.type == 'SET SOVEREIGNTIES' && !store.length) {
                store = event.items;
            }
            return store;
        },
        sovereigntyTypes: function (store = [], event) {
            if (event.type == 'SET SOVEREIGNTIES TYPES' && !store.length) {
                store = event.items;
            }
            return store;
        },
        settelments: getReducer('SET SETTLEMENTS'),
        municipalities: getReducer('SET MUNICIPALITIES'),
        districts: getReducer('SET DISTRICTS'),
        contentType: function (store = [], event) {
            if (event.type == 'SET CONTENT TYPE') {
                store = event.items;
            }
            return store;
        },
        plans: Plans,
        plansPlaces:
            function (store = [], event) {
                if (event.type == 'SET PLANS PLACES' && !store.length) {
                    store = event.items;
                }
                return store;
            }

        ,
        initiator: getReducer('SET PLANS INITIATORS'),
        ownerShipType:
            allActionsReducer('SET PLANS OWNER SHIP TYPE', 'ADD PLAN OWNERSHIP TYPE', 'UPDATE PLAN OWNERSHIP TYPE', 'REMOVE PLAN OWNERSHIP TYPE', 'ownershiptypeid'),
        planType:
            allActionsReducer('SET PLAN TYPE', 'ADD DESCRIPTOR PLAN TYPE', 'UPDATE DESCRIPTOR PLAN TYPE', 'REMOVE DESCRIPTOR PLAN TYPE', 'plantypeid'),
        allLanduses:
            getReducer('SET PLAN LANDUSES'),
        planRelations: function (store = [], event) {
            if (event.type == 'SET PLAN RELATIONS') {
                store = event.items;
            }






            // if (event.type == 'ADD RELATIONSHIP') {
            //     console.log(store);
            //     console.log(event);
            //     store.push(event.item);
            // }
            //
            // if (event.type == 'REMOVE RELATIONSHIP') {
            //     store = store.filter(item => {
            //         return item.generalcategoryid != event.id
            //     });
            // }
            //
            // if (event.type == 'EDIT RELATIONSHIP') {
            //     return store.map(function (item) {
            //         if (item.generalcategoryid == event.id) {
            //             let item1 = Object.assign({}, event.item);
            //             return item1;
            //         }
            //         return item;
            //     });
            // }

            return store;
        },
        source:
            getReducer('SET PLAN SOURCES'),
        tendersArr:
            getReducer('SET PLAN TENDERS'),
        tenderPublicationPurpose:
            allActionsReducer('SET PLAN TENDER PUBLICATIONS', 'ADD TENDER PUBLICATION PURPOSE', 'UPDATE TENDER PUBLICATION PURPOSE', 'REMOVE TENDER PUBLICATION PURPOSE', 'tenderpublicationpurposeid'),
        tenders:
        Tenders,
        tendersPlaces:
            getReducer('SET TENDERS PLACES'),
        tendersTypes:
            allActionsReducer('SET TENDERS TYPES', 'ADD TENDER TYPE', 'UPDATE TENDER TYPE', 'REMOVE TENDER TYPE', 'tendertypeid'),
        tendersPurposes:
            allActionsReducer('SET TENDERS PURPOSES', 'ADD TENDER PURPOSE', 'UPDATE TENDER PURPOSE', 'REMOVE TENDER PURPOSE', 'tenderpurposeid'),
        tenderInitiator:
            getReducer('SET TENDERS INITIATOR'),
        tenderLanduses:
            getReducer('SET TENDERS LADUSES'),
        sourcesArr:
            getReducer('SET TENDERS TENDER PUBLIC SOURCE ARR'),
        sourcecredibility:
            getReducer('SET TENDERS SOURCE CREDIBILITY'),
        generalData:

            function (store = [], event) {
                if (event.type == 'SET GENERAL DATA') {
                    store = event.items;
                }

                if (event.type == 'ADD GENERAL CATEGORY') {
                    store.push(event.item);
                }

                if (event.type == 'REMOVE GENERAL DATA CATEGORY') {
                    store = store.filter(item => {
                        return item.generalcategoryid != event.id
                    });
                }

                if (event.type == 'EDIT GENERAL CATEGORY') {
                    return store.map(function (item) {
                        if (item.generalcategoryid == event.id) {
                            let item1 = Object.assign({}, event.item);
                            return item1;
                        }
                        return item;
                    });
                }

                if (event.type == 'UPDATE GENERAL DATA VALUE') {
                    console.log(event);
                    return store.map(function (item) {
                        if (item.generalcategoryid == event.id) {
                            let item1 = Object.assign({}, item, {
                                values: event.items[1]
                            });
                            return item1;
                        }
                        return item;
                    });
                }

                if (event.type === 'ADD GENERAL VALUE') {
                    return store.map(function (item) {
                        if (item.generalcategoryid == event.item.generalcategoryid) {
                            item.values.push(event.item);
                        }
                        return item;
                    });
                }

                if (event.type === 'REMOVE GENERAL VALUE') {
                    return store.map(function (item) {
                        if (item.generalcategoryid == event.id.generalcategoryid) {
                            item.values = item.values.filter(statusItem => {
                                return statusItem.generalvalueid !== event.id.generalvalueid;
                            });
                            let newItem = Object.assign({}, item);
                            return newItem;
                        }
                        return item;
                    });
                }

                if (event.type === 'UPDATE GENERAL VALUE') {
                    return store.map(function (item) {
                        if (item.generalcategoryid == event.item.generalcategoryid) {
                            item.values = item.values.map(statusItem => {
                                if (statusItem.generalvalueid === event.item.generalvalueid) {
                                    let editedItem = Object.assign({}, event.item);
                                    return editedItem;
                                }
                                return statusItem;
                            });
                            let newItem = Object.assign({}, item);
                            return newItem;
                        }
                        return item;
                    });
                }

                return store;
            }
        ,
        generalDataSources: getReducer('SET GENERAL DATA SOURCE'),
        generalDataUnits:
            getReducer('SET GENERAL DATA UNIT'),
        generalDataDescriptorUnits: function (store = [], event) {
            if (event.type == 'SET GENERAL DATA DESCRIPTOR UNITS') {
                store = event.items;
            }

            if (event.type == 'ADD GENERAL UNIT') {
                store.push(event.item);
            }

            if (event.type == 'REMOVE GENERAL UNIT') {
                store = store.filter(item => {
                    return item.generalunitid != event.id
                });
            }

            if (event.type == 'UPDATE GENERAL UNIT') {
                return store.map(function (item) {
                    if (item.generalunitid == event.id) {
                        let item1 = Object.assign({}, event.item);
                        return item1;
                    }
                    return item;
                });
            }

            return store;
        },
        generalDataDescriptorSources: function (store = [], event) {
            if (event.type == 'SET GENERAL DATA DESCRIPTOR SOURCES') {
                store = event.items;
            }

            if (event.type == 'ADD GENERAL SOURCE') {
                store.push(event.item);
            }

            if (event.type == 'REMOVE GENERAL SOURCE') {
                store = store.filter(item => {
                    return item.generalsourceid != event.id
                });
            }

            if (event.type == 'UPDATE GENERAL SOURCE') {
                return store.map(function (item) {
                    if (item.generalsourceid == event.id) {
                        let item1 = Object.assign({}, event.item);
                        return item1;
                    }
                    return item;
                });
            }

            return store;
        },
        descriptorsPlaceTypes:

            function (store = [], event) {
                if (event.type == 'SET DESCRIPTORS PLACE TYPES') {
                    store = event.items;
                }

                if (event.type == 'ADD CONTENT TYPES') {
                    store.push(event.item);
                }

                if (event.type == 'REMOVE CONTENT TYPE') {
                    store = store.filter(item => {
                        return item.contenttypeid != event.id
                    });
                }

                if (event.type == 'UPDATE CONTENT TYPE') {
                    return store.map(function (item) {
                        if (item.contenttypeid == event.id) {
                            let item1 = Object.assign({}, event.item);
                            return item1;
                        }
                        return item;
                    });
                }

                if (event.type == 'SET DESCRIPTORS CONTENT TYPE') {
                    return store.map(function (item) {
                        if (item.contenttypeid == event.id) {
                            let item1 = Object.assign({}, item, {
                                contentTypeArray: event.items
                            });
                            return item1;
                        }
                        return item;
                    });
                }

                if (event.type === 'ADD CONTENT TYPE URL') {
                    console.log('add content');
                    return store.map(function (item) {
                        if (item.contenttypeid == event.item.conenttypeid) {
                            console.log(item);
                            console.log(event.item);
                            item.contentTypeArray.push(event.item);
                        }
                        return item;
                    });
                }

                if (event.type === 'REMOVE CONTENT TYPE URL') {
                    console.log('remove');
                    console.log(store);
                    return store.map(function (item) {
                        console.log(item);
                        console.log(event.id);
                        if (item.contenttypeid == event.id.conenttypeid) {
                            console.log(item.contentTypeArray);
                            item.contentTypeArray = item.contentTypeArray.filter(statusItem => {
                                return statusItem.placeurlid !== event.id.placeurlid;
                            });
                            let newItem = Object.assign({}, item);
                            return newItem;
                        }
                        return item;
                    });
                }

                if (event.type === 'UPDATE CONTENT TYPE URL') {
                    console.log('update content');
                    return store.map(function (item) {
                        if (item.placeid == event.item.placeid) {
                            item.contentTypeArray = item.contentTypeArray.map(statusItem => {
                                if (statusItem.placeurlid === event.item.placeurlid) {
                                    let editedItem = Object.assign({}, event.item);
                                    return editedItem;
                                }
                                return statusItem;
                            });
                            let newItem = Object.assign({}, item);
                            return newItem;
                        }
                        return item;
                    });
                }
                return store;
            },
        trackingTypes: function (store = [], event) {
            if (event.type == 'SET TRACKING TYPES') {
                store = event.items;
            }

            if (event.type == 'ADD PLACE TRACKING TYPE') {
                store.push(event.item);
            }

            if (event.type == 'REMOVE PLACE TRACKING TYPE') {
                store = store.filter(item => {
                    return item.trackingtypeid != event.id
                });
            }

            if (event.type == 'UPDATE PLACE TRACKING TYPE') {
                return store.map(function (item) {
                    if (item.trackingtypeid == event.id) {
                        let item1 = Object.assign({}, event.item);
                        return item1;
                    }
                    return item;
                });
            }
            return store;
        }
        ,
        trackingValueTypes: allActionsReducer('SET TRACKING VALUE TYPES', 'ADD PLACE TRACKING VALUE TYPE', 'UPDATE PLACE TRACKING VALUE TYPE', 'REMOVE PLACE TRACKING VALUE TYPE', 'trackingvaluetypeid'),
        statusCategories:
            allActionsReducer('SET PLACE STATUS CATEGORIES', 'ADD PLACE STATUSES CATEGORY', 'UPDATE PLACE STATUSES CATEGORY', 'REMOVE DESCRIPTOR STATUSES CATEGORY', 'statuscategoryid'),
        planningPhases: function(store=[], action) {
            if (action.type === 'SET PLANNING PHASES') {
                store = action.items;
            }

            if (action.type === 'ADD PLAN PHASE') {
                store.push(action.item);
            }

            if (action.type === 'UPDATE PLAN PHASE') {
                store.push(action.item);
                return store.map(function (item) {
                    if (item.planningphaseid == action.id) {
                        let item1 = Object.assign({}, action.item);
                        return item1;
                    }
                    return item;
                });
            }

            if (action.type === 'REMOVE PLAN PHASE') {
                store = store.filter(item => {
                    return item.planningphaseid != action.id
                });
            }

            return store
        },
        planPublicationType:
            allActionsReducer('SET PLAN PUBLICATION TYPE', 'ADD PLAN PUBLICATION TYPE', 'UPDATE PLAN PUBLICATION TYPE', 'REMOVE PLAN PUBLICATION TYPE', 'planpublicationunittypeid'),
        tenderPublicationUnits:
            allActionsReducer('SET TENDER PUBLICATION UNIT', 'ADD TENDER PUBLICATION UNIT TYPE', 'UPDATE TENDER PUBLICATION UNIT TYPE', 'REMOVE TENDER PUBLICATION UNIT TYPE', 'tenderpublicationunittypeid'),
        tenderPublicationEvents:
            allActionsReducer('SET TENDER PUBLICATION EVENT', 'ADD TENDER PUBLICATION EVENT TYPE', 'UPDATE TENDER PUBLICATION EVENT TYPE', 'REMOVE TENDER PUBLICATION EVENT TYPE', 'tenderpublicationeventtypeid'),
        tenderPublicationCost:
            allActionsReducer('SET TENDER PUBLICATION COST', 'ADD TENDER PUBLICATION COST TYPE', 'UPDATE TENDER PUBLICATION COST TYPE', 'REMOVE TENDER PUBLICATION COST TYPE', 'tenderpublicationcosttypeid'),
        planningPhasesCategory:
            allActionsReducer('SET PLANNING PHASES CATEGORY', 'ADD PLAN PHASE CATEGORY', 'UPDATE PLAN PHASE CATEGORY', 'REMOVE PLAN PHASE CATEGORY', 'planningphasecategoryid'),
        contractors:

            function (store = [], event) {
                if (event.type == 'SET CONTRACTORS') {
                    store = event.items.contractors;
                }

                if (event.type == 'SET SINGLE CONTRACTOR') {
                    return store.map(function (item) {
                        if (item.contractorid == event.id) {
                            let item1 = Object.assign({}, item, {
                                tenders: event.items[1]
                            });
                            return item1;
                        }
                        return item;
                    });
                }

                if (event.type == 'ADD TENDER CONTACTORS 44') {
                    store.push(event.item);
                }

                if (event.type == 'UPDATE TENDER CONTACTORS 44') {
                    console.log(event);
                    console.log(store);
                    return store.map(function (item) {
                        if (item.contractorid == event.id) {
                            let item1 = Object.assign({}, event.item);
                            return item1;
                        }
                        return item;
                    });
                }


                if (event.type == 'ADD TENDER CONTACTORS') {
                    return store.map(function (item) {
                        if (item.contractorid == event.item.contractorid) {
                            item.tenders.push(event.item);
                        }
                        return item;
                    });
                }

                if (event.type == 'REMOVE TENDER CONTACTORS') {
                    return store.map(function (item) {
                        if(item.contractorid == event.id.contractorid){
                            item.tenders = item.tenders.filter((statusItem) => {
                                return statusItem.tendercontractorid !== event.id.tendercontractorid;
                            });
                            return Object.assign({},item);
                        }
                        return item;
                    });
                }

                if (event.type == 'UPDATE TENDER CONTACTORS') {
                    return store.map(function (item) {
                        if (item.contractorid == event.item.contractorid) {
                            item.tenders = item.tenders.map(statusItem => {
                                if (statusItem.tendercontractorid == event.item.tendercontractorid) {
                                    let editedItem = Object.assign({}, event.item);
                                    return editedItem;
                                }
                                return statusItem;
                            });
                            let newItem = Object.assign({}, item);
                            return newItem;
                        }
                        return item;
                    });
                }
                return store;
            }
        ,
        contractorArr: function (store = [], event) {
            if (event.type == 'SET TENDERS CONTACTORS') {
                store = event.items;
            }
            return store;
        },


        initiatorData: function (store = [], event) {
            if (event.type == 'SET INITIATORS') {
                store = event.items;
            }

            if (event.type == 'ADD INITIATOR TYPE') {
                store.initiatorTypes.push(event.item);
            }

            if (event.type == 'REMOVE INITIATOR TYPE') {
                store = store.initiatorTypes.filter(item => {
                    return item.statusid != event.id
                });
            }

            if (event.type == 'UPDATE INITIATOR TYPE') {
                return store.initiatorTypes.map(function (item) {
                    if (item.statusid == event.id) {
                        let item1 = Object.assign({}, event.item);
                        return item1;
                    }
                    return item;
                });
            }
            return store;
        },

        initiatorsByType: function (store = [], event) {
            if (event.type == 'SET INITIATORS BY TYPE') {
                store = event.items;
            }

            if (event.type == 'ADD INITIATOR') {
                store.initiators.push(event.item);
            }

            if (event.type == 'REMOVE INITIATOR') {
                return store.initiators = store.initiators.filter(item => {
                    return item.initiatorid != event.id.initiatorid
                });
            }

            if (event.type == 'UPDATE INITIATOR') {
                return store.initiators.map(function (item) {
                    if (item.initiatorid == event.id) {
                        let item1 = Object.assign({}, event.item);
                        return item1;
                    }
                    return item;
                });
            }
            return store;
        },
        singleInitiator:
            function (store = [], event) {
                if (event.type == 'SET SINGLE INITIATOR') {
                    store = event.items;
                }

                if (event.type == 'SET INITIATOR PLAN') {
                    let temp = store;
                    console.log(store)
                    console.log(event.items)
                    if (store.initiatorid == event.id) {
                        store = Object.assign({}, temp, {
                            initiatorPlans: event.items
                        });
                    }
                }
                if (event.type == 'SET SUBMITTER PLAN') {
                    let temp1 = store;
                    if (store.initiatorid == event.id) {
                        store = Object.assign({}, temp1, {
                            submitterPlans: event.items
                        });
                    }
                }
                if (event.type == 'SET INITIATOR TENDERS') {
                    let temp2 = store;
                    if (store.initiatorid == event.id) {
                        store = Object.assign({}, temp2, {
                            initiatorTenders: event.items
                        });
                    }
                }
                return store;
            }
        ,
        descriptorTerritories: function (store = [], event) {
            if (event.type == 'SET DESCRIPTORS TERRITORIES') {
                store = event.items;
            }

            if(event.type === 'ADD TERRITORY') {
                store.push(event.item);
            }
            if(event.type === 'UPDATE TERRITORY') {
               return store.map(function (item) {
                    if (item.territoryid == event.id) {
                        let item1 = Object.assign({}, event.item);
                        return item1;
                    }
                    return item;
                });
            }

            if (event.type == 'REMOVE TERRITORY') {
                store = store.filter(item => {
                    return item.territoryid != event.id
                });
                console.log(store);
            }
            return store;
        },
        sources: function (store = [], event) {
            if (event.type == 'SET SOURCES') {
                store = event.items;
            }

            if (event.type == 'ADD SOURCE') {
                store.sourceCategories.push(event.item);
            }

            if (event.type == 'REMOVE SOURCE') {
                return  store.sourceCategories = store.sourceCategories.filter(item => {
                    return item.sourcecategoryid != event.id
                });
            }


            if (event.type == 'UPDATE SOURCE') {
                return store.sourceCategories.map(function (item) {
                    if (item.sourcecategoryid == event.id) {
                        let item1 = Object.assign({}, event.item);
                        return item1;
                    }
                    return item;
                });
            }
            return store;

        },
        sourceByCategory: function (store = [], event) {
            if (event.type == 'SET SOURCE BY CATEGORY') {
                store = event.items.sources;
            }

            if (event.type == 'ADD SOURCE CATEGORY') {
                store.push(event.item);
            }

            if (event.type == 'REMOVE SOURCE CATEGORY') {
                store = store.filter(item => {
                    return item.sourceid != event.id
                });
            }

            if (event.type == 'UPDATE SOURCE CATEGORY') {
                return store.map(function (item) {
                    if (item.sourceid == event.id) {
                        let item1 = Object.assign({}, event.item);
                        return item1;
                    }
                    return item;
                });
            }


            return store;
        }
        ,

        singleSource: function (store = [], event) {
            if (event.type == 'SET SINGLE SOURCE') {
                store = event.items[0];
            }
            if (event.type == 'SET SOURCE PLANS') {
                let temp = store;
                store = Object.assign({}, temp, {
                    singleSourcePlan: event.items
                });
            }
            if (event.type == 'SET SOURCE TENDERS') {
                let temp1 = store;
                store = Object.assign({}, temp1, {
                    singleSourceTenders: event.items
                });
            }
            return store;
        }
        ,
        landuses: function (store = [], event) {
            if (event.type == 'SET LANDUSES') {
                store = event.items;
            }

            if (event.type === 'ADD LANDUSE') {
                store.allLanduses.push(event.item);
            }

            if (event.type === 'REMOVE LANDUSE') {
                store = store.allLanduses.filter(item => {
                    return item.landuseid != event.id
                });
            }

            if (event.type === 'UPDATE LANDUSE') {
                return store.allLanduses.map(function (item) {
                    if (item.landuseid == event.id) {
                        let item1 = Object.assign({}, event.item);
                        return item1;
                    }
                    return item;
                });
            }

            return store;
        },
        singleLanduse:

            function (store = [], event) {
                if (event.type == 'SET SINGLE LANDUSE') {
                    store = event.items;
                }
                if (event.type == 'SET LANDUSE PLANS') {
                    let temp = store;
                    store = Object.assign({}, temp, {
                        landusePlans: event.items
                    });
                }
                if (event.type == 'SET LANDUSE TENDERS') {
                    let temp1 = store;
                    store = Object.assign({}, temp1, {
                        landuseTenders: event.items
                    });
                }
                return store;
            }

        ,

        isLoged: function (store = false, event) {
            if (event.type === 'UPDATE LOGIN USER') {
                store = event.item;
            }
            return store;
        }
    })
;

const Storege = createStore(
    todoApp,
    {
        isLoged: isLoged
    },
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export default Storege;
