import React from 'react';
// import Storege from '../../storage/storage'
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Storege from "../../storage/storage";

class InfoTableComponent extends React.Component {
    state = {
        chart: {}
    }

    constructor(props) {
        super(props);
        this.state.chart = props.chart;
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if(oldProps.chart.chartid !== newProps.chart.chartid) {
            this.setState({chart :  newProps.chart})
        }
    }

    changeState(name, e) {
        let temp = this.state.chart;
        temp[name] = e.value;
        this.setState({chart: temp});
    }

    render() {
        var token = document.querySelector("input[name=_token]");
        Storege.currentId = this.state.chart.chartid;
        return (
            <form method="post" action={"/peacenow/home/charts/edit/" + this.state.chart.chartid}>
	            <div className="place-top"><h3>Details</h3>
		            <div className="left-id">Chart ID: {this.state.chart.chartid}</div>
	            </div>
                <table className={"table table-striped"} valign={"middle"}>
                    <tbody>
                    <tr>
                        <td>Chart Name</td>
                        <td>
                            {this.state.chart.chartname ? this.state.chart.chartname : ""}
                        </td>
                    </tr>
                    <tr>
                        <td>Chart Type</td>
                        <td>
	                        {this.props.types.map((item) => {
		                        return (
			                        this.state.chart.charttypeid == item.charttypeid ? item.charttypename : ''
		                        )
	                        })}
                        </td>
                    </tr>
                    <tr>
                        <td>Chart Style</td>
                        <td>
                             {this.props.styles.map((item) => {
		                        return (
			                        this.state.chart.chartstyleid == item.chartstyleid ? item.chartstylename : ''
		                        )
	                        })}
                        </td>
                    </tr>
                    <tr>
                        <td>Data Source</td>
                        <td>
                            {this.state.chart.datasource ? this.state.chart.datasource : ""}
                        </td>
                    </tr>
                    <tr>
                        <td>Params</td>
                        <td>
                            {this.state.chart.params ? this.state.chart.params : ""}
                        </td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td>
                           {this.state.chart.description ? this.state.chart.description : ""}
                        </td>
                    </tr>
                    <tr>
                        <td>Title</td>
                        <td>
                            {this.state.chart.title ? this.state.chart.title : ""}
                        </td>
                    </tr>
                    <tr>
                        <td>Title Eng</td>
                        <td>
                          {this.state.chart.titleeng ? this.state.chart.titleeng : ""}
                        </td>
                    </tr>
                    <tr>
                        <td>Subtitle</td>
                        <td>
                            {this.state.chart.subtitle ? this.state.chart.subtitle : ""}
                        </td>
                    </tr>
                    <tr>
                        <td>Subtitle Eng</td>
                        <td>
                            {this.state.chart.subtitleeng ? this.state.chart.subtitleeng : ""}
                        </td>
                    </tr>
                    <tr>
                        <td>XAxis</td>
                        <td>
                            {this.state.chart.xaxis ? this.state.chart.xaxis : ""}
                        </td>
                    </tr>
                    <tr>
                        <td>XAxis Eng</td>
                        <td>
                            {this.state.chart.xaxiseng ? this.state.chart.xaxiseng : ""}
                        </td>
                    </tr>
                    <tr>
                        <td>YAxis</td>
                        <td>
                            {this.state.chart.yaxis ? this.state.chart.yaxis : ""}
                        </td>
                    </tr>
                    <tr>
                        <td>YAxis Eng</td>
                        <td>
                            {this.state.chart.yaxiseng ? this.state.chart.yaxiseng : ""}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </form>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let chart = {};
    state.charts.forEach(function (el) {
        if (el.chartid == ownProps.match.params.id) {
            chart = el;
        }
    });
    return {
        chart: Object.assign({},chart),
        types: state.types,
        styles: state.styles
    }
};

const InfoTable = connect(mapStateToProps)(InfoTableComponent);
export default InfoTable;