import React from 'react';
import {connect} from 'react-redux';
import {
    ActionTenderContactors,
    ActionTenderDescriptorContactorAdd, ActionTenderDescriptorContactorDelete, ActionTenderDescriptorContactorEdit,
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import getFullDateYearitem,{getItemData} from "../../../parts/functions";
import ToolButtons from "../../../parts/ToolButtons";
import Storege from "../../../storage/storage";
import TenderContractorsAdd from "./TenderContractorsAdd";
import RemoveButton from "../../../parts/removeBtn";
import CommonTab from "../../../parts/CommonTabClass";
import EditBtn from "../../../parts/EditBtn";

class TenderContactorsComponent extends CommonTab {
    orderFields = {
        "Contractor": 'contractorid',
        "Work Start Date": "workstartdate",
        "Work End Date": "workenddate",
        "Winner Sum": "winnersum",
        "Tender Total Sum": "tendertotalsum",
        "Additional Sum": "tendertotalsum",
        "Remarks": "remarks"
    };

    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            popupType: undefined,
            currentItemIndex: -1,
            rowIndex: -1,
            order: null
        }
    }

    addContentType = (object) => {
        ActionTenderDescriptorContactorAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD TENDER CONTACTOR',
                item: data
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionTenderDescriptorContactorEdit(object.tendercontractorid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE TENDER CONTACTOR',
                item: data,
                id: data.tendercontractorid
            });
            this.changePopupState();
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    render() {
        const {tender, contactors} = this.props;
        if (!tender.contactors) {
            ActionTenderContactors(tender.tenderid);
        }
        let tenderContactors = tender.contactors ? tender.contactors : [];
        this.sortResults(contactors);
        return (
            <React.Fragment>
                <div className={'custom-tool-bar'}>
                    <h3>Tender Contractors</h3>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <div className={'scrolled'}>
                    <table className={'table table-striped geometry-table'}>
                        <thead>
                        {this.orderPanel()}
                        </thead>
                        <tbody>
                        {tenderContactors.map((item, index) => {
                            return (
                                <tr key={index} onClick={
                                    () => {
                                        this.setState({
                                            rowIndex: index,
                                            currentItemIndex: item.tendercontractorid
                                        });
                                    }
                                }
                                    className={'list-group-item clear no-padding' + (this.state.rowIndex == index ? ' active' : '')}>
                                    <td class="edit-buttons">
                                        <div className="flex-row">
                                            <RemoveButton
                                                action={ActionTenderDescriptorContactorDelete}
                                                id={item.tendercontractorid}
                                                descriptor={'REMOVE TENDER CONTACTOR'}
                                            />
                                            <EditBtn
                                                actionType={this.changePopupType}
                                                popupChange={this.changePopupState}
                                                change={this.setCurrentIndex}
                                                id={item.tendercontractorid}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        {getItemData(contactors, 'contractorid', item.contractorid, 'contrarctorname')}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {item.workstartdate ? getFullDateYearitem(item.workstartdate) : ''}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {item.workenddate ? getFullDateYearitem(item.workenddate) : ''}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {item.winnersum}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {item.tendertotalsum}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {item.tendertotalsum}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {item.remarks}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                {this.state.popup ? <TenderContractorsAdd
                    tenderid={tender.tenderid}
                    close={this.changePopupState}
                    itemList={tenderContactors}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        contactors: state.contractorArr
    }
};

const TenderContactors = connect(mapStateToProps)(TenderContactorsComponent);

export default TenderContactors;
