import React from 'react';
import {connect} from 'react-redux';
import Popup from "../../../../parts/Popup";
import Select from "../../../../parts/select";
import CommonTab from "../../../../parts/CommonTabClass";

class addCostComponent extends CommonTab {
    state = {
        popupItem: {
            tenderpublicationid: this.props.publication
        },
        errors: [],
        validateFields: [
            'tenderpublicationcosttypeid',
            'cost',
        ]
    };

    handleChange = (date) => {
        let temp = this.state.popupItem;
        temp.publicationdate = date;
        this.setState({popupItem: temp});
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }


    placeChange = (value) => {
        let temp = this.state.popupItem;
        temp.popupItemid = value.target.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        const {popupItem, validateFields, errors} = this.state;
        const {tenderPublicationCostsType} = this.props;
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('planningphaseid' in popupItem)) {
                            popupItem['planningphaseid'] = ''
                        }
                        if (!('remarks' in popupItem)) {
                            popupItem['remarks'] = ''
                        }
                        if (!('url' in popupItem)) {
                            popupItem['url'] = ''
                        }
                        this.formValidation(popupItem, validateFields);
                    }}
                >
                    <h3>{this.props.button + (this.props.button === 'Add ' ? 'New ' : '')} Cost</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr className={errors.indexOf('tenderpublicationcosttypeid') !== -1 ? 'error' : ''}>
                            <td>Cost Type:</td>
                            <td>
                                <Select
                                    id={"tenderpublicationcosttypeid"}
                                    className={"form-control"}
                                    name={"tenderpublicationcosttypeid"}
                                    value={popupItem.tenderpublicationcosttypeid ? popupItem.tenderpublicationcosttypeid : null}
                                    types={tenderPublicationCostsType}
                                    optionValue={"tenderpublicationcosttypeid"}
                                    optionName={"tenderpublicationcosttypename"}
                                    onChange={(e) => {
                                        this.changeState('tenderpublicationcosttypeid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr  className={errors.indexOf('cost') !== -1 ? 'error' : ''}>
                            <td>Costs:</td>
                            <td>
                                <input
                                    type={'number'}
                                    value={popupItem.cost ? popupItem.cost.replace('$','').replace(',',''): ''}
                                    onChange={e => {
                                        this.changeState('cost', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state , ownProps) => {
    let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item.tenderpublicationcostdetailid == ownProps.id) {
            popupItem = item;
        }
    });
    return {
        tenderPublicationCostsType: state.tenderPublicationCost,
        popupItem : popupItem
    }
};

const AddCost = connect(mapStateToProps)(addCostComponent);
export default AddCost;
