import React from 'react';
import api from "../api";
import {Link} from "react-router-dom";

class ToolButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id
        }
    }

    getId(pathName) {
        let path = (pathName).split('/');
        let id = path[path.length - 1];
        return id;
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if (oldProps.url !== newProps.url) {
            this.setState({id: this.getId(newProps.url)})
        }
        if (oldProps.id !== newProps.id) {
            this.setState({id: newProps.id})
        }
    }

    render() {
        return (
            <div id={'nav-buttons'}>
                <Link
                    to={''}
                    className={'btn btn-primary'}
                    onClick={event => {
                        event.preventDefault();
                        this.props.actionType('add');
                        if(this.props.popupName) {
                            this.props.popupName(this.props.name);
                        }
                        this.props.popupChange();
                    }}
                >Add new</Link>
                {/*<Link*/}
                    {/*to={''}*/}
                    {/*className={'btn btn-primary'}*/}
                    {/*onClick={event => {*/}
                        {/*event.preventDefault();*/}
                        {/*this.props.actionType('edit');*/}
                        {/*if(this.props.popupName) {*/}
                            {/*this.props.popupName(this.props.name);*/}
                        {/*}*/}
                        {/*this.props.popupChange();*/}
                    {/*}}*/}
                {/*>Edit</Link>*/}
                {this.props.customAction ?
                <a
                    href={'#'}
                    onClick={(e) => {
                        e.preventDefault();
                        if (confirm('Are your sure?')) {
                            this.props.customAction(this.state.id, this.props.dispatch);
                            this.props.redirect ? this.props.redirect() : undefined;
                        }
                    }}
                    className={'btn btn-primary'}
                >-</a>
                : ''}
            </div>
        )
    }
}

export default ToolButtons;