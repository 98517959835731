import React from 'react';
import {connect} from 'react-redux';
import {
    ActionDescriptorsOwnerShip,
    ActionOwnershipTypeAdd,
    ActionOwnershipTypeDelete,
    ActionOwnershipTypeEdit
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import ToolButtons from "../../../parts/ToolButtons";
import Search from "../../../parts/Search";
import Storege from "../../../storage/storage";
import PlanOwnershipPopup from "./PlanOwnershipPopup";
import RemoveButton from "../../../parts/removeBtn";
import CommonTab from "../../../parts/CommonTabClass";
import {doFilter} from "../../../parts/functions";
import EditBtn from "../../../parts/EditBtn";

class OwnerShipDescriptorComponent extends CommonTab {

    orderFields = {
        "Ownership Type Name": "ownershiptypename",
        "Ownership Type Eng": "ownershiptypenameeng",
        "Description": "description"
    };

    state = {
        ownerShipTypes: {},
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        order: null,
        searchValue: ''
    };

    constructor(props) {
        super(props);
        this.state.ownerShipTypes = props.ownerShipTypes;
    }

    changeState = (name, e, id) => {
        let temp = this.props.ownerShipTypes;
        temp.forEach(function (item) {
            if (item.ownershiptypeid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            ownerShipTypes: Object.assign({}, temp)
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    addContentType = (object) => {
        ActionOwnershipTypeAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLAN OWNERSHIP TYPE',
                item: data
            });
            this.changePopupState();
            this.props.history.push(data.ownershiptypeid);
        });
    };

    editContentType = (object) => {
        ActionOwnershipTypeEdit(object.ownershiptypeid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLAN OWNERSHIP TYPE',
                item: data,
                id: data.ownershiptypeid
            });
            this.changePopupState();
            this.props.history.push(data.ownershiptypeid);
        });
    };

    render() {
        if (!this.props.ownerShipTypes.length) {
            ActionDescriptorsOwnerShip();
        }
        let {ownerShipTypes} = this.props;
        let {searchValue} = this.state;
        if (!ownerShipTypes) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        let outPutPlaces = ownerShipTypes;
        outPutPlaces = doFilter(
            outPutPlaces,
            [
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'ownershiptypename',
                        'ownershiptypenameeng',
                        'description'
                    ]
                }
            ]
        );
        this.sortResults(outPutPlaces);
        return (
            <div>
                <div className="inline-toolbuttons">
                    <h3>Ownership Types</h3>
                    <div className={'tool-bar'}>
                        <ToolButtons
                            actionType={this.changePopupType}
                            popupChange={this.changePopupState}
                            id={this.state.currentItemIndex}
                        />
                        <Search
                            change={this.search}
                        />
                    </div>
                </div>
                <table className='table table-striped'>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {outPutPlaces.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                e => {
                                    e.preventDefault();
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.ownershiptypeid
                                    });
                                }
                            } className={this.state.rowIndex == index ? 'active' : ''}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionOwnershipTypeDelete}
                                        id={item.ownershiptypeid}
                                        descriptor={'REMOVE PLAN OWNERSHIP TYPE'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.ownershiptypeid}
                                    />
                                </td>
                                <td>{item.ownershiptypename}</td>
                                <ColumnResizer className="columnResizer" minWidth={5} />
                                <td>{item.ownershiptypenameeng}</td>
                                <ColumnResizer className="columnResizer" minWidth={5} />
                                <td>{item.description}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <PlanOwnershipPopup
                    openClose={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ownerShipTypes: state.ownerShipType
    }
};
const DescriptorOwnership = connect(mapStateToProps)(OwnerShipDescriptorComponent);

export default DescriptorOwnership;