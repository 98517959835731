import React from 'react';
import Popup from "../../parts/Popup";
import {connect} from 'react-redux';
import Select from "../../parts/select";
import CommonTab from "../../parts/CommonTabClass";
import DatePicker from "react-datepicker";
import {getItemData} from "../../parts/functions";

class DescriptorAddComponent extends CommonTab {

    state = {
        placeUrl: {
            conenttypeid: this.props.typeId,
            placeid : this.props.placeid
        },
        errors: [],
        validateFields: [
            'placeid',
            'url',
            'conenttypeid',
            // 'createdate'
        ]
    };

    constructor(props) {
        super(props);
        if (this.props.button == 'Update' && Object.keys(this.props.placeUrl).length) {
            this.state = Object.assign({}, this.state, {placeUrl: this.props.placeUrl});
        }
        console.log(this.state);
    }

    changeState = (name, e) => {
        let temp = this.state.placeUrl;
        temp[name] = e.value;
        this.setState({
            placeUrl: Object.assign({}, temp)
        });
    };

    changeSelect = (name, e) => {
        let temp = this.state.placeUrl;
        temp[name] = +e.value;
        this.setState({
            placeUrl: Object.assign({}, temp)
        });
    };

    handleChange = (date) => {
        let temp = this.state.placeUrl;
        temp.createdate = date;
        this.setState({placeUrl: temp});
    };

    render() {
        const {placeUrl, errors, validateFields} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <form onSubmit={event => {
                    event.preventDefault();
                    if (!('description' in placeUrl)) {
                        placeUrl['description'] = '';
                    }
                    if (!('createdate' in placeUrl)) {
                        placeUrl['createdate'] = new Date();
                    }

                    this.formValidation(placeUrl, validateFields);
                }}>
                    <h3>{this.props.button} Place URL {placeUrl.placeurlid}</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>
                                Place:
                            </td>
                            <td className={errors.indexOf('placeid') !== -1 ? 'error' : ''}>
                                <Select
                                    types={this.props.places}
                                    value={placeUrl.placeid}
                                    className={'form-control'}
                                    optionValue={'placeid'}
                                    optionName={'name'}
                                    onChange={(e) => {
                                        this.changeSelect('placeid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('placeid') !== -1 ?
                                    <span className="errorText">Choose Place</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>URL:</td>
                            <td className={errors.indexOf('url') !== -1 ? 'error' : ''}>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'plan_name'}
                                       value={placeUrl.url}
                                       onChange={(e) => {
                                           this.changeState('url', e.target);
                                       }}
                                />
                                {errors.indexOf('url') !== -1 ?
                                    <span className="errorText">Set URL</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Content Type:</td>
                            <td className={errors.indexOf('url') !== -1 ? 'error' : ''}>
                                 <Select
                                    types={this.props.contentType}
                                    value={placeUrl.conenttypeid}
                                    className={'form-control'}
                                    optionValue={'contenttypeid'}
                                    optionName={'contenttypename'}
                                    onChange={(e) => {
                                        this.changeSelect('conenttypeid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('contenttypeid') !== -1 ?
                                    <span className="errorText">Set Content Type</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'plan_name'}
                                       value={placeUrl.description}
                                       onChange={(e) => {
                                           this.changeState('description', e.target);
                                       }}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Date:</td>
                            <td className={errors.indexOf('createdate') !== -1 ? 'error' : ''}>
                                <DatePicker
                                    selected={placeUrl.createdate ? new Date(placeUrl.createdate) : ''}
                                    onChange={this.handleChange}
                                    //showTimeSelect
                                    timeFormat="HH:mm:s"
                                    timeIntervals={60}
                                    dateFormat="dd/MM/yyyy h:mm:s"
                                    timeCaption="time"
                                />
                                {errors.indexOf('createdate') !== -1 ?
                                    <span className="errorText">Set Date</span> : ''}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let placeUrl = {};
    ownProps.items.forEach(function (item) {
        if (item.placeurlid == ownProps.id) {
            placeUrl = item;
        }
    });

    return {
        placeUrl: Object.assign({}, placeUrl),
        places: state.places,
        contentType: state.contentType
    }
};

const DescriptorAddUrl = connect(mapStateToProps)(DescriptorAddComponent);

export default DescriptorAddUrl;
