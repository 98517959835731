import React from 'react';
import {connect} from 'react-redux';
import Popup from "../../parts/Popup";
import CommonTab from "../../parts/CommonTabClass";

class ContentTypePopupComponent extends CommonTab {
    state = {
        contentType: {},
        errors: [],
        validateFields: [
            'contenttypename',
            'contenttypenameeng'
        ]
    };

    constructor(props) {
        super(props);
        this.state = Object.assign({}, this.state, {contentType: this.props.contentType});
    }

    changeState(name, e) {
        let temp = this.state.contentType;
        temp[name] = e.value;
        this.setState({contentType: temp});
    }


    render() {
        let {contentType, errors, validateFields} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <div className={'popup-content-types'}>
                    <form onSubmit={event => {
                        event.preventDefault();
                        if (!('description' in contentType)) {
                            contentType['description'] = ''
                        }
                        this.formValidation(contentType, validateFields);
                        // this.props.action(this.state.contentType);
                    }}>
                        <h2>{this.props.button} content type</h2>
                        <table className={'table table-striped'} align={'right'}>
                            <tbody>
                            <tr>
                                <td>Content Type Name :</td>
                                <td className={errors.indexOf('contenttypename') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={contentType.contenttypename}
                                        onChange={(e) => {
                                            this.changeState('contenttypename', e.target);
                                        }}
                                    />
                                    {errors.indexOf('contenttypename') !== -1 ?
                                        <span className="errorText">Content Type Name</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Content Type Name Eng: </td>
                                <td className={errors.indexOf('contenttypenameeng') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={contentType.contenttypenameeng}
                                        onChange={(e) => {
                                            this.changeState('contenttypenameeng', e.target);
                                        }}
                                    />
                                    {errors.indexOf('contenttypenameeng') !== -1 ?
                                        <span className="errorText">Content Type Name Eng</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Description :</td>
                                <td>
                                    <input
                                        type={'text'}
                                        value={contentType.description}
                                        onChange={(e) => {
                                            this.changeState('description', e.target);
                                        }}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </form>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let contentType = {};
    state.descriptorsPlaceTypes.forEach(function (item) {
        if (item.contenttypeid == ownProps.id) {
            contentType = item;
        }
    });
    return {
        contentType: Object.assign({}, contentType)
    }
};

const ContentTypePopup = connect(mapStateToProps)(ContentTypePopupComponent);

export default ContentTypePopup;

