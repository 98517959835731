import React from 'react';
import api from "../../api";
import Storege from "../../storage/storage";


const DescriptorRequest = (route) => {
    fetch(api.ajaxUrl + route, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-type": "application/json",
            "X-CSRF-TOKEN": api.token
        }
    }).then((response) => response.json()).then(function (data) {
        Storege.dispatch({
            type: 'SET PLACE TYPES',
            items: data
        });
    });
};

export default DescriptorRequest;