import React from 'react';
import {connect} from "react-redux";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class TrackingValuePopupComponent extends CommonTab {
    state = {
        trackingValue: {},
        errors : [],
        validateFields: [
            'trackingvaluetypename',
            'trackingvaluetypenameeng'
        ]
    };

    constructor(props) {
        super(props);
        this.state.trackingValue = this.props.trackingValue ? this.props.trackingValue : {};
    }

    changeState(name, e) {
        let temp = this.state.trackingValue;
        temp[name] = e.value;
        this.setState({trackingValue: temp});
    }

    changeStateCheckbox(name, e) {
        let temp = this.state.trackingValue;
        temp[name] = !e.value;
        this.setState({trackingValue: temp});
    }

    render() {
        console.log(this.state);
        console.log(this.props);
        let {trackingValue,errors,validateFields} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <div className={'popup-content-types'}>
                    <form onSubmit={event => {
                        event.preventDefault();
                        this.formValidation(trackingValue, validateFields);

                    }}>
                        <h2>{this.props.button + (this.props.button === 'Add' ? ' New' : '')} Tracking Value</h2>
                        <table className={'table table-striped'} align={'right'}>
                            <tbody>
                            <tr>
                                <td>Place Tracking Value Name :</td>
                                <td>
                                    <input
                                        type={'text'}
                                        value={trackingValue.trackingvaluetypename}
                                        onChange={(e) => {
                                            this.changeState('trackingvaluetypename', e.target);
                                        }}
                                    />
                                    {errors.indexOf('trackingvaluetypename') !== -1 ? <span className="errorText">Please set Tracking Value Name</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Place Tracking Value Name Eng :</td>
                                <td>
                                    <input
                                        type={'text'}
                                        value={trackingValue.trackingvaluetypenameeng}
                                        onChange={(e) => {
                                            this.changeState('trackingvaluetypenameeng', e.target);
                                        }}
                                    />
                                    {errors.indexOf('trackingvaluetypenameeng') !== -1 ? <span className="errorText">Please set Tracking Value Name Eng</span> : ''}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </form>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let content = {};
    console.log(ownProps.id);
    state.trackingValueTypes.map((item) => {
        if (item.trackingvaluetypeid == ownProps.id) {
            content = item;
        }
    });
    return {
        trackingValue: Object.assign({}, content)
    }
};

const TrackingValuePopup = connect(mapStateToProps)(TrackingValuePopupComponent);

export default TrackingValuePopup;