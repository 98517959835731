import React from 'react';
import {connect} from "react-redux";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class PlanTypePopupComponent extends CommonTab{
    state = {
        planType: this.props.planType,
        errors: [],
        validateFields: [
            'plantypename',
            'plantypenameeng'
        ]
    };

    changeState(name, e) {
        let temp = this.state.planType;
        temp[name] = e.value;
        this.setState({planType: temp});
    }

    render() {
        let {planType, errors, validateFields} = this.state;
        return (
            <Popup openClose={this.props.openClose}>
                <div className={'popup-content-types'}>
                    <form onSubmit={event => {
                        event.preventDefault();
                        this.formValidation(planType, validateFields);
                    }}>
                        <h2>{this.props.button} Plan Type</h2>
                        <table className={'table table-striped'} align={'right'}>
                            <tbody>
                            <tr>
                                <td>Plan Type Name :</td>
                                <td className={errors.indexOf('plantypename') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={planType.plantypename}
                                        onChange={(e) => {
                                            this.changeState('plantypename', e.target);
                                        }}
                                    />
                                    {errors.indexOf('plantypename') !== -1 ? <span className="errorText">Please set Plan Type Name</span> : '' }
                                </td>
                            </tr>
                            <tr>
                                <td>Plan Type Nameeng :</td>
                                <td className={errors.indexOf('plantypenameeng') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={planType.plantypenameeng}
                                        onChange={(e) => {
                                            this.changeState('plantypenameeng', e.target);
                                        }}
                                    />
                                    {errors.indexOf('plantypenameeng') !== -1 ? <span className="errorText">Please set Plan Type Name Eng</span> : '' }
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </form>
                </div>
            </Popup>
        )
    }
}



const mapStateToProps = (state, ownProps) => {
    let content = {};
    console.log(state.planType);
    console.log(ownProps.id);
    state.planType.map((item) => {
        if (item.plantypeid == ownProps.id) {
            content = item;
        }
    });
    return {
        planType: Object.assign({}, content)
    }
};

const PlanTypePopup = connect(mapStateToProps)(PlanTypePopupComponent);

export default PlanTypePopup;