import React from 'react';
import {connect} from 'react-redux';
import {ActionPlanDelete, ActionSingleInitiator, ActionSubmitterPlan} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import CommonList from "../../../parts/CommonListClass";
import Search from "../../../parts/Search";
import {doFilter} from "../../../parts/functions";
import PlansRequest from "../../plans/PlansRequest";
import RemoveButton from "../../../parts/removeBtn";
import EditBtn from "../../../parts/EditBtn";
import PlanAdd from "../../plans/PlansAdd";

class SubmitterPlanComponent extends CommonList {

    orderFields = {
        "Submitter": (a, b) => {
            const {initiators} = this.props;
            let result = 0;
            if (initiators[a.submitterid] < initiators[b.submitterid]) {
                result = -1;
            }

            if (initiators[a.submitterid] > initiators[b.submitterid]) {
                result = 1;
            }
            return result
        },
        "Plan Name": 'planname',
        "Place": (a, b) => {
            const {placesList} = this;
            let result = 0;
            if (placesList[a.placeid] < placesList[b.placeid]) {
                result = -1;
            }

            if (placesList[a.placeid] > placesList[b.placeid]) {
                result = 1;
            }
            return result
        },
        "Objective": 'objective',
        "Initiator": (a, b) => {
            const {initiators} = this.props;
            let result = 0;
            if (initiators[a.initiatorid] < initiators[b.initiatorid]) {
                result = -1;
            }

            if (initiators[a.initiatorid] > initiators[b.initiatorid]) {
                result = 1;
            }
            return result
        },
        "Ownership Type": (a, b) => {
            const {ownerShipType} = this.props;
            let result = 0;
            if (ownerShipType[a.ownershiptypeid] < ownerShipType[b.ownershiptypeid]) {
                result = -1;
            }

            if (ownerShipType[a.ownershiptypeid] > ownerShipType[b.ownershiptypeid]) {
                result = 1;
            }
            return result
        },
        "Ownership Descriptor": 'ownershipdescription',
        "Architect Name": 'architectname',
        "Address": 'address',
        "Mawqa": 'mawqa',
        "Remarks": 'remarks',
        "Plan Type": (a, b) => {
            const {planTypes} = this.props;
            let result = 0;
            if (planTypes[a.plantypeid] < planTypes[b.plantypeid]) {
                result = -1;
            }

            if (planTypes[a.plantypeid] > planTypes[b.plantypeid]) {
                result = 1;
            }
            return result
        },
        "Update Date": 'updatedate',
    };

    constructor(props) {
        super(props);
    }

    changeStateSubmitter = (name, e, id) => {
        let temp = this.props.initiator;
        temp.submitterPlans.forEach(function (item) {
            if (item.planid == id) {
                item[name] = e.value;
            }
        })
        this.setState({
            initiator: Object.assign({}, temp)
        });
    };

    render() {
        if (!this.props.plans.length) {
            PlansRequest('plans');
        }
        const {searchValue} = this.state;
        let initiatorId = this.props._id
        let ownerShipTypes = this.props.ownerShipType;
        let planTypes = this.props.planTypes;
        let places = [];
        if (this.props.places) {
            for (var key in this.props.places) {
                places.push(this.props.places[key])
            }
        }
        this.placesList = places;
        let initiator = this.props.initiator;
        let initiators = this.props.initiators;
        if (initiator.length == 0 || (initiatorId != initiator.initiatorid)) {
            ActionSingleInitiator(initiatorId);
        }
        if (!initiator.hasOwnProperty('submitterPlans')) {
            ActionSubmitterPlan(initiatorId);
        }
        let submitterPlans = this.props.initiator.submitterPlans;
        if (!submitterPlans) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        let outPutPlans = doFilter(submitterPlans, [
            {
                name: 'searchValue',
                value: searchValue,
                searchId: [
                    'planname',
                    'objective',
                    'ownershipdescription',
                    'architectname',
                    'address',
                    'mawqa',
                    'remarks',
                    'updatedate',
                ],
                additionalFilter: (item, str) => {
                    console.log(item);
                    let result = 0;

                    const {placesList} = this;
                    const {initiators} = this.props;
                    const {ownerShipType} = this.props;
                    const {planTypes} = this.props;

                    if (placesList[item.placeid] && placesList[item.placeid].indexOf(str) != -1) {
                        result++
                    }
                    if (initiators[item.initiatorid] && initiators[item.initiatorid].indexOf(str) != -1) {
                        result++
                    }
                    if (initiators[item.submitterid] && initiators[item.submitterid].indexOf(str) != -1) {
                        result++
                    }
                    if (ownerShipType[item.ownershiptypeid] && ownerShipType[item.ownershiptypeid].indexOf(str) != -1) {
                        result++
                    }
                    if (planTypes[item.plantypeid] && planTypes[item.plantypeid].indexOf(str) != -1) {
                        result++
                    }
                    return result;
                }
            }
        ]);
        this.sortResults(outPutPlans);
        return (
            <div>
                <div className="inline-toolbuttons">
                    <h3>Submitter Plans:</h3>
                    <Search
                        change={this.search}
                        id={'search2'}
                    />
                </div>
                <div className="scrolled">
                <table className='table table-striped'>
                    <thead>
                    {this.orderPanelTabel()}
                    </thead>
                    <tbody>
                    {(outPutPlans ? outPutPlans : []).map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <div className="flex-row">
                                        <RemoveButton
                                            action={ActionPlanDelete}
                                            id={item.planid}
                                            descriptor={'REMOVE PLAN'}
                                        />
                                        <EditBtn
                                            actionType={this.changePopupType}
                                            popupChange={this.changePopupState}
                                            change={this.setCurrentIndex}
                                            id={item.planid}
                                        />
                                    </div>
                                </td>
                                <td>
                                    {initiators ? initiators[item.submitterid] : ''}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.planname}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.placeid ? (places ? places : []).map((unit, index) => {
                                        if (item.placeid == index) {
                                            return unit;
                                        }
                                    }) : ''}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.objective}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {initiators ? initiators[item.initiatorid] : ''}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {ownerShipTypes ? ownerShipTypes[item.ownershiptypeid] : ''}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.ownershipdescription}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.architectname}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.address}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.mawqa}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.remarks}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {/*{item.plantypeid ? (planTypes ? planTypes : []).map((unit,index) => {*/}
                                    {/*if (item.plantypeid == index) {*/}
                                    {/*return unit;*/}
                                    {/*}*/}
                                    {/*}) : ''}*/}
                                    {planTypes ? planTypes[item.plantypeid] : ''}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.updatedate}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                </div>
                {this.state.popup ? <PlanAdd
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        initiator: state.singleInitiator,
        initiators: state.initiatorData.initiators_arr,
        places: state.initiatorData.places,
        ownerShipType: state.initiatorData.ownershiptype_arr,
        planTypes: state.initiatorData.plantypes_arr,
        plans: state.plans
    }
};
const SubmitterPlan = connect(mapStateToProps)(SubmitterPlanComponent);

export default SubmitterPlan;