import React from 'react';
import SelectObject from "../../parts/SelectObject";
import {connect} from "react-redux";
import Popup from "../../parts/Popup";
import DatePicker from "react-datepicker";
import CommonTab from "../../parts/CommonTabClass";

class TerritorieDetailsAddComponent extends CommonTab {

    state = {
        territory: this.props.territory,
        errors: [],
        validateFields: [
            'territoryname',
            'territorynameeng',
            // 'remarks'
        ]
    };

    changeState = (name, e) => {
        let temp = this.state.territory;
        console.log(temp);
        temp[name] = e.value;
        this.setState({territory: temp});
    };

    changeDate = (date) => {
        let temp = this.state.territory;
        temp.sovereigntydate = date;
        this.setState({
            territory: Object.assign({}, temp)
        });
    };

    handleChange = (date) => {
        let temp = this.state.territory;
        temp.sovereigntydate = date;
        this.setState({territory: temp});
    };

    render() {
        const {territory, validateFields, errors} = this.state;
        console.log(territory);
        return (
            <Popup openClose={this.props.close}>
                <form onSubmit={event => {
                    event.preventDefault();
                    if(!('sovereigntytypeid' in territory)) {
                        territory['sovereigntytypeid'] = 0;
                    }
                    if(!('sovereigntyid' in territory)) {
                        territory['sovereigntyid'] = 0;
                    }

                    if(!('shapewgs84gmapsencoded' in territory)) {
                        territory['shapewgs84gmapsencoded'] = '';
                    }
                    if(!('shapewgs84wkt' in territory)) {
                        territory['shapewgs84wkt'] = '';
                    }
                    if(!('shapewkt' in territory)) {
                        territory['shapewkt'] = '';
                    }
                    if(!('shapelength' in territory)) {
                        territory['shapelength'] = 0;
                    }
                    if(!('shapearea' in territory)) {
                        territory['shapearea'] = 0;
                    }
                    if(!('url' in territory)) {
                        territory['url'] = 0;
                    }
                    if(!('territorydescription' in territory)) {
                        territory['territorydescription'] = 0;
                    }
                    if(!('sovereigntydate' in territory)) {
                        territory['sovereigntydate'] = 0;
                    }

                    this.formValidation(territory, validateFields);
                }}>
                    <h2>{this.props.button} Territory</h2>
                    <table className={'table table-striped'}>
                        <tbody>
                        <tr>
                            <td>Territory Name:</td>
                            <td className={errors.indexOf('territoryname') !== -1 ? 'error' : ''}>
                                <input
                                    type={'text'}
                                    value={territory.territoryname}
                                    onChange={(e) => {
                                        this.changeState('territoryname', e.target);
                                    }}
                                />
                                {errors.indexOf('territoryname') !== -1 ? <span className="errorText">Please set Territory Name</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>Territory Name Eng</td>
                            <td className={errors.indexOf('territorynameeng') !== -1 ? 'error' : ''}>
                                <input
                                    type={'text'}
                                    value={territory.territorynameeng}
                                    onChange={(e) => {
                                        this.changeState('territorynameeng', e.target);
                                    }}
                                />
                                {errors.indexOf('territorynameeng') !== -1 ? <span className="errorText">Please set Territory Name Eng</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>Sovereignty date:</td>
                            <td>
                                {console.log(territory.sovereigntydate)}
                                <DatePicker
                                    selected={territory.sovereigntydate ? new Date(territory.sovereigntydate) : ''}
                                    onChange={this.handleChange}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Territory Description:</td>
                            <td>
                            <textarea
                                name={'red'}
                                className={'form-control'}
                                value={territory.territorydescription}
                                onChange={(e) => {
                                    this.changeState('territorydescription', e.target);
                                }}
                            />
                            </td>
                        </tr>
                        <tr>
                            <td>URL:</td>
                            <td>
                                <input
                                    type={'text'}
                                    value={territory.url}
                                    onChange={(e) => {
                                        this.changeState('url', e.target);
                                    }}
                                />
                                <a
                                    href={'http://' + territory.url}
                                    target={'_blank'}
                                >Test</a>
                            </td>
                        </tr>
                        <tr>
                            <td>Shape Area</td>
                            <td>
                                <input
                                    type={'text'}
                                    value={territory.shapearea}
                                    onChange={(e) => {
                                        this.changeState('shapearea', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Shape Length</td>
                            <td>
                                <input
                                    type={'text'}
                                    value={territory.shapelength}
                                    onChange={(e) => {
                                        this.changeState('shapelength', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Shape WKT</td>
                            <td>
                                <input
                                    type={'text'}
                                    value={territory.shapewkt}
                                    onChange={(e) => {
                                        this.changeState('shapewkt', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Shape WKT84</td>
                            <td>
                                <input
                                    type={'text'}
                                    value={territory.shapewgs84wkt}
                                    onChange={(e) => {
                                        this.changeState('shapewgs84wkt', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Shape WKT84Gmaps</td>
                            <td>
                                <input
                                    type={'text'}
                                    value={territory.shapewgs84gmapsencoded}
                                    onChange={(e) => {
                                        this.changeState('shapewgs84gmapsencoded', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Sovereignty:</td>
                            <td>
                                <SelectObject
                                    name={'sovereignities'}
                                    value={territory.sovereigntyid}
                                    types={this.props.sovereignties}
                                    change={(e) => {
                                        this.changeState('sovereigntyid', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Sovereignty Type:</td>
                            <td>
                                <SelectObject
                                    name={'sovereignities_type'}
                                    value={territory.sovereigntytypeid}
                                    types={this.props.sovereigntyTypes}
                                    change={(e) => {
                                        this.changeState('sovereigntytypeid', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    {/*<h2>Location</h2>*/}
                    {/*<table className={'table table-striped'}>*/}
                    {/*<tbody>*/}
                    {/*<tr>*/}
                    {/*<td>Map URL:</td>*/}
                    {/*<td>*/}
                    {/*<a target={'_blank'}*/}
                    {/*href={'http://sw.peacenow.org.il/TerritoryMap.html?TerritoryID=' + territory.territoryid}>{'http://sw.peacenow.org.il/TerritoryMap.html?TerritoryID=' + territory.territoryid}</a>*/}
                    {/*</td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                    {/*<td>Shape Area:</td>*/}
                    {/*<td>{territory.shapearea}</td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                    {/*<td>Shape Length:</td>*/}
                    {/*<td>{territory.shapelength}</td>*/}
                    {/*</tr>*/}
                    {/*</tbody>*/}
                    {/*</table>*/}
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let territory = {};
    state.descriptorTerritories.forEach(item => {
        if (item.territoryid == ownProps.id) {
            territory = item;
        }
    });


    return {
        territory: Object.assign({}, territory),
        sovereignties: state.sovereignties,
        sovereigntyTypes: state.sovereignties
    }
};

const TerritorieDetailsAdd = connect(mapStateToProps)(TerritorieDetailsAddComponent);

export default TerritorieDetailsAdd;

