import React from 'react';
import {connect} from 'react-redux';
import {
    ActionPlacesStatusAdd, ActionPlacesStatusDel,
    ActionSetStatuses,
    PlacesStatusEdit
} from "../actions";
import ColumnResizer from "react-column-resizer";
import ToolButtons from "../ToolButtons";
import Storege from "../../storage/storage";
import StatusesAdd from "./StatusesAdd";
import RemoveButton from "../removeBtn";
import CommonTab from "../CommonTabClass";
import EditBtn from "../EditBtn";
import getFullDateYear from  "../../parts/functions"

class StatusesComponent extends CommonTab {

    constructor(props){
        super(props);
    }

    orderFields = {
        "Status Date": 'statusdate',
        "Status": 'statusid',
        "Remarks": 'remarks'
    };

    state = {
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        order: null
    };

    addContentType = (object) => {
        ActionPlacesStatusAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLACE STATUS',
                item: data,
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        PlacesStatusEdit(object.placestatusid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLACE STATUS',
                item: data,
                id: data.placestatusid
            });
            this.changePopupState();
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    render() {
        const {place} = this.props;
        if (!place.statusList) {
            ActionSetStatuses(place.placeid);
        }
        let statuses = place.statusList ? place.statusList : [];

        this.sortResults(statuses);

        return (
            <React.Fragment>
                <div className={'inline-toolbuttons'}>
                    <h3>Statuses</h3>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <table className={"table table-striped"} valign={"middle"}>
                    <thead>
                        {this.orderPanel()}
                    </thead>
                    <tbody>
                    {statuses.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                () => {
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.placestatusid
                                    });
                                }
                            } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                {console.log(item.placestatusid)}

                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionPlacesStatusDel}
                                        id={item.placestatusid}
                                        descriptor={'REMOVE PLACE STATUS'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.placestatusid}
                                    />
                                </td>
                                <td>{getFullDateYear(item.statusdate)}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{this.props.statuses[item.statusid]}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.remarks}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

                {console.log(this.changePopupState)}
                {this.state.popup ? <StatusesAdd
                    placeid={place.placeid}
                    close={this.changePopupState}
                    itemList={place.statusList}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return Object.assign({},ownProps,{
        statuses: state.statuses,
    })
};

const Statuses = connect(mapStateToProps)(StatusesComponent);
export default Statuses;
