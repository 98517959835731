import React from 'react';
import {default as RSelect} from 'react-select'

export default class Select extends React.Component {
    render() {
    	// console.log(this.props.types);
        let optionName = this.props.optionName;
        let optionValue = this.props.optionValue;
        let types = this.props.types ? this.props.types : [];
        let onFocus = this.props.onFocus ? this.props.onFocus : ()=>{};

       let selected = undefined;
        let options = types.map((item, index) => {
		    let obj = {value: item[optionValue], label: item[optionName]};
		    if (this.props.value == item[optionValue]) {
			    selected = obj
		    }
		    return obj;
	    });

	     return (
            <RSelect
                className={'select-autocomplete'}
                options = {options}
                onFocus = {onFocus}
                onChange = {(value)=>{this.props.onChange({target : value, preventDefault : function () {

	                }})}}
                value = {selected ? selected : ''}
            />
        );
        // return (
        //     <select className={this.props.className} name={this.props.name} value={this.props.value != null ? this.props.value : '-1'} onChange={this.props.onChange} id={this.props.id}>
        //         <option value="-1"> </option>
        //         {types.map(function (item, index) {
        //             return (
        //                 <option key={index} value={item[optionValue]}>{item[optionName]}</option>
        //             )
        //         })}
        //     </select>
        // )
    }
}
