    import React from 'react';
import {connect} from 'react-redux';
import GeneralRequest from "./GeneralRequest";
import ColumnResizer from "react-column-resizer";
import CommonList from "../../parts/CommonListClass";
import {ActionGeneralSourceAdd, ActionGeneralSourceDelete, ActionGeneralSourceEdit} from "../../parts/actions";
import Storege from "../../storage/storage";
import {doFilter} from "../../parts/functions";
import RemoveButton from "../../parts/removeBtn";
import Search from "../../parts/Search";
import ToolButtons from "../../parts/ToolButtons";
import GeneralDataDescriptorSourceAdd from "./general_data_descriptor_source_add";
    import EditBtn from "../../parts/EditBtn";

class GeneralDataDescriptorSourceComponent extends CommonList {

    orderFields = {
        "Source Name": 'generalsourcename',
        "Source Name Eng": 'generalsourcenameeng',
        "Description": 'description'
    };

    state = {
        items: {},
        popup: false,
        searchValue: '',
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        order: null
    };

    constructor(props) {
        super(props);
        this.state.items = props.items;
    }

    changeStateSources = (name, e, id) => {
        let temp = this.props.items;
        temp.forEach(function (item) {
            if (item.generalsourceid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            items: Object.assign({}, temp)
        });
    };

    orderPanel = () => {
        let fields = [];
        if (this.orderFields) {
            for (let label in this.orderFields) {
                fields.push((
                    <React.Fragment>
                        <th>
                            <a className="sort-items" onClick={() => {
                                this.setOrder(this.orderFields[label])
                            }}><h4>{label}</h4>{this.diractionSymbol(this.orderFields[label])}</a>
                        </th>
                        {Object.keys(this.orderFields).indexOf(label) !== Object.keys(this.orderFields).length - 1 ?
                            <ColumnResizer className="columnResizer" minWidth={5}/> : ''}
                    </React.Fragment>
                ))
            }
        }
        if (fields.length) {
            return (
                <tr className={'sortable-btn'}>
                    <th></th>
                    {fields}
                </tr>
            )
        }
        return null;
    };

    addContentType = (object) => {
        ActionGeneralSourceAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD GENERAL SOURCE',
                item: data
            });
            this.changePopupState();
        });
    };


    editContentType = (object) => {
        ActionGeneralSourceEdit(object.generalsourceid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE GENERAL SOURCE',
                item: data,
                id: data.generalsourceid
            });
            this.changePopupState();
        });
    };

    render() {
        if (!this.props.items.length) {
            GeneralRequest('general');
        }
        let {items} = this.props;
        let {searchValue} = this.state;

        items = doFilter(items, [
            {
                name: 'searchValue',
                value: searchValue,
                searchId: [
                    'generalsourcename',
                    'generalsourcenameeng'
                ]
            }
        ]);

        this.sortResults(items);

        return (
            <div>
                <div className="inline-toolbuttons">
                <h3>Sources</h3>
                <div className={'tool-bar'}>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                    <Search
                        change={this.search}
                        id={'general-search-1'}
                    />
                </div>
                </div>
                <table className={'table table-striped'}>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {items.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                () => {
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.generalsourceid
                                    });
                                }
                            } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                <td>
                                    <div className="flex-row">
                                    <RemoveButton
                                        action={ActionGeneralSourceDelete}
                                        id={item.generalsourceid}
                                        descriptor={'REMOVE GENERAL SOURCE'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.generalsourceid}
                                    />
                                    </div>
                                </td>
                                <td>{item.generalsourcename}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.generalsourcenameeng}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.description}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <GeneralDataDescriptorSourceAdd
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        items: state.generalDataDescriptorSources
    }
};
const GeneralDataDescriptorSource = connect(mapStateToProps)(GeneralDataDescriptorSourceComponent);

export default GeneralDataDescriptorSource;