import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import api from "../../../api";
import {
    ActionSourceAdd,
    ActionSourceByCategory, ActionSourceDelete, ActionSourceEdit
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import ToolButtons from "../../../parts/ToolButtons";
import Storege from "../../../storage/storage";
import SourceByCategoryAdd from "./source-by-category-add";
import RemoveButton from "../../../parts/removeBtn";
import CommonTab from "../../../parts/CommonTabClass";
import EditBtn from "../../../parts/EditBtn";
import {getItemData} from "../../../parts/functions";

class SourceByCategoryComponent extends CommonTab {

    orderFields = {
        "Source Category": "sourcecategoryid",
        "Source Name": "sourcename",
        "Source Name Eng": "sourcenameeng"
    };

    state = {
        sourceByCategory: {},
        sourcesCategory: this.props.sourcesCategory,
        popup: false,
        searchValue: '',
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        order: null
    };

    addContentType = (object) => {
        ActionSourceAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD SOURCE CATEGORY',
                item: data
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionSourceEdit(object.sourceid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE SOURCE CATEGORY',
                item: data,
                id: data.sourceid
            });
            this.changePopupState();
        });
    };

    constructor(props) {
        super(props);
        this.state.sourceByCategory = props.sourceByCategory
        this.state.sourcesCategory = props.sourcesCategory
    }

    componentDidUpdate(props){
        this.state.sourceByCategory = props.sourceByCategory;
        this.state.sourcesCategory = props.sourcesCategory;
        super.componentDidUpdate(props)
    }

    changeStateCategory = (name, e, id) => {
        let temp = this.props.sourceByCategory;
        temp.forEach(function (item) {
            if (item.sourceid == id) {
                item[name] = +e.value;
            }
        })
        this.setState({
            sourceByCategory: Object.assign({}, temp)
        });
    };

    changeState = (name, e, id) => {
        let temp = this.props.sourceByCategory;
        temp.forEach(function (item) {
            if (item.sourceid == id) {
                item[name] = e.value;
            }
        })
        this.setState({
            sourceByCategory: Object.assign({}, temp)
        });
    };

    render() {
        let sourceByCategory = this.props.sourceByCategory;
        if (!sourceByCategory.length || (sourceByCategory.length && this.props.match.params.id != sourceByCategory[0].sourcecategoryid)) {
            ActionSourceByCategory(this.props.match.params.id);
        }
        let {sourcesCategory} = this.state;
        console.log(this.props);

        this.sortResults(sourceByCategory);
        if (!sourcesCategory) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className={'inline-toolbuttons'}>
                    <h3>Sources</h3>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <table className={'table table-striped'}>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {(sourceByCategory ? sourceByCategory : []).map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                () => {
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.sourceid
                                    });
                                }
                            } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionSourceDelete}
                                        id={item.sourceid}
                                        descriptor={'REMOVE SOURCE CATEGORY'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.sourceid}
                                    />
                                </td>
                                {console.log(sourcesCategory)}
                                {console.log(item.sourcecategoryid)}
                                <td>
                                    <Link
                                        to={api.getRoute('sources/category/' + item.sourcecategoryid + '/source/plan_publications/' + item.sourceid)}>
                                        {getItemData(sourcesCategory, 'sourcecategoryid', item.sourcecategoryid, 'sourcecategoryname')}
                                    </Link>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    <Link
                                        to={api.getRoute('sources/category/' + item.sourcecategoryid + '/source/plan_publications/' + item.sourceid)}>
                                        {item.sourcename}</Link>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    <Link
                                        to={api.getRoute('sources/category/' + item.sourcecategoryid + '/source/plan_publications/' + item.sourceid)}>
                                        {item.sourcenameeng}
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <SourceByCategoryAdd
                    categoryId={this.props.match.params.id}
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        sourceByCategory: state.sourceByCategory,
        sourcesCategory: state.sources.sourceCategories
    }
};

const SourceByCategory = connect(mapStateToProps)(SourceByCategoryComponent);
export default SourceByCategory;