import React from 'react';
import {connect} from 'react-redux';
import SelectObject from "../../parts/SelectObject";
import TabsRow from "../../parts/tabs-row";
import PlanTabs from "./plans_tabs";
import {Route} from "react-router-dom";
import PlanDetails from "./tabs/PlanDetails";
import PlanPublications from "./tabs/PlanPublication";
import PlanLanduses from "./tabs/PlanLanduses";
import PlanRelationship from "./tabs/PlanRelationship";
import PlanTenderPublications from "./tabs/PlanTenderPublications";
import api from "../../api";
import {getItemData} from "../../parts/functions";

class PlanSingleComponent extends React.Component {
    state = {
        plan: {},
    }

    constructor(props) {
        super(props);
         this.state.plan = props.plan;
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if (oldProps.plan.planid !== newProps.plan.planid) {
            this.setState({plan: newProps.plan})
        }
    }

    landusChange = (name, e, id) => {
        let temp = this.props.plan;
        temp.landuses.map((item) => {
            if (item.planlanduseid == id) {
                item[name] = +e.value;
            }
        });
        this.setState({
            plan: Object.assign({}, temp)
        });
    };

    placeChange = (name, e) => {
        let temp = this.props.plan;
        temp[name] = +e.value;
        this.setState({
            plan: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.props.plan;
        temp[name] = e.value;
        this.setState({
            plan: Object.assign({}, temp)
        });
    };

    relationChange = (name, arrayId, e, id) => {
        let temp = this.props.plan;
        temp.relationship[arrayId].map((item)=>{
            if(item[name] == id){
                item[name] = +e.value;
            }
        });
        this.setState({
            plan: Object.assign({}, temp)
        });
    };

    relationChangeInput = (name, arrayId, propertyName, e, id) => {
        let temp = this.props.plan;
        temp.relationship[arrayId].map((item)=>{
            if(item[name] == id){
                item[propertyName] = e.value;
            }
        });
        this.setState({
            plan: Object.assign({}, temp)
        });
    };

    render() {
        const {plan, places} = this.props;
        if (!plan && !places) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <form action="">
                    <div className={'place-top'}>
                        <h3>Selected plan</h3>
                        <div className="left-id">
                            Place ID: {plan.planid}
                        </div>
                    </div>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Plan Name:</td>
                            <td>{plan.planname}</td>
                            <td>Place:</td>
                            <td>{places[plan.placeid]}</td>
                        </tr>
                        </tbody>
                    </table>
                </form>
                <TabsRow
                    tabs={PlanTabs}
                    id={plan.planid}
                    path={this.props.location.pathname}
                />
                <Route
                    path={api.getRoute('plans/plan-details/plan/:id')}
                    render={(props) => <PlanDetails
                        {...props}
                        plan={plan}
                        change={this.placeChange}
                        changeInput={this.changeState}
                    />}
                />
                <Route
                    path={api.getRoute('plans/plan-publications/plan/:id')}
                    render={(props) => <PlanPublications
                        {...props}
                        plan={plan}
                        change={this.placeChange}
                        changeInput={this.changeState}
                    />}
                />
                <Route
                    path={api.getRoute('plans/plan-landuses/plan/:id')}
                    render={(props) => <PlanLanduses
                        {...props}
                        plan={plan}
                        change={this.landusChange}
                    />}
                />
                <Route
                    path={api.getRoute('plans/plan-relationship/plan/:id')}
                    render={(props) => <PlanRelationship
                        {...props}
                        plan={plan}
                        change={this.relationChange}
                        changeInput={this.relationChangeInput}
                    />}
                />
                <Route
                    path={api.getRoute('plans/tender-publications/plan/:id')}
                    render={(props) => <PlanTenderPublications
                        {...props}
                        plan={plan}
                    />}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let plan = {};
    state.plans.forEach(function (item) {
        if (item.planid == ownProps.match.params.id) {
            plan = item;
        }
    });
    return {
        plan: Object.assign({}, plan),
        places: state.plansPlaces
    }
};

const PlanSingle = connect(mapStateToProps)(PlanSingleComponent);

export default PlanSingle;