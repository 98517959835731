import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Pagination from "../../parts/Pagination";
import TendersRequest from "./TendersRequest";
import api from "../../api";
import {ActionTenderAdd, ActionTenderDelete, ActionTenderEdit} from '../../parts/actions';
import ToolButtons from "../../parts/ToolButtons";
import Search from "../../parts/Search";
import Storege from "../../storage/storage";
import TenderAdd from "./TenderAdd";
import RemoveButton from "../../parts/removeBtn";
import Filter from "../../parts/Filters";
import getFullDateYear, {doFilter} from "../../parts/functions";
import CommonList from "../../parts/CommonListClass";
import EditBtn from "../../parts/EditBtn";

const perPega = 25;


const labelClasses = {
    'Tender Name' : 'col-sm-3',
    'LOT' : 'col-sm-3'
}


class TendersListComponent extends CommonList {

    orderFields = {
        "Tender Name": 'tendername',
        "Place": 'placeid',
        "P.Date": 'p_date',
        "H.Units": 'units',
        "LOT": 'lot',
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            items: nextProps.items,
            // page: this.getCurrentPage(),
        });
    }

    addContentType = (object) => {
        ActionTenderAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD TENDER',
                item: data
            });
            this.changePopupState();
            this.props.history.push('/tenders/tender-details/tender/' + data.tenderid);
        });
    };

    editContentType = (object) => {
        ActionTenderEdit(object.tenderid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE TENDER',
                item: data,
                id: data.tenderid
            });
            this.changePopupState();
            this.props.history.push(data.tenderid);
        });
    };

    orderPanel = () => {
        let fields = [];
        if (this.orderFields) {
            for (let label in this.orderFields) {

                fields.push((
                    <a className={labelClasses[label] ? labelClasses[label] : 'col-sm-2'} onClick={() => {
                        this.setOrder(this.orderFields[label])
                    }}><h4>{label}</h4>{this.diractionSymbol(this.orderFields[label])}</a>)
                )
            }
        }
        if (fields.length) {
            return (
                <li key={-1} className={'sortable-btn'}>
                    <div className="list-group-item clear">
                        {fields}
                    </div>
                </li>
            )
        }
        return null;
    };

    render() {
        if (!this.props.items.length) {
            TendersRequest('tenders');
        }

        const {tendersPlaces} = this.props;
        const {valuePlaceTypesFilter, searchValue, items} = this.state;

        let outPutPlaces = items;

        outPutPlaces.forEach(item => {
            item.placeName = this.props.tendersPlaces[item.placeid];
        });

        outPutPlaces = doFilter(outPutPlaces, [
            {
                name: 'valuePlaceTypesFilter',
                value: valuePlaceTypesFilter,
                searchId: 'placeid'
            },
            {
                name: 'searchValue',
                value: searchValue,
                searchId: [
                    'tendername',
                    'placeName'
                ]
            }
        ]);

        this.sortResults(outPutPlaces);
        let maxPage = Math.ceil(outPutPlaces.length / perPega);
        outPutPlaces = outPutPlaces.slice((this.state.page - 1) * perPega, (this.state.page) * perPega);

        let pages = [];
        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }
        var path = this.props.location.pathname.split('/');
        var activeTab = 'tender-details';
        if (path.length > 3) {
            activeTab = path[2]
        }

        if (!items) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        let tenderPlacesArray = [];
        for (let key in tendersPlaces) {
            let singlePlace = {
                id: key,
                name: tendersPlaces[key]
            };
            tenderPlacesArray.push(singlePlace);
        }

        return (
            <div>
                <h2>Tenders</h2>
                <div className="filter">
                    <Filter
                        filterElemens={tenderPlacesArray}
                        filterName={'filter_regions'}
                        value={this.state.valuePlaceTypesFilter}
                        optionValue={'id'}
                        optionName={'name'}
                        filterValue={'valuePlaceTypesFilter'}
                        change={this.filter}
                        reset={this.resetFilter}
                        resetBtnName={'Clear Regions Filter'}
                        title={'Choose Place:'}
                    />
                </div>
                <div className={'tool-bar'}>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                    <Search
                        change={this.search}
                    />
                </div>
                <ul className={'list-group'}>
                    {this.orderPanel()}
                    {outPutPlaces.map((item, index) => {
                        return (
                            <li key={index}>
                                <RemoveButton
                                    action={ActionTenderDelete}
                                    id={item.tenderid}
                                    descriptor={'REMOVE TENDER'}
                                />
                                <EditBtn
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    change={this.setCurrentIndex}
                                    id={item.tenderid}
                                />
                                <Link
                                    to={api.baseUrl + 'tenders/' + activeTab + '/tender/' + item.tenderid}
                                    onClick={
                                        () => {
                                            this.setState({
                                                rowIndex: index,
                                                currentItemIndex: item.tenderid
                                            });
                                        }
                                    }
                                    className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}
                                >
                                    <div className={'col-sm-3'}>{item.tendername}</div>
                                    <div className={'col-sm-2'}>{this.props.tendersPlaces[item.placeid]}</div>
                                    <div
                                        className={'col-sm-2'}>{item.p_date ? getFullDateYear(item.p_date) : '1/1/1900'}</div>
                                    <div className={'col-sm-2'}>{item.units}</div>
                                    <div className={'col-sm-3'}>{item.lot}</div>

                                </Link>
                            </li>
                        )
                    })}
                </ul>
                <Pagination currentPage={this.state.page} pages={pages} click={this.paginationChange}
                            paginationCount={5}/>
                {this.state.popup ? <TenderAdd
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        items: state.tenders,
        tendersPlaces: state.tendersPlaces
    }
};

const TendersList = connect(mapStateToProps)(TendersListComponent);
export default TendersList;
