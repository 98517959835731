import React from 'react';
import ColumnResizer from "react-column-resizer";

class CommonList extends React.Component {

    state = {
        page: 1,
        items: this.props.items,
        perPage: 44,
        filtered: false,
        valueRegionsFilter: -1,
        valuePlaceTypesFilter: -1,
        searchValue: '',
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        order: null,
    };

    setCurrentIndex = (id) => {
        this.setState({
            currentItemIndex : id,
            popupType : 'edit'
        });
    };



    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        });
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    // getCurrentPage = () => {
    //     let pathArray = this.props.location.pathname.split('/').length ? this.props.location.pathname.split('/') : [];
    //     let currentPage, id;
    //     if (!isNaN(pathArray[pathArray.length - 1])) {
    //         currentPage = this.state.items.filter(item => {
    //             return item.placeid === pathArray[pathArray.length - 1]
    //         });
    //         id = this.state.items.indexOf(currentPage[0]);
    //     }
    //     return id ? Math.ceil(id / this.state.perPage) : 1;
    // };

    search = (value) => {
        this.setState({
            searchValue: value,
            page : 1
        });
    };

    filter = (filterName, fieldValue) => {
        this.setState({
            [filterName]: +fieldValue
        });
    };

    resetFilter = (filterName) => {
        this.setState({
            [filterName]: -1
        });
    };

    changeState(name, e) {
        let temp = this.state.place;
        temp[name] = e.value;
        this.setState({place: temp});
    }

    paginationChange = (number) => {
        this.setState({
            page: number
        });
    };

    setOrder = (type) => {
        let order = {
            type: type,
            diraction: true
        };
        if (this.state.order && this.state.order.type == type) {
            order.diraction = !this.state.order.diraction;
        }
        this.setState({
            order: order
        })
    };

    diractionSymbol(type) {
        if (this.state.order && this.state.order.type == type) {
            return (
                <span
                    className={this.state.order.diraction ? 'asc ' : 'desc'}>{this.state.order.diraction ? 'asc ' : 'desc'}</span>
            )
        }
        return null;
    }

    sortResults = (items) => {
        const {order} = this.state;
        if (order) {
            const k = order.diraction ? 1 : -1;
            if(order.type instanceof Function){
                 items.sort((a, b) => {
                     return order.type(a, b) * k;
                 });
            }
            else {
	            items.sort((a, b) => {
		            let result = 0
		            if (a[order.type] === null) {
			            a[order.type] = '';
		            }
		            if (b[order.type] === null) {
			            b[order.type] = '';
		            }
		            if (a[order.type] < b[order.type]) {
			            result = -1;
		            }

		            if (a[order.type] > b[order.type]) {
			            result = 1;
		            }
		            return result * k;
	            });
            }
        }
    };

    orderPanel = () => {
        let fields = [];
        if (this.orderFields) {
            for (let label in this.orderFields) {
                fields.push((
                    <a className="col-sm-6" onClick={() => {
                        this.setOrder(this.orderFields[label])
                    }}><h4>{label}</h4>{this.diractionSymbol(this.orderFields[label])}</a>
                ))
            }
        }
        if (fields.length) {
            return (
                <li key={-1} className={'sortable-btn'}>
                    <div className="list-group-item clear">
                        {fields}
                    </div>
                </li>
            )
        }
        return null;
    };

    orderPanelTabel = (DisableColumnResizer,DisableFirstColumn) => {
        let fields = [];
        if (this.orderFields) {
            for (let label in this.orderFields) {
                fields.push((
                    <React.Fragment>
                        <th>
                            <a onClick={() => {
                                this.setOrder(this.orderFields[label])
                            }}><h4>{label}</h4>{this.diractionSymbol(this.orderFields[label])}</a>
                        </th>
                        {!DisableColumnResizer &&Object.keys(this.orderFields).indexOf(label) !== Object.keys(this.orderFields).length - 1 ?
                            <ColumnResizer className="columnResizer" minWidth={5}/> : ''}
                    </React.Fragment>
                ));
            }
        }


        if (fields.length) {
            return (
                <tr className={'sortable-btn'}>
                    {!DisableFirstColumn ? (<th></th>) : ''}
                    {fields}
                </tr>
            )
        }
        return null;
    };

    render() {

    }
}

export default CommonList;