import React from 'react';
import {connect} from "react-redux";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class TenderTypesPublicationEventTypeComponent extends CommonTab {
    state = {
        tenderPublicationEventType: this.props.tenderPublicationEventType,
        errors: [],
        validateFields: [
            'tenderpublicationeventtypename',
            'tenderpublicationeventtypenameeng'
        ]
    };

    changeState(name, e) {
        let temp = this.state.tenderPublicationEventType;
        temp[name] = e.value;
        this.setState({tenderPublicationEventType: temp});
    }

    render() {
        let {tenderPublicationEventType, errors, validateFields} = this.state;
        let {close} = this.props;
        return (
            <Popup openClose={close}>
                <div className={'popup-content-types'}>
                    <form onSubmit={event => {
                        event.preventDefault();
                        this.formValidation(tenderPublicationEventType, validateFields);
                    }}>
                        <h2>{this.props.button} Tender Publication Event Type</h2>
                        <table className={'table table-striped'} align={'right'}>
                            <tbody>
                            <tr>
                                <td>Tender Publication Event Type Name :</td>
                                <td className={errors.indexOf('tenderpublicationeventtypename') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={tenderPublicationEventType.tenderpublicationeventtypename}
                                        onChange={(e) => {
                                            this.changeState('tenderpublicationeventtypename', e.target);
                                        }}
                                    />
                                    {errors.indexOf('tenderpublicationeventtypename') !== -1 ? <span className="errorText">Please set Event Type Name</span> : '' }
                                </td>
                            </tr>
                            <tr>
                                <td>Tender Publication Event Type Name Eng :</td>
                                <td className={errors.indexOf('tenderpublicationeventtypenameeng') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={tenderPublicationEventType.tenderpublicationeventtypenameeng}
                                        onChange={(e) => {
                                            this.changeState('tenderpublicationeventtypenameeng', e.target);
                                        }}
                                    />
                                    {errors.indexOf('tenderpublicationeventtypenameeng') !== -1 ? <span className="errorText">Please set Event Type Name Eng</span> : '' }
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </form>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let content = {};
    state.tenderPublicationEvents.map((item) => {
        if (item.tenderpublicationeventtypeid == ownProps.id) {
            content = item;
        }
    });
    return {
        tenderPublicationEventType: Object.assign({}, content)
    }
};

const TenderTypesPublicationEventType = connect(mapStateToProps)(TenderTypesPublicationEventTypeComponent);

export default TenderTypesPublicationEventType;