import React from 'react';
import api from "../../api";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Pagination from "../../parts/Pagination";
import Search from "../../parts/Search";
import ToolButtons from "../../parts/ToolButtons";
import getFullDateYear, {doFilter} from "../../parts/functions";
import {
	ActionTimeRanges,
    ActionTimeRangesAdd,
    ActionTimeRangesEdit,
    ActionTimeRangesDelete,
} from "../../parts/actions";
import RemoveButton from "../../parts/removeBtn";
import CommonList from "../../parts/CommonListClass";
import EditBtn from "../../parts/EditBtn";
import TimeRangeAdd from "./TimeRangeAdd";

const perPega = 25;

class TimeRangesComponent extends CommonList {
    orderFields = {
        "Name": 'timerangename',
        // "Name Eng": 'timerangenameeng',
        // "Start Date": 'startdate',
        // "End Date": 'enddate',
        // "Remarks": 'remarks'
    };

    // componentWillReceiveProps(nextProps) {
    //     this.setState({
    //         // page: this.getCurrentPage(),
    //         items: nextProps.items
    //     });
    // }

    addContentType = (object) => {
        ActionTimeRangesAdd('', object).then(data => {
            this.changePopupState();
            //this.props.history.push(api.getRoute('plans/plan-details/plan/' + data.timerangeid));
        });
    };

    editContentType = (object) => {
        ActionTimeRangesEdit(object.timerangeid, object).then(data => {
            this.changePopupState();
        });
    };

    render() {
        const {searchValue} = this.state;
        let activeTab = '';
        const {timeRanges} = this.props;
        if (!timeRanges || !timeRanges.length) {
            ActionTimeRanges();
        }
        console.log(timeRanges);
         if (!timeRanges.length) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        let outPutItems = timeRanges;
        outPutItems = doFilter(
            outPutItems,
            [
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'timerangename'
                    ]
                }
            ]
        );

        this.sortResults(outPutItems);
        let maxPage = Math.ceil(outPutItems.length / perPega);
        outPutItems = outPutItems.slice((this.state.page - 1) * perPega, (this.state.page) * perPega);
        let pages = [];
        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }
        return (
            <div>
                <h2>Time Ranges</h2>
                <div className={'tool-bar'}>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                    <Search
                        change={this.search}
                    />
                </div>
                <ul className={'list-group'}>
                    {this.orderPanel()}
                    {outPutItems.map((item, index) => {
                        return (
                            <li key={index}>
                                <RemoveButton
                                    action={ActionTimeRangesDelete}
                                    id={item.timerangeid}
                                    descriptor={'REMOVE TIME RANGE'}
                                />
                                <EditBtn
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    change={this.setCurrentIndex}
                                    id={item.timerangeid}
                                />
                                <Link to={api.getRoute('time_ranges/' +item.timerangeid + '/' + activeTab)} onClick={
                                    () => {
                                        this.setState({
                                            rowIndex: index,
                                            currentItemIndex: item.timerangeid
                                        });
                                    }
                                } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                    <div className="col-sm-12">
                                        {item.timerangename}
                                    </div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                <Pagination currentPage={this.state.page} pages={pages} click={this.paginationChange}
                            paginationCount={5}/>
                {this.state.popup ? <TimeRangeAdd
                    close={this.changePopupState}
                    itemList = {timeRanges}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        timeRanges : state.timeRanges,
    }
};

const TimeRanges = connect(mapStateToProps)(TimeRangesComponent);
export default TimeRanges;