import React from 'react';
import api from "../../api";

const PlacesTabs = [
    {
        tabName: 'Statuses',
        tabEnd: 'statuses',
        tabUrl: api.getRoute('places/statuses/place/')
    },
    {
        tabName: 'Synonyms',
        tabEnd: 'synonyms',
        tabUrl: api.getRoute('places/synonyms/place/')
    },
    {
        tabName: 'Hierarchies',
        tabEnd: 'hierarchies',
        tabUrl: api.getRoute('places/hierarchies/place/')
    },
    {
        tabName: 'Geometry',
        tabEnd: 'geometry',
        tabUrl: api.getRoute('places/geometry/place/')
    },
    {
        tabName: 'Sovereignties',
        tabEnd: 'sovereignties',
        tabUrl: api.getRoute('places/sovereignties/place/')
    },
    {
        tabName: 'Tracking',
        tabEnd: 'tracking',
        tabUrl: api.getRoute('places/tracking/place/')
    },{
        tabName: 'Links',
        tabEnd: 'links',
        tabUrl: api.getRoute('places/links/place/')
    },{
        tabName: 'ICBS',
        tabEnd: 'ICBS',
        tabUrl: api.getRoute('places/ibspdetails/place/')
    }
];

export default PlacesTabs;