import React from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import api from "../../api";
import TeritorriesRequest from "./territorriesRequest";
import ToolButtons from "../../parts/ToolButtons";
import {ActionTerritoryAdd, ActionTerritoryDelete, ActionTerritoryEdit} from "../../parts/actions";
import Search from "../../parts/Search";
import Storege from "../../storage/storage";
import TerritorieDetailsAdd from "./territoryAdd";
import CommonList from "../../parts/CommonListClass";
import getFullDateYear, {doFilter} from "../../parts/functions";
import RemoveButton from "../../parts/removeBtn";
import EditBtn from "../../parts/EditBtn";

class TeritorriesListComponent extends CommonList {

    orderFields = {
        "Territory Name": 'territoryname',
        "Date": 'updated_at'
    };

    addContentType = (object) => {
        ActionTerritoryAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD TERRITORY',
                item: data
            });
            this.changePopupState();
            this.props.history.push(data.territoryid);
        });
    };


    editContentType = (object) => {
        ActionTerritoryEdit(object.territoryid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE TERRITORY',
                item: data,
                id: data.territoryid
            });
            this.changePopupState();
            this.props.history.push(data.territoryid);
        });
    };

    render() {
        if (!this.props.items.length) {
            TeritorriesRequest('territories');
        }
        let {items} = this.props;
        let {searchValue} = this.state;
        if (!items) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        let outPutPlaces = items;
        outPutPlaces = doFilter(
            outPutPlaces,
            [
                {
                    name: 'valueRegionsFilter',
                    value: -1,
                    searchId: 'regionid'
                },
                {
                    name: 'valuePlaceTypesFilter',
                    value: -1,
                    searchId: 'placetypeid'
                },
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'territoryname',
                        'territorynameeng'
                    ]
                }
            ]
        );

        this.sortResults(outPutPlaces);
        let arr = this.props.location.pathname.split('/');
        // console.log(arr);
        return (
            <div>
                <h2>Territories</h2>
                <div className={'tool-bar'}>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                    <Search
                        change={this.search}
                    />
                </div>

                <ul className={'list-group'}>
                    {this.orderPanel()}
                    {(outPutPlaces ? outPutPlaces : []).map((item, index) => {
                        return (
                            <li key={index}>
                                <RemoveButton
                                    action={ActionTerritoryDelete}
                                    id={item.territoryid}
                                    descriptor={'REMOVE TERRITORY'}
                                />
                                <EditBtn
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    change={this.setCurrentIndex}
                                    id={item.territoryid}
                                />

                                <Link
                                    to={api.getRoute('territories/' + ((arr[2] && arr[2].length) ? arr[2] : 'details') + '/territory/' + item.territoryid)}
                                    onClick={
                                        () => {
                                            this.setState({
                                                rowIndex: index,
                                                currentItemIndex: item.territoryid
                                            });
                                        }
                                    } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}
                                >
                                    <div className="col-sm-6">
                                        {item.territoryname}
                                    </div>

                                    <div className="col-sm-6">
                                        <span>{item.updated_at ? getFullDateYear(item.updated_at) : '1/1/1900'}</span>
                                    </div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                {this.state.popup ? <TerritorieDetailsAdd
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        items: state.descriptorTerritories
    }
};

const TeritorriesList = connect(mapStateToProps)(TeritorriesListComponent);
export default TeritorriesList;
