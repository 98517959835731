import React from 'react';
import api from "../api";

const EditBtn = function (props) {
    return (
        <div>
            <a href="#" className={'edit-list-item'} onClick={
                e => {
                    e.preventDefault();
                    props.change(props.id, props.name);
                    props.actionType('edit');
                    if(props.popupName) {
                        props.popupName(props.name);
                    }
                    props.popupChange();
                }
            }><img src={api.getRoute("public/img/pencil.png" )} alt=""/></a>
        </div>
    );
};

export default EditBtn;