import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import api from "../../../api";
import {
    ActionSource,
    ActionSourceCategoryAdd, ActionSourceCategoryDelete, ActionSourceCategoryEdit,
} from "../../../parts/actions";
import RemoveButton from "../../../parts/removeBtn";
import Storege from "../../../storage/storage";
import ToolButtons from "../../../parts/ToolButtons";
import Search from "../../../parts/Search";
import CommonList from "../../../parts/CommonListClass";
import SourceCategoryAdd from "./source-category-add"
import EditBtn from "../../../parts/EditBtn";
import {doFilter} from "../../../parts/functions";

class SourceCategoryComponent extends CommonList {
    state = {
        sourcesCategory: this.props.sourcesCategory,
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        searchValue: '',
        order: null
    };

    orderFields = {
        "Source Category Name": 'sourcecategoryname',
        "Source Category Name Eng": 'sourcecategorynameeng'
    };

    changeState(name, e, id) {
        let temp = this.props.sourcesCategory;
        temp.forEach(function (item) {
            if (item.sourcecategoryid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            initiatorTypes: Object.assign({}, temp)
        });
    }

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    addContentType = (object) => {
        ActionSourceCategoryAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD SOURCE',
                item: data
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionSourceCategoryEdit(object.sourcecategoryid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE SOURCE',
                item: data,
                id: data.sourcecategoryid
            });
            this.changePopupState();
        });
    };

    setOrder = (type) => {
        let order = {
            type: type,
            diraction: true
        }
        if (this.state.order && this.state.order.type == type) {
            order.diraction = !this.state.order.diraction;
        }
        this.setState({
            order: order
        })
    };

    diractionSymbol(type) {
        if (this.state.order && this.state.order.type == type) {
            return (
                <span
                    className={this.state.order.diraction ? 'asc ' : 'desc'}>{this.state.order.diraction ? 'asc ' : 'desc'}</span>
            )
        }
        return null;
    }

    sortResults = (items) => {
        const {order} = this.state;
        if (order) {
            const k = order.diraction ? 1 : -1;
            items.sort((a, b) => {
                let result = 0
                if (a[order.type] === null) {
                    a[order.type] = '';
                }
                if (b[order.type] === null) {
                    b[order.type] = '';
                }
                if (a[order.type] < b[order.type]) {
                    result = -1;
                }

                if (a[order.type] > b[order.type]) {
                    result = 1;
                }
                return result * k;
            });
        }
    };

    orderPanel = () => {
        let fields = [];
        if (this.orderFields) {
            for (let label in this.orderFields) {
                fields.push((
                    <a className="col-sm-6" onClick={() => {
                        this.setOrder(this.orderFields[label])
                    }}><h4>{label}</h4>{this.diractionSymbol(this.orderFields[label])}</a>
                ))
            }
        }

        if (fields.length) {
            return (
                <li key={-1} className={'sortable-btn'}>
                    <div className="list-group-item clear">
                        {fields}
                    </div>
                </li>
            )
        }
        return null;
    };

    render() {
        let sourcesCategory = this.props.sourcesCategory;
        let {searchValue} = this.state;
        if (!sourcesCategory) {
            ActionSource();
        }

        sourcesCategory = doFilter(
            sourcesCategory,
            [
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'sourcecategoryname',
                        'sourcecategorynameeng'
                    ]
                }
            ]
        );

        let arrayForFillters = [];

        if (!arrayForFillters.length) {
            for (let key in this.props.plansPlaces) {
                let singleObject = {};
                singleObject.placeid = key;
                singleObject.placename = this.props.plansPlaces[key];
                arrayForFillters.push(singleObject);
            }
        }

        this.sortResults(sourcesCategory);

        return (
            <div>
                <h2>Source Categories</h2>
                <div className={'tool-bar'}>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                    <Search
                        change={this.search}
                    />
                </div>
                <ul className={'list-group'}>
                    {this.orderPanel()}
                    {(sourcesCategory ? sourcesCategory : []).map((item, index) => {
                        return (
                            <li key={index}>
                                <RemoveButton
                                    descriptor={'REMOVE SOURCE'}
                                    action={ActionSourceCategoryDelete}
                                    id={item.sourcecategoryid}
                                />
                                <EditBtn
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    change={this.setCurrentIndex}
                                    id={item.sourcecategoryid}
                                />
                                <Link to={api.getRoute('sources/category/' + item.sourcecategoryid)} onClick={
                                    () => {
                                        this.setState({
                                            rowIndex: index,
                                            currentItemIndex: item.sourcecategoryid
                                        });
                                    }
                                } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                    <div className={'col-sm-6'}>{item.sourcecategoryname}</div>
                                    <div className={'col-sm-6'}>{item.sourcecategorynameeng}</div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                {this.state.popup ? <SourceCategoryAdd
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sourcesCategory: state.sources.sourceCategories
    }
};

const SourceCategory = connect(mapStateToProps)(SourceCategoryComponent);
export default SourceCategory;