import React from 'react';
import {connect} from 'react-redux';
import {
    ActionDescriptorsPlanPublication, ActionOwnershipTypeDelete,
    ActionPublicationTypeAdd, ActionPublicationTypeDelete,
    ActionPublicationTypeEdit
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import Storege from "../../../storage/storage";
import ToolButtons from "../../../parts/ToolButtons";
import Search from "../../../parts/Search";
import PlanPublicationPopup from "./PlanPublicationPopup";
import RemoveButton from "../../../parts/removeBtn";
import CommonTab from "../../../parts/CommonTabClass";
import {doFilter} from "../../../parts/functions";
import EditBtn from "../../../parts/EditBtn";

class PlanPublicationDescriptorComponent extends CommonTab {

    orderFields = {
        "Plan Publication Unit Type Name": "ownershiptypename",
        "Plan Publication Unit Type Name Eng": "ownershiptypenameeng"
    };

    state = {
        planPublication: {},
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        order: null,
        searchValue: ''
    };

    constructor(props) {
        super(props);
        this.state.planPublication = props.planPublication;
    }

    changeState = (name, e, id) => {
        let temp = this.props.planPublication;
        temp.forEach(function (item) {
            if (item.planpublicationunittypeid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            planPublication: Object.assign({}, temp)
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    addContentType = (object) => {
        ActionPublicationTypeAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLAN PUBLICATION TYPE',
                item: data
            });
            this.changePopupState();
            this.props.history.push(data.planpublicationunittypeid);
        });
    };

    editContentType = (object) => {
        ActionPublicationTypeEdit(object.planpublicationunittypeid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLAN PUBLICATION TYPE',
                item: data,
                id: data.planpublicationunittypeid
            });
            this.changePopupState();
            this.props.history.push(data.planpublicationunittypeid);
        });
    };


    render() {
        if (!this.props.planPublication.length) {
            ActionDescriptorsPlanPublication();
        }
        let {planPublication} = this.props;
        let {searchValue} = this.state;
        if (!planPublication) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        let outPutPlaces = planPublication;
        outPutPlaces = doFilter(
            outPutPlaces,
            [
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'planpublicationunittypename',
                        'planpublicationunittypenameeng'
                    ]
                }
            ]
        );

        this.sortResults(outPutPlaces);
        return (
            <div>
                <div className="inline-toolbuttons">
                    <h3>Plan Publication Unit Types:</h3>
                    <div className={'tool-bar'}>
                        <ToolButtons
                            actionType={this.changePopupType}
                            popupChange={this.changePopupState}
                            id={this.state.currentItemIndex}
                        />
                        <Search
                            change={this.search}
                        />
                    </div>
                </div>
                <table className='table table-striped'>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {outPutPlaces.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                e => {
                                    e.preventDefault();
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.planpublicationunittypeid
                                    });
                                }
                            } className={this.state.rowIndex == index ? 'active' : ''}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionPublicationTypeDelete}
                                        id={item.planpublicationunittypeid}
                                        descriptor={'REMOVE PLAN PUBLICATION TYPE'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.planpublicationunittypeid}
                                    />
                                </td>
                                <td>{item.planpublicationunittypename}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.planpublicationunittypenameeng}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <PlanPublicationPopup
                    openClose={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        planPublication: state.planPublicationType
    }
};
const DescriptorPlanPublication = connect(mapStateToProps)(PlanPublicationDescriptorComponent);

export default DescriptorPlanPublication;