import React from 'react';
import Select from "./select";

class Filter extends React.Component {
    render() {
        return (
            <div>
                <h3 className={'select-header'}>{this.props.title ? this.props.title : ''}</h3>
                <Select
                    types={this.props.filterElemens}
                    name={this.props.filterName}
                    value={this.props.value}
                    optionValue={this.props.optionValue}
                    optionName={this.props.optionName}
                    onChange={(e) => {
                        e.preventDefault();
                        let searchValue = (e.target).value;
                        // this.setState({
                        //     value: searchValue
                        // });
                        this.props.change(this.props.filterValue, searchValue);
                    }
                    }
                />
                <a
                    className={this.props.value != -1 ? '' : 'hide-reset-search'}
                    href={'#'}
                    onClick={(e)=>{
                        e.preventDefault();
                        this.props.reset(this.props.filterValue);
                    }}
                >{this.props.resetBtnName}</a>
            </div>
        )
    }
}

export default Filter;

