import React from 'react';
import {connect} from "react-redux";
import SelectObject from "../../../parts/SelectObject";

class TenderPopupComponent extends React.Component {
    state = {
        data: {}
    }

    constructor(props) {
        super(props);
        this.state.data = props.data
    }

    changeTenderName = (name, e) => {
        let temp = this.props.data;
        temp[name] = e.value;
        this.setState({
            data: Object.assign({}, temp)
        });
    }

    changeSelect = (name, e) => {
        let temp = this.props.data;
        temp[name] = +e.value;
        this.setState({
            data: Object.assign({}, temp)
        });
    }


    render() {
        console.log(this.props);
        let {data,  tenderTypes, initiator, tenderPurpose} = this.props;
        let tenderPlaces = [];
        for (let key in this.props.tenderPlaces) {
            tenderPlaces.push(this.props.tenderPlaces[key])
        }
        if (!this.props.active) {
            return (
                <div></div>
            )
        }
        return (
            <div className="tender-popup modal fade" id="tender-popup">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{data.tendername}</h5>
                            {/*<button type="button" className="close" data-dismiss="modal" aria-label="Close">*/}
                                {/*<span aria-hidden="true">&times;</span>*/}
                            {/*</button>*/}
                        </div>
                        <div className="modal-body">
                            <form action="">
                                <label htmlFor="tender-name">Tender Name</label>
                                <input
                                    type="text"
                                    value={data.tendername}
                                    name='tender-name'
                                    onChange={(e) => {
                                        this.changeTenderName('tendername', e.target)}
                                    }
                                    className='form-control'
                                />
                                <label htmlFor="tender-type">Tender Type</label>
                                <select
                                    className='form-control'
                                    name={'tender-type'}
                                    value={data.tendertypeid}
                                    onChange={(e) => {
                                        this.changeSelect('tendertypeid', e.target);
                                    }}
                                    id=''>
                                    <option value="-1"> </option>
                                    {tenderTypes.map(function (item, index) {
                                        return (
                                            <option key={index} value={index}>{item.tendertypename}</option>
                                        )
                                    })}
                                </select>
                                <label htmlFor="initiator">Initiator</label>
                                <select
                                    className='form-control'
                                    name={'initiator'}
                                    value={data.initiatorid}
                                    onChange={(e) => {
                                        this.changeSelect('initiatorid', e.target);
                                    }}
                                    id=''>
                                    <option value="-1"> </option>
                                    {initiator.map(function (item, index) {
                                        return (
                                            <option key={index} value={index}>{item.initiatorname}</option>
                                        )
                                    })}
                                </select>
                                <label htmlFor="tender-purpose">Tender Purpose</label>
                                <select
                                    className='form-control'
                                    name={'tender-purpose'}
                                    value={data.tenderpurposeid}
                                    onChange={(e) => {
                                        this.changeSelect('tenderpurposeid', e.target);
                                    }}
                                    id=''>
                                    <option value="-1"> </option>
                                    {tenderPurpose.map(function (item, index) {
                                        return (
                                            <option key={index} value={index}>{item.tenderpurposename}</option>
                                        )
                                    })}
                                </select>
                                <label htmlFor="place">Place</label>
                                <select
                                    className='form-control'
                                    name={'place'}
                                    value={data.placeid}
                                    onChange={(e) => {
                                        this.changeSelect('placeid', e.target);
                                    }}
                                    id=''>
                                    <option value="-1"> </option>
                                    {tenderPlaces.map(function (item, index) {
                                        return (
                                            <option key={index} value={index}>{item}</option>
                                        )
                                    })}
                                </select>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            {/*<button type="button" className="btn btn-primary">Save changes</button>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tenderTypes: state.tendersTypes,
        initiator: state.tenderInitiator,
        tenderPurpose: state.tendersPurposes,
        tenderPlaces: state.tendersPlaces
    }
};

const TenderPopup = connect(mapStateToProps)(TenderPopupComponent);

export default TenderPopup;