import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import api from "../../../api";
import {
    ActionInitiatorAdd,
    ActionInitiatorByType, ActionInitiatorDelete, ActionInitiatorEdit,
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import ToolButtons from "../../../parts/ToolButtons";
import Search from "../../../parts/Search";
import Storege from "../../../storage/storage";
import RemoveButton from "../../../parts/removeBtn";
import InitiatorByCategoryAdd from "./initiator_by_category_add";
import CommonTab from "../../../parts/CommonTabClass";
import {doFilter} from "../../../parts/functions";
import EditBtn from "../../../parts/EditBtn";
import Pagination from "../../../parts/Pagination";

class InitiatorByTypeComponent extends CommonTab {

    orderFields = {
        "Initiator Name": "initiatorname",
        "Initiator Name Eng": "initiatornameeng",
        "Date": "updated_at"
    };

    state = {
        page: 1,
        initiatorsByType: {},
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        order: null,
        searchValue: ''
    };

    constructor(props) {
        super(props);
        this.state.initiatorsByType = props.initiatorsByType
    }

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    changeState = (name, e, id) => {
        let temp = this.props.initiatorsByType;
        temp.forEach(function (item) {
            if (item.initiatorid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            initiatorsByType: Object.assign({}, temp)
        });
    };


    addContentType = (object) => {
        ActionInitiatorAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD INITIATOR',
                item: data
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionInitiatorEdit(object.initiatorid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE INITIATOR',
                item: data,
                id: data.initiatorid
            });
            this.changePopupState();
        });
    };

    paginationChange = (number) => {
        this.setState({
            page: number
        });
    };

    render() {
        let {initiatorsByType} = this.props;
        if (!initiatorsByType || (initiatorsByType && this.props.match.params.id != ((initiatorsByType ? initiatorsByType : {})[0] ? initiatorsByType[0] : {}).initiatortypeid)) {
            ActionInitiatorByType(this.props.match.params.id);
        }

        let {filtered, filteredItems, searchValue} = this.state;
        let list = filtered ? filteredItems : initiatorsByType;
        if (!list) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        let outPutPlaces = initiatorsByType;
        outPutPlaces = doFilter(
            outPutPlaces,
            [
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'initiatorname',
                        'initiatornameeng'
                    ]
                }
            ]
        );

        this.sortResults(outPutPlaces);

        let maxPage = Math.ceil(outPutPlaces.length / 15);
        outPutPlaces = outPutPlaces.slice((this.state.page - 1) * 15, (this.state.page) * 15);

        let pages = [];

        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }
        let tabName = window.location.pathname.split('/initiator/')[1];
        if(tabName){
            tabName = tabName.split('/')[0]
        }
        tabName = tabName ? tabName : 'initiator_plans';

        return (
            <div>
                <div className="inline-toolbuttons">
                    <h3>Initiators</h3>
                    <div className={'tool-bar contactors-tool-bar'}>
                        <ToolButtons
                            actionType={this.changePopupType}
                            popupChange={this.changePopupState}
                            id={this.state.currentItemIndex}
                        />
                        <Search
                            change={this.search}
                            id={'initiator-id'}
                        />
                    </div>
                </div>
                <table className={'table table-striped initiators-table'}>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {outPutPlaces.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                e => {
                                    e.preventDefault();
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.initiatorid
                                    });
                                }
                            } className={this.state.rowIndex == index ? 'active' : ''}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionInitiatorDelete}
                                        id={item.initiatorid}
                                        descriptor={'REMOVE INITIATOR'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.initiatorid}
                                    />
                                </td>
                                <td>
                                    <Link
                                        to={api.getRoute('initiators/type/' + this.props.match.params.id + '/initiator/'+tabName+'/' + item.initiatorid)}>
                                        {item.initiatorname}</Link>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    <Link
                                        to={api.getRoute('initiators/type/' + this.props.match.params.id + '/initiator/'+tabName+'/' + item.initiatorid)}>
                                      {item.initiatornameeng}</Link>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>

                                <td>
                                    <Link
                                        to={api.getRoute('initiators/type/' + this.props.match.params.id + '/initiator/'+tabName+'/' + item.initiatorid)}>{item.updated_at ? item.updated_at : '1/1/1900'}
                                    </Link>
                                        </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <Pagination
                    currentPage={this.state.page}
                    pages={pages}
                    click={this.paginationChange}
                    paginationCount={5}
                />
                {this.state.popup ? <InitiatorByCategoryAdd
                    initiatortypeid={this.props.match.params.id}
                    close={this.changePopupState}
                    itemList={initiatorsByType}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        initiatorsByType: state.initiatorsByType.initiators
    }
};

const InitiatorByType = connect(mapStateToProps)(InitiatorByTypeComponent);
export default InitiatorByType;
