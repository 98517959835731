import React from 'react';
import {connect} from "react-redux";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";
import Select from "../../../parts/select";

class PlanPhasesPopupComponent extends CommonTab {
    state = {
        phase: {},
        errors: [],
        validateFields: [
            'planningphasename',
            'planningphasenameeng',
            'planningphasecategoryid',
            'order'
        ]
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.phase).length) {
            this.state = Object.assign({}, this.state, {phase: this.props.phase});
        }
    }

    changeState(name, e) {
        let temp = this.state.phase;
        temp[name] = e.value;
        this.setState({phase: temp});
    }

    render() {
        let {phase, errors, validateFields} = this.state;
        let {planningPhasesCategory} = this.props;
        console.log(phase);
        console.log(planningPhasesCategory)
        return (
            <Popup openClose={this.props.openClose}>
                <div className={'popup-content-types'}>
                    <form onSubmit={event => {
                        event.preventDefault();
                        if (!('description' in phase)) {
                            phase['description'] = ''
                        }
                        this.formValidation(phase, validateFields);
                    }}>
                        <h2>{this.props.button + (this.props.button === 'Add ' ? 'New ' : '')} Planning Phase</h2>
                        <table className={'table table-striped'} align={'right'}>
                            <tbody>
                            <tr>
                                <td>Planning Phase Name:</td>
                                <td className={errors.indexOf('planningphasename') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={phase.planningphasename}
                                        onChange={(e) => {
                                            this.changeState('planningphasename', e.target);
                                        }}
                                    />
                                    {errors.indexOf('planningphasename') !== -1 ? <span className="errorText">Please set Phase Name</span> : '' }
                                </td>
                            </tr>
                            <tr>
                                <td>Planning Phase Name Eng:</td>
                                <td className={errors.indexOf('planningphasenameeng') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={phase.planningphasenameeng ? phase.planningphasenameeng : ''}
                                        onChange={(e) => {
                                            this.changeState('planningphasenameeng', e.target);
                                        }}
                                    />
                                    {errors.indexOf('planningphasenameeng') !== -1 ? <span className="errorText">Please set Phase Name Eng</span> : '' }
                                </td>
                            </tr>
                            <tr>
                                <td>Description:</td>
                                <td>
                                    <input
                                        type={'text'}
                                        value={phase.description}
                                        onChange={(e) => {
                                            this.changeState('description', e.target);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Planning Phase Category:</td>
                                <td className={errors.indexOf('planningphasecategoryid') !== -1 ? 'error' : ''}>
                                    <Select
                                        types={planningPhasesCategory}
                                        name={'planningphasecategoryid'}
                                        value={(phase.planningphasecategoryid || phase.planningphasecategoryid===0) ? phase.planningphasecategoryid : null}
                                        className={'form-control'}
                                        optionValue={'planningphasecategoryid'}
                                        optionName={'planningphasecategoryname'}
                                        onChange={(e) => {
                                            this.changeState('planningphasecategoryid', e.target);
                                        }
                                        }
                                    />
                                    {errors.indexOf('planningphasecategoryid') !== -1 ? <span className="errorText">Please set Planning Phase Category</span> : '' }
                                </td>
                            </tr>
                            <tr>
                                <td>Order:</td>
                                <td className={errors.indexOf('order') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'number'}
                                        value={phase.order ? phase.order : ''}
                                        onChange={(e) => {
                                            this.changeState('order', e.target);
                                        }}
                                    />
                                    {errors.indexOf('order') !== -1 ? <span className="errorText">Please set Order</span> : '' }
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </form>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let content = {};
    state.planningPhases.map((item) => {
        if (item.planningphaseid == ownProps.id) {
            content = item;
        }
    });
    return {
        phase: Object.assign({}, content),
        planningPhasesCategory: state.planningPhasesCategory
    }
};

const PlanPhasesPopup = connect(mapStateToProps)(PlanPhasesPopupComponent);
export default PlanPhasesPopup;