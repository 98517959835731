import React from 'react';
import {connect} from 'react-redux';
import Popup from "../Popup";
import Select from "../select";
import CommonTab from "../CommonTabClass";

class SovereigntiesAddComponent extends CommonTab {
    state = {
        popupItem: {
            placeid: this.props.place.placeid
        },
        errors: [],
        validateFields: [
            'sovereigntyid',
            'territoryid',
        ]
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }


    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

     formValidation2 = (objectForSending, requireFields) => {
         let exist = false;
         this.props.itemList.map((el)=>{
             if(objectForSending.placesovereigntyid != el.placesovereigntyid && objectForSending.territoryid == el.territoryid ){
                 exist = true;
             }
         });
         if(exist){
             this.setState({
                    errors: ['exist']
                });
         }else {
             this.formValidation(objectForSending, requireFields)
         }
     }

    render() {
        const {popupItem, validateFields, errors} = this.state;
        const {territories,sovereignties} = this.props;
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('planningphaseid' in popupItem)) {
                            popupItem['planningphaseid'] = ''
                        }
                        if (!('remarks' in popupItem)) {
                            popupItem['remarks'] = ''
                        }
                        if (!('url' in popupItem)) {
                            popupItem['url'] = ''
                        }
                        this.formValidation2(popupItem, validateFields);
                    }}
                >
                    <h3>{this.props.button + (this.props.button === 'Add ' ? 'New ' : '')} Place Sovereignty</h3>
                    {errors.indexOf('exist') !== -1 ?
                                    <span className="errorText">This combination exist in the database.</span> : ''}
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr className={errors.indexOf('territoryid') !== -1 ? 'error' : ''}>
                            <td>Territory:</td>
                            <td>
                                <Select
                                    id={"territoryid"}
                                    className={"form-control"}
                                    name={"territoryid"}
                                    value={(popupItem.territoryid || popupItem.territoryid === 0) ? popupItem.territoryid : null}
                                    types={territories}
                                    optionValue={"territoryid"}
                                    optionName={"territoryname"}
                                    onChange={(e) => {
                                        this.changeState('territoryid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr className={errors.indexOf('sovereigntyid') !== -1 ? 'error' : ''}>
                            <td>Sovereignty:</td>
                            <td>
                                <Select
                                    id={"sovereigntyid"}
                                    className={"form-control"}
                                    name={"sovereigntyid"}
                                    value={ (popupItem.sovereigntyid || popupItem.sovereigntyid === 0) ? popupItem.sovereigntyid : null}
                                    types={sovereignties}
                                    optionValue={"sovereigntyid"}
                                    optionName={"sovereigntyname"}
                                    onChange={(e) => {
                                        this.changeState('sovereigntyid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state , ownProps) => {
    let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item.placesovereigntyid == ownProps.id) {
            popupItem = item;
        }
    });
    return {
        territories: state.territories,
        sovereignties: state.sovereignties,
        popupItem : popupItem
    }
};

const SovereigntiesAdd = connect(mapStateToProps)(SovereigntiesAddComponent);
export default SovereigntiesAdd;
