import * as React from 'react';
import {connect} from 'react-redux';
import CommonTab from "../../../../parts/CommonTabClass";
import ToolButtons from "../../../../parts/ToolButtons";
import RemoveButton from "../../../../parts/removeBtn";
import {
	ActionDescriptorsPlanPublication,
	ActionPlanPublicationUnitDelete,
	ActionPlanPublicationUnitAdd,
	ActionPlanPublicationUnitEdit,
	ActionPlanPublications
} from "../../../../parts/actions";
import EditBtn from "../../../../parts/EditBtn";
import ColumnResizer from "react-column-resizer";
import AddUnit from './addUnit';
import {getItemData} from "../../../../parts/functions";
import Storege from "../../../../storage/storage";

class UnitsComponent extends CommonTab {

	orderFields = {
        "Plan Publication Unit Type": 'planpublicationunittypeid',
        "Units": 'unit'
    };


	addContentType = (object) => {
		console.log(object);
        ActionPlanPublicationUnitAdd('', object).then(data => {
            this.changePopupState();
            ActionPlanPublications(this.props.planid);
        });
    };

    editContentType = (object) => {
        ActionPlanPublicationUnitEdit(object.planpublicationunittypedetailid, object).then(data => {
            this.changePopupState();
             ActionPlanPublications(this.props.planid);
        });
    };

    DeleteContentType = (id,descriptor) => {
        ActionPlanPublicationUnitDelete(id,descriptor).then(()=>{
        	ActionPlanPublications(this.props.planid);
        });
    };


	render() {
		const {items,planPublicationType,publication} = this.props
		 if (!planPublicationType.length) {
            ActionDescriptorsPlanPublication();
        }

		this.sortResults(items);
		return (
			<div>
                <div className={'custom-tool-bar'}>
                    <h3>Unit Details</h3>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <div className="scrolled ">
                    <table className={"table table-striped"} valign={"middle"}>
                        <thead>
                        {this.orderPanel()}
                        </thead>
	                    <tbody>
	                    {items.map((item, index) => {
                            return (
                                <tr key={index}  className={'list-group-item'}>
                                    <td  className={'flex-row'}>
                                        <RemoveButton
                                            descriptor={'REMOVE PLAN PUBLICATION'}
                                            action={this.DeleteContentType}
                                            id={item.planpublicationunittypedetailid}
                                        />
                                        <EditBtn
                                            actionType={this.changePopupType}
                                            popupChange={this.changePopupState}
                                            change={this.setCurrentIndex}
                                            id={item.planpublicationunittypedetailid}
                                        />
                                    </td>
                                    <td>
	                                    {getItemData(planPublicationType ? planPublicationType : [], 'planpublicationunittypeid', item.planpublicationunittypeid, 'planpublicationunittypename')}
	                                    &nbsp;/&nbsp;
	                                    {getItemData(planPublicationType ? planPublicationType : [], 'planpublicationunittypeid', item.planpublicationunittypeid, 'planpublicationunittypenameeng')}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {item.unit}
                                    </td>
                                </tr>
                            )
                        })}
	                    </tbody>
                    </table>
                </div>
				{this.state.popup ? <AddUnit
                    close={this.changePopupState}
                    itemList={items}
                    publication = {publication}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
			</div>
		)
	}
}

const mapStateToProps = (state , ownProps) => {
    return {
        planPublicationType: state.planPublicationType,
	    items : ownProps.items ? ownProps.items : []
    }
};
const Units = connect(mapStateToProps)(UnitsComponent);

export default Units;