import React from 'react';
import {connect} from 'react-redux';
import {
    ActionPlanTenderPublication,
    ActionSourceTenderPublicationAdd,
    ActionSourceTenderPublicationDelete,
    ActionSourceTenderPublicationEdit
} from "../../../parts/actions";
import Select from "../../../parts/select";
import ColumnResizer from "react-column-resizer";
import ToolButtons from "../../../parts/ToolButtons";
import Storege from "../../../storage/storage";
import RemoveButton from "../../../parts/removeBtn";
import PlanTenderPublicationAdd from "./PlanTenderPublicationAdd";
import CommonTab from "../../../parts/CommonTabClass";
import TendersRequest from "../../tenders/TendersRequest";
import EditBtn from "../../../parts/EditBtn";
import getFullDateYear from "../../../parts/functions";

class PlanTenderPublicationsComponent extends CommonTab {

    state = {
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        order: null
    };

    orderFields = {
        "Tender": "tenderid",
        "Plan": 'planid',
        "Publication Date": "publicationdate",
        "Lot": "lot",
        "Remarks": "remarks",
        "Url": "url",
        "Source": "sourceid",
        "Tender Publication Purpose": "tenderpublicationpurposeid",
    };

    getSourceName(sourceId, sources) {
        let name = '';
        sources.forEach((item) => {
            if (item.sourceid == sourceId) {
                name = item.sourcename;
            }
        });
        return name;
    };

    addContentType = (object) => {
        ActionSourceTenderPublicationAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD TENDER PUBLICATION',
                item: data
            });
            this.changePopupState();
        });
    };


    editContentType = (object) => {
        ActionSourceTenderPublicationEdit(object.tenderpublicationid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE TENDER PUBLICATION',
                item: data,
                id: data.tenderpublicationid
            });
            this.changePopupState();
        });
    };

    render() {
        let {plan, plans, source, tendersArr, tenderPublicationPurpose} = this.props;
         if (!this.props.sourcecredibility.length) {
            TendersRequest('tenders');
        }
        if (!plan.tenderPublications) {
            ActionPlanTenderPublication(plan.planid);
        }
        let publications = plan.tenderPublications ? plan.tenderPublications : [];
        let plansArray = plans ? plans : [];
        let sourcesArray = source ? source : [];
        let tenders = tendersArr ? tendersArr : [];
        let tenderPublication = tenderPublicationPurpose ? tenderPublicationPurpose : [];
        this.sortResults(publications);
        return (
            <div>
                <div className="scrolled ">
                    <div className="custom-tool-bar">
                        <h3>Tender Publications</h3>
                        {/*<ToolButtons*/}
                        {/*    actionType={this.changePopupType}*/}
                        {/*    popupChange={this.changePopupState}*/}
                        {/*    id={this.state.currentItemIndex}*/}
                        {/*/>*/}
                    </div>
                    <table className={"table table-striped geometry-table"} valign={"middle"}>
                        <thead>
                        {this.orderPanel()}
                        </thead>
                        <tbody>
                        {publications.map((item, index) => {
                            let sourcename = this.getSourceName(item.sourceid, sourcesArray);
                            let tender = tenders[item.tenderid];
                            let tenderPubl = tenderPublication[item.tenderpublicationpurposeid];
                            return (
                                <tr key={index} onClick={
                                    () => {
                                        this.setState({
                                            rowIndex: index,
                                            currentItemIndex: item.tenderpublicationid
                                        });
                                    }
                                } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>

                                    <td>
                                        <div className="flex-row">
                                        <RemoveButton
                                            action={ActionSourceTenderPublicationDelete}
                                            id={item.tenderpublicationid}
                                            descriptor={'REMOVE TENDER PUBLICATION'}
                                        />
                                        <EditBtn
                                            actionType={this.changePopupType}
                                            popupChange={this.changePopupState}
                                            change={this.setCurrentIndex}
                                            id={item.tenderpublicationid}
                                        />
                                        </div>
                                    </td>
                                    <td>{tender}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {plan.planname}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{getFullDateYear(item.publicationdate)}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{item.lot}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{item.remarks}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td><a href={item.url}>{item.url}</a></td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{sourcename}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{tenderPubl}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                {this.state.popup ? <PlanTenderPublicationAdd
                    planid={plan.planid}
                    close={this.changePopupState}
                    itemList={publications}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        plans: state.plans,
        source: state.source,
        tendersArr: state.tendersArr,
        sourcecredibility: state.sourcecredibility,
        tenderPublicationPurpose: state.tenderPublicationPurpose,
    }
};

const PlanTenderPublications = connect(mapStateToProps)(PlanTenderPublicationsComponent);
export default PlanTenderPublications;
