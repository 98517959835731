import React from 'react';
import {connect} from "react-redux";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class StatusCategoryPopupComponent extends CommonTab {
    state = {
        status: this.props.status,
        errors: [],
        validateFields: [
            'statuscategoryname',
            'statuscategorynameeng'
        ]
    };

    constructor(props) {
        super(props);
    }

    changeState(name, e) {
        let temp = this.state.status;
        temp[name] = e.value;
        this.setState({status: temp});
    }

    render() {
        let {status, validateFields, errors} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <div className={'popup-content-types'}>
                    <form onSubmit={event => {
                        event.preventDefault();
                        this.formValidation(status, validateFields);
                    }}>
                        <h2>{this.props.button + (this.props.button === 'Add' ? ' New' : '')} Status Category</h2>
                        <table className={'table table-striped'} align={'right'}>
                            <tbody>
                            <tr>
                                <td>Status Category Name :</td>
                                <td className={errors.indexOf('statuscategoryname') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={status.statuscategoryname}
                                        onChange={(e) => {
                                            this.changeState('statuscategoryname', e.target);
                                        }}
                                    />
                                    {errors.indexOf('statuscategoryname') !== -1 ?
                                        <span className="errorText">Please set Status Category Name</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Status Category Name Eng :</td>
                                <td className={errors.indexOf('statuscategorynameeng') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={status.statuscategorynameeng}
                                        onChange={(e) => {
                                            this.changeState('statuscategorynameeng', e.target);
                                        }}
                                    />
                                    {errors.indexOf('statuscategorynameeng') !== -1 ?
                                        <span className="errorText">Please set Status Category Name Eng</span> : ''}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </form>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let content = {};
    state.statusCategories.map((item) => {
        if (item.statuscategoryid == ownProps.id) {
            content = item;
        }
    });
    return {
        status: Object.assign({}, content)
    }
};

const StatusCategoryPopup = connect(mapStateToProps)(StatusCategoryPopupComponent);

export default StatusCategoryPopup;