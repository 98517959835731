import React from 'react';
import {connect} from 'react-redux';
import {
    ActionPlacesHierarchyAdd,
    ActionPlacesHierarchyDelete,
    ActionPlacesHierarchyEdit,
    ActionSetHierarchies
} from "../actions";
import ColumnResizer from "react-column-resizer";
import Storege from "../../storage/storage";
import RemoveButton from "../removeBtn";
import HierarchiesAdd from "./HierarchiesAdd";
import ToolButtons from "../ToolButtons";
import CommonTab from "../CommonTabClass";
import EditBtn from "../EditBtn";
import {getItemData} from "../functions";

class HierarchiesComponent extends CommonTab {

    orderFields = {
        'Place Parent' :  (a, b) => {
            const {places} = this.props;
            let result = 0;
            if (this.getItemData(places, 'placeid', a.parentplaceid, 'name') < this.getItemData(places, 'placeid', b.parentplaceid, 'name')) {
                result = -1;
            }

            if (this.getItemData(places, 'placeid', a.parentplaceid, 'name') > this.getItemData(places, 'placeid', b.parentplaceid, 'name')) {
                result = 1;
            }
            return result
        },
        "ICBSPLACES SETTLEMENT":  (a, b) => {
            const {settlements} = this.props;
            let result = 0;
            if (this.getItemData(settlements, 'settlementid', a.settlementid, 'settlementname') < this.getItemData(settlements, 'settlementid', b.settlementid, 'settlementname')) {
                result = -1;
            }

            if (this.getItemData(settlements, 'settlementid', a.settlementid, 'settlementname') > this.getItemData(settlements, 'settlementid', b.settlementid, 'settlementname')) {
                result = 1;
            }
            return result
        },
        "ICBSPLACES MUNICIPALITY":  (a, b) => {
            const {municipalities} = this.props;
            let result = 0;
            if ( this.getItemData(municipalities, 'municipalityid', a.municipalityid, 'municipalityname') <  this.getItemData(municipalities, 'municipalityid', b.municipalityid, 'municipalityname')) {
                result = -1;
            }

            if (this.getItemData(municipalities, 'municipalityid', a.municipalityid, 'municipalityname') >  this.getItemData(municipalities, 'municipalityid', b.municipalityid, 'municipalityname')) {
                result = 1;
            }
            return result
        },
        "ICBSPLACES DISTRICT":  (a, b) => {
            const {districts} = this.props;
            let result = 0;
            if ( this.getItemData(districts, 'districtid', a.districtid, 'districtname') <  this.getItemData(districts, 'districtid', b.districtid, 'districtname')) {
                result = -1;
            }

            if (this.getItemData(districts, 'districtid', a.districtid, 'districtname') >  this.getItemData(districts, 'districtid', b.districtid, 'districtname')) {
                result = 1;
            }
            return result
        }
    };

    state = {
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        popupName : '',
    };

    addContentType = (object) => {
        ActionPlacesHierarchyAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLACE HIERARCHIES',
                item: data,
            });
            this.changePopupState();
             ActionSetHierarchies(this.props.place.placeid);
        });
    };

    editContentType = (object) => {
        ActionPlacesHierarchyEdit(object.placehierarchyid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLACE HIERARCHIES',
                item: data,
                id: data.placehierarchyid
            });
            ActionSetHierarchies(this.props.place.placeid);
            this.changePopupState();
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType

        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    getItemData = (collection, firstId, secondId, fieldName) => {
        let item = collection.filter(placeItem => {
            return placeItem[firstId] === secondId;
        });
        return item[0] ? item[0][fieldName] : '';
    };

    render() {
        const {place} = this.props;
        if (!place.hierarchiesList) {
            ActionSetHierarchies(place.placeid);
        }
        let hierarchies = place.hierarchiesList ? place.hierarchiesList : {};
        let overliyng = hierarchies.overlyingPlaces ? hierarchies.overlyingPlaces : [];
        let underlying = hierarchies.underlyingPlaces ? hierarchies.underlyingPlaces : [];

        this.sortResults(overliyng);
        underlying.sort((a,b) =>{
            if(!this.state.UOrder){
                return getItemData(this.props.places, 'placeid', a.placeid, 'name') > getItemData(this.props.places, 'placeid', b.placeid, 'name');
            }
            return getItemData(this.props.places, 'placeid', a.placeid, 'name') < getItemData(this.props.places, 'placeid', b.placeid, 'name');
        });
        return (
            <div>
                <div className={'inline-toolbuttons'}>
                    <h3>Overlying Places</h3>
                    <ToolButtons
                        name={'overliyng'}
                        popupName={this.popupName}
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <table className={"table table-striped"} valign={"middle"}>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {overliyng.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                () => {
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.placehierarchyid
                                    });
                                }
                            } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                <td class={'edit-buttons'}>
                                    <div className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionPlacesHierarchyDelete}
                                        id={item.placehierarchyid}
                                        descriptor={'REMOVE PLACE HIERARCHIES'}
                                    />
                                    <EditBtn
                                        name={'overliyng'}
                                        popupName = {this.popupName}
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.placehierarchyid}
                                    />
                                    </div>
                                </td>
                                <td>
                                    {this.getItemData(this.props.places, 'placeid', item.parentplaceid, 'name')}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {this.getItemData(this.props.settlements, 'settlementid', item.settlementid, 'settlementname')}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {this.getItemData(this.props.municipalities, 'municipalityid', item.municipalityid, 'municipalityname')}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {this.getItemData(this.props.districts, 'districtid', item.districtid, 'districtname')}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <div class="inline-toolbuttons">
                     <h4>Underlying places</h4>
                    <ToolButtons
                        name={'underlying'}
                        popupName={this.popupName}
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <table className={"table table-striped"} valign={"middle"}>
                    <thead>
                    <tr className={'sortable-btn'}>
                        <th></th>
                        <th>
                            <a className="sort-items" onClick={() => {
                                this.setState({
                                            UOrder : !this.state.UOrder
                                });
                            }}><h4>Place Child</h4><span  className={this.state.UOrder ? 'asc ' : 'desc'}>{this.state.UOrder ? 'asc ' : 'desc'}</span></a>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {underlying.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td class={'edit-buttons'}>
                                    <div className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionPlacesHierarchyDelete}
                                        id={item.placehierarchyid}
                                        descriptor={'REMOVE PLACE HIERARCHIES'}
                                    />
                                    <EditBtn
                                        name={'underlying'}
                                        popupName = {this.popupName}
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.placehierarchyid}
                                    />
                                    </div>
                                </td>
                                <td colSpan={4}>{getItemData(this.props.places, 'placeid', item.placeid, 'name')}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <HierarchiesAdd
                    placeid={place.placeid}
                    close={this.changePopupState}
                    name = {this.state.popupName}
                    itemList={place.hierarchiesList.overlyingPlaces.concat(place.hierarchiesList.underlyingPlaces)}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        places: state.places,
        settlements: state.settelments,
        municipalities: state.municipalities,
        districts: state.districts
    }
};

const Hierarchies = connect(mapStateToProps)(HierarchiesComponent);
export default Hierarchies;