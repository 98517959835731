import React from 'react';
import {connect} from 'react-redux';
import Select from "../../../parts/select";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class PlanLandusAddComponent extends CommonTab {

    state = {
        popupItem: {
            planid: this.props.planid
        },
        errors: [],
        validateFields: [
            'landuseid'
        ]
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }

    placeChange = (value) => {
        let temp = this.state.popupItem;
        temp.popupItemid = value.target.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        const {popupItem, errors, validateFields} = this.state;
        const {laduses} = this.props;
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.formValidation(popupItem, validateFields);
                    }}
                >
                    <h3>{this.props.button} Plan Landuse</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Landuse:</td>
                            <td className={errors.indexOf('landuseid') !== -1 ? 'error' : ''}>
                                <Select
                                    name={'landuseid'}
                                    types={laduses}
                                    className={"form-control"}
                                    value={popupItem.landuseid}
                                    optionValue={"landuseid"}
                                    optionName={"landusename"}
                                    onChange={(e) => {
                                        this.changeState('landuseid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('landuseid') !== -1 ?
                                    <span className="errorText">Please set Landuse</span> : ''}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item.planlanduseid == ownProps.id) {
            popupItem = item;
        }
    });

    return {
        laduses: state.allLanduses,
        popupItem: Object.assign({}, popupItem)
    }
};

const PlanLandusAdd = connect(mapStateToProps)(PlanLandusAddComponent);
export default PlanLandusAdd;
