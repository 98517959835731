import React, {Component} from 'react';

export default class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startPagination: this.getPaginationInterval(),
            endPagination: this.getPaginationInterval() + this.props.paginationCount,
            currentPage: this.props.currentPage,
            pages: this.props.pages
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            pages: nextProps.pages
        });
    };

    getPaginationInterval = () => {
        return Math.floor(this.props.currentPage / this.props.paginationCount) * 5;
    };

    nextClick = () => {
        this.setState(prevState => {
            if (prevState.endPagination < this.state.pages.length) {
                return {
                    startPagination: prevState.startPagination + this.props.paginationCount,
                    endPagination: prevState.endPagination + this.props.paginationCount
                }
            }
        });
    };

    prevClick = () => {
        this.setState(prevState => {
            if (prevState.startPagination >= this.props.paginationCount) {
                return {
                    startPagination: prevState.startPagination - this.props.paginationCount,
                    endPagination: prevState.endPagination - this.props.paginationCount
                }
            }
        });
    };

    render() {
        const {pages, click, currentPage, paginationCount} = this.props;
        let stylePrev = '';
        let styleNext = '';
        if (this.state.startPagination < paginationCount) {
            stylePrev = ' hide';
        }
        if (this.state.endPagination > pages.length) {
            styleNext = " hide";
        }
        return (
            <ul className={'pagination'}>
                <li className={'page-item'}>
                    <a className={'prev page-link' + stylePrev} onClick={this.prevClick}>{'<'}</a>
                </li>
                {pages.filter((item) => {
                    return (item <= this.state.endPagination && item > this.state.startPagination);
                })
                    .map((item, index) => {
                        const number = item;
                        return (
                            <li key={index} className={'page-item' + (currentPage == item ? ' active' : '')}>
                                <a className={'page-link'} onClick={(e) => {
                                    e.preventDefault();
                                    this.state.currentPage = number;
                                    click(number);
                                }}>{item}</a>
                            </li>
                        )
                    })}
                <li className={'page-item'}>
                    <a className={'next page-link' + styleNext} onClick={this.nextClick}>{'>'}</a>
                </li>
            </ul>
        )
    }
}
