import React from 'react';
import {connect} from 'react-redux';
import Popup from "../../parts/Popup";
import CommonTab from "../../parts/CommonTabClass";


class GeneralDataDescriptorAddComponent extends CommonTab {

    state = {
        popupItem: {},
        errors: [],
        validateFields: [
            'generalunitname',
            'generalunitnameeng'
        ]
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }

    changeState = (name, e) => {
        let temp = this.props.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    render() {
        const {popupItem, errors, validateFields} = this.state;
        console.log(popupItem)
        return (
            <Popup openClose={this.props.close}>
                <form onSubmit={event => {
                    event.preventDefault();
                    if (!('description' in popupItem)) {
                        popupItem['description'] = ''
                    }
                    this.formValidation(popupItem, validateFields);
                }}>
                    <h3>{this.props.button + (this.props.button === 'Add ' ? 'New ' : '')} General Unit:</h3>
                    <table className={'table table-striped'}>
                        <tbody>
                        <tr>
                            <td>General Unit Name:</td>
                            <td className={errors.indexOf('generalunitname') !== -1 ? 'error' : ''}>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'generalunitname'}
                                       value={popupItem.generalunitname ? popupItem.generalunitname : ''}
                                       onChange={(e) => {
                                           this.changeState('generalunitname', e.target);
                                       }}
                                />
                                {errors.indexOf('generalunitname') !== -1 ?
                                    <span className="errorText">Please set General Unit Name</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>General Source Name Eng:</td>
                            <td className={errors.indexOf('generalunitnameeng') !== -1 ? 'error' : ''}>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'generalunitnameeng'}
                                       value={popupItem.generalunitnameeng ? popupItem.generalunitnameeng : ''}
                                       onChange={(e) => {
                                           this.changeState('generalunitnameeng', e.target);
                                       }}
                                />
                                {errors.indexOf('generalunitnameeng') !== -1 ?
                                    <span className="errorText">Please set General Unit Name Eng</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Description:</td>
                            <td><input type={'text'}
                                       className={'form-control'}
                                       name={'description'}
                                       value={popupItem.description ? popupItem.description : ''}
                                       onChange={(e) => {
                                           this.changeState('description', e.target);
                                       }}
                            />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}


const mapStateToProps = (state, ownProps) => {

    let itemNew = {};

    state.generalDataDescriptorUnits.forEach(item => {
        if (ownProps.id === item.generalunitid) {
            itemNew = item;
        }
    });

    return {
        popupItem: Object.assign({}, itemNew)
    }

};
const GeneralDataDescriptorUnitAdd = connect(mapStateToProps)(GeneralDataDescriptorAddComponent);

export default GeneralDataDescriptorUnitAdd;