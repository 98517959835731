import React from 'react';
import {connect} from 'react-redux';
import {
    ActionPlanDelete,
    ActionPlanEdit,
    ActionSingleLandusePlans,
    ActionSingleLanduseTenders
} from "../../../parts/actions";
import PlansRequest from "../../plans/PlansRequest";
import PlanPopup from "./plans_popup";
import Pagination from "../../../parts/Pagination";
import Search from "../../../parts/Search";
import CommonTab from "../../../parts/CommonTabClass";
import {doFilter} from "../../../parts/functions";
import RemoveButton from "../../../parts/removeBtn";
import EditBtn from "../../../parts/EditBtn";
import PlanAdd from "../../plans/PlansAdd";
import Storege from "../../../storage/storage";

class LandusePlanComponent extends CommonTab {

    orderFields = {
        "Plan": "planname"
    };

    state = {
        landuse: {},
        planPopup: {},
        planNames: {},
        page: 1,
        order: null,
        searchValue: ''
    };

    constructor(props) {
        super(props);
        this.state.landuse = props.landuse;
        this.state.planPopup = props.planPopup;
        this.state.planNames = props.planNames;
    }

    paginationChange = (number) => {
        this.setState({
            page: number
        });
    };

    changeStatePopup = (id) => {
        let temp = this.props.planPopup;
        temp.active = true;
        this.props.planNames.forEach(function (item) {
            if (item.planid == id) {
                temp.data = item;
            }
        })
        this.setState({
            tenderPopup: Object.assign({}, temp)
        });
    }

    editContentType = (object) => {
        ActionPlanEdit(object.planid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLAN',
                item: data,
                id: data.planid
            });
            ActionSingleLandusePlans(this.props.id);
            this.changePopupState();
        });
    };

    orderPanel = () => {
        let fields = [];
        if (this.orderFields) {
            for (let label in this.orderFields) {
                fields.push((
                    <React.Fragment>
                        <th>
                            <a className="sort-items" onClick={() => {
                                this.setOrder(this.orderFields[label])
                            }}><h4>{label}</h4>{this.diractionSymbol(this.orderFields[label])}</a>
                        </th>
                        {Object.keys(this.orderFields).indexOf(label) !== Object.keys(this.orderFields).length - 1 ?
                            <ColumnResizer className="columnResizer" minWidth={5}/> : ''}
                    </React.Fragment>
                ))
            }
        }
        if (fields.length) {
            return (
                <tr className={'sortable-btn'}>
                    {fields}
                </tr>
            )
        }
        return null;
    };


    render() {
        let landuseId = this.props.id;
        let landuse = this.props.landuse;
        if (!landuse.hasOwnProperty('landusePlans')) {
            ActionSingleLandusePlans(landuseId);
        }
        let plans = [];
        let plansArr = this.props.plans;
        for (let key in plansArr) {
            plans.push({id: +key, plan: plansArr[key]});
        }
        let landusePlans = this.props.landuse.landusePlans;
        let planNames = this.props.planNames;
        if (!planNames.length) {
            PlansRequest('plans');
        }
        let items = [];
        let pages = [];
        const perPage = 20;
        if (landusePlans) {
            items = landusePlans;
            if (landusePlans.length > perPage) {
                items = (landusePlans).slice((this.state.page - 1) * perPage, (this.state.page) * perPage);
                let maxPage = Math.ceil((landusePlans).length / perPage);
                for (let i = 1; i <= maxPage; i++) {
                    pages.push(i);
                }
            }
        }
        if (!landusePlans) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        let {searchValue} = this.state;

        let displayItems = planNames;

        let arr = [];
        displayItems.forEach(item => {
            let item2 = landusePlans.filter(planItem => {
                return planItem.planid == item.planid;
            });
            if(item2.length){
                arr.push(item);
            }
        });

        if (searchValue.length) {
            arr = arr.filter(item => {
                return item.plan.toLowerCase().indexOf(searchValue) != -1;
            });
        }

        this.sortResults(displayItems);
        return (
            <div>
                <div className={'inline-toolbuttons'}>
                    <h3>Plans</h3>
                    <Search
                        change={this.search}
                        id={'land-plan'}
                    />
                </div>
                <table className="table table-striped">
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {arr.map((item, index) => {
                        if(!item){
                            return '';
                        }
                        return (
                            <tr key={index}>
                                <td>
                                <EditBtn
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    change={this.setCurrentIndex}
                                    id={item.planid}
                                />
                                </td>
                                <td>{item ? item.planname : ''}</td>
                            </tr>
                        )
                    })}
                    <tr>
                        <Pagination currentPage={this.state.page} pages={pages} click={this.paginationChange}
                                    paginationCount={5}/>
                    </tr>
                    </tbody>
                </table>
                {/*{this.state.popup ? <PlanPopup active={this.props.planPopup.active} data={this.props.planPopup.data}/> : ''}*/}
                {this.state.popup ? <PlanAdd
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        landuse: state.singleLanduse,
        plans: state.landuses.plans,
        planPopup: {
            active: false
        },
        planNames: state.plans
    }
};
const LandusePlan = connect(mapStateToProps)(LandusePlanComponent);

export default LandusePlan;