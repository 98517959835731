import React from 'react';
import {connect} from 'react-redux';
import Popup from "../Popup";
import Select from "../select";
import CommonTab from "../CommonTabClass";

class HierarchiesAddComponent extends CommonTab {

    state = {
        popupItem: {
            placeid: this.props.placeid
        },
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        errors: [],
        validateFields: [
            'municipalityid',
            'settlementid',
            'districtid'
        ]
    };

    constructor(props) {
        super(props);
        let popupItem ={}
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }else {
            if(this.props.name == 'underlying'){
                popupItem = {
                    parentplaceid: this.props.placeid
                }
            }else {
                popupItem = {
                    placeid: this.props.placeid
                }
            }
            this.state = Object.assign({}, this.state, {popupItem: popupItem});
        }
    }


    placeChange = (value) => {
        let temp = this.state.popupItem;
        temp.popupItemid = value.target.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        const {popupItem, validateFields, errors} = this.state;
        const  {name} = this.props;
        console.log(this.props);
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('parentplaceid' in popupItem)) {
                            popupItem['parentplaceid'] = ''
                        }
                        //this.formValidation(popupItem, validateFields);
                        this.formValidation(popupItem, [(name == 'underlying' ? 'placeid' : 'parentplaceid')]);
                    }}
                >
                    <h3>{this.props.button} Place Hierarchies</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>ICBSPlaces District:</td>
                            <td className={errors.indexOf('districtid') !== -1 ? 'error' : ''}>
                                <Select
                                    id={"community_type"}
                                    className={"form-control"}
                                    name={"population_type"}
                                    value={popupItem.districtid ? popupItem.districtid : ''}
                                    types={this.props.districts}
                                    optionValue={"districtid"}
                                    optionName={"districtname"}
                                    onChange={(e) => {
                                        this.changeState('districtid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('districtid') !== -1 ?
                                    <span className="errorText">Please set District</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>ICBSPlaces Municipality:</td>
                            <td className={errors.indexOf('municipalityid') !== -1 ? 'error' : null}>
                                <Select
                                    id={"community_type"}
                                    className={"form-control"}
                                    name={"overlying_places_municipality"}
                                    value={(popupItem.municipalityid || popupItem.municipalityid === 0) ? popupItem.municipalityid : null}
                                    types={this.props.municipalities}
                                    optionValue={"municipalityid"}
                                    optionName={"municipalityname"}
                                    onChange={(e) => {
                                        this.changeState('municipalityid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('municipalityid') !== -1 ?
                                    <span className="errorText">Please set Municipality</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>ICBSPlaces Settlement:</td>
                            <td className={errors.indexOf('settlementid') !== -1 ? 'error' : ''}>
                                <Select
                                    id={"community_type"}
                                    className={"form-control"}
                                    name={"overlying_places_settelment"}
                                    value={popupItem.settlementid ? popupItem.settlementid : ''}
                                    types={this.props.settlements}
                                    optionValue={"settlementid"}
                                    optionName={"settlementname"}
                                    onChange={(e) => {
                                        this.changeState('settlementid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('settlementid') !== -1 ?
                                    <span className="errorText">Please set Settlement</span> : ''}
                            </td>
                        </tr>
                        {name == 'underlying' ? (
                             <tr>
                            <td>Place Child:</td>
                            <td>
                                <Select
                                    id={"underlying_places"}
                                    className={"form-control"}
                                    name={"underlying_places"}
                                    value={popupItem.placeid ? popupItem.placeid : null}
                                    types={this.props.places}
                                    optionValue={"placeid"}
                                    optionName={"name"}
                                    onChange={(e) => {
                                        this.changeState('placeid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('placeid') !== -1 ?
                                    <span className="errorText">Please set Place Child</span> : ''}
                            </td>
                        </tr>
                        ):(
                             <tr>
                            <td>Place Parent:</td>
                            <td>
                                <Select
                                    id={"overlying_places"}
                                    className={"form-control"}
                                    name={"overlying_places"}
                                    value={popupItem.parentplaceid ? popupItem.parentplaceid : null}
                                    types={this.props.places}
                                    optionValue={"placeid"}
                                    optionName={"name"}
                                    onChange={(e) => {
                                        this.changeState('parentplaceid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('parentplaceid') !== -1 ?
                                    <span className="errorText">Please set Place Parent</span> : ''}
                            </td>
                        </tr>
                        )}
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item.placehierarchyid == ownProps.id) {
            popupItem = item;
        }
    });

    return {
        places: state.places,
        settlements: state.settelments,
        municipalities: state.municipalities,
        districts: state.districts,
        popupItem: Object.assign({}, popupItem)
    }
};

const HierarchiesAdd = connect(mapStateToProps)(HierarchiesAddComponent);
export default HierarchiesAdd;
