import React from 'react';
import {connect} from "react-redux";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class TenderContactorPopupComponent extends CommonTab {
    state = {
        contactorPopup: this.props.contactorPopup,
        errors: [],
        validateFields: [
            'contrarctorname',
            'contrarctornameeng',
            // 'remarks'
        ]
    };

    changeState(name, e) {
        let temp = this.state.contactorPopup;
        temp[name] = e.value;
        this.setState({contactorPopup: temp});
    }

    render() {
        let {contactorPopup, validateFields, errors} = this.state;

        let {close} = this.props;
        return (
            <Popup openClose={close}>
                <div className={'popup-content-types'}>
                    <form onSubmit={event => {
                        event.preventDefault();
                        if (!('remarks' in contactorPopup)) {
                            contactorPopup['remarks'] = ''
                        }
                        this.formValidation(contactorPopup, validateFields);
                    }}>


                        <h2>{this.props.button} Contractor</h2>
                        <table className={'table table-striped'} align={'right'}>
                            <tbody>
                            <tr>
                                <td>Tender Contactor Name :</td>
                                <td className={errors.indexOf('contrarctorname') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={contactorPopup.contrarctorname}
                                        onChange={(e) => {
                                            this.changeState('contrarctorname', e.target);
                                        }}
                                    />
                                    {errors.indexOf('contrarctorname') !== -1 ?
                                        <span className="errorText">Please set Tender Contractor Name</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Tender Contactor Nameeng :</td>
                                <td className={errors.indexOf('contrarctornameeng') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={contactorPopup.contrarctornameeng}
                                        onChange={(e) => {
                                            this.changeState('contrarctornameeng', e.target);
                                        }}
                                    />
                                    {errors.indexOf('contrarctornameeng') !== -1 ?
                                        <span className="errorText">Please set Tender Contractor Name Eng</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Tender Contactor Remarks :</td>
                                <td className={errors.indexOf('remarks') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={contactorPopup.remarks}
                                        onChange={(e) => {
                                            this.changeState('remarks', e.target);
                                        }}
                                    />
                                    {errors.indexOf('remarks') !== -1 ?
                                        <span className="errorText">Please set Tender Contractor Remarks</span> : ''}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </form>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let content = {};
    state.contractors.map((item) => {
        if (item.contractorid == ownProps.id) {
            content = item;
        }
    });
    return {
        contactorPopup: Object.assign({}, content)
    }
};

const TenderContactorPopup = connect(mapStateToProps)(TenderContactorPopupComponent);

export default TenderContactorPopup;