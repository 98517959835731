import React from 'react';
import api from "./api";
import Storege from "./storage/storage";

export default class Login extends React.Component {
    render() {
        return (
            <div>
                <form className={"form-horizontal login-form qwerr"}
                      onSubmit={(e) => {
                          e.preventDefault();
                          fetch('/login_now', {
                              method: "POST",
                              mode: 'cors',
                              body: JSON.stringify({
                                  email: document.getElementById('email').value,
                                  password: document.getElementById('password').value
                              }),
                              headers: {
                                  "Content-type": "application/json",
                                  "X-Requested-With": "XMLHttpRequest",
                                  "X-CSRF-TOKEN": api.token
                              }
                          }).then((response) => response.json()).then((data) => {
                              Storege.dispatch({
                                  type: 'UPDATE LOGIN USER',
                                  item: data
                              });

                          });
                      }}
                >
                    <img src="/public/img/logo-en.png" alt=""/>
                    <div className={'form-group'}>
                        <label htmlFor={"email"} className={"col-md-4 control-label"}>E-Mail Address</label>
                        <div className={"col-md-6"}>
                            <input id={"email"} type={"email"} className={"form-control"} name={"email"}
                                   required={true}
                                   autoFocus={"autoFocus"}/>
                        </div>
                    </div>
                    <div className={'form-group'}>
                        <label htmlFor={"password"} className={"col-md-4 control-label"}>Password</label>
                        <div className={"col-md-6"}>
                            <input id={"password"} type={"password"} className={"form-control"} name={"password"}
                                   required={true}/>
                        </div>
                    </div>
                    <div className={"form-group"}>
                        <div className={"col-md-6 col-md-offset-4"}>
                            <div className={"checkbox"}>
                                <label>
                                    <input type={"checkbox"} name={"remember"}
                                           red={"{{old('remember') ? 'checked' : ''}}"}/> Remember Me</label>
                            </div>
                        </div>
                    </div>
                    <div className={"form-group"}>
                        <div className={"col-md-8 col-md-offset-4"}>
                            <button type={"submit"} className={"btn btn-primary"}>Login</button>
                            {/*<a className={"btn btn-link"} href={"route('password.request')"}>Forgot Your Password?</a>*/}
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}