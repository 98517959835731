import React from 'react';
import {connect} from 'react-redux';
import Popup from "../Popup";
import CommonTab from "../CommonTabClass";
import DatePicker from "react-datepicker";

import wkt from 'wkt' ;
// console.log(wkt.parse('POINT (217891.30551489486 610506.84112128208)'));
// console.log(wkt.parse('POINT (35.188409786635539 31.587680028277408)'));
// console.log(wkt.parse('POLYGON ((35.189917879152539 31.582394539595505, 35.190185422314755 31.582727811103751, 35.189674268370339 31.583956731816926, 35.18935788841452 31.584623176948394, 35.189017113236709 31.58497721038415, 35.188822355102886 31.585977000016928, 35.18921157425163 31.586414454267938, 35.189795503406174 31.586810263484878, 35.189698071128554 31.587330991999526, 35.189406031140258 31.587914197120178, 35.189430258134905 31.58860160521877, 35.189941152083755 31.589226479917063, 35.190427686364963 31.590038938718624, 35.190719533245627 31.59076795175957, 35.190500507949729 31.591330333745073, 35.190685876979089 31.591478460750047, 35.190468736892768 31.591886193869115, 35.1904731165032 31.592021760649633, 35.190257007324945 31.5920801210314, 35.189819092115378 31.591580165204697, 35.188967332373679 31.591642563941445, 35.188237497315384 31.590975991507541, 35.18782393647453 31.590476031860227, 35.18721560830658 31.590330118628273, 35.18699672268135 31.589476108422797, 35.186996847412026 31.588809536494672, 35.186777856833409 31.588538764957796, 35.186948339495181 31.587913882858903, 35.1869483863854 31.587663974699719, 35.186267052720247 31.587580546424636, 35.185780596474849 31.586622329280317, 35.185050823243962 31.585955647957018, 35.184831954431182 31.585164133081239, 35.18509968606957 31.58453926806261, 35.185610823532194 31.584226936784475, 35.18573246063729 31.583935379995491, 35.187362826849096 31.583269036717677, 35.189114855144076 31.582581944260049, 35.189917879152539 31.582394539595505))'));

class GeometryAddComponent extends CommonTab {

    state = {
        popupItem: {
            placeid : this.props.placeid
        },
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        errors: [],
        validateFields: [
            'shapewkt',
            'createdate',
            'shapewgs84pointwkt',
            // 'shapewgs84wkt'
            // 'statusid',
            // 'remarks'
        ]
    };
    wktFields = ['shapewkt','shapepointwkt','shapewgs84wkt','shapewgs84pointwkt','labelpointwkt','labelpointwgs84wkt'];

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }


    formValidation = (objectForSending, requireFields) => {
        let counter = 0;
        let arr = [];
        this.wktFields.forEach(item => {
            if(objectForSending[item] && !wkt.parse(objectForSending[item])){
                arr.push(item);
            }
        });
        requireFields.forEach(item => {
            if((objectForSending[item] || objectForSending[item]===0) && (objectForSending[item].length || (typeof objectForSending[item] && (new Date(objectForSending[item]))))) {
                counter++;
            } else {
                if(arr.indexOf(item) === -1) {
                    arr.push(item);
                }
            }
        });
        if (arr.length) {
            this.setState({
                errors: arr
            })
        } else {
            this.setState({
                errors: []
            });
            this.props.action(objectForSending);
        }
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        const {popupItem, validateFields, errors} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.formValidation(popupItem, validateFields);
                    }}
                >
                    <h3>{this.props.button} Place Geometry</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr className={errors.indexOf('createdate') !== -1 ? 'error' : ''}>
                            <td>Create Date:</td>
                            <td>
                                <DatePicker
                                    selected={popupItem.createdate ? new Date(popupItem.createdate) : ''}
                                    onChange={(data)=>{
                                        this.changeState('createdate',{value : data})
                                    }}
                                    //showTimeSelect
                                    timeFormat="HH:mm:s"
                                    timeIntervals={60}
                                    dateFormat="dd/MM/yyyy h:mm:s"
                                    timeCaption="time"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Shape WKT:
                            </td>
                            <td className={errors.indexOf('shapewkt') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.shapewkt}
                                    onChange={(e) => {
                                        this.changeState('shapewkt', e.target);
                                    }}
                                />
                                {errors.indexOf('shapewkt') !== -1 ? <span className="errorText">Please set shapewkt</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Shape Point WKT:
                            </td>
                            <td className={errors.indexOf('shapepointwkt') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.shapepointwkt}
                                    onChange={(e) => {
                                        this.changeState('shapepointwkt', e.target);
                                    }}
                                />
                                {errors.indexOf('shapepointwkt') !== -1 ? <span className="errorText">Please set shapepointwkt</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Shape:
                            </td>
                            <td className={errors.indexOf('shape') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.shape}
                                    onChange={(e) => {
                                        this.changeState('shape', e.target);
                                    }}
                                />
                                {errors.indexOf('shape') !== -1 ? <span className="errorText">Please set shape</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Shapepoint:
                            </td>
                            <td className={errors.indexOf('shapepoint') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.shapepoint}
                                    onChange={(e) => {
                                        this.changeState('shapepoint', e.target);
                                    }}
                                />
                                {errors.indexOf('shapepoint') !== -1 ? <span className="errorText">Please set shapepoint</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Shape WGS 84 WKT:
                            </td>
                            <td className={errors.indexOf('shapewgs84wkt') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.shapewgs84wkt}
                                    onChange={(e) => {
                                        this.changeState('shapewgs84wkt', e.target);
                                    }}
                                />
                                {errors.indexOf('shapewgs84wkt') !== -1 ? <span className="errorText">Please set shapewgs84wkt</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Shape WGS 84 Point WKT:
                            </td>
                            <td className={errors.indexOf('shapewgs84pointwkt') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.shapewgs84pointwkt}
                                    onChange={(e) => {
                                        this.changeState('shapewgs84pointwkt', e.target);
                                    }}
                                />
                                {errors.indexOf('shapewgs84pointwkt') !== -1 ? <span className="errorText">Please set shapewgs84pointwkt</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Shape WGS 84:
                            </td>
                            <td className={errors.indexOf('shapewgs84') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.shapewgs84}
                                    onChange={(e) => {
                                        this.changeState('shapewgs84', e.target);
                                    }}
                                />
                                {errors.indexOf('shapewgs84') !== -1 ? <span className="errorText">Please set shapewgs84</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Shape WGS 84 Point:
                            </td>
                            <td className={errors.indexOf('shapewgs84point') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.shapewgs84point}
                                    onChange={(e) => {
                                        this.changeState('shapewgs84point', e.target);
                                    }}
                                />
                                {errors.indexOf('shapewgs84point') !== -1 ? <span className="errorText">Please set shapewgs84point</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Shape Gmaps Encoded:
                            </td>
                            <td className={errors.indexOf('shapegmapsencoded') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.shapegmapsencoded}
                                    onChange={(e) => {
                                        this.changeState('shapegmapsencoded', e.target);
                                    }}
                                />
                                {errors.indexOf('shapegmapsencoded') !== -1 ? <span className="errorText">Please set shapegmapsencoded</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Labelpoint:
                            </td>
                            <td className={errors.indexOf('labelpoint') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.labelpoint}
                                    onChange={(e) => {
                                        this.changeState('labelpoint', e.target);
                                    }}
                                />
                                {errors.indexOf('labelpoint') !== -1 ? <span className="errorText">Please set labelpoint</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Labelpoint WGS 84:
                            </td>
                            <td className={errors.indexOf('labelpointwgs84') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.labelpointwgs84}
                                    onChange={(e) => {
                                        this.changeState('labelpointwgs84', e.target);
                                    }}
                                />
                                {errors.indexOf('labelpointwgs84') !== -1 ? <span className="errorText">Please set labelpointwgs84</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Labelpoint WKT:
                            </td>
                            <td className={errors.indexOf('labelpointwkt') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.labelpointwkt}
                                    onChange={(e) => {
                                        this.changeState('labelpointwkt', e.target);
                                    }}
                                />
                                {errors.indexOf('labelpointwkt') !== -1 ? <span className="errorText">Please set labelpointwkt</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Labelpoint WGS 84 WKT:
                            </td>
                            <td className={errors.indexOf('labelpointwgs84wkt') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.labelpointwgs84wkt}
                                    onChange={(e) => {
                                        this.changeState('labelpointwgs84wkt', e.target);
                                    }}
                                />
                                {errors.indexOf('labelpointwgs84wkt') !== -1 ? <span className="errorText">Please set labelpointwgs84wkt</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Shapearea:
                            </td>
                            <td className={errors.indexOf('shapearea') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.shapearea}
                                    onChange={(e) => {
                                        this.changeState('shapearea', e.target);
                                    }}
                                />
                                {errors.indexOf('shapearea') !== -1 ? <span className="errorText">Please set shapearea</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Shapearea Length:
                            </td>
                            <td className={errors.indexOf('shapelength') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.shapelength}
                                    onChange={(e) => {
                                        this.changeState('shapelength', e.target);
                                    }}
                                />
                                {errors.indexOf('shapelength') !== -1 ? <span className="errorText">Please set shapelength</span> : '' }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
	let place = {};
	state.places.forEach(function (item) {
		if (item.placeid == ownProps.placeid) {
			console.log(item);
			place = item;
		}
	});
	return {
		popupItem: Object.assign({}, place.geometriesList)
	}
};

const GeometryAdd = connect(mapStateToProps)(GeometryAddComponent);
export default GeometryAdd;
