import React from 'react';

const token = document.querySelector("input[name=_token]");

const api = {
    // baseUrl: '/peacenow_data/',
    baseUrl: '/',
    // ajaxUrl: 'https://test.it-hive.net/peacenow_data/',
    ajaxUrl: '/',

    headers: {
        'Content-type': 'application/json',
        'X-CSRF-TOKEN' : token.value
    },

    token: token.value,
    getRoute : function (route) {
        return this.baseUrl+route;
    }
};

export default api;