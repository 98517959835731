import React from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import ChartsRequest from "./chartsRequest";
import api from "../../api";
import Pagination from "../../parts/Pagination";
import RemoveButton from "../../parts/removeBtn";
import {ChartAdd, ChartDel, ChartEdit} from "../../parts/actions";
import Storege from "../../storage/storage";
import ToolButtons from "../../parts/ToolButtons";
import Search from "../../parts/Search";
import AddNew from "../item_list/new_chart_add";
import CommonList from "../../parts/CommonListClass";
import getFullDateYear from "../../parts/functions";
import EditBtn from "../../parts/EditBtn";

const perPega = 15;

class ItemListComponent extends CommonList {

    orderFields = {
        "Chart": 'chartname',
        "Update Date": 'updated_at'
    };

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            searchValue: '',
            popup: false,
            popupType: undefined,
            currentItemIndex: -1,
            rowIndex: -1
        };
    }

    paginationChange = (number) => {
        this.setState({
            page: number
        });
    };

    addContentType = (object) => {
        ChartAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD CHART',
                item: data
            });
            this.changePopupState();
            this.props.history.push(data.chartid);
        });
    };

    editContentType = (object) => {
        ChartEdit(object.chartid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE CHART',
                item: data,
                id: data.chartid
            });
            this.changePopupState();
            this.props.history.push(data.chartid);
        });
    };

    search = (value) => {
        this.setState({
            searchValue: value
        })
    };

    render() {
        const {searchValue} = this.state;
        if (!this.props.charts.length) {
            ChartsRequest('charts');
        }
        let items = this.props.charts ? this.props.charts : [];
        if (searchValue.length) {
            items = items.filter((item) => {
                return item.chartname.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
            });
        }
        this.sortResults(items);
        let maxPage = Math.ceil(items.length / perPega);
        items = items.slice((this.state.page - 1) * perPega, (this.state.page) * perPega);
        let pages = [];
        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }
        return (
            <div>
                <h2>Charts</h2>
                <div className={'tool-bar'}>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                    <Search
                        change={this.search}
                    />
                </div>
                <ul className={"list-group"}>
                    {this.orderPanel()}
                    {items.map( (item, index) => {
                        return (
                            <li key={index}>
                                <RemoveButton
                                    action={ChartDel}
                                    id={item.chartid}
                                    descriptor={'REMOVE CHART'}
                                />
                                <EditBtn
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    change={this.setCurrentIndex}
                                    id={item.chartid}
                                />
                                <Link to={api.getRoute('charts/chart/') + item.chartid} onClick={
                                    () => {
                                        this.setState({
                                            rowIndex: index,
                                            currentItemIndex: item.placeid
                                        });
                                    }
                                } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                    <div className="col-sm-6">
                                        {item.chartname}
                                    </div>
                                    <div className="col-sm-6">
                                        <span>{item.updated_at ? getFullDateYear(item.updated_at) : '01.01.1970'}</span>
                                    </div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                <Pagination
                    currentPage={this.state.page}
                    pages={pages}
                    click={this.paginationChange}
                    paginationCount={5}
                />
                {this.state.popup ? <AddNew
                    openClose={this.changePopupState}
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    charts: state.charts,
    pagination: state.pagination
});

const ItemList = connect(mapStateToProps)(ItemListComponent);
export default ItemList;