import React from 'react';
import {Link, Route} from 'react-router-dom';
import api from "../../api";
import GeneralDataList from "./general_data_list";
import GeneralDataSingle from "./general_data_single";
import GeneralDataDescriptorUnit from "./general_data_descriptor_unit";
import GeneralDataDescriptorSource from "./general_data_descriptor_source";
import GeneralRequest from "./GeneralRequest";
import GeneralDataAdd from "./general_data_add";
import GeneralDataEdit from "./general_data_edit";

export default class TabsDataRow extends React.Component {
    render() {
        const GeneralDataTabs = [
            {
                tabName: 'General Data',
                tabEnd: 'general',
                tabUrl: api.getRoute('general/category')
            },
            {
                tabName: 'General Data Descriptors',
                tabEnd: 'descriptors',
                tabUrl: api.getRoute('general/descriptors')
            },
        ];

        let path=this.props.location.pathname;
        return(

            <div>
                <h2>General Data</h2>
                <div className="row">
                    <div className="col-sm-12">
                        <ul className={"nav navbar-nav place-tabs"}>
                            {GeneralDataTabs.map((item, index)=>{
                                let link = item.tabUrl;
                                return(
                                    <li key={index}>
                                        <Link className={path.indexOf(link) != -1 ? 'active' : ''} to={link} onClick={(e)=>{
                                        }}>{item.tabName}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-4">
                        <Route path={api.getRoute('general/category/')} component={GeneralDataList}/>
                    </div>
                    <div className="col-sm-8">
                        <Route  path={api.getRoute("general/category/:id(\\d+)")} component={GeneralDataSingle}/>
                        <Route path={api.getRoute('general/category/add/')} component={GeneralDataAdd}/>
                        <Route path={api.getRoute('general/category/edit/:id(\\d+)')} component={GeneralDataEdit}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 custom-padding">
                        <Route path={api.getRoute('general/descriptors/')} component={GeneralDataDescriptorUnit}/>
                    </div>
                    <div className="col-sm-6 custom-padding">
                        <Route path={api.getRoute('general/descriptors')} component={GeneralDataDescriptorSource}/>
                    </div>
                </div>
            </div>
        )
    }
}