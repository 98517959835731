import React from 'react';
import {connect} from 'react-redux';
import Select from "../../../parts/select";
import {
    ActionSourceTenderPublicationAdd, ActionSourceTenderPublicationDelete, ActionSourceTenderPublicationEdit,
    ActionTenderPublications
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import TenderPublicationsAdd from "./TenderPublicationsAdd";
import Storege from "../../../storage/storage";
import ToolButtons from "../../../parts/ToolButtons";
import RemoveButton from "../../../parts/removeBtn";
import CommonTab from "../../../parts/CommonTabClass";
import {getItemData} from "../../../parts/functions";
import EditBtn from "../../../parts/EditBtn";
import PlansRequest from "../../plans/PlansRequest";
import Units from './unit/Units';
import Events from './event/Events';
import Costs from './cost/Costs';

class TenderPublicationsComponent extends CommonTab {
    orderFields = {
        "Publication Date": 'publicationdate',
        "Tender Publication Purpose": 'tenderpublicationpurposeid',
        "Source": 'sourceid',
        "Source Credibility": 'sourcecredibiltyid',
        "Plan": "planid",
        "Lot": "lot",
        "Remarks": 'remarks',
        "URL": 'url'
    };

    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            popupType: undefined,
            currentItemIndex: -1,
            rowIndex: -1,
            order: null,
            tab : 0
        }
    }

    getFullDateYear(date) {
        let newDate = new Date(date);
        let Year = newDate.getFullYear();
        let Month = newDate.getMonth()+1;
        let Day = newDate.getDate();
        //let dateString = Year + '-' + (Month < 10 ? '0' + Month : Month) + '-' + (Day < 10 ? '0' + Day : Day);
        // console.log(Month,'Month');
        let dateString = (Day < 10 ? '0' + Day : Day) + '-' + (Month < 10 ? '0' + Month : Month) + '-' + Year   ;
        // let dateString = Day + '-' + Month + '-' + Year   ;
        return dateString;
    };

    addContentType = (object) => {
        object.publicationdate.setHours(object.publicationdate.getHours()+12);
        const {tender} = this.props;
        ActionSourceTenderPublicationAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD TENDER PUBLICATION',
                item: data
            });
            ActionTenderPublications(tender.tenderid);
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionSourceTenderPublicationEdit(object.tenderpublicationid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE TENDER PUBLICATION',
                item: data,
                id: data.tenderpublicationid
            });
            this.changePopupState();
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    render() {
        const {plans,tender, tenderPublicPurp, tenderSourceArr, sourcecredibility, tendersPlacesArr} = this.props;
        if (!plans.length) {
            PlansRequest('plans');
        }
        if (!tender.tenderPublications && tender.tenderid) {
            ActionTenderPublications(tender.tenderid);
        }
        let publications = tender.tenderPublications ? tender.tenderPublications : [];
        let details = tender.tenderPublicationsDetails ? tender.tenderPublicationsDetails : {};
        this.sortResults(publications);
        let curent = null;
        if(this.state.currentItemIndex && this.state.currentItemIndex > 0){
            // curent = publications.filter( (item) => {
            //     return item.tenderpublicationid == this.state.currentItemIndex;
            // } )[0];
            curent = details[this.state.currentItemIndex];
        }
        let tabs =[]
        if(curent){
             tabs = [
                {
                    'tabName' : 'Unit Details',
                    'component' :  <Units items={curent.unit} publication={this.state.currentItemIndex} tenderid={tender.tenderid} />
                },
                {
                    'tabName' : 'Event Details',
                    'component' :  <Events items={curent.event} publication={this.state.currentItemIndex} tenderid={tender.tenderid} />
                },
                {
                    'tabName' : 'Cost Details',
                    'component' :  <Costs items={curent.cost} publication={this.state.currentItemIndex} tenderid={tender.tenderid} />
                },
            ]
        }
        return (
            <div>
                <div className={'custom-tool-bar'}>
                    <h3>Tender Publications</h3>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <div className={'scrolled'}>
                    <table className={'table table-striped publication-table'}>
                        <thead>
                        {this.orderPanel()}
                        </thead>
                        <tbody>
                        {publications.map((item, index) => {
                            return (
                                <tr key={index} onClick={
                                    () => {
                                        this.setState({
                                            rowIndex: index,
                                            currentItemIndex: item.tenderpublicationid
                                        });
                                    }
                                } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                    <td>
                                        <div className="flex-row">
                                        <RemoveButton
                                            id={item.tenderpublicationid}
                                            action={ActionSourceTenderPublicationDelete}
                                            descriptor={'REMOVE TENDER PUBLICATION'}
                                        />
                                        <EditBtn
                                            actionType={this.changePopupType}
                                            popupChange={this.changePopupState}
                                            change={this.setCurrentIndex}
                                            id={item.tenderpublicationid}
                                        />
                                        </div>
                                    </td>
                                    <td>{item.publicationdate ? this.getFullDateYear(item.publicationdate) : ''}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{tenderPublicPurp[item.tenderpublicationpurposeid]}</td>
                                    {/*<td>{getItemData(tenderPublicPurp ? tenderPublicPurp : [], 'tenderpurposeid', item.tenderpublicationpurposeid, 'tenderpurposename')}</td>*/}
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{getItemData(tenderSourceArr ? tenderSourceArr : [], 'sourceid', item.sourceid, 'sourcename')}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{getItemData(sourcecredibility ? sourcecredibility : [], 'sourcecredibilityid', item.sourcecredibiltyid, 'sourcecredibilityname')}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{getItemData(plans ? plans : [], 'planid', item.planid, 'planname')}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{item.lot ? item.lot : ''}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{item.remarks ? item.remarks : ''}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{item.url ? item.url : ''}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    {curent ? (
                            <div>
                                <ul className={"nav navbar-nav place-tabs"}>
                                    {tabs.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <a className={this.state.tab == index ? 'active' : ''} href={'#'} onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({tab: index});
                                                }}>{item.tabName}</a>
                                            </li>
                                        )
                                    })}
                                </ul>
                                {tabs[this.state.tab].component}
                            </div>

                        )
                        : ''
                    }
                    {this.state.popup ? <TenderPublicationsAdd
                        tenderid={tender.tenderid}
                        close={this.changePopupState}
                        itemList={tender.tenderPublications}
                        action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                        button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                        id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                    /> : ''}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        plans: state.plans,
        tenderPublicPurp: state.tenderPublicationPurpose,
        tenderSourceArr: state.sourcesArr,
        sourcecredibility: state.sourcecredibility,
        tendersPlacesArr: state.tendersPlacesArr,
    }
};

const TenderPublications = connect(mapStateToProps)(TenderPublicationsComponent);

export default TenderPublications;
