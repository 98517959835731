import React from 'react';
import api from "../../api";
import Storege from "../../storage/storage";

const PlacesRequest = (route) => {
    fetch(api.ajaxUrl + route, {
        method: "POST",
        headers: {
            "Content-type": "application/json",
            "X-CSRF-TOKEN": api.token
        }
    }).then((response) => response.json()).then(function (data) {
        console.log(data);
        Storege.dispatch({
            type: 'SET PLACES',
            items: data.places
        });
        Storege.dispatch({
            type: 'SET PLACES SYNONYMS',
            items: data.synonyms
        });
        Storege.dispatch({
            type: 'SET PLACE TYPES',
            items: data.placeTypes
        });
        Storege.dispatch({
            type: 'SET PLACE REGIONS',
            items: data.region
        });
        Storege.dispatch({
            type: 'SET PLACE COMMUNITY TYPE',
            items: data.communityType
        });
        Storege.dispatch({
            type: 'SET PLACE POPULATION TYPE',
            items: data.populationType

        });
        Storege.dispatch({
            type: 'SET PLACE STATUSES',
            items: data.statuses
        });
        Storege.dispatch({
            type: 'SET TERRITORIES',
            items: data.territories
        });
        Storege.dispatch({
            type: 'SET SOVEREIGNTIES',
            items: data.sovereignties
        });
        Storege.dispatch({
            type: 'SET SETTLEMENTS',
            items: data.settelments
        });
        Storege.dispatch({
            type: 'SET MUNICIPALITIES',
            items: data.municipalities
        });
        Storege.dispatch({
            type: 'SET DISTRICTS',
            items: data.districts
        });
        Storege.dispatch({
            type: 'SET CONTENT TYPE',
            items: data.ContentType
        });
        Storege.dispatch({
            type: 'SET TRACKING TYPES',
            items: data.trackingType
        });
        Storege.dispatch({
            type: 'SET TRACKING VALUE TYPES',
            items: data.trackingValueType
        });
    });
};

export default PlacesRequest;