import React from 'react';
import {connect} from 'react-redux';
import Select from "../../../parts/select";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";
import DatePicker from "react-datepicker";
import TendersRequest from "../TendersRequest";
import PlansRequest from "../../plans/PlansRequest";

class TenderPublicationsAddComponent extends CommonTab {

    state = {
        popupItem: {
            tenderid: this.props.tenderid
        },
        error: false,
        errors: [],
        validateFields: [
            'publicationdate',
            'sourceid',
            'sourcecredibiltyid',
            'tenderpublicationpurposeid'
        ]
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }

    handleChange = (date) => {
        let temp = this.state.popupItem;
        temp.publicationdate = date;
        this.setState({popupItem: temp});
    };

    placeChange = (value) => {
        let temp = this.state.popupItem;
        temp.popupItemid = value.target.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        const {popupItem, validateFields, errors} = this.state;
        const {plans, tenderPublicPurp, tenderSourceArr, sourcecredibility, tendersPlaces} = this.props;
        if (!plans.length) {
            PlansRequest('plans');
        }
        if (!sourcecredibility.length) {
            TendersRequest('tenders');

        }
        let tenderPublicPurpArr = [];
        for (var key in tenderPublicPurp) {
            let tmp = {};
            tmp.tenderpublicationpurposeid = key;
            tmp.tenderpublicationpurposename = tenderPublicPurp[key];
            tenderPublicPurpArr.push(tmp);
        }
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('planid' in popupItem)) {
                            popupItem['planid'] = ''
                        }
                        if (!('lot' in popupItem)) {
                            popupItem['lot'] = ''
                        }
                        if (!('remarks' in popupItem)) {
                            popupItem['remarks'] = ''
                        }
                        if (!('url' in popupItem)) {
                            popupItem['url'] = ''
                        }
                        this.formValidation(popupItem, validateFields);
                        // this.props.action(popupItem);
                    }}
                >
                    <h3>{this.props.button} Tender Publication</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Publication Date:</td>
                            <td className={errors.indexOf('publicationdate') !== -1 ? 'error' : ''}>
                                <DatePicker
                                    selected={popupItem.publicationdate ? new Date(popupItem.publicationdate) : ''}
                                    onChange={this.handleChange}
                                    //showTimeSelect
                                    timeFormat="HH:mm:s"
                                    timeIntervals={60}
                                    dateFormat="dd/MM/yyyy h:mm:s"
                                    timeCaption="time"
                                />
                                {errors.indexOf('publicationdate') !== -1 ?
                                    <span className="errorText">Please set Date</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Publication Purposed:</td>
                            <td className={errors.indexOf('tenderpublicationpurposeid') !== -1 ? 'error' : ''}>
                                <Select
                                    name={'tenderpublicationpurposeid'}
                                    types={tenderPublicPurpArr ? tenderPublicPurpArr : []}
                                    className={"form-control"}
                                    value={popupItem.tenderpublicationpurposeid}
                                    optionValue={"tenderpublicationpurposeid"}
                                    optionName={"tenderpublicationpurposename"}
                                    onChange={(e) => {
                                        this.changeState('tenderpublicationpurposeid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('tenderpublicationpurposeid') !== -1 ?
                                    <span className="errorText">Please set Publication Purposed</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Source :</td>
                            <td className={errors.indexOf('sourceid') !== -1 ? 'error' : ''}>
                                <Select
                                    name={'sourceid'}
                                    types={tenderSourceArr ? tenderSourceArr : []}
                                    className={"form-control"}
                                    value={popupItem.sourceid}
                                    optionValue={"sourceid"}
                                    optionName={"sourcename"}
                                    onChange={(e) => {
                                        this.changeState('sourceid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('sourceid') !== -1 ?
                                    <span className="errorText">Please set Source</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Source Credibility:</td>
                            <td className={errors.indexOf('sourcecredibiltyid') !== -1 ? 'error' : ''}>
                                <Select
                                    name={'sourcecredibiltyid'}
                                    types={sourcecredibility ? sourcecredibility : []}
                                    className={"form-control"}
                                    value={popupItem.sourcecredibiltyid}
                                    optionValue={"sourcecredibilityid"}
                                    optionName={"sourcecredibilityname"}
                                    onChange={(e) => {
                                        this.changeState('sourcecredibiltyid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('sourcecredibiltyid') !== -1 ?
                                    <span className="errorText">Please set Source Credibility</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Plan:</td>
                            <td>
                                <Select
                                    onClick={(e) => {
                                        console.log("onClick");
                                        console.log(e);
                                    }}
                                    name={'place_name'}
                                    types={plans ? plans : []}
                                    className={"form-control"}
                                    value={popupItem.planid}
                                    optionValue={"planid"}
                                    optionName={"planname"}
                                    onChange={(e) => {
                                        this.changeState('planid', e.target);
                                    }
                                    }
                                    onFocus={(e) => {
                                        this.changeState('planid', '');
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Lot:</td>
                            <td>
                                <input
                                    type={'text'}
                                    value={popupItem.lot ? popupItem.lot : ''}
                                    className={'form-control'}
                                    onChange={(e) => {
                                        this.changeState('lot', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Remarks:</td>
                            <td>
                                <input
                                    type={'text'}
                                    value={popupItem.remarks ? popupItem.remarks : ''}
                                    className={'form-control'}
                                    onChange={(e) => {
                                        this.changeState('remarks', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>URL:</td>
                            <td>
                                <input
                                    type={'text'}
                                    value={popupItem.url ? popupItem.url : ''}
                                    className={'form-control'}
                                    onChange={(e) => {
                                        this.changeState('url', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item.tenderpublicationid == ownProps.id) {
            popupItem = item;
        }
    });

    return {
        tenderPublicPurp: state.tenderPublicationPurpose,
        // tenderPublicPurp: state.tendersPurposes,
        tenderSourceArr: state.sourcesArr,
        sourcecredibility: state.sourcecredibility,
        tendersPlaces: state.tendersPlaces,
        plans: state.plans,
        popupItem: Object.assign({}, popupItem)
    }
};

const TenderPublicationsAdd = connect(mapStateToProps)(TenderPublicationsAddComponent);
export default TenderPublicationsAdd;
