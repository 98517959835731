import React from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import PlacesRequest from "./PlacesRequest";
import api from "../../api";
import Pagination from "../../parts/Pagination";
import Search from "../../parts/Search";
import {ActionPlacesAdd, ActionPlacesDel, PlacesEdit} from "../../parts/actions";
import ToolButtons from "../../parts/ToolButtons";
import Filter from "../../parts/Filters";
import PlaceAdd from './placeAdd';
import RemoveButton from "../../parts/removeBtn";
import getFullDateYear, {doFilter} from "../../parts/functions";
import CommonList from "../../parts/CommonListClass";
import Storege from "../../storage/storage";
import EditBtn from "../../parts/EditBtn";
import UploadExcel from "../../parts/UploadExcel";
import ExportExcel from "../../parts/ExportExcel";
import Popup from "../../parts/Popup";

const perPega = 25;

class PlaceListComponent extends CommonList {
    orderFields = {
        "Title": 'name',
        "Date": 'updated_at'
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            // page: this.getCurrentPage(),
            items: nextProps.items,
        });
    }

    addContentType = (object) => {
        ActionPlacesAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLACE',
                item: data
            });
            this.changePopupState();
            this.props.history.push(data.placeid);
        });
    };

    editContentType = (object) => {
        PlacesEdit(object.placeid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLACE',
                item: data,
                id: data.placeid
            });
            this.changePopupState();
            this.props.history.push(data.placeid);
        });
    };

    changeImportPopupState = () => {
        let currentPopupState = this.state.import_popup;
        this.setState(
            {
                import_popup: !currentPopupState
            }
        );
        if(this.state.file_loaded){
            window.location.reload();
        }
    };

    changeExportPopupState = () => {
        let currentPopupState = this.state.export_popup;
        this.setState(
            {
                export_popup: !currentPopupState
            }
        );
        if(this.state.file_loaded){
            window.location.reload();
        }
    };

    afterLoading = () => {
        this.setState(
            {
                file_loaded: true
            }
        );
    };

    render() {
        if (!this.props.items.length) {
            PlacesRequest('places');
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        const {valueRegionsFilter, valuePlaceTypesFilter, searchValue, items} = this.state;

        let outPutPlaces = items;

        outPutPlaces = doFilter(
            outPutPlaces,
            [
                {
                    name: 'valueRegionsFilter',
                    value: valueRegionsFilter,
                    searchId: 'regionid'
                },
                {
                    name: 'valuePlaceTypesFilter',
                    value: valuePlaceTypesFilter,
                    searchId: 'placetypeid'
                },
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'name',
                        'nameeng'
                    ],
                    additionalFilter: function (item, str) {
                        let synonyms = [];
                        if (item.synonymsList && item.synonymsList.length) {
                            synonyms = item.synonymsList.filter(function (synonym) {
                                if (
                                    (synonym.name && synonym.name.toLowerCase().indexOf(str) != -1) ||
                                    (synonym.nameeng && synonym.nameeng.toLowerCase().indexOf(str) != -1)
                                ) {
                                    return true
                                }
                                return false
                            });
                        }
                        return synonyms.length;
                    }
                }
            ]
        );

        this.sortResults(outPutPlaces);
        let maxPage = Math.ceil(outPutPlaces.length / perPega);
        outPutPlaces = outPutPlaces.slice((this.state.page - 1) * perPega, (this.state.page) * perPega);

        let pages = [];

        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }

        var path = this.props.location.pathname.split('/');
        var activeTab = 'statuses';
        if (path.length > 3) {
            activeTab = path[2]
        }

        if (!items.length) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <h2>Places</h2>
                <button className={"import-excel-button btn btn-primary"} onClick={() => {
                    this.setState({
                        import_popup: true,
                    });
                }
                }>Import
                </button>
                {this.state.import_popup ?
                    <Popup openClose={this.changeImportPopupState}
                           children={[<UploadExcel
                               title={"Upload Tracking Construction"}
                               route={"import_tracking_construction"}
                               errors_id={"tracking-construction-errors"}
                               afterLoading={this.afterLoading}
                           />
                           ,
                           <UploadExcel
                               title={"Upload Tracking Population"}
                               route={"import_tracking_population"}
                               errors_id={"tracking-population-errors"}
                               afterLoading={this.afterLoading}
                           />]}
                    />
                    : ''}
                <button className={"export-excel-button btn btn-primary"} onClick={() => {
                    this.setState({
                        export_popup: true,
                    });
                }
                }>Export
                </button>
                {this.state.export_popup ?
                    <Popup openClose={this.changeExportPopupState}
                           children={<ExportExcel
                               title={"Export data"}
                               route={"export_places_data"}
                           />}
                    /> : ''}
                <div className={'tool-bar'}>
                    <div id={'filters-wrapper'}>
                        <Filter
                            filterElemens={this.props.regions}
                            filterName={'filter_regions'}
                            value={this.state.valueRegionsFilter}
                            optionValue={'regionid'}
                            optionName={'regionname'}
                            filterValue={'valueRegionsFilter'}
                            change={this.filter}
                            reset={this.resetFilter}
                            resetBtnName={'Clear Regions Filter'}
                            title={'Choose Region:'}
                        />
                        <Filter
                            filterElemens={this.props.placeTypes}
                            filterName={'filter_place_types'}
                            value={this.state.valuePlaceTypesFilter}
                            optionValue={'placetypeid'}
                            optionName={'placetypename'}
                            filterValue={'valuePlaceTypesFilter'}
                            change={this.filter}
                            reset={this.resetFilter}
                            resetBtnName={'Clear Place Filter'}
                            title={'Choose Place Type:'}
                        />
                    </div>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                    <Search
                        change={this.search}
                    />
                </div>
                <ul className={"list-group"}>
                    {this.orderPanel()}
                    {outPutPlaces.map((item, index) => {
                        return (
                            <li key={index}>
                                <RemoveButton
                                    action={ActionPlacesDel}
                                    id={item.placeid}
                                    descriptor={'REMOVE PLACE'}
                                />
                                <EditBtn
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    change={this.setCurrentIndex}
                                    id={item.placeid}
                                />
                                <Link to={api.getRoute('places/' + activeTab + '/place/' + item.placeid)} onClick={
                                    () => {
                                        this.setState({
                                            rowIndex: index,
                                            currentItemIndex: item.placeid
                                        });
                                    }
                                } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                    <div className="col-sm-6">
                                        {item.name} | {item.nameeng}
                                    </div>
                                    <div className="col-sm-6">
                                        <span>{item.updated_at ? getFullDateYear(item.updated_at) : '1/1/1900'}</span>
                                    </div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                <Pagination
                    currentPage={this.state.page}
                    pages={pages}
                    click={this.paginationChange}
                    paginationCount={5}
                />
                {this.state.popup ? <PlaceAdd
                    openClose={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: state.places,
    synonyms: state.synonyms,
    pagination: state.pagination,
    regions: state.regions,
    placeTypes: state.placeTypes
});

const PlaceList = connect(mapStateToProps)(PlaceListComponent);
export default PlaceList;
