import React from 'react';
import {
    ActionContentTypesUrlAdd, ActionContentTypesUrlEdit,
    ActionDescriptorsContentType,
    ActionSetLinks,
    ActionContentTypesUrlDelete
} from "../actions";
import {connect} from 'react-redux';
import Select from "../select";
import ColumnResizer from "react-column-resizer";
import CommonTab from "../CommonTabClass";
import {getItemData} from "../functions";
import RemoveButton from "../removeBtn";
import EditBtn from "../EditBtn";
import GeneralDataAdd from "../../main/general_data/GeneralDataAdd";
import DescriptorAddUrl from "../../main/descriptors/discriptor_content_type_add";
import Storege from "../../storage/storage";
import Search from "../Search";
import ToolButtons from "../ToolButtons";

class LinksComponent extends CommonTab {

    state = {
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        order: null
    };

    orderFields = {
        "Content Type": 'conenttypeid',
        "URL": 'url',
        "Create Date": 'createdate',
        "Description": 'description'
    };

    addContentType = (object) => {
        ActionContentTypesUrlAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD CONTENT TYPE URL',
                item: data
            });
            ActionSetLinks(this.props.place.placeid);
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionContentTypesUrlEdit(object.placeurlid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE CONTENT TYPE URL',
                item: data,
                id: data.placeurlid
            });
             ActionSetLinks(this.props.place.placeid);
            this.changePopupState();
        });
    };
    DeleteContentType = (...params) => {
        ActionContentTypesUrlDelete(...params).then((data)=>{
            console.log('dsfsdfsdfsdf');
            ActionSetLinks(this.props.place.placeid);
        });
    };

    render() {
        const {place} = this.props;
        if (!place.links) {
            ActionSetLinks(place.placeid);
        }
        let links = place.links ? place.links : [];
        this.sortResults(links);
        return (
            <div>
                <div className="inline-toolbuttons">
                    <h3>Place URLs</h3>
                    <div className="tool-bar contactors-tool-bar">
                        <ToolButtons
                            actionType={this.changePopupType}
                            popupChange={this.changePopupState}
                            id={this.state.currentItemIndex}
                        />
                    </div>
                </div>
                <table className={'table table-striped geometry-table'}>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {links.map((item, index) => {
                        console.log(item);
                        return (
                            <tr key={index}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        descriptor={'REMOVE CONTENT TYPE URL'}
                                        action={this.DeleteContentType}
                                        id={item.placeurlid}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.placeurlid}
                                    />
                                </td>
                                <td>{getItemData(this.props.contentType, 'contenttypeid', item.conenttypeid, 'contenttypename')}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td><a href={item.url}>{item.url}</a></td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.createdate}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.description}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                 {this.state.popup ? <DescriptorAddUrl
                        close={this.changePopupState}
                        items={links}
                        typeId={3}
                        placeid = {this.props.place.placeid}
                        action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                        button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                        id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                    /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        contentType: state.contentType
    }
};

const Links = connect(mapStateToProps)(LinksComponent);
export default Links;

