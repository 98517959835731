import React from 'react';
import {connect} from 'react-redux';
import SelectObject from "../../parts/SelectObject";
import Select from "../../parts/select";
import Popup from "../../parts/Popup";
import CommonTab from "../../parts/CommonTabClass";

class TenderAddComponent extends CommonTab {
    state = {
        tender: {},
        errors: [],
        validateFields: [
            'tendername',
            'tendertypeid',
            'tenderpurposeid',
            'placeid'
            ]
    };

    constructor(props) {
        super(props);
        this.state.tender = props.tender;
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if (oldProps.tender.tenderid !== newProps.tender.tenderid) {
            this.setState({tender: newProps.tender})
        }
    }

    placeChange = (name, e) => {
        let temp = this.props.tender;
        temp[name] = +e.value;
        this.setState({
            tender: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.props.tender;
        temp[name] = e.value;
        this.setState({
            tender: Object.assign({}, temp)
        });
    };

    render() {
        console.log(this.props)
        const {places} = this.props;
        let {tender, errors, validateFields} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <form onSubmit={event => {
                    event.preventDefault();
                    if (!('initiatorid' in tender)) {
                       tender['initiatorid'] = ''
                    }
                    this.formValidation(tender, validateFields);
                    // this.props.action(this.state.tender);
                }}>
                    <h2>{this.props.button} Tender</h2>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Tender Name:</td>
                            <td className={errors.indexOf('tendername') !== -1 ? 'error' : ''}>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'tendername'}
                                       value={this.state.tender.tendername}
                                       onChange={(e) => {
                                           this.changeState('tendername', e.target);
                                       }}
                                />
                                {errors.indexOf('tendername') !== -1 ?
                                    <span className="errorText">Please set Tender Name</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Place:</td>
                            <td className={errors.indexOf('placeid') !== -1 ? 'error' : ''}>
                                <SelectObject
                                    name={'placeid'}
                                    value={this.state.tender.placeid}
                                    types={places}
                                    change={(e) => {
                                        this.placeChange('placeid', e.target);
                                    }}
                                />
                                {errors.indexOf('placeid') !== -1 ?
                                    <span className="errorText">Please set Place</span> : ''}
                            </td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr>
                            <td>Tender Type:</td>
                            <td className={errors.indexOf('tendertypeid') !== -1 ? 'error' : ''}>
                                <Select
                                    name={'tendertypeid'}
                                    types={this.props.tendersTypes}
                                    className={"form-control"}
                                    value={this.state.tender.tendertypeid}
                                    optionValue={"tendertypeid"}
                                    optionName={"tendertypename"}
                                    onChange={(e) => {
                                        this.placeChange('tendertypeid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('tendertypeid') !== -1 ?
                                    <span className="errorText">Please set Tender Type</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Tender Purpose:</td>
                            <td  className={errors.indexOf('tenderpurposeid') !== -1 ? 'error' : ''}>
                                <Select
                                    name={'tenderpurposeid'}
                                    types={this.props.tendersPurposes}
                                    className={"form-control"}
                                    value={this.state.tender.tenderpurposeid}
                                    optionValue={"tenderpurposeid"}
                                    optionName={"tenderpurposename"}
                                    onChange={(e) => {
                                        this.placeChange('tenderpurposeid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('tenderpurposeid') !== -1 ?
                                    <span className="errorText">Please set Tender Purpose</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Tender Initiator:</td>
                            <td>
                                <Select
                                    name={'plan_name'}
                                    types={this.props.initiator}
                                    className={"form-control"}
                                    value={this.state.tender.initiatorid}
                                    optionValue={"initiatorid"}
                                    optionName={"initiatorname"}
                                    onChange={(e) => {
                                        this.placeChange('initiatorid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let tender = {};
    state.tenders.forEach(function (item) {
        if (item.tenderid == ownProps.id) {
            tender = item;
        }
    });
    return {
        tender: Object.assign({}, tender),
        places: state.tendersPlaces,
        tendersTypes: state.tendersTypes,
        tendersPurposes: state.tendersPurposes,
        initiator: state.tenderInitiator
    }
};

const TenderAdd = connect(mapStateToProps)(TenderAddComponent);

export default TenderAdd;