import React from 'react';
import {connect} from 'react-redux';
import {
    ActionDescriptorsTenderPublicationCost,
    ActionTenderPublicationCostTypeAdd, ActionTenderPublicationCostTypeDelete,
    ActionTenderPublicationCostTypeEdit
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import Storege from "../../../storage/storage";
import ToolButtons from "../../../parts/ToolButtons";
import Search from "../../../parts/Search";
import TenderTypesPublicationCostTypePopup from "./TenderPublicationCostTypePopup";
import RemoveButton from "../../../parts/removeBtn";
import CommonTab from "../../../parts/CommonTabClass";
import EditBtn from "../../../parts/EditBtn";

class TenderPublicationCostDescriptorComponent extends CommonTab {

    orderFields = {
        "Tender Publication Cost Type Name" : "tenderpublicationcosttypename",
        "Tender Publication Cost Type Name Eng": "tenderpublicationcosttypenameeng"
    };

    state = {
        tenderPublicationCost: {},
        popup: false,
        popupType: undefined,
        rowIndex: -1,
        currentItemIndex: -1,
        order: null
    };

    constructor(props) {
        super(props);
        this.state.tenderPublicationCost = props.tenderPublicationCost;
    }

    changeState = (name, e, id) => {
        let temp = this.props.tenderPublicationCost;
        temp.forEach(function (item) {
            if (item.tenderpublicationcosttypeid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            tenderPublicationCost: Object.assign({}, temp)
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    addContentType = (object) => {
        ActionTenderPublicationCostTypeAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD TENDER PUBLICATION COST TYPE',
                item: data
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionTenderPublicationCostTypeEdit(object.tenderpublicationcosttypeid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE TENDER PUBLICATION COST TYPE',
                item: data,
                id: data.tenderpublicationcosttypeid
            });
            this.changePopupState();
        });
    };

    search = (value) => {
        if (value.length) {
            let filteredArray = this.props.tenderPublicationCost.filter(item => {
                return item.tenderpublicationcosttypename.toLowerCase().indexOf(value.toLowerCase()) != -1 || item.tenderpublicationcosttypenameeng.toLowerCase().indexOf(value.toLowerCase()) != -1;
            });
            this.setState({
                filtered: true,
                filteredItems: filteredArray
            });
        } else {
            this.setState({
                filtered: false
            });
        }
    };

    render() {
        if (!this.props.tenderPublicationCost.length) {
            ActionDescriptorsTenderPublicationCost();
        }
        let {tenderPublicationCost} = this.props;
        let {filtered, filteredItems} = this.state;
        let list = filtered ? filteredItems : tenderPublicationCost;
        if (!list) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        this.sortResults(list);
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div>
                        <div className="inline-toolbuttons">
                            <h3>Tender Publication Cost Types:</h3>
                            <div className={'tool-bar'}>
                                <ToolButtons
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    id={this.state.currentItemIndex}
                                />
                                <Search
                                    change={this.search}
                                />
                            </div>
                        </div>
                        <table className={'table table-striped'} width="100%">
                            <thead>
                            {this.orderPanel()}
                            </thead>
                            <tbody>
                            {(list ? list : []).map((item, index) => {
                                return (
                                    <tr key={index} onClick={
                                        e => {
                                            e.preventDefault();
                                            this.setState({
                                                rowIndex: index,
                                                currentItemIndex: item.tenderpublicationcosttypeid
                                            });
                                        }
                                    } className={this.state.rowIndex == index ? 'active' : ''}>
                                        <td className={'flex-row'}>
                                            <RemoveButton
                                                action={ActionTenderPublicationCostTypeDelete}
                                                id={item.tenderpublicationcosttypeid}
                                                descriptor={'REMOVE TENDER PUBLICATION COST TYPE'}
                                            />
                                            <EditBtn
                                                actionType={this.changePopupType}
                                                popupChange={this.changePopupState}
                                                change={this.setCurrentIndex}
                                                id={item.tenderpublicationcosttypeid}
                                            />
                                        </td>
                                        <td>{item.tenderpublicationcosttypename}</td>
                                        <ColumnResizer className="columnResizer" minWidth={5}/>
                                        <td>{item.tenderpublicationcosttypenameeng}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {this.state.popup ? <TenderTypesPublicationCostTypePopup
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tenderPublicationCost: state.tenderPublicationCost
    }
};
const DescriptorTenderPublicationCost = connect(mapStateToProps)(TenderPublicationCostDescriptorComponent);

export default DescriptorTenderPublicationCost;