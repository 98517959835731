import React from 'react';
import SelectObject from "../../../parts/SelectObject";
import DatePicker from "react-datepicker";

class TerritorieDetails extends React.Component {
    render() {
        const {territory, change} = this.props;
        return (
            <div className={'left-alignment'}>
                <h3>Details</h3>
                <table className={'table table-striped'}>
                    <tbody>
                    <tr>
                        <td>Territory ID:</td>
                        <td>{territory.territoryid}</td>
                    </tr>
                    <tr>
                        <td>Name:</td>
                        <td>{territory.territoryname}</td>
                    </tr>
                    <tr>
                        <td>Name eng</td>
                        <td>{territory.territorynameeng}</td>
                    </tr>
                    <tr>
                        <td>Sovereignities:</td>
                        <td>{territory.sovereigntyid}</td>
                    </tr>
                    <tr>
                        <td>Sovereignities Type:</td>
                        <td>{territory.sovereigntytypeid}</td>
                    </tr>
                    <tr>
                        <td>Sovereigniti date:</td>
                        <td>
                            {territory.sovereigntydate}
                            {/*<DatePicker*/}
                                {/*selected={new Date(territory.sovereigntydate ? territory.sovereigntydate : '')}*/}
                                {/*onChange={this.handleChange}*/}
                                {/*showTimeSelect*/}
                                {/*timeFormat="HH:mm"*/}
                                {/*timeIntervals={60}*/}
                                {/*dateFormat="dd/MM/yyyy"*/}
                                {/*timeCaption="time"*/}
                            {/*/>*/}
                            {/*<DateChoose*/}
                                {/*date={territory.sovereigntydate ? territory.sovereigntydate : ''}*/}
                                {/*change={this.props.changeDate}*/}
                            {/*/>*/}
                        </td>
                    </tr>

                    <tr>
                        <td>Description:</td>
                        <td>{territory.territorydescription}</td>
                    </tr>
                    <tr>
                        <td>URL:</td>
                        <td className={'flex-row'}>{territory.url}<a
                                href={territory.url}
                                target={'_blank'}
                            >Test</a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <h3>Location</h3>
                <table className={'table table-striped'}>
                    <tbody>
                    <tr>
                        <td>Map URL:</td>
                        <td>
                            <a target={'_blank'}
                                href={'http://sw.peacenow.org.il/TerritoryMap.html?TerritoryID=' + territory.territoryid}>{'http://sw.peacenow.org.il/TerritoryMap.html?TerritoryID=' + territory.territoryid}</a>
                        </td>
                    </tr>
                    <tr>
                        <td>Shape Area:</td>
                        <td>{territory.shapearea}</td>
                    </tr>
                    <tr>
                        <td>Shape Length:</td>
                        <td>{territory.shapelength}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )

    }

}

export default TerritorieDetails;
