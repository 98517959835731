import React from 'react';
import api from "../../api";
import Storege from "../../storage/storage";

const TeritorriesRequest = (route) => {
    fetch(api.ajaxUrl + route, {
        method: "POST",
        headers: {
            "Content-type": "application/json",
            "X-CSRF-TOKEN": api.token
        }
    }).then((response) => response.json()).then(function (data) {
        console.log(data);
        Storege.dispatch({
            type: 'SET DESCRIPTORS TERRITORIES',
            items: data.allTerritories
        });
        Storege.dispatch({
            type: 'SET SOVEREIGNTIES',
            items: data.sovereignties
        });
        Storege.dispatch({
            type: 'SET SOVEREIGNTIES TYPES',
            items: data.sovereigntyTypes
        });
        Storege.dispatch({
            type: 'SET TERRITORY PLACES',
            items: data.places
        });
    });
};

export default TeritorriesRequest;