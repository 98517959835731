import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {
    ActionDescriptorsTrackingValues,
    ActionTrackingValueAdd,
    ActionTrackingValueDelete,
    ActionTrackingValueEdit
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import Storege from "../../../storage/storage";
import ToolButtons from "../../../parts/ToolButtons";
import Search from "../../../parts/Search";
import TrackingValuePopup from "./TrackingValuePopup";
import api from "../../../api";
import RemoveButton from "../../../parts/removeBtn";
import CommonTab from "../../../parts/CommonTabClass";
import {doFilter} from "../../../parts/functions";
import EditBtn from "../../../parts/EditBtn";

class TrackingValueDescriptorComponent extends CommonTab {

    orderFields = {
        "Tracking Value Type Name": "trackingvaluetypename",
        "Tracking Value Type Name Eng": "trackingvaluetypenameeng"
    };

    state = {
        trackingValues: {},
        popup: false,
        popupType: undefined,
        order: null,
        searchValue: ''
    };

    constructor(props) {
        super(props);
        this.state.trackingValues = props.trackingValues;
    }

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    getId(pathName) {
        let path = (pathName).split('/');
        let id = path[path.length - 1];
        return id;
    }

    addContentType = (object) => {
        ActionTrackingValueAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLACE TRACKING VALUE TYPE',
                item: data
            });
            this.changePopupState();
            this.props.history.push('tracking_value_types/' + data.trackingtypeid);
        });
    };

    editContentType = (object) => {
        ActionTrackingValueEdit(object.trackingvaluetypeid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLACE TRACKING VALUE TYPE',
                item: data,
                id: data.trackingvaluetypeid
            });
            this.changePopupState();
            this.props.history.push(data.trackingtypeid);
        });
    };

    changeState = (name, e, id) => {
        let temp = this.props.trackingValues;
        temp.forEach(function (item) {
            if (item.trackingvaluetypeid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            trackingValues: Object.assign({}, temp)
        });
    };

    render() {
        if (!this.props.trackingValues.length) {
            ActionDescriptorsTrackingValues();
        }
        let {trackingValues} = this.props;
        let {searchValue} = this.state;
        if (!trackingValues) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        let outPutPlaces = trackingValues;

        outPutPlaces = doFilter(
            outPutPlaces,
            [
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'trackingvaluetypename',
                        'trackingvaluetypenameeng'
                    ]
                }
            ]
        );

        this.sortResults(outPutPlaces);
        return (
            <div>
                <div className="inline-toolbuttons">
                    <h3>Tracking Value Types</h3>
                    <div className={'tool-bar'}>
                        <ToolButtons
                            actionType={this.changePopupType}
                            popupChange={this.changePopupState}
                            redirect={this.redirectFunction}
                            url={this.props.location.pathname}
                        />
                        <Search
                            change={this.search}
                        />
                    </div>
                </div>
                <table className='table table-striped'>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {outPutPlaces.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionTrackingValueDelete}
                                        id={item.trackingvaluetypeid}
                                        descriptor={'REMOVE PLACE TRACKING VALUE TYPE'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.trackingvaluetypeid}
                                    />
                                </td>
                                <td>
                                    <Link
                                        to={api.getRoute('descriptors/place_types/tracking_value_types/' + item.trackingvaluetypeid)}>
                                       {item.trackingvaluetypename}
                                    </Link>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    <Link
                                        to={api.getRoute('descriptors/place_types/tracking_value_types/' + item.trackingvaluetypeid)}>
                                        {item.trackingvaluetypenameeng}
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <TrackingValuePopup
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                    close={this.changePopupState}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        trackingValues: state.trackingValueTypes
    }
};
const DescriptorTrackingValue = connect(mapStateToProps)(TrackingValueDescriptorComponent);

export default DescriptorTrackingValue;