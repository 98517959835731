import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import api from "../../../api";
import {
    ActionInitiator,
    ActionInitiatorTypesAdd,
    ActionInitiatorTypesDelete,
    ActionInitiatorTypesEdit,
} from "../../../parts/actions";
import Storege from "../../../storage/storage";
import ToolButtons from "../../../parts/ToolButtons";
import Search from "../../../parts/Search";
import InitiatorPopup from "./InitiatorPopup";
import RemoveButton from "../../../parts/removeBtn";
import CommonList from "../../../parts/CommonListClass";
import {doFilter} from "../../../parts/functions";
import EditBtn from "../../../parts/EditBtn";
import ColumnResizer from "react-column-resizer";

class InitiatorComponent extends CommonList {
    state = {
        initiatorTypes: {},
        popup: false,
        popupType: undefined,
        rowIndex: -1,
        currentItemIndex: -1,
        order: null,
        searchValue: ''
    };

    orderFields = {
        "Initiator Type Name": 'initiatortypename',
        "Initiator Type Name Eng": 'initiatortypenameeng',
        "Date": 'updated_at'
    };

    constructor(props) {
        super(props);
        this.state.initiatorTypes = props.initiatorTypes
    }

    changeState(name, e, id) {
        let temp = this.props.initiatorTypes;
        temp.forEach(function (item) {
            if (item.initiatortypeid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            initiatorTypes: Object.assign({}, temp)
        });
    }

    addContentType = (object) => {
        ActionInitiatorTypesAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD INITIATOR TYPE',
                item: data
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionInitiatorTypesEdit(object.initiatortypeid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE INITIATOR TYPE',
                item: data,
                id: data.initiatortypeid
            });
            this.changePopupState();
        });
    };

    render() {
        let initiatorTypes = this.props.initiatorTypes;
        if (!initiatorTypes) {
            ActionInitiator();
        }

        let {filtered, filteredItems, searchValue} = this.state;
        let list = filtered ? filteredItems : initiatorTypes;

        let outPutPlaces = initiatorTypes;

        outPutPlaces = doFilter(
            outPutPlaces,
            [
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'initiatortypename',
                        'initiatortypenameeng'
                    ]
                }
            ]
        );

        this.sortResults(outPutPlaces);

        if (!list) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <h2>Initiators</h2>
                <div className="inline-toolbuttons initiator-margin">
                    <h3>Initiator Types</h3>
                    <div className={'tool-bar contactors-tool-bar'}>
                        <ToolButtons
                            actionType={this.changePopupType}
                            popupChange={this.changePopupState}
                            id={this.state.currentItemIndex}
                        />
                        <Search
                            change={this.search}
                        />
                    </div>
                </div>
                <table className={"table table-striped initiator-type-table"}>
                    <thead>
                    {this.orderPanelTabel()}
                    </thead>
                    <tbody>
                    {outPutPlaces.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <div className="flex-row">
                                        <RemoveButton
                                            action={ActionInitiatorTypesDelete}
                                            id={item.initiatortypeid}
                                            descriptor={'REMOVE INITIATOR TYPE'}
                                        />
                                        <EditBtn
                                            actionType={this.changePopupType}
                                            popupChange={this.changePopupState}
                                            change={this.setCurrentIndex}
                                            id={item.initiatortypeid}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <Link to={api.getRoute('initiators/type/' + item.initiatortypeid)} onClick={
                                        () => {
                                            this.setState({
                                                rowIndex: index,
                                                currentItemIndex: item.initiatortypeid
                                            });
                                        }
                                    }
                                          className={(this.state.rowIndex == index ? ' active' : '')}>
                                        {item.initiatortypename}
                                    </Link>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    <Link to={api.getRoute('initiators/type/' + item.initiatortypeid)} onClick={
                                        () => {
                                            this.setState({
                                                rowIndex: index,
                                                currentItemIndex: item.initiatortypeid
                                            });
                                        }
                                    }
                                          className={(this.state.rowIndex == index ? ' active' : '')}>
                                        {item.initiatortypenameeng}
                                    </Link>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    <Link to={api.getRoute('initiators/type/' + item.initiatortypeid)} onClick={
                                        () => {
                                            this.setState({
                                                rowIndex: index,
                                                currentItemIndex: item.initiatortypeid
                                            });
                                        }
                                    }
                                          className={(this.state.rowIndex == index ? ' active' : '')}>
                                        {item.updated_at ? item.updated_at : '1/1/1900'}
                                    </Link>
                                </td>

                                {/*<div className="col-sm-4">*/}
                                {/*    {item.initiatortypename}*/}
                                {/*</div>*/}
                                {/*<div className="col-sm-4">*/}
                                {/*    {item.initiatortypenameeng}*/}
                                {/*</div>*/}
                                {/*<div className="col-sm-4">*/}
                                {/*    {item.updated_at ? item.updated_at : '1/1/1900'}*/}
                                {/*</div>*/}

                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <InitiatorPopup
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        initiatorTypes: state.initiatorData.initiatorTypes
    }
};

const InitiatorList = connect(mapStateToProps)(InitiatorComponent);
export default InitiatorList;
