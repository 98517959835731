import React from 'react';
import api from "../../api";
import Storege from "../../storage/storage";


const ChartsRequest = (route) => {
    fetch(api.ajaxUrl + route, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-type": "application/json",
            "X-CSRF-TOKEN": api.token
        }
    }).then((response) => response.json()).then(function (data) {
        console.log(data);
        Storege.dispatch({
            type: 'SET CHARTS',
            items: data.charts
        });
        Storege.dispatch({
            type: 'SET TYPES',
            items: data.chartTypes
        });
        Storege.dispatch({
            type: 'SET STYLES',
            items: data.chartStyles
        });
        Storege.dispatch({
            type: 'SET PAGINATION',
            items: Math.ceil(data.charts.length / 10)
        });
    });
};

export default ChartsRequest;