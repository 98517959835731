import React from 'react';
import {connect} from 'react-redux';
import Popup from "../Popup.js";
import Select from "../select.js";
import CommonTab from "../CommonTabClass.js";
import DatePicker from "react-datepicker";

class TrackingAddComponent extends CommonTab {
    state = {
        popupItem: {
            placeid: this.props.placeid,
        },
        errors: [],
        validateFields: [
            'placeid',
            'trackingdate',
            'trackingtypeid',
            'trackingvaluetypeid',
        ]
    };

    handleChange = (date) => {
        let temp = this.state.popupItem;
        temp.trackingdate = date;
        this.setState({popupItem: temp});
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }


    placeChange = (value) => {
        let temp = this.state.popupItem;
        temp.popupItemid = value.target.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        const {popupItem, validateFields, errors} = this.state;
        const {trackingTypes,trackingValueTypes} = this.props;
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('remarks' in popupItem)) {
                            popupItem['remarks'] = ''
                        }
                        this.formValidation(popupItem, validateFields);
                    }}
                >
                    <h3>{this.props.button + (this.props.button === 'Add ' ? 'New ' : '')} Tracking Value</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                         <tr  className={errors.indexOf('trackingdate') !== -1 ? 'error' : ''}>
                            <td>Tracking Date:</td>
                            <td>
                                <DatePicker
                                    selected={popupItem.trackingdate ? new Date(popupItem.trackingdate) : ''}
                                    onChange={this.handleChange}
                                    //showTimeSelect
                                    timeFormat=" "
                                    timeIntervals={60}
                                    dateFormat="dd/MM/yyyy h:mm:s"
                                    timeCaption="time"
                                />
                            </td>
                        </tr>
                        <tr className={errors.indexOf('trackingvaluetypeid') !== -1 ? 'error' : ''}>
                            <td>Tracking Value Type:</td>
                            <td>
                                <Select
                                    id={"trackingvaluetypeid"}
                                    className={"form-control"}
                                    name={"trackingvaluetypeid"}
                                    value={popupItem.trackingvaluetypeid ? popupItem.trackingvaluetypeid : null}
                                    types={trackingValueTypes}
                                    optionValue={"trackingvaluetypeid"}
                                    optionName={"trackingvaluetypename"}
                                    onChange={(e) => {
                                        this.changeState('trackingvaluetypeid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr className={errors.indexOf('trackingtypeid') !== -1 ? 'error' : ''}>
                            <td>Tracking Type:</td>
                            <td>
                                <Select
                                    id={"trackingtypeid"}
                                    className={"form-control"}
                                    name={"trackingtypeid"}
                                    value={popupItem.trackingtypeid ? popupItem.trackingtypeid : null}
                                    types={trackingTypes}
                                    optionValue={"trackingtypeid"}
                                    optionName={"trackingtypename"}
                                    onChange={(e) => {
                                        this.changeState('trackingtypeid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Tracking Value:</td>
                            <td>
                                <input
                                    type={'number'}
                                    value={popupItem.trackingvalue}
                                    onChange={e => {
                                        this.changeState('trackingvalue', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Tracking Remarks:</td>
                            <td>
                                <input
                                    type={'text'}
                                    value={popupItem.remarks}
                                    onChange={e => {
                                        this.changeState('remarks', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state , ownProps) => {
     let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item.trackingid == ownProps.id) {
            popupItem = item;
        }
    });
    return {
         trackingTypes: state.trackingTypes,
        trackingValueTypes: state.trackingValueTypes,
        popupItem : popupItem
    }
};

const TrackingAdd = connect(mapStateToProps)(TrackingAddComponent);
export default TrackingAdd;
