import React from 'react';
import {Link} from 'react-router-dom';

export default class MenuList extends React.Component {
    render() {
        return (
            <li className={"dropdown"}>
                <Link to={"#"} className={"dropdown-toggle"} data-toggle={"dropdown"} role={"button"}
                      aria-expanded={"false"} aria-haspopup={"true"} >{this.props.item.menuTitle}</Link>
                <ul className={"dropdown-menu"} role={"menu"}>
                    {this.props.item.items.map(function (item, index) {
                        return (
                            <li key={index}>
                                <Link to={item.path+''}>{item.title}</Link>
                            </li>
                        );
                    })}
                </ul>
            </li>
        )
    }
}


