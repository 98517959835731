import React from 'react';

class Popup extends React.Component {
    close() {
        this.props.openClose();
    }

    render() {
        const {children} = this.props;
        return (
            <div className={'overlay'}>
                <div className={'popup'}>
                    <a href="#" className={'close-popup'} onClick={e => {
                        e.preventDefault();
                        this.close()
                    }}>close</a>
                    {children}
                </div>
            </div>
        )
    }
}

export default Popup;