import React from 'react';
import api from "../../api";

const TenderTabs = [
    {
        tabName: 'Tender Details',
        tabEnd: 'tender-details',
        tabUrl: api.getRoute('tenders/tender-details/tender/')
    },
    {
        tabName: 'Tender Publications',
        tabEnd: 'publications',
        tabUrl: api.getRoute('tenders/publications/tender/')
    },
    {
        tabName: 'Tender Landuses',
        tabEnd: 'landuses',
        tabUrl: api.getRoute('tenders/landuses/tender/')
    },
    {
        tabName: 'Tender Contactors',
        tabEnd: 'contractors',
        tabUrl: api.getRoute('tenders/contractors/tender/')
    },
    {
        tabName: 'Tender Relationships',
        tabEnd: 'relationship',
        tabUrl: api.getRoute('tenders/relationship/tender/')
    }
];


export default TenderTabs;