import React from 'react';
import {connect} from "react-redux";
import ColumnResizer from "react-column-resizer";
import CommonTab from "../../../parts/CommonTabClass";
import {doFilter, getItemData} from "../../../parts/functions";
import {ActionDescriptorsTracking , ActionDescriptorsTrackingValues} from "../../../parts/actions";
import Pagination from "../../../parts/Pagination";
const perPega = 25;

class TrackingComponent extends CommonTab {

    state = {
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        order: null,
        page : 1
    };

    orderFields = {
        "Tracking Date": 'trackingdate',
        "Tracking Type": 'trackingtypeid',
        "Tracking Value Type": 'trackingvaluetypeid',
        "Tracking Value": 'trackingvalue',
        "Tracking Is Realative": '',
        "Tracking Remarks": 'trackingid'
    };


    render() {
        const {place, trackingTypes, trackingValueTypes,items} = this.props;
        const tracking = items;
         if (!trackingValueTypes.length) {
            ActionDescriptorsTracking();
            ActionDescriptorsTrackingValues();
        }

        this.sortResults(tracking);
         let outPutItems = items.concat([]);

        this.sortResults(outPutItems);
        let maxPage = Math.ceil(outPutItems.length / perPega);
        outPutItems = outPutItems.slice((this.state.page - 1) * perPega, (this.state.page) * perPega);
        let pages = [];
        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }
        return (
            <div className={'scrolled'}>
                <div className="inline-toolbuttons">
                <h4>Trackings</h4>
                </div>
                <table className={'table table-striped'}>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {(outPutItems ? outPutItems : []).map((item, index) => {
                        return (
                            <tr key={index}>
                                <td  className={'flex-row'}>
                                </td>
                                <td>
                                    {item.trackingdate}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {getItemData(trackingTypes,'trackingtypeid', item.trackingtypeid, 'trackingtypename' )}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {getItemData(trackingValueTypes,'trackingvaluetypeid', item.trackingvaluetypeid, 'trackingvaluetypename' )}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.trackingvalue ? item.trackingvalue : ''}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {getItemData(trackingTypes,'trackingtypeid', item.trackingtypeid, 'isrelativevalue' ) ? 'yes' : 'no'}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                   {item.remarks ? item.remarks : ''}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <Pagination currentPage={this.state.page} pages={pages} click={this.paginationChange}
                            paginationCount={5}/>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    trackingTypes: state.trackingTypes,
    trackingValueTypes: state.trackingValueTypes
});

const Tracking = connect(mapStateToProps)(TrackingComponent);

export default Tracking;

