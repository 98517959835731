import React from 'react';

class RemoveButton extends React.Component {
    render() {
        return (
            <div>
                <a href="#" className={'remove-from-list'} onClick={
                    e => {
                        e.preventDefault();
                        if (confirm('Are your sure?')) {
                            this.props.action(this.props.id, this.props.descriptor);
                        }
                    }
                }>X</a>
            </div>
        );
    }
}


export default RemoveButton;