import React from 'react';
import {connect} from 'react-redux';
import Popup from "../../parts/Popup";
import CommonTab from "../../parts/CommonTabClass";

class GeneralDataDescriptorSourceAddComponent extends CommonTab {

    state = {
        popupItem : {},
        errors: [],
        validateFields: [
            'generalsourcename',
            'generalsourcenameeng'
        ]
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }

    changeState = (name, e) => {
        let temp = this.props.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    render() {
        const {popupItem, errors, validateFields} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <form onSubmit={event => {
                    event.preventDefault();
                    if (!('description' in popupItem)) {
                        popupItem['description'] = ''
                    }
                    this.formValidation(popupItem, validateFields);
                }}>
                    <h3>{this.props.button + (this.props.button === 'Add ' ? 'New ' : '')} General Source</h3>
                    <table className={'table table-striped'}>
                        <tbody>
                        <tr>
                            <td>General Source Name:</td>
                            <td className={errors.indexOf('generalsourcename') !== -1 ? 'error' : ''}>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'generalsourcename'}
                                       value={popupItem.generalsourcename ? popupItem.generalsourcename : ''}
                                       onChange={(e) => {
                                           this.changeState('generalsourcename', e.target);
                                       }}
                                />
                                {errors.indexOf('generalsourcename') !== -1 ?
                                    <span className="errorText">Please set General Source Name</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>General Source Name Eng:</td>
                            <td className={errors.indexOf('generalsourcenameeng') !== -1 ? 'error' : ''}>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'generalsourcenameeng'}
                                       value={popupItem.generalsourcenameeng ? popupItem.generalsourcenameeng : ''}
                                       onChange={(e) => {
                                           this.changeState('generalsourcenameeng', e.target);
                                       }}
                                />
                                {errors.indexOf('generalsourcenameeng') !== -1 ?
                                    <span className="errorText">Please set General Source Name Eng</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Description:</td>
                            <td><input type={'text'}
                                       className={'form-control'}
                                       name={'description'}
                                       value={popupItem.description ? popupItem.description : ''}
                                       onChange={(e) => {
                                           this.changeState('description', e.target);
                                       }}
                            />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    let itemNew = {};

    state.generalDataDescriptorSources.forEach(item => {
        if (ownProps.id === item.generalsourceid) {
            itemNew = item;
        }
    });

    return {
        popupItem: Object.assign({}, itemNew)
    }

};
const GeneralDataDescriptorSourceAdd = connect(mapStateToProps)(GeneralDataDescriptorSourceAddComponent);

export default GeneralDataDescriptorSourceAdd;


