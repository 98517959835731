import React from 'react';
import {connect} from 'react-redux';
import Popup from "../../../../parts/Popup";
import Select from "../../../../parts/select";
import CommonTab from "../../../../parts/CommonTabClass";
import DatePicker from "react-datepicker";

class addEventComponent extends CommonTab {
    state = {
        popupItem: {
            tenderpublicationid: this.props.publication
        },
        errors: [],
        validateFields: [
            'tenderpublicationeventtypeid',
            'eventdate',
        ]
    };

    handleChange = (date) => {
        let temp = this.state.popupItem;
        temp.eventdate = date;
        this.setState({popupItem: temp});
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }


    placeChange = (value) => {
        let temp = this.state.popupItem;
        temp.popupItemid = value.target.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        const {popupItem, validateFields, errors} = this.state;
        const {tenderPublicationEventsType} = this.props;
        console.log(popupItem);
        console.log(this.props);
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('planningphaseid' in popupItem)) {
                            popupItem['planningphaseid'] = ''
                        }
                        if (!('remarks' in popupItem)) {
                            popupItem['remarks'] = ''
                        }
                        if (!('url' in popupItem)) {
                            popupItem['url'] = ''
                        }
                        this.formValidation(popupItem, validateFields);
                    }}
                >
                    <h3>{this.props.button + (this.props.button === 'Add ' ? 'New ' : '')} Event</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr className={errors.indexOf('tenderpublicationeventtypeid') !== -1 ? 'error' : ''}>
                            <td>Event Type:</td>
                            <td>
                                <Select
                                    id={"tenderpublicationeventtypeid"}
                                    className={"form-control"}
                                    name={"tenderpublicationeventtypeid"}
                                    value={popupItem.tenderpublicationeventtypeid ? popupItem.tenderpublicationeventtypeid : null}
                                    types={tenderPublicationEventsType}
                                    optionValue={"tenderpublicationeventtypeid"}
                                    optionName={"tenderpublicationeventtypename"}
                                    onChange={(e) => {
                                        this.changeState('tenderpublicationeventtypeid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr  className={errors.indexOf('eventdate') !== -1 ? 'error' : ''}>
                            <td>Event Date:</td>
                            <td>
                                <DatePicker
                                    selected={popupItem.eventdate ? new Date(popupItem.eventdate) : ''}
                                    onChange={this.handleChange}
                                    showTimeSelect
                                    timeFormat="HH:mm:s"
                                    timeIntervals={60}
                                    dateFormat="dd/MM/yyyy h:mm:s"
                                    timeCaption="time"
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state , ownProps) => {
    let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item.tenderpublicationeventdetailid == ownProps.id) {
            popupItem = item;
        }
    });
    return {
        tenderPublicationEventsType: state.tenderPublicationEvents,
        popupItem : popupItem
    }
};

const AddEvent = connect(mapStateToProps)(addEventComponent);
export default AddEvent;
