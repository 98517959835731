import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import api from "../../../api";
import {
    ActionLanduseAdd,
    ActionLanduseDelete,
    ActionLanduseEdit,
    ActionLanduses
} from "../../../parts/actions";
import RemoveButton from "../../../parts/removeBtn";
import ToolButtons from "../../../parts/ToolButtons";
import Storege from "../../../storage/storage";
import LandusAdd from "./LandusAdd";
import Search from "../../../parts/Search";
import EditBtn from "../../../parts/EditBtn";
import CommonList from "../../../parts/CommonListClass";
import Pagination from "../../../parts/Pagination";

class LandusesListComponent extends CommonList {
    state = {
        landuses: {}
    };

    orderFields = {
        "Landuse Name": 'landusename',
        "Landuse Name Eng": 'landusenameeng',
        "Date": 'updated_at',
    };

    constructor(props) {
        super(props);
        this.state = {
            landuses: props.landuses,
            searchValue: '',
            popup: false,
            popupType: undefined,
            currentItemIndex: -1,
            rowIndex: -1,
            order: null,
            page: 1
        }
    }

    addContentType = (object) => {
        ActionLanduseAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD LANDUSE',
                item: data
            });
            this.changePopupState();
        });
    };


    editContentType = (object) => {
        ActionLanduseEdit(object.landuseid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE LANDUSE',
                item: data,
                id: data.landuseid
            });
            this.changePopupState();
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    orderPanel = () => {
        let fields = [];
        if (this.orderFields) {
            for (let label in this.orderFields) {
                fields.push((
                    <a className="col-sm-4" onClick={() => {
                        this.setOrder(this.orderFields[label])
                    }}><h4>{label}</h4>{this.diractionSymbol(this.orderFields[label])}</a>
                ))
            }
        }
        if (fields.length) {
            return (
                <li key={-1} className={'sortable-btn'}>
                    <div className="list-group-item clear">
                        {fields}
                    </div>
                </li>
            )
        }
        return null;
    };

    render() {
        let {landuses} = this.props;
        const {searchValue} = this.state;
        if (!landuses) {
            ActionLanduses();
        }
        if (!landuses) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        if (searchValue.length) {
            landuses = landuses.filter((item) => {
                return item.landusename.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 || item.landusenameeng.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
            });
        }

        let arrayForFillters = [];

        if (!arrayForFillters.length) {
            for (let key in this.props.plansPlaces) {
                let singleObject = {};
                singleObject.placeid = key;
                singleObject.placename = this.props.plansPlaces[key];
                arrayForFillters.push(singleObject);
            }
        }

        this.sortResults(landuses);
        let perPega = 25;
        let maxPage = Math.ceil(landuses.length / perPega);
        landuses = landuses.slice((this.state.page - 1) * perPega, (this.state.page) * perPega);
        let pages = [];
        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }
        return (
            <div>
                <h2>Landuses</h2>
                <div className={'tool-bar'}>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                    <Search
                        change={this.search}
                    />
                </div>
                <ul className={'list-group'}>
                    {this.orderPanel()}
                    {(landuses ? landuses : []).map((item, index) => {
                        return (
                            <li key={index}>
                                <RemoveButton
                                    action={ActionLanduseDelete}
                                    id={item.landuseid}
                                    descriptor={'REMOVE LANDUSE'}
                                />
                                <EditBtn
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    change={this.setCurrentIndex}
                                    id={item.landuseid}
                                />
                                <Link to={api.getRoute('landuses/landuse/' + item.landuseid + '/plans')}
                                      onClick={
                                          () => {
                                              this.setState({
                                                  rowIndex: index,
                                                  currentItemIndex: item.contenttypeid
                                              });
                                          }
                                      }
                                      className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                    <div className="col-sm-4">{item.landusename}</div>
                                    <div className="col-sm-4">{item.landusenameeng}</div>
                                    <div className={'col-sm-4'}>{item.updated_at ? item.updated_at : '1/1/1900'}</div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                <Pagination
                    currentPage={this.state.page}
                    pages={pages}
                    click={this.paginationChange}
                    paginationCount={5}
                />
                {this.state.popup ? <LandusAdd
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        landuses: state.landuses.allLanduses,
    }
};

const LandusesList = connect(mapStateToProps)(LandusesListComponent);
export default LandusesList;
