import React from 'react';
import api from "../../api";

const PlanTabs = [
    {
        tabName: 'Plan Details',
        tabEnd: 'plan-details',
        tabUrl: api.getRoute('plans/plan-details/plan/')
    },
    {
        tabName: 'Plan Publications',
        tabEnd: 'plan-publications',
        tabUrl: api.getRoute('plans/plan-publications/plan/')
    },
    {
        tabName: 'Plan Landuses',
        tabEnd: 'plan-landuses',
        tabUrl: api.getRoute('plans/plan-landuses/plan/')
    },
    {
        tabName: 'Plan Relationship',
        tabEnd: 'plan-relationship',
        tabUrl: api.getRoute('plans/plan-relationship/plan/')
    },
    {
        tabName: 'Tender Publications',
        tabEnd: 'tender-publications',
        tabUrl: api.getRoute('plans/tender-publications/plan/')
    }
];

export default PlanTabs;