import React from 'react';
import {connect} from 'react-redux';
import Popup from "../../../parts/Popup";
import Select from "../../../parts/select";
import SelectObject from "../../../parts/SelectObject";
import CommonTab from "../../../parts/CommonTabClass";
import DatePicker from "react-datepicker";

class PlanTenderPublicationAddComponent extends CommonTab {
    state = {
        popupItem: {
            planid: this.props.planid
        },
        errors: [],
        validateFields: [
            'publicationdate',
            'sourceid',
            'sourcecredibiltyid',
            'tenderid',
            'tenderpublicationpurposeid'
        ]
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }

    handleChange = (date) => {
        let temp = this.state.popupItem;
        temp.publicationdate = date;
        this.setState({popupItem: temp});
    };


    placeChange = (value) => {
        let temp = this.state.popupItem;
        temp.popupItemid = value.target.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };


    selectChange = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = +e.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    render() {
        const {popupItem, errors, validateFields} = this.state;
        const {sourcecredibility, source, plans, tenderPublicationPurpose, tendersArr} = this.props;
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('planid' in popupItem)) {
                            popupItem['planid'] = ''
                        }
                        if (!('lot' in popupItem)) {
                            popupItem['lot'] = ''
                        }
                        if (!('remarks' in popupItem)) {
                            popupItem['remarks'] = ''
                        }
                        if (!('url' in popupItem)) {
                            popupItem['url'] = ''
                        }
                        this.formValidation(popupItem, validateFields);                    }}
                >
                    <h3>{this.props.button} Plan Tender Publication</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        {/*<tr>*/}
                        {/*    <td>Publication Date:</td>*/}
                        {/*    <td className={errors.indexOf('publicationdate') !== -1 ? 'error' : ''}>*/}
                        {/*        <DatePicker*/}
                        {/*        selected={popupItem.publicationdate ? new Date(popupItem.publicationdate) : ''}*/}
                        {/*        onChange={this.handleChange}*/}
                        {/*        //showTimeSelect*/}
                        {/*        timeFormat="HH:mm:s"*/}
                        {/*        timeIntervals={60}*/}
                        {/*        dateFormat="dd/MM/yyyy h:mm:s"*/}
                        {/*        timeCaption="time"*/}
                        {/*    />*/}
                        {/*        {errors.indexOf('publicationdate') !== -1 ?*/}
                        {/*            <span className="errorText">Please set Date</span> : ''}*/}
                        {/*    </td>*/}
                        {/*</tr>*/}

                        {/*<tr>*/}
                        {/*    <td>Publication Purpose:</td>*/}
                        {/*    <td className={errors.indexOf('tenderpublicationpurposeid') !== -1 ? 'error' : ''}>*/}
                        {/*        <SelectObject*/}
                        {/*            name={'tenderpublicationpurposeid'}*/}
                        {/*            value={popupItem.tenderpublicationpurposeid}*/}
                        {/*            types={tenderPublicationPurpose}*/}
                        {/*            change={(e) => {*/}
                        {/*                this.selectChange('tenderpublicationpurposeid', e.target);*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*        {errors.indexOf('tenderpublicationpurposeid') !== -1 ?*/}
                        {/*            <span className="errorText">Please set Publication Purpose</span> : ''}*/}
                        {/*    </td>*/}
                        {/*</tr>*/}

                        <tr>
                            <td>Tender:</td>
                            <td className={errors.indexOf('tenderid') !== -1 ? 'error' : ''}>
                                <SelectObject
                                    name={'tenderid'}
                                    value={popupItem.tenderid}
                                    types={tendersArr}
                                    change={(e) => {
                                        this.selectChange('tenderid', e.target);
                                    }}
                                />
                                {errors.indexOf('tenderid') !== -1 ?
                                    <span className="errorText">Please set Tender</span> : ''}
                            </td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td>Source:</td>*/}
                        {/*    <td  className={errors.indexOf('sourceid') !== -1 ? 'error' : ''}>*/}
                        {/*        <Select*/}
                        {/*            id={"source"}*/}
                        {/*            className={"form-control"}*/}
                        {/*            name={"source"}*/}
                        {/*            value={popupItem.sourceid ? popupItem.sourceid : ''}*/}
                        {/*            types={source}*/}
                        {/*            optionValue={"sourceid"}*/}
                        {/*            optionName={"sourcename"}*/}
                        {/*            onChange={(e) => {*/}
                        {/*                this.changeState('sourceid', e.target);*/}
                        {/*            }*/}
                        {/*            }*/}
                        {/*        />*/}
                        {/*        {errors.indexOf('sourceid') !== -1 ?*/}
                        {/*            <span className="errorText">Please set Source</span> : ''}*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>Source Credibility:</td>*/}
                        {/*    <td className={errors.indexOf('sourcecredibiltyid') !== -1 ? 'error' : ''}>*/}
                        {/*        <Select*/}
                        {/*            id={"source_credability"}*/}
                        {/*            className={"form-control"}*/}
                        {/*            name={"source_credability"}*/}
                        {/*            value={(popupItem.sourcecredibiltyid || popupItem.sourcecredibiltyid === 0) ? popupItem.sourcecredibiltyid : null}*/}
                        {/*            types={sourcecredibility}*/}
                        {/*            optionValue={"sourcecredibilityid"}*/}
                        {/*            optionName={"sourcecredibilityname"}*/}
                        {/*            onChange={(e) => {*/}
                        {/*                this.changeState('sourcecredibiltyid', e.target);*/}
                        {/*            }*/}
                        {/*            }*/}
                        {/*        />*/}
                        {/*        {errors.indexOf('sourcecredibiltyid') !== -1 ?*/}
                        {/*            <span className="errorText">Please set Source Credibility</span> : ''}*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>Plan:</td>*/}
                        {/*    <td>*/}
                        {/*        <Select*/}
                        {/*            id={"source_credability"}*/}
                        {/*            className={"form-control"}*/}
                        {/*            name={"source_credability"}*/}
                        {/*            value={popupItem.planid ? popupItem.planid : ''}*/}
                        {/*            types={plans}*/}
                        {/*            optionValue={"planid"}*/}
                        {/*            optionName={"planname"}*/}
                        {/*            onChange={(e) => {*/}
                        {/*                this.changeState('planid', e.target);*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>Lot:</td>*/}
                        {/*    <td>*/}
                        {/*        <input*/}
                        {/*            type={'text'}*/}
                        {/*            value={popupItem.lot}*/}
                        {/*            onChange={e => {*/}
                        {/*                this.changeState('lot', e.target);*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>Remarks:</td>*/}
                        {/*    <td>*/}
                        {/*        <input*/}
                        {/*            type={'text'}*/}
                        {/*            value={popupItem.remarks}*/}
                        {/*            onChange={e => {*/}
                        {/*                this.changeState('remarks', e.target);*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>URL:</td>*/}
                        {/*    <td >*/}
                        {/*        <input*/}
                        {/*            type={'text'}*/}
                        {/*            value={popupItem.url}*/}
                        {/*            onChange={(e) => {*/}
                        {/*                this.changeState('url', e.target);*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*        <a href={popupItem.url} target={'_blank'}>Test</a>*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item.tenderpublicationid == ownProps.id) {
            popupItem = item;
        }
    });
    return {
        source: state.source,
        plans: state.plans,
        tendersArr: state.tendersArr,
        tenderPublicationPurpose: state.tenderPublicationPurpose,
        planningPhases: state.planningPhases,
        sourcecredibility: state.sourcecredibility,
        popupItem: Object.assign({}, popupItem)
    }
};

const PlanTenderPublicationAdd = connect(mapStateToProps)(PlanTenderPublicationAddComponent);
export default PlanTenderPublicationAdd;
