import React from 'react';
import {connect} from 'react-redux';
import {
    ActionPlacesAdd,
    ActionPlacesDel,
    ActionSingleContractor,
    ActionTenderDescriptorContactorAdd, ActionTenderDescriptorContactorDelete, ActionTenderDescriptorContactorEdit,
    PlacesEdit
} from "../../../parts/actions";
import TendersRequest from "../../tenders/TendersRequest";
import TenderPopup from "./tender_popup";
import ColumnResizer from "react-column-resizer";
import ToolButtons from "../../../parts/ToolButtons";
import Storege from "../../../storage/storage";
import RemoveButton from "../../../parts/removeBtn";
import PlanLandusAdd from "../../plans/tabs/PlanLandusAdd";
import TenderContractorAdd from "./tender_contractor_add";
import CommonTab from "../../../parts/CommonTabClass";
import EditBtn from "../../../parts/EditBtn";
import {getItemData} from "../../../parts/functions";

class ContractorSingleComponent extends CommonTab {

    orderFields = {
        "Contractor": "contractorid",
        "Tender": "tendername",
        "Work Start Date": "workstartdate",
        "Work End Date": "workenddate",
        "Winner Sum": "winnersum",
        "Tender Total Sum": "tendertotalsum",
        "Additional Sum": "additionalsum",
        "Remarks": "remarks",
    };

    state = {
        contractor: {},
        tenderNames: {},
        contractors: {},
        tenderPopup: {},
        searchValue: '',
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        order: null
    };

    constructor(props) {
        super(props);
        this.state.contractor = props.contractor;
        this.state.tenderNames = props.tenderNames;
        this.state.contractors = props.contractors;
        this.state.tenderPopup = props.tenderPopup;
    }

    changeStatePopup = (id) => {
        let temp = this.props.tenderPopup;
        temp.active = true;
        this.props.tenderNames.forEach(function (item) {
            if (item.tenderid == id) {
                temp.data = item;
            }
        })
        this.setState({
            tenderPopup: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.props.contractor;
        temp[name] = e.value;
        this.setState({
            contractor: Object.assign({}, temp)
        });
    };

    changeStateTender = (name, e, id) => {
        let temp = this.props.contractor.tenders;
        temp.forEach(function (item) {
            if (item.tenderid == id) {
                item[name] = e.value;
            }
        });

        this.setState({
            contractor: Object.assign({}, temp)
        });
    };

    addContentType = (object) => {
        ActionTenderDescriptorContactorAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD TENDER CONTACTORS',
                item: data
            });
            this.changePopupState();
        });
    };


    editContentType = (object) => {
        ActionTenderDescriptorContactorEdit(object.tendercontractorid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE TENDER CONTACTORS',
                item: data,
                id: data.tendercontractorid
            });
            this.changePopupState();
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    render() {
        let contractor = this.props.contractor;
        let tenders = this.props.contractor.tenders;
        let tenderNames = this.props.tenderNames;
        let contractors = this.props.contractors;
        if (!tenders && contractor && contractor.contractorid) {
            ActionSingleContractor(contractor.contractorid);
        }
        if (!tenderNames.length) {
            TendersRequest('tenders');
        }
        if (!tenders) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        this.sortResults(tenders);
        return (
            <div>
                <div className={'inline-toolbuttons'}>
                    <h3>Selected Contractor</h3>
                </div>
                <table className='table table-striped'>
                    <thead>
                    <tr>
                        <th>Contractor Name</th>
                        <th></th>
                        <th>Contractor Name Eng</th>
                        <th></th>
                        <th>Remarks</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{contractor.contrarctorname}</td>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <td>{contractor.contrarctornameeng}</td>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <td>{contractor.remarks ? contractor.remarks : ""}</td>
                    </tr>
                    </tbody>
                </table>

                <div className={'inline-toolbuttons'}>
                    <h3>Tender Contractors</h3>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <table className='table table-striped'>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {tenders.map((item, index) => {
                        let name;
                        tenderNames.forEach((one) => {
                            if (one.tenderid == item.tenderid) {
                                name = one.tendername;
                            }
                            item = Object.assign(item, {
                                tendername: name
                            })
                        });
                        return (
                            <tr key={index} onClick={
                                () => {
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.tendercontractorid
                                    });
                                }
                            } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionTenderDescriptorContactorDelete}
                                        id={item.tendercontractorid}
                                        descriptor={'REMOVE TENDER CONTACTORS'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.tendercontractorid}
                                    />
                                </td>
                                <td>{getItemData(contractors, 'contractorid', item.contractorid, 'contrarctorname')}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    <a href="#" data-toggle="modal" data-target="#tender-popup" onClick={(e) => {
                                        this.changeStatePopup(item.tenderid);
                                    }}>
                                        {item.tendername}
                                    </a>
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.workstartdate}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.workenddate}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.winnersum ? item.winnersum : ''}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.tendertotalsum ? item.tendertotalsum : ''}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.additionalsum ? item.additionalsum : ''}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.remarks}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

                {this.state.popup ? <TenderContractorAdd
                    contractorid={contractor.contractorid}
                    close={this.changePopupState}
                    itemList={contractor.tenders}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let contractor = {};
    state.contractors.forEach(function (item) {
        if (item.contractorid == ownProps.match.params.id) {
            contractor = item;
        }
    });

    return {
        contractor: Object.assign({}, contractor),
        tenderNames: state.tenders,
        contractors: state.contractors,
        tenderPopup: {
            active: false
        }
    }
};

const ContractorSingle = connect(mapStateToProps)(ContractorSingleComponent);

export default ContractorSingle;