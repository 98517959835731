import React from 'react';
import CommonList from "../../../parts/CommonListClass";
import Pagination from "../../../parts/Pagination";
import Search from "../../../parts/Search";
import {doFilter} from "../../../parts/functions";

let perPega = 25;

class TerritorieSovergneites extends CommonList {

	orderFields = {
        "Place": 'name',
        "Sovereignty": 'sovereignty'
    };

    render() {
        const {territory,sovereigntyTypes,places} = this.props;
        const {searchValue} = this.state;
        console.log(this.state);
        let actualPlaces =[];
        if(territory.sovereignties){
	        actualPlaces = territory.sovereignties.map((item) => {
		        if (places[item.placeid]) {
			        return {
				        name : places[item.placeid].name,
				        sovereignty : sovereigntyTypes[item.sovereigntyid]
			        }
		        }
		        return null;
	        });
        }
        actualPlaces = doFilter(
            actualPlaces,
            [
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'name',
                        'sovereignty'
                    ]
                }
            ]
        );
        this.sortResults(actualPlaces);
        let maxPage = Math.ceil(actualPlaces.length / perPega);
        actualPlaces = actualPlaces.slice((this.state.page - 1) * perPega, (this.state.page) * perPega);
        let pages = [];
        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }
        return (
            <div>
	            <div className={'tool-bar'}>
		            <Search
			            change={this.search}
			            id ={'search2'}
		            />
	            </div>
                <ul className={'list-group place-sovereignities'}>
	                {this.orderPanel()}
	                {actualPlaces.map((item) =>{
	                	if(item){
			                return (<li>
				                <div className={'list-group-item clear'}>
					                <div className="col-sm-6">
						                {item.name}
					                </div>
					                <div className="col-sm-6">
						                {item.sovereignty}
					                </div>
				                </div>
			                </li>);
		                }
		                return item;
	                })}
                </ul>
	             <Pagination currentPage={this.state.page} pages={pages} click={this.paginationChange}
                            paginationCount={5}/>
            </div>
        )
    }
}

export default TerritorieSovergneites;
