import React from 'react';
import {connect} from "react-redux";
import {Route} from "react-router-dom";
import TabsRow from "../../parts/tabs-row";
import api from "../../api";
import TerritorieDetails from "./tabs/territoriDetails";
import TerritorieSovergneites from "./tabs/territoriPlaceSovereignities";

class TerritorySingleComponent extends React.Component {
    state = {
        territory: {},
        activeTabName: ''
    };

    constructor(props) {
        super(props);
        this.state.territory = props.territory;
    };

    getActiveTab = (tab) => {
        this.setState = {
            activeTabName: tab,
        }
    }

    changeState = (name, e) => {
        let temp = this.state.territory;
        temp[name] = e.value;
        this.setState({territory: temp});
    };

    changeDate = (date) => {
        let temp = this.props.territory;
        temp.sovereigntydate = date;
        console.log(temp);
        this.setState({
            territory: Object.assign({}, temp)
        });
    };

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if (oldProps.territory.territoryid !== newProps.territory.territoryid) {
            this.setState({territory: newProps.territory})
        }
    };

    render() {
        const TerritoryTabs = [
            {
                tabName: 'Details',
                tabEnd: 'details',
                tabUrl: api.getRoute('territories/details/territory/')
            },
            {
                tabName: 'Place Sovereignities',
                tabEnd: 'sovereignties',
                tabUrl: api.getRoute('territories/sovereignties/territory/')
            }
        ];
        const {territory, location} = this.props;
        if (!territory && !location) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <TabsRow
                    tabs={TerritoryTabs}
                    id={territory.territoryid}
                    path={location.pathname}
                />
                <Route
                    path={api.getRoute('territories/details/territory/:id')}
                    render={
                        (props) => <TerritorieDetails
                            {...props}
                            territory={this.props.territory}
                            change={this.changeState}
                            changeDate={this.changeDate}
                            sovereignties={this.props.sovereignties}
                            sovereigntyTypes={this.props.sovereigntyTypes}
                        />
                    }
                />
                <Route
                    path={api.getRoute('territories/sovereignties/territory/:id')}
                    render={
                        (props) => <TerritorieSovergneites
                            {...props}
                            territory={this.props.territory}
                            change={this.changeState}
                            changeDate={this.changeDate}
                            sovereignties={this.props.sovereignties}
                            places={this.props.places}
                            sovereigntyTypes={this.props.sovereigntyTypes}
                        />
                    }
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let territory = {};
    state.descriptorTerritories.forEach(function (item) {
        if (item.territoryid == ownProps.match.params.id) {
            territory = item;
        }
    });
    return {
        sovereignties: state.sovereignties,
        sovereigntyTypes: state.sovereignties,
        places: state.territoriesPlaces,
        territory: Object.assign({}, territory)
    }
};

const TerritorySingle = connect(mapStateToProps)(TerritorySingleComponent);

export default TerritorySingle;

