import React from 'react';
import Storege from "../storage/storage";
import api from "../api";
import PlansRequest from "../main/plans/PlansRequest";
import PlacesRequest from "../main/places/PlacesRequest";
import TendersRequest from "../main/tenders/TendersRequest";
import ChartsRequest from "../main/charts/chartsRequest";
import DescriptorRequest from "../main/descriptors/descriptors_request";
import DescriptorsTypesRequest from "../main/descriptors/DescriptorsTypeRequest";
import GeneralRequest from "../main/general_data/GeneralRequest";


function updateGlobalData() {
    PlansRequest('plans');
    PlacesRequest('places');
    TendersRequest('tenders');
    ChartsRequest('charts');
    DescriptorsTypesRequest('descriptors/content_types');
    DescriptorRequest('descriptors/place_types');
    GeneralRequest('general');
}

function ajaxActionsCreator(rout, dispatcher) {
    return (params = '') => {
        return fetch(api.ajaxUrl + rout + params, {
            method: "POST",
            headers: api.headers
        }).then((response) => response.json()).then(function (data) {
            Storege.dispatch({
                type: dispatcher,
                items: data,
                item: data,
                id: params
            });
        });
        updateGlobalData();
    }
}


function ajaxActionsCreator2Params(rout1, rout2, dispatcher) {
    return (params = '', id = '') => {
        fetch(api.ajaxUrl + rout1 + params + rout2 + id, {
            method: "POST",
            headers: api.headers
        }).then((response) => response.json()).then(function (data) {
            Storege.dispatch({
                type: dispatcher,
                items: data,
                id: params[1],
                id_category: params[0]
            });
            updateGlobalData();
        });
    }
}

function ajaxActionsCreator3Params(rout1, rout2, rout3, dispatcher) {
    return (params = '', id = '') => {
        fetch(api.ajaxUrl + rout1 + params + rout2 + id + rout3, {
            method: "POST",
            headers: api.headers
        }).then((response) => response.json()).then(function (data) {
            Storege.dispatch({
                type: dispatcher,
                items: data,
                id: params
            });
        });
        updateGlobalData();
    }
}

function ajaxEdit(rout,dispatcher) {
    return (params = '', info) => {
        return fetch(api.ajaxUrl + rout + params, {
            method: "POST",
            headers: api.headers,
            body: JSON.stringify(info)
        }).then((response) => response.json()).then(function (data) {
            if(dispatcher){
                Storege.dispatch({
                type: dispatcher,
                item: data,
                id: params
            });
            }
            return data;
        });
    }
}

function ajaxDelete(rout) {
    return (params = '', dispatch) => {
        return  fetch(api.ajaxUrl + rout + params, {
            method: "POST",
            headers: api.headers,
        }).then((response) => response.json()).then(function (data) {
            Storege.dispatch({
                type: dispatch,
                id: data
            });
            updateGlobalData();
        });
    }
}

function ajaxDeleteNew(rout) {
    return (params = '', dispatch) => {
        fetch(api.ajaxUrl + rout + params + '/delete', {
            method: "POST",
            headers: api.headers,
        }).then((response) => response.json()).then(function (data) {
            Storege.dispatch({
                type: dispatch,
                id: data
            });
            updateGlobalData();
        });
    }
}

//charts
let ChartEdit = ajaxEdit('charts/edit/');
let ChartAdd = ajaxEdit('charts/add');
let ChartDel = ajaxDelete('charts/delete/');

let ChartSingle = ajaxEdit('charts/chart');

let ActionPlacesAdd = ajaxEdit('places/add');
let PlacesSingle = ajaxEdit('places/place/');
let PlacesEdit = ajaxEdit('places/edit/place/');
let ActionPlacesDel = ajaxDelete('places/delete/place/');

let PlacesStatusEdit = ajaxEdit('places/status/edit/');
let ActionPlacesStatusAdd = ajaxEdit('places/status/add');
let ActionPlacesStatusDel = ajaxDelete('places/status/delete/');

let ActionPlacesSynonymAdd = ajaxEdit('places/synonym/add');
let ActionPlacesSynonymEdit = ajaxEdit('places/synonym/edit/');
let ActionPlacesSynonymDel = ajaxDelete('places/synonym/delete/');

let ActionPlacesHierarchyAdd = ajaxEdit('places/hierarchy/add');
let ActionPlacesHierarchyEdit = ajaxEdit('places/hierarchy/edit/');
let ActionPlacesHierarchyDelete = ajaxDelete('places/hierarchy/delete/');

let ActionPlacesGeometryAdd = ajaxEdit('places/geometry_s/add');
let ActionPlacesGeometryEdit = ajaxEdit('places/geometry_s/edit/')
let ActionPlacesGeometryDelete = ajaxDelete('places/geometry_s/delete/')

let ActionPlacesSovereigntyAdd = ajaxEdit('places/sovereignty/add');
let ActionPlacesSovereigntyEdit = ajaxEdit('places/sovereignty/edit/');
let ActionPlacesSovereigntyDelete = ajaxDelete('places/sovereignty/delete/');

let ActionPlacesTrackingAdd = ajaxEdit('places/tracking_s/add');
let ActionPlacesTrackingEdit = ajaxEdit('places/tracking_s/edit/');
let ActionPlacesTrackingDelete = ajaxDelete('places/tracking_s/delete/');

//////////////////////////////////////

let ActionPlanAdd = ajaxEdit('plans/add');
let ActionPlanEdit = ajaxEdit('plans/edit/plan/');
let ActionPlanDelete = ajaxDelete('plans/delete/plan/');

//tenders add / edit/ delete
let ActionTenderEdit = ajaxEdit('tenders/edit/tender/');
let ActionTenderAdd = ajaxEdit('tenders/add');
let ActionTenderDelete = ajaxDelete('tenders/delete/tender/');
let ActionTenderPublicationDelete = ajaxDeleteNew('tenders/publications/');

//general data add / edit/ delete
let ActionGeneralCategoryEdit = ajaxEdit('general/edit/category/');
let ActionGeneralCategoryAdd = ajaxEdit('general/add/category');
let ActionGeneralCategoryDelete = ajaxDelete('general/delete/category/');


let ActionGeneralValueAdd = ajaxEdit('general/add/value');
let ActionGeneralValueEdit = ajaxEdit('general/edit/value/');
let ActionGeneralValueDelete = ajaxDelete('general/delete/value/');

let ActionGeneralUnitAdd = ajaxEdit('general/add/unit');
let ActionGeneralUnitEdit = ajaxEdit('general/edit/unit/');
let ActionGeneralUnitDelete = ajaxDelete('general/delete/unit/');

let ActionGeneralSourceAdd = ajaxEdit('general/add/source');
let ActionGeneralSourceEdit = ajaxEdit('general/edit/source/');
let ActionGeneralSourceDelete = ajaxDelete('general/delete/source/');

//territories add / edit/ delete
let ActionTerritoryEdit = ajaxEdit('territories/edit/territory/');
let ActionTerritoryAdd = ajaxEdit('territories/add/territory');
let ActionTerritoryDelete = ajaxDelete('territories/delete/territory/');

let ActionTerritorySovereignityEdit = ajaxEdit('territories/edit/sovereignity/');
let ActionTerritorySovereignityAdd = ajaxEdit('territories/add/sovereignity');
let ActionTerritorySovereignityDelete = ajaxDelete('territories/delete/sovereignity/');

//descriptor categories

//content types add / edit/ delete
let ActionContentTypesEdit = ajaxEdit('descriptors/edit/content_type/');
let ActionContentTypesAdd = ajaxEdit('descriptors/add/content_type');
let ActionContentTypesDelete = ajaxDelete('descriptors/delete/content_type/');

let ActionContentTypesUrlEdit = ajaxEdit('descriptors/edit/place_url/');
let ActionContentTypesUrlAdd = ajaxEdit('descriptors/add/place_url');
let ActionContentTypesUrlDelete = ajaxDelete('descriptors/delete/place_url/');

//place types types add / edit/ delete
let ActionPlaceTypesEdit = ajaxEdit('descriptors/place_types/edit/place_type/');
let ActionPlaceTypesAdd = ajaxEdit('descriptors/place_types/add/place_type');
let ActionPlaceTypesDelete = ajaxDelete('descriptors/place_types/delete/place_type/');

//region add / edit/ delete
let ActionDescRegionsEdit = ajaxEdit('descriptors/place_types/edit/regions/');
let ActionDescRegionsAdd = ajaxEdit('descriptors/place_types/add/regions');
let ActionDescRegionsDelete = ajaxDelete('descriptors/place_types/delete/regions/');

//status add / edit/ delete
let ActionStatusEdit = ajaxEdit('descriptors/place_types/edit/status_category/');
let ActionStatusAdd = ajaxEdit('descriptors/place_types/add/status_category');
let ActionStatusDelete = ajaxDelete('descriptors/place_types/delete/status_category/');

//status content add / edit/ delete
let ActionStatusContentEdit = ajaxEdit('descriptors/place_types/edit/status/');
let ActionStatusContentAdd = ajaxEdit('descriptors/place_types/add/status');
let ActionStatusContentDelete = ajaxDelete('descriptors/place_types/delete/status/');

//place community types / edit/ delete
let ActionCommunityTypesEdit = ajaxEdit('descriptors/place_types/edit/community_type/');
let ActionCommunityTypesAdd = ajaxEdit('descriptors/place_types/add/community_type');
let ActionCommunityTypesDelete = ajaxDelete('descriptors/place_types/delete/community_type/');

//place population types / edit/ delete
let ActionPopulationTypesEdit = ajaxEdit('descriptors/place_types/edit/population_type/');
let ActionPopulationTypesAdd = ajaxEdit('descriptors/place_types/add/population_type');
let ActionPopulationTypesDelete = ajaxDelete('descriptors/place_types/delete/population_type/');

//place tracking types / edit/ delete
let ActionTrackingTypesEdit = ajaxEdit('descriptors/place_types/edit/tracking_type/');
let ActionTrackingTypesAdd = ajaxEdit('descriptors/place_types/add/tracking_type');
let ActionTrackingTypesDelete = ajaxDelete('descriptors/place_types/delete/tracking_type/');


//place tracking value types / edit/ delete
let ActionTrackingValueEdit = ajaxEdit('descriptors/place_types/edit/tracking_value_type/');
let ActionTrackingValueAdd = ajaxEdit('descriptors/place_types/add/tracking_value_type');
let ActionTrackingValueDelete = ajaxDelete('descriptors/place_types/delete/tracking_value_type/');


//plan descriptors types / edit/ delete
let ActionPlanTypesEdit = ajaxEdit('plan_descriptors/edit/plan_type/');
let ActionPlanTypesAdd = ajaxEdit('plan_descriptors/add/plan_type');
let ActionPlanTypesDelete = ajaxDelete('plan_descriptors/delete/plan_type/');

//plan descriptors types / edit/ delete
let ActionPublicationTypeEdit = ajaxEdit('plan_descriptors/edit/plan_publication_unit_type/');
let ActionPublicationTypeAdd = ajaxEdit('plan_descriptors/add/plan_publication_unit_type');
let ActionPublicationTypeDelete = ajaxDelete('plan_descriptors/delete/plan_publication_unit_type/');

//plan ownership types / edit/ delete
let ActionOwnershipTypeEdit = ajaxEdit('plan_descriptors/edit/ownership_type/');
let ActionOwnershipTypeAdd = ajaxEdit('plan_descriptors/add/ownership_type');
let ActionOwnershipTypeDelete = ajaxDelete('plan_descriptors/delete/ownership_type/');

//plan planning phases cat / edit/ delete
let ActionPlanningPhasesCatEdit = ajaxEdit('plan_descriptors/edit/planning_phases_cat/');
let ActionPlanningPhasesCatAdd = ajaxEdit('plan_descriptors/add/planning_phases_cat');
let ActionPlanningPhasesCatDelete = ajaxDelete('plan_descriptors/delete/planning_phases_cat/');

//plan planning phases / edit/ delete
let ActionPlanningPhasesEdit = ajaxEdit('plan_descriptors/edit/planning_phase/');
let ActionPlanningPhasesAdd = ajaxEdit('plan_descriptors/add/planning_phase');
let ActionPlanningPhasesDelete = ajaxDelete('plan_descriptors/delete/planning_phase/');

//tender descriptors tender types / edit/ delete
let ActionTenderTypesEdit = ajaxEdit('tender_descriptors/edit/tender_type/');
let ActionTenderTypesAdd = ajaxEdit('tender_descriptors/add/tender_type');
let ActionTenderTypesDelete = ajaxDelete('tender_descriptors/delete/tender_type/');

//tender descriptors tender purpose / edit/ delete
let ActionTenderPurposeEdit = ajaxEdit('tender_descriptors/edit/tender_purpose/');
let ActionTenderPurposeAdd = ajaxEdit('tender_descriptors/add/tender_purpose');
let ActionTenderPurposeDelete = ajaxDelete('tender_descriptors/delete/tender_purpose/');

//tender descriptors tender publication purpose / edit/ delete
let ActionTenderPublicationPurposeEdit = ajaxEdit('tender_descriptors/edit/tender_publication_purpose/');
let ActionTenderPublicationPurposeAdd = ajaxEdit('tender_descriptors/add/tender_publication_purpose');
let ActionTenderPublicationPurposeDelete = ajaxDelete('tender_descriptors/delete/tender_publication_purpose/');

//tender descriptors unit type / edit/ delete
let ActionTenderPublicationUnitTypeEdit = ajaxEdit('tender_descriptors/edit/tender_publication_unit_type/');
let ActionTenderPublicationUnitTypeAdd = ajaxEdit('tender_descriptors/add/tender_publication_unit_type');
let ActionTenderPublicationUnitTypeDelete = ajaxDelete('tender_descriptors/delete/tender_publication_unit_type/');

//tender descriptors event type / edit/ delete
let ActionTenderPublicationEventTypeEdit = ajaxEdit('tender_descriptors/edit/tender_publication_event_type/');
let ActionTenderPublicationEventTypeAdd = ajaxEdit('tender_descriptors/add/tender_publication_event_type');
let ActionTenderPublicationEventTypeDelete = ajaxDelete('tender_descriptors/delete/tender_publication_event_type/');

//tender descriptors cost type / edit/ delete
let ActionTenderPublicationCostTypeEdit = ajaxEdit('tender_descriptors/edit/tender_publication_cost_type/');
let ActionTenderPublicationCostTypeAdd = ajaxEdit('tender_descriptors/add/tender_publication_cost_type');
let ActionTenderPublicationCostTypeDelete = ajaxDelete('tender_descriptors/delete/tender_publication_cost_type/');

let ActionTenderDescriptorContactorAdd = ajaxEdit('tender_descriptors/add/tender_contractor');
let ActionTenderDescriptorContactorEdit = ajaxEdit('tender_descriptors/edit/tender_contractor/');
let ActionTenderDescriptorContactorDelete = ajaxDelete('tender_descriptors/delete/tender_contractor/');

let ActionTenderContactorsEdit = ajaxEdit('tender_descriptors/edit/contractor/');
let ActionTenderContactorsAdd = ajaxEdit('tender_descriptors/add/contractor');
let ActionTenderContactorsDelete = ajaxDelete('tender_descriptors/delete/contractor/');

//initiators add / edit/ delete

let ActionInitiatorTypesEdit = ajaxEdit('initiators/edit/initiator_type/');
let ActionInitiatorTypesAdd = ajaxEdit('initiators/add/initiator_type');
let ActionInitiatorTypesDelete = ajaxDelete('initiators/delete/initiator_type/');

let ActionInitiatorEdit = ajaxEdit('initiators/edit/initiator/');
let ActionInitiatorAdd = ajaxEdit('initiators/add/initiator');
let ActionInitiatorDelete = ajaxDelete('initiators/delete/initiator/');

// sources add / edit/ delete
let ActionSourceEdit = ajaxEdit('sources/edit/source/');
let ActionSourceAdd = ajaxEdit('sources/add/source');
let ActionSourceDelete = ajaxDelete('sources/delete/source/');

let ActionSourceCategoryEdit = ajaxEdit('sources/edit/category/');
let ActionSourceCategoryAdd = ajaxEdit('sources/add/category');
let ActionSourceCategoryDelete = ajaxDelete('sources/delete/category/');

let ActionSourcePlanPublicationAdd = ajaxEdit('sources/add/plan_publication');
let ActionSourcePlanPublicationEdit = ajaxEdit('sources/edit/plan_publication/');
let ActionSourcePlanPublicationDelete = ajaxDelete('sources/delete/plan_publication/');

let ActionSourceTenderPublicationAdd = ajaxEdit('sources/add/tender_publication');
let ActionSourceTenderPublicationEdit = ajaxEdit('sources/edit/tender_publication/');
let ActionSourceTenderPublicationDelete = ajaxDelete('sources/delete/tender_publication/');

// landuses add / edit/ delete

let ActionLanduseAdd = ajaxEdit('landuses/add/landuse');
let ActionLanduseEdit = ajaxEdit('landuses/edit/landuse/');
let ActionLanduseDelete = ajaxDelete('landuses/delete/landuse/');

// tender landuse add / edit/ delete

let ActionTenderLanduseAdd = ajaxEdit('tender_landuse/add');
let ActionTenderLanduseEdit = ajaxEdit('tender_landuse/edit/');
let ActionTenderLanduseDelete = ajaxDelete('tender_landuse/delete/');

// tender relationship add / edit/ delete

let ActionTenderRelationshipAdd = ajaxEdit('tender_relationship/add');
let ActionTenderRelationshipEdit = ajaxEdit('tender_relationship/edit/');
let ActionTenderRelationshipDelete = ajaxDelete('tender_relationship/delete/');

// plan relationship add / edit/ delete

let ActionPlanRelationshipAdd = ajaxEdit('plan_relationship/add');
let ActionPlanRelationshipEdit = ajaxEdit('plan_relationship/edit/');
let ActionPlanRelationshipDelete = ajaxDelete('plan_relationship/delete/');
// plan landuse add / edit/ delete

let ActionPlanLanduseAdd = ajaxEdit('plan_landuse/add');
let ActionPlanLanduseEdit = ajaxEdit('plan_landuse/edit/');
let ActionPlanLanduseDelete = ajaxDelete('plan_landuse/delete/');

// time ranges add / edit/ delete

let ActionTimeRanges = ajaxActionsCreator('time_ranges','SET TIME RANGES');
let ActionTimeRangeSingle = ajaxActionsCreator('time_ranges/','UPDATE TIME RANGE');
let ActionTimeRangesAdd = ajaxEdit('time_ranges/add/time_range','ADD TIME RANGE');
let ActionTimeRangesEdit = ajaxEdit('time_ranges/edit/time_range/','UPDATE TIME RANGE');
let ActionTimeRangesDelete = ajaxDelete('time_ranges/delete/time_range/','REMOVE TIME RANGE');


////////////////////////////////////////////////////////////

//places actions
let ActionSetStatuses = ajaxActionsCreator('places/statuses/', 'UPDATE PLACES');
let ActionSetSynonyms = ajaxActionsCreator('places/synonyms/', 'UPDATE SYNONYMS');
let ActionSetHierarchies = ajaxActionsCreator('places/hierarchies/', 'UPDATE HIERARCHIES');
let ActionSetGeometries = ajaxActionsCreator('places/geometry/', 'UPDATE GEOMETRIES');
let ActionSetSovereignties = ajaxActionsCreator('places/sovereignties/', 'UPDATE SOVEREGNITIES');
let ActionSetTracking = ajaxActionsCreator('places/tracking/', 'UPDATE TRACKING');
let ActionSetLinks = ajaxActionsCreator('places/links/', 'UPDATE LINKS');
let ActionSetICBS = ajaxActionsCreator('places/ibspdetails/', 'UPDATE ICBS');

//plans actions
let ActionPlanPublications = ajaxActionsCreator('plans/publications/plan/', 'UPDATE PLAN PUBLICATIONS');
let ActionPlanLanduses = ajaxActionsCreator('plans/landuses/plan/', 'UPDATE PLAN LANDUSES');
let ActionPlanRelationship = ajaxActionsCreator('plans/relationship/plan/', 'UPDATE PLAN RELATIONSHIP');
let ActionPlanTenderPublication = ajaxActionsCreator('plans/tenderpublications/plan/', 'UPDATE PLAN TENDER PUBLICATION');
let ActionPlanDetails = ajaxActionsCreator('/plan-details/plan/', 'UPDATE PLAN DETAILS')
let ActionPlanPublicationSingle = ajaxActionsCreator('/publication/plan/', 'UPDATE SINGLE PUBLICATION PLAN')

let ActionPlanPublicationUnits = ajaxActionsCreator('plan_publication_unit_details/', 'lol');
let ActionPlanPublicationUnitDelete = ajaxActionsCreator('plan_publication_unit_details/delete/', 'lol');
let ActionPlanPublicationUnitAdd = ajaxEdit('plan_publication_unit_details/add', 'lol');
let ActionPlanPublicationUnitEdit = ajaxEdit('plan_publication_unit_details/edit/', 'lol');


let ActionTenderPublicationUnitDelete = ajaxActionsCreator('tender_publication_unit_details/delete/', 'lol');
let ActionTenderPublicationUnitAdd = ajaxEdit('tender_publication_unit_details/add', 'lol');
let ActionTenderPublicationUnitEdit = ajaxEdit('tender_publication_unit_details/edit/', 'lol');

let ActionTenderPublicationEventDelete = ajaxActionsCreator('tender_publication_event_details/delete/', 'lol');
let ActionTenderPublicationEventAdd = ajaxEdit('tender_publication_event_details/add', 'lol');
let ActionTenderPublicationEventEdit = ajaxEdit('tender_publication_event_details/edit/', 'lol');

let ActionTenderPublicationCostDelete = ajaxActionsCreator('tender_publication_cost_details/delete/', 'lol');
let ActionTenderPublicationCostAdd = ajaxEdit('tender_publication_cost_details/add', 'lol');
let ActionTenderPublicationCostEdit = ajaxEdit('tender_publication_cost_details/edit/', 'lol');



//tenders actions
let ActionTenderLanduses = ajaxActionsCreator('tenders/landuses/tender/', 'UPDATE TENDER LANDUSES');
let ActionTenderContactors = ajaxActionsCreator('tenders/contractors/tender/', 'UPDATE TENDER CONTACTORS');
let ActionTenderRelationship = ajaxActionsCreator('tenders/relationship/tender/', 'UPDATE TENDER RELATIONSHIPS');
let ActionTenderPublications = ajaxActionsCreator('tenders/publications/tender/', 'UPDATE TENDER PUBLICATIONS');
let ActionTenderDetails = ajaxActionsCreator('tenders/tender-details/tender/', 'UPDATE TENDER DETAILS');

//general

let ActionGeneralDataValue = ajaxActionsCreator('general/category/', 'UPDATE GENERAL DATA VALUE');

// descriptors places

let ActionDescriptorsPlaceTypes = ajaxActionsCreator('descriptors/place_types', 'SET PLACE TYPES');
let ActionDescriptorsPlaceTypeSingle = ajaxActionsCreator('descriptors/place_types/place_type', 'SET PLACE TYPE SINGLE');

let ActionDescriptorsRegion = ajaxActionsCreator('descriptors/place_types/regions', 'SET PLACE REGIONS');
let ActionDescriptorsStatusCategories = ajaxActionsCreator('descriptors/place_types/status_categories', 'SET PLACE STATUS CATEGORIES');
let ActionDescriptorsStatus = ajaxActionsCreator('descriptors/place_types/statuses', 'SET PLACE STATUSES');
let ActionDescriptorsCommunity = ajaxActionsCreator('descriptors/place_types/community_types', 'SET PLACE COMMUNITY TYPE');
let ActionDescriptorsPopulation = ajaxActionsCreator('descriptors/place_types/population_types', 'SET PLACE POPULATION TYPE');
let ActionDescriptorsTracking = ajaxActionsCreator('descriptors/place_types/tracking_types', 'SET TRACKING TYPES');
let ActionDescriptorsTrackingValues = ajaxActionsCreator('descriptors/place_types/tracking_value_types', 'SET TRACKING VALUE TYPES');

//descriptors plans

let ActionDescriptorsPlanningPhases = ajaxActionsCreator('plan_descriptors/planning_phases', 'SET PLANNING PHASES');
let ActionDescriptorsPlanningPhasesCategory = ajaxActionsCreator('plan_descriptors/planning_phases_cats', 'SET PLANNING PHASES CATEGORY');
let ActionDescriptorsOwnerShip = ajaxActionsCreator('plan_descriptors/ownership_types', 'SET PLANS OWNER SHIP TYPE');
let ActionDescriptorsPlanPublication = ajaxActionsCreator('plan_descriptors/plan_publication_unit_types', 'SET PLAN PUBLICATION TYPE');
let ActionDescriptorsPlanTypes = ajaxActionsCreator('plan_descriptors/plan_types', 'SET PLAN TYPE');
let ActionDescriptorsContentType = ajaxActionsCreator('descriptors/content_type/', 'SET DESCRIPTORS CONTENT TYPE');
let ActionDescriptorsContentTypes = ajaxActionsCreator('descriptors/content_types/', 'SET DESCRIPTORS CONTENT TYPES');

// descriptors tenders


let ActionDescriptorsTenderTypes = ajaxActionsCreator('tender_descriptors/tender_types', 'SET TENDERS TYPES');
let ActionDescriptorsTenderPurposes = ajaxActionsCreator('tender_descriptors/tender_purposes', 'SET TENDERS PURPOSES');
let ActionDescriptorsTenderPublicationPurposes = ajaxActionsCreator('tender_descriptors/tender_publication_purposes', 'SET PLAN TENDER PUBLICATIONS');
let ActionDescriptorsTenderPublicationUnits = ajaxActionsCreator('tender_descriptors/tender_publication_unit_types', 'SET TENDER PUBLICATION UNIT');
let ActionDescriptorsTenderPublicationEvents = ajaxActionsCreator('tender_descriptors/tender_publication_event_types', 'SET TENDER PUBLICATION EVENT');
let ActionDescriptorsTenderPublicationCost = ajaxActionsCreator('tender_descriptors/tender_publication_cost_types', 'SET TENDER PUBLICATION COST');
let ActionDescriptorsContractor = ajaxActionsCreator('tenders', 'SET CONTRACTORS');
let ActionSingleContractor = ajaxActionsCreator('tender_descriptors/tender_contractors/contractor/', 'SET SINGLE CONTRACTOR');
let ActionSingleContractors = ajaxActionsCreator('tender_descriptors/tender_contractors', 'SET CONTRACTORS');


let ActionDescriptorsTerritories = ajaxActionsCreator('territories/territory/', 'SET DESCRIPTOR TERRITORY');

// initiators

let ActionInitiator = ajaxActionsCreator('initiators', 'SET INITIATORS');
let ActionInitiatorByType = ajaxActionsCreator('initiators/type/', 'SET INITIATORS BY TYPE');
let ActionSingleInitiator = ajaxActionsCreator('initiators/initiator/', 'SET SINGLE INITIATOR');
let ActionInitiatorPlan = ajaxActionsCreator('initiators/initiator/initiator_plans/', 'SET INITIATOR PLAN');
let ActionSubmitterPlan = ajaxActionsCreator('initiators/initiator/submitter_plans/', 'SET SUBMITTER PLAN');
let ActionInitiatorTenders = ajaxActionsCreator('initiators/initiator/initiator_tenders/', 'SET INITIATOR TENDERS');


// sources

let ActionSource = ajaxActionsCreator('sources', 'SET SOURCES');
let ActionSourceByCategory = ajaxActionsCreator('sources/category/', 'SET SOURCE BY CATEGORY');
let ActionSingleSource = ajaxActionsCreator2Params('sources/category/', '/source/', "SET SINGLE SOURCE");
let ActionSingleSourcePlan = ajaxActionsCreator3Params('sources/category/', '/source/', '/plan_publications', "SET SOURCE PLANS");
let ActionSingleSourceTender = ajaxActionsCreator3Params('sources/category/', '/source/', '/tender_publications', "SET SOURCE TENDERS");

//landuses

let ActionLanduses = ajaxActionsCreator('landuses', 'SET LANDUSES');
let ActionSingleLanduse = ajaxActionsCreator('landuses/landuse/', 'SET SINGLE LANDUSE');
let ActionSingleLandusePlans = ajaxActionsCreator2Params('landuses/landuse/', '/plans', "SET LANDUSE PLANS");
let ActionSingleLanduseTenders = ajaxActionsCreator2Params('landuses/landuse/', '/tenders', "SET LANDUSE TENDERS");


let ActionTenderLanduse = ajaxActionsCreator('tender_landuse', 'SET TENDER LANDUSE');
let ActionTenderRelationshipSingle = ajaxActionsCreator('tender_relationship', 'SET TENDER RELATIONSHIP');
let ActionPlanRelationshipSingle = ajaxActionsCreator('plan_relationship', 'SET PLAN RELATIONSHIP');
let ActionPlanLanduseSingle = ajaxActionsCreator('plan_landuse', 'SET PLAN LANDUSE');
let ActionTimeRange = ajaxActionsCreator('time_ranges', 'SET TIME RANGES');


export {
    ActionPlanPublicationUnits,
    ActionPlanPublicationUnitDelete,
    ActionPlanPublicationUnitAdd,
    ActionPlanPublicationUnitEdit,

    ActionTenderPublicationUnitDelete,
    ActionTenderPublicationUnitAdd,
    ActionTenderPublicationUnitEdit,

    ActionTenderPublicationEventDelete,
    ActionTenderPublicationEventAdd,
    ActionTenderPublicationEventEdit,

    ActionTenderPublicationCostDelete,
    ActionTenderPublicationCostAdd,
    ActionTenderPublicationCostEdit,

    ActionPlacesTrackingAdd,
    ActionPlacesTrackingEdit,
    ActionPlacesTrackingDelete,

    ActionPlacesSovereigntyAdd,
    ActionPlacesSovereigntyEdit,
    ActionPlacesSovereigntyDelete,

    ActionTimeRanges,
    ActionTimeRangeSingle,
    ActionTimeRangesAdd,
    ActionTimeRangesEdit,
    ActionTimeRangesDelete,


    ActionSetSynonyms,
    ActionSetStatuses,
    ActionSetHierarchies,
    ActionSetGeometries,
    ActionSetSovereignties,
    ActionSetTracking,
    ActionSetLinks,
    ActionSetICBS,
    ActionPlanPublications,
    ActionPlanLanduses,
    ActionPlanRelationship,
    ActionPlanTenderPublication,
    ActionTenderLanduses,
    ActionTenderContactors,
    ActionTenderRelationship,
    ActionTenderPublications,
    ActionGeneralDataValue,
    ActionDescriptorsRegion,
    ActionDescriptorsStatusCategories,
    ActionDescriptorsStatus,
    ActionDescriptorsCommunity,
    ActionDescriptorsPopulation,
    ActionDescriptorsTracking,
    ActionDescriptorsTrackingValues,
    ActionDescriptorsPlanningPhases,
    ActionDescriptorsOwnerShip,
    ActionDescriptorsPlanPublication,
    ActionDescriptorsPlanTypes,
    ActionDescriptorsTenderTypes,
    ActionDescriptorsTenderPurposes,
    ActionDescriptorsTenderPublicationPurposes,
    ActionDescriptorsTenderPublicationUnits,
    ActionDescriptorsTenderPublicationEvents,
    ActionDescriptorsTenderPublicationCost,
    ActionDescriptorsPlanningPhasesCategory,
    ActionDescriptorsContractor,
    ActionDescriptorsContentType,
    ActionDescriptorsContentTypes,
    ActionSingleContractor,
    ActionInitiator,
    ActionInitiatorByType,
    ActionDescriptorsTerritories,
    ActionSingleInitiator,
    ActionInitiatorPlan,
    ActionSubmitterPlan,
    ActionInitiatorTenders,
    ActionSource,
    ActionSourceByCategory,
    ActionSingleSource,
    ActionSingleSourcePlan,
    PlacesEdit,
    ActionPlacesAdd,
    ActionPlacesDel,
    ActionPlanAdd,
    ActionPlanEdit,
    ActionPlanDelete,
    ActionTenderEdit,
    ActionTenderDelete,
    ActionTenderAdd,
    ActionSingleSourceTender,
    ActionGeneralCategoryEdit,
    ActionGeneralCategoryAdd,
    ActionGeneralCategoryDelete,
    ActionLanduses,
    ActionSingleLanduse,
    ActionSingleLandusePlans,
    ActionSingleLanduseTenders,
    ActionContentTypesEdit,
    ActionContentTypesAdd,
    ActionContentTypesDelete,
    ActionPlaceTypesEdit,
    ActionPlaceTypesAdd,
    ActionPlaceTypesDelete,
    ActionDescRegionsEdit,
    ActionDescRegionsAdd,
    ActionDescRegionsDelete,
    ActionStatusEdit,
    ActionStatusAdd,
    ActionStatusDelete,
    ActionCommunityTypesDelete,
    ActionCommunityTypesAdd,
    ActionCommunityTypesEdit,
    ActionPopulationTypesEdit,
    ActionPopulationTypesAdd,
    ActionPopulationTypesDelete,
    ActionTrackingTypesEdit,
    ActionTrackingTypesAdd,
    ActionTrackingTypesDelete,
    ActionTrackingValueEdit,
    ActionTrackingValueAdd,
    ActionTrackingValueDelete,
    ActionStatusContentEdit,
    ActionStatusContentAdd,
    ActionStatusContentDelete,
    ActionPlanTypesEdit,
    ActionPlanTypesAdd,
    ActionPlanTypesDelete,
    ActionPublicationTypeEdit,
    ActionPublicationTypeAdd,
    ActionPublicationTypeDelete,
    ActionOwnershipTypeEdit,
    ActionOwnershipTypeAdd,
    ActionOwnershipTypeDelete,
    ActionPlanningPhasesCatEdit,
    ActionPlanningPhasesCatAdd,
    ActionPlanningPhasesCatDelete,
    ActionPlanningPhasesEdit,
    ActionPlanningPhasesAdd,
    ActionPlanningPhasesDelete,
    ActionTenderTypesEdit,
    ActionTenderTypesAdd,
    ActionTenderTypesDelete,
    ActionTenderPurposeEdit,
    ActionTenderPurposeAdd,
    ActionTenderPurposeDelete,
    ActionTenderPublicationPurposeEdit,
    ActionTenderPublicationPurposeAdd,
    ActionTenderPublicationPurposeDelete,
    ActionTenderPublicationUnitTypeEdit,
    ActionTenderPublicationUnitTypeAdd,
    ActionTenderPublicationUnitTypeDelete,
    ActionTenderPublicationEventTypeEdit,
    ActionTenderPublicationEventTypeAdd,
    ActionTenderPublicationEventTypeDelete,
    ActionTenderPublicationCostTypeEdit,
    ActionTenderPublicationCostTypeAdd,
    ActionTenderPublicationCostTypeDelete,
    ActionTenderContactorsEdit,
    ActionTenderContactorsAdd,
    ActionTenderContactorsDelete,
    ActionInitiatorTypesEdit,
    ActionInitiatorTypesAdd,
    ActionInitiatorTypesDelete,
    ActionTenderPublicationDelete,
    ActionTerritoryEdit,
    ActionTerritoryAdd,
    ActionTerritoryDelete,
    ChartDel,
    ChartAdd,
    ChartEdit,
    PlacesStatusEdit,
    ActionPlacesStatusAdd,
    ActionPlacesStatusDel,
    ActionContentTypesUrlEdit,
    ActionContentTypesUrlAdd,
    ActionContentTypesUrlDelete,
    ActionPlacesSynonymAdd,
    ActionPlacesSynonymEdit,
    ActionPlacesSynonymDel,
    ActionPlacesHierarchyAdd,
    ActionPlacesHierarchyEdit,
    ActionPlacesHierarchyDelete,
    ActionSourceEdit,
    ActionSourceAdd,
    ActionSourceDelete,
    ActionSourcePlanPublicationAdd,
    ActionSourcePlanPublicationEdit,
    ActionSourcePlanPublicationDelete,
    ActionTenderDescriptorContactorAdd,
    ActionTenderDescriptorContactorEdit,
    ActionTenderDescriptorContactorDelete,
    ActionTenderDetails,
    ActionLanduseAdd,
    ActionLanduseEdit,
    ActionLanduseDelete,
    ActionTenderLanduseAdd,
    ActionTenderLanduseEdit,
    ActionTenderLanduseDelete,
    ActionTenderRelationshipAdd,
    ActionTenderRelationshipEdit,
    ActionTenderRelationshipDelete,
    ActionPlanLanduseAdd,
    ActionPlanLanduseEdit,
    ActionPlanLanduseDelete,
    ActionGeneralValueAdd,
    ActionGeneralValueEdit,
    ActionGeneralValueDelete,
    ActionSourceTenderPublicationAdd,
    ActionSourceTenderPublicationEdit,
    ActionSourceTenderPublicationDelete,
    ActionGeneralUnitAdd,
    ActionGeneralUnitEdit,
    ActionGeneralUnitDelete,
    ActionGeneralSourceAdd,
    ActionGeneralSourceEdit,
    ActionGeneralSourceDelete,
    ActionPlanRelationshipAdd,
    ActionPlanRelationshipEdit,
    ActionPlanRelationshipDelete,
    ActionSourceCategoryAdd,
    ActionSourceCategoryEdit,
    ActionSourceCategoryDelete,
    ActionInitiatorAdd,
    ActionInitiatorEdit,
    ActionInitiatorDelete,
    ActionPlacesGeometryAdd,
    ActionPlacesGeometryEdit,
    ActionPlacesGeometryDelete
};