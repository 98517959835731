import React from 'react';
import {connect} from 'react-redux';
import {
    ActionContentTypesUrlAdd,
    ActionContentTypesUrlDelete, ActionContentTypesUrlEdit,
    ActionDescriptorsContentType,
} from "../../parts/actions";
import Pagination from "../../parts/Pagination";
import ToolButtons from "../../parts/ToolButtons";
import Storege from "../../storage/storage";
import RemoveButton from "../../parts/removeBtn";
import DescriptorAddUrl from "./discriptor_content_type_add";
import CommonTab from "../../parts/CommonTabClass";
import ColumnResizer from "react-column-resizer";
import {doFilter, getItemData} from "../../parts/functions";
import EditBtn from "../../parts/EditBtn";
import Search from "../../parts/Search";

class DescriptorContentTypesComponent extends CommonTab {
    state = {
        page: 1,
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        order: null,
        searchValue: ''
    };

    orderFields = {
        "Place": 'placeid',
        // "Content type": 'conenttypeid',
        "Url": 'url',
        "Create Date": 'createdate'
    };

    paginationChange = (number) => {
        this.setState({
            page: number
        });
    };

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if (oldProps.singleType.contenttypeid !== newProps.singleType.contenttypeid) {
            this.setState({singleType: newProps.singleType})
        }
    }

    changeSelect = (name, e, id) => {
        let temp = this.props.singleType;
        temp.contentTypeArray[id][name] = +e.value;
        this.setState({
            singleType: Object.assign({}, temp)
        });
    };

    addContentType = (object) => {
        ActionContentTypesUrlAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD CONTENT TYPE URL',
                item: data
            });
            ActionDescriptorsContentType(this.props.singleType.contenttypeid);
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionContentTypesUrlEdit(object.placeurlid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE CONTENT TYPE URL',
                item: data,
                id: data.placeurlid
            });
            ActionDescriptorsContentType(this.props.singleType.contenttypeid);
            this.changePopupState();
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    render() {
        let perPega = 20;
        let {searchValue} = this.state;

        if (!this.props.singleType.contentTypeArray) {
            ActionDescriptorsContentType(this.props.singleType.contenttypeid);
        }
        let typesArr = this.props.singleType.contentTypeArray ? this.props.singleType.contentTypeArray : [];
        let items = typesArr;

        items = doFilter(items, [
            {
                name: 'searchValue',
                value: searchValue,
                searchId: [
                    'url'
                ],
                additionalFilter : (item,str) => {
                    return getItemData(this.props.places, 'placeid', item.placeid, 'name') && getItemData(this.props.places, 'placeid', item.placeid, 'name').toLowerCase().indexOf(str) != -1 ;
                }
            }
        ]);

        let maxPage = Math.ceil(items.length / perPega);
        items = items.slice((this.state.page - 1) * perPega, (this.state.page) * perPega);
        let pages = [];

        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }

        console.log(items);

        this.sortResults(items);

        const {places, contentTypes} = this.props;
        if (!places && !contentTypes) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className={'inline-toolbuttons'}>
                    <h3>Place URLS</h3>
                    <Search
                        change={this.search}
                        id={'custom-22'}
                    />
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <div className={'scrolled'}>
                    <table className={'table table-striped geometry-table'}>
                        <thead>
                        {this.orderPanel()}
                        </thead>
                        <tbody>
                        {(items ? items : []).map((item, index) => {
                            return (
                                <tr key={index} onClick={
                                    () => {
                                        this.setState({
                                            rowIndex: index,
                                            currentItemIndex: item.placeurlid
                                        });
                                    }
                                } className={'list-group-item' + (this.state.rowIndex == index ? ' active' : '')}>
                                    <td className={'flex-row'}>
                                        <RemoveButton
                                            action={ActionContentTypesUrlDelete}
                                            id={item.placeurlid}
                                            descriptor={'REMOVE CONTENT TYPE URL'}
                                        />
                                        <EditBtn
                                            actionType={this.changePopupType}
                                            popupChange={this.changePopupState}
                                            change={this.setCurrentIndex}
                                            id={item.placeurlid}
                                        />
                                    </td>
                                    <td>{getItemData(places, 'placeid', item.placeid, 'name')}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    {/*<td>{getItemData(contentTypes, 'contenttypeid', item.conenttypeid, 'contenttypename')}</td>*/}
                                    {/*<ColumnResizer className="columnResizer" minWidth={5}/>*/}
                                    <td>
                                        <a href={item.url}>{item.url}</a>
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {item.createdate}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    <Pagination
                        currentPage={this.state.page}
                        pages={pages}
                        click={this.paginationChange}
                        paginationCount={5}
                    />
                    {this.state.popup ? <DescriptorAddUrl
                        close={this.changePopupState}
                        items={items}
                        typeId={this.props.singleType.contenttypeid}
                        action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                        button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                        id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                    /> : ''}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let content = {};
    state.descriptorsPlaceTypes.map((item) => {
        if (item.contenttypeid == ownProps.match.params.id) {
            content = item;
        }
    });
    return {
        contentTypes: state.descriptorsPlaceTypes,
        places: state.places,
        singleType: Object.assign({}, content)
    }
};

const DescriptorContentTypes = connect(mapStateToProps)(DescriptorContentTypesComponent);
export default DescriptorContentTypes;
