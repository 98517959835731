import React from 'react';
import {connect} from 'react-redux';
import SelectObject from "../../parts/SelectObject";
import {
    ActionGeneralDataValue,
    ActionGeneralValueAdd,
    ActionGeneralValueDelete,
    ActionGeneralValueEdit,
} from "../../parts/actions";
import Storege from "../../storage/storage";
import ToolButtons from "../../parts/ToolButtons";
import RemoveButton from "../../parts/removeBtn";
import GeneralDataAdd from "./GeneralDataAdd";
import Search from "../../parts/Search";
import ColumnResizer from "react-column-resizer";
import EditBtn from "../../parts/EditBtn";
import getFullDateYear from "../../parts/functions";
import Pagination from "../../parts/Pagination";

const perPega = 25;

class GeneralDataSingleComponent extends React.Component {

    orderFields = {
        "Value Date": 'valuedate',
        "Value": 'value',
        "Remarks": 'remarks'
    };

    constructor(props) {
        super(props);
        this.state = {
            page : 1,
            category: props.category,
            popup: false,
            searchValue: '',
            popupType: undefined,
            currentItemIndex: -1,
            rowIndex: -1,
            order: null
        };
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if (oldProps.category.generalcategoryid !== newProps.category.generalcategoryid) {
            this.setState({category: newProps.category})
        }
    }

    changeState = (name, e) => {
        let temp = this.props.category;
        temp[name] = e.value;
        this.setState({
            category: Object.assign({}, temp)
        });
    };

    changeValue = (name, e, id) => {
        let temp = this.props.category.values;
        temp.forEach(function (item) {
            if (item.generalvalueid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            category: Object.assign({}, temp)
        });
    };

    selectChange = (name, e) => {
        let temp = this.props.category;
        temp[name] = +e.value;
        this.setState({
            category: Object.assign({}, temp)
        });
    };

    addContentType = (object) => {
        ActionGeneralValueAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD GENERAL VALUE',
                item: data
            });
            ActionGeneralDataValue(this.props.category.generalcategoryid);
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionGeneralValueEdit(object.generalvalueid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE GENERAL VALUE',
                item: data,
                id: data.generalvalueid
            });
            ActionGeneralDataValue(this.props.category.generalcategoryid);
            this.changePopupState();

        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    search = (value) => {
        this.setState({
            searchValue: value
        });
    };

    setOrder = (type) => {
        let order = {
            type: type,
            diraction: true
        };
        if (this.state.order && this.state.order.type == type) {
            order.diraction = !this.state.order.diraction;
        }
        this.setState({
            order: order
        })
    };

    diractionSymbol(type) {
        if (this.state.order && this.state.order.type == type) {
            return (
                <span
                    className={this.state.order.diraction ? 'asc ' : 'desc'}>{this.state.order.diraction ? 'asc ' : 'desc'}</span>
            )
        }
        return null;
    }

    sortResults = (items) => {
        const {order} = this.state;
        if (order) {
            const k = order.diraction ? 1 : -1;
            items.sort((a, b) => {
                let result = 0
                if (a[order.type] === null) {
                    a[order.type] = '';
                }
                if (b[order.type] === null) {
                    b[order.type] = '';
                }
                if (a[order.type] < b[order.type]) {
                    result = -1;
                }

                if (a[order.type] > b[order.type]) {
                    result = 1;
                }
                return result * k;
            });
        }
    };

    orderPanel = () => {
        let fields = [];
        if (this.orderFields) {
            for (let label in this.orderFields) {
                fields.push((
                    <React.Fragment>
                        <th>
                            <a className="sort-items" onClick={() => {
                                this.setOrder(this.orderFields[label])
                            }}><h4>{label}</h4>{this.diractionSymbol(this.orderFields[label])}</a>
                        </th>
                        {Object.keys(this.orderFields).indexOf(label) !== Object.keys(this.orderFields).length - 1 ?
                            <ColumnResizer className="columnResizer" minWidth={5}/> : ''}
                    </React.Fragment>
                ))
            }
        }
        if (fields.length) {
            return (
                <tr className={'sortable-btn'}>
                    <th></th>
                    {fields}
                </tr>
            )
        }
        return null;
    };

    setCurrentIndex = (id) => {
        this.setState({
            currentItemIndex : id,
            popupType : 'edit'
        });
    };


    paginationChange = (number) => {
        this.setState({
            page: number
        });
    };

    render() {
        let {category, source, unit} = this.props;
        let {searchValue} = this.state;
        if (!category.values) {
            ActionGeneralDataValue(category.generalcategoryid);
        }
        let valueArr = category.values ? category.values : [];

        if (!category && !source && !unit) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        // if (searchValue.length) {
        //     valueArr = valueArr.filter((item) => {
        //         return (item.remarks ? item.remarks : '').toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
        //     });
        // }

        this.sortResults(valueArr);
        let maxPage = Math.ceil(valueArr.length / perPega);
        let items = valueArr.slice((this.state.page - 1) * perPega, (this.state.page) * perPega);
        let pages = [];

        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }

        return (
            <div>
                <h3>Details</h3>
                <table className={"table table-striped"} valign={"middle"}>
                    <tbody>
                    <tr>
                        <td>Name:</td>
                        <td>{category.generalcategoryname}</td>
                    </tr>
                    <tr>
                        <td>Name Eng:</td>
                        <td>{category.generalcategorynameeng}</td>
                    </tr>
                    <tr>
                        <td>Unit:</td>
                        <td>{ unit[category.generalunitid]}</td>
                    </tr>
                    <tr>
                        <td>Source:</td>
                        <td>{source[category.generalsourceid]}</td>
                    </tr>
                    <tr>
                        <td>Description:</td>
                        <td>{category.description}</td>
                    </tr>
                    </tbody>
                </table>
                <div className={'inline-toolbuttons'}>
                    <h3>General Values</h3>
                    <div className={'tool-bar'}>
                        {/*<Search*/}
                            {/*change={this.search}*/}
                            {/*id={'general-search'}*/}
                        {/*/>*/}
                        <ToolButtons
                            actionType={this.changePopupType}
                            popupChange={this.changePopupState}
                            change={this.setCurrentIndex}
                            id={this.state.currentItemIndex}
                        />
                    </div>
                </div>
                <table className={"table table-striped"} valign={"middle"}>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {items.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                () => {
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.generalvalueid
                                    });
                                }
                            } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionGeneralValueDelete}
                                        id={item.generalvalueid}
                                        descriptor={'REMOVE GENERAL VALUE'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.generalvalueid}
                                    />
                                </td>
                                <td>{getFullDateYear(item.valuedate)}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.value ? parseInt(item.value) : ''}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.remarks}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <Pagination
                    currentPage={this.state.page}
                    pages={pages}
                    click={this.paginationChange}
                    paginationCount={5}
                />
                {this.state.popup ? <GeneralDataAdd
                    generalcategoryid={category.generalcategoryid}
                    close={this.changePopupState}
                    itemList={category.values}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let category = {};
    state.generalData.forEach(function (item) {
        if (item.generalcategoryid == ownProps.match.params.id) {
            category = item;
        }
    });
    return {
        category: Object.assign({}, category),
        source: state.generalDataSources,
        unit: state.generalDataUnits
    }
};

const GeneralDataSingle = connect(mapStateToProps)(GeneralDataSingleComponent);

export default GeneralDataSingle;