import React from 'react';

let Place = function (store = [], event) {
    if (event.type == 'SET PLACES') {
        store = event.items;
    }
    if (event.type == 'UPDATE PLACES') {
        return store.map(function (item) {
            if (item.placeid == event.id) {
                let item1 = Object.assign({}, item, {
                    statusList: event.items
                });
                return item1;
            }
            return item;
        });
    }
    if (event.type === 'UPDATE PLACE GEOMETRY') {
        return store.map(function (item) {
            if (item.placeid == event.placeid) {
                let item1 = Object.assign({}, item, {
                    geometriesList: event.data
                });
                return item1;
            }
            return item;
        });
    }
    if (event.type === 'UPDATE SYNONYMS') {
        return store.map(function (item) {
            if (item.placeid == event.id) {
                let item1 = Object.assign({}, item, {
                    synonymsList: event.items
                });
                return item1;
            }
            return item;
        });
    }

    if (event.type === 'UPDATE HIERARCHIES') {
        return store.map(function (item) {
            if (item.placeid == event.id) {
                let item1 = Object.assign({}, item, {
                    hierarchiesList: event.items
                });
                return item1;
            }
            return item;
        });
    }

    if (event.type === 'UPDATE GEOMETRIES') {
        return store.map(function (item) {
            if (item.placeid == event.id) {
                let item1 = Object.assign({}, item, {
                    geometriesList: event.items
                });
                return item1;
            }
            return item;
        });
    }
    if (event.type === 'UPDATE SOVEREGNITIES') {
        return store.map(function (item) {
            if (item.placeid == event.id) {
                let item1 = Object.assign({}, item, {
                    PlaceSovereignties: event.items.PlaceSovereignties,
                    PlaceSovereigntyChanges: event.items.PlaceSovereigntyChanges
                });
                return item1;
            }
            return item;
        });
    }


    if (event.type === 'UPDATE TRACKING') {
        return store.map(function (item) {
            if (item.placeid == event.id) {
                let item1 = Object.assign({}, item, {
                    tracking: event.items
                });
                return item1;
            }
            return item;
        });
    }
    if (event.type === 'UPDATE LINKS') {
        return store.map(function (item) {
            if (item.placeid == event.id) {
                let item1 = Object.assign({}, item, {
                    links: event.items
                });
                return item1;
            }
            return item;
        });
    }
    if (event.type === 'UPDATE ICBS') {
        return store.map(function (item) {
            if (item.placeid == event.id) {
                let item1 = Object.assign({}, item, {
                    ICBS: event.items
                });
                return item1;
            }
            return item;
        });
    }

    if (event.type === 'ADD PLACE') {
        store.push(event.item);
    }

    if (event.type === 'REMOVE PLACE') {
        store = store.filter(item => {
            return item.placeid != event.id
        });
    }

    if (event.type === 'UPDATE PLACE') {
        return store.map(function (item) {
            if (item.placeid == event.id) {
                let item1 = Object.assign({}, event.item);
                return item1;
            }
            return item;
        });
    }

    if (event.type === 'ADD PLACE STATUS') {
        return store.map(function (item) {
            if (item.placeid == event.item.placeid) {
                item.statusList.push(event.item);
            }
            return item;
        });
    }

    if (event.type === 'REMOVE PLACE STATUS') {
        return store.map(function (item) {
            if (item.placeid == event.id.placeid) {
                item.statusList = item.statusList.filter(statusItem => {
                    return statusItem.placestatusid !== event.id.placestatusid;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    if (event.type === 'UPDATE PLACE STATUS') {
        return store.map(function (item) {
            if (item.placeid == event.item.placeid) {
                item.statusList = item.statusList.map(statusItem => {
                    if (statusItem.placestatusid === event.item.placestatusid) {
                        let editedItem = Object.assign({}, event.item);
                        return editedItem;
                    }
                    return statusItem;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }


    if (event.type === 'ADD PLACE SYNONYMS') {
        return store.map(function (item) {
            if (item.placeid == event.item.placeid) {
                item.synonymsList.push(event.item);
            }
            return item;
        });
    }

    if (event.type === 'REMOVE PLACE SYNONYMS') {
        return store.map(function (item) {
            if (item.placeid == event.id.placeid) {
                item.synonymsList = item.synonymsList.filter(statusItem => {
                    return statusItem.placesynonymid != event.id.placesynonymid;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    if (event.type === 'UPDATE PLACE SYNONYMS') {
        return store.map(function (item) {
            if (item.placeid == event.item.placeid) {
                item.synonymsList = item.synonymsList.map(statusItem => {
                    if (statusItem.placesynonymid == event.item.placesynonymid) {
                        let editedItem = Object.assign({}, event.item);
                        return editedItem;
                    }
                    return statusItem;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    if (event.type === 'ADD PLACE HIERARCHIES') {
        return store.map(function (item) {
            if (item.placeid == event.item.placeid) {
                item.hierarchiesList = item.hierarchiesList ? item.hierarchiesList : {underlyingPlaces : [] , overlyingPlaces :[]};
                item.hierarchiesList.underlyingPlaces.push(event.item);
            }
            if(item.placeid == event.item.parentplaceid){
                item.hierarchiesList = item.hierarchiesList ? item.hierarchiesList : {underlyingPlaces : [] , overlyingPlaces :[]};
                 item.hierarchiesList.overlyingPlaces.push(event.item);
            }
            return item;
        });
    }

    if (event.type === 'REMOVE PLACE HIERARCHIES') {
        return store.map(function (item) {
            if (item.placeid == event.id.placeid) {
                item.hierarchiesList = item.hierarchiesList ? item.hierarchiesList : [];
                item.hierarchiesList.overlyingPlaces = item.hierarchiesList.overlyingPlaces.filter(statusItem => {
                    return statusItem.placehierarchyid != event.id.placehierarchyid;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    if (event.type === 'UPDATE PLACE HIERARCHIES') {
        console.log(event);
        return store.map(function (item) {
            if (item.placeid == event.item.placeid) {
                item.hierarchiesList = item.hierarchiesList ? item.hierarchiesList : {
                    overlyingPlaces : [],
                    underlyingPlaces :[]
                };
                item.hierarchiesList.overlyingPlaces = item.hierarchiesList.overlyingPlaces.map(statusItem => {
                    if (statusItem.placehierarchyid == event.item.placehierarchyid) {
                        let editedItem = Object.assign({}, event.item);
                        return editedItem;
                    }
                    return statusItem;
                });
                item.hierarchiesList.underlyingPlaces = item.hierarchiesList.underlyingPlaces.map(statusItem => {
                    if (statusItem.placehierarchyid == event.item.placehierarchyid) {
                        let editedItem = Object.assign({}, event.item);
                        return editedItem;
                    }
                    return statusItem;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    return store;
};

export default Place;