import React from 'react';
import {connect} from 'react-redux';
import { ActionTenderLanduseAdd, ActionTenderLanduseDelete, ActionTenderLanduseEdit, ActionTenderLanduses} from "../../../parts/actions";
import Storege from "../../../storage/storage";
import RemoveButton from "../../../parts/removeBtn";
import {getItemData} from "../../../parts/functions";
import ToolButtons from "../../../parts/ToolButtons";
import TenderLandusAdd from "./TenderLandusAdd";
import CommonTab from "../../../parts/CommonTabClass";
import EditBtn from "../../../parts/EditBtn";

class TenderLandusComponent extends CommonTab {

    orderFields = {
        "Landuse": 'landuseid'
    };

    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            popupType: undefined,
            currentItemIndex: -1,
            rowIndex: -1,
            order: null
        }
    }

    addContentType = (object) => {
        ActionTenderLanduseAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD TENDER LANDUSE',
                item: data
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionTenderLanduseEdit(object.tenderlanduseid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE TENDER LANDUSE',
                item: data,
                id: data.tenderlanduseid
            });
            this.changePopupState();
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    render() {
        const {tender, change} = this.props;
        let allLanduses = this.props.tenderLanduses ? this.props.tenderLanduses : [];
        let tenderLanduses = tender.landusArray ? tender.landusArray : [];
        if(!tender.landusArray){
            ActionTenderLanduses(tender.tenderid);
        }
        this.sortResults(tenderLanduses);
        return (
            <div>
                <div className={'custom-tool-bar'}>
                    <h3>Tender Landuses</h3>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <table className={'table table-striped'}>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {tenderLanduses.map((item, index)=>{
                        return(
                            <tr key={index} onClick={
                                () => {
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.tenderlanduseid
                                    });
                                }
                            } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                <td className={'edit-buttons'}>
                                    <div className="flex-row">
                                    <RemoveButton
                                        action={ActionTenderLanduseDelete}
                                        id={item.tenderlanduseid}
                                        descriptor={'REMOVE TENDER LANDUSE'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.tenderlanduseid}
                                    />
                                    </div>
                                </td>
                                <td>
                                    {getItemData(allLanduses, 'landuseid', item.landuseid, 'landusename')}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <TenderLandusAdd
                    tenderid={tender.tenderid}
                    close={this.changePopupState}
                    itemList={tender.landusArray}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tenderLanduses: state.tenderLanduses
    }
};

const TenderLandus = connect(mapStateToProps)(TenderLandusComponent);

export default TenderLandus;