import React from 'react';
import {connect} from 'react-redux';
import {ActionInitiatorTenders, ActionSingleInitiator, ActionTenderDelete} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import CommonList from "../../../parts/CommonListClass";
import RemoveButton from "../../../parts/removeBtn";
import EditBtn from "../../../parts/EditBtn";
import TenderAdd from "../../tenders/TenderAdd";
import TendersRequest from "../../tenders/TendersRequest";
import Search from "../../../parts/Search";
import {doFilter} from "../../../parts/functions";

class InitiatorTendersComponent extends CommonList {

    orderFields = {
        "Initiator": (a, b) => {
            const {initiator} = this.props;
            let result = 0;
            if (initiator[a.initiatorid] < initiator[b.initiatorid]) {
                result = -1;
            }

            if (initiator[a.initiatorid] > initiator[b.initiatorid]) {
                result = 1;
            }
            return result
        },
        "Tender Name": 'tendername',
        "Place": (a, b) => {
            const {placesList} = this;
            let result = 0;
            if (placesList[a.placeid] < placesList[b.placeid]) {
                result = -1;
            }

            if (placesList[a.placeid] > placesList[b.placeid]) {
                result = 1;
            }
            return result
        },
        "Tender Purpose": (a, b) => {
            const {tenders} = this.props;
            let result = 0;
            if (tenders[a.tenderpurposeid] < tenders[b.tenderpurposeid]) {
                result = -1;
            }
            if (tenders[a.tenderpurposeid] > tenders[b.tenderpurposeid]) {
                result = 1;
            }
            return result
        }
    };

    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.tendersFull.length) {
            TendersRequest('tenders');
        }
        const {searchValue} = this.state;
        let initiatorId = this.props._id;
        let initiators = this.props.initiators;
        let tenders = this.props.tenders;
        let places = [];
        if (this.props.places) {
            for (var key in this.props.places) {
                places.push(this.props.places[key])
            }
        }
        this.placesList = places;
        let initiator = this.props.initiator;
        if (initiator.length == 0 || (initiatorId != initiator.initiatorid)) {
            ActionSingleInitiator(initiatorId);
        }
        if (!initiator.hasOwnProperty('initiatorTenders')) {
            ActionInitiatorTenders(initiatorId);
        }
        let initiatorTenders = this.props.initiator.initiatorTenders;
        if (!initiatorTenders) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        let outPutTenders = doFilter(initiatorTenders, [
            {
                name: 'searchValue',
                value: searchValue,
                searchId: [
                    'tendername'
                ],
                additionalFilter: (item, str) => {
                    console.log(item);
                    let result = 0;

                    const {placesList} = this;
                    const {initiator} = this.props;
                    const {tenders} = this.props;

                    if (placesList[item.placeid] && placesList[item.placeid].indexOf(str) != -1) {
                        result++
                    }
                    if (initiator[item.initiatorid] && initiator[item.initiatorid].indexOf(str) != -1) {
                        result++
                    }
                    if (tenders[item.tenderpurposeid] && tenders[item.tenderpurposeid].indexOf(str) != -1) {
                        result++
                    }
                    return result;
                }
            }
        ]);
        this.sortResults(outPutTenders);
        return (
            <div>
                <div className="inline-toolbuttons">
                    <h3>Initiator Tenders:</h3>
                    <Search
                        change={this.search}
                        id={'search2'}
                    />
                </div>
                <div className="scrolled">
                <table className='table table-striped'>
                    <thead>
                    {this.orderPanelTabel()}
                    </thead>
                    <tbody>
                    {(outPutTenders ? outPutTenders : []).map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <div className="flex-row">
                                        <RemoveButton
                                            action={ActionTenderDelete}
                                            id={item.tenderid}
                                            descriptor={'REMOVE TENDER'}
                                        />
                                        <EditBtn
                                            actionType={this.changePopupType}
                                            popupChange={this.changePopupState}
                                            change={this.setCurrentIndex}
                                            id={item.tenderid}
                                        />
                                    </div>
                                </td>
                                <td>
                                    {initiators ? initiators[item.initiatorid] : ''}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.tendername}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {places ? places[item.placeid] : ''}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.tenderpurposeid ? (tenders ? tenders : []).map((unit, index) => {
                                        if (item.tenderpurposeid == index) {
                                            return unit;
                                        }
                                    }) : ''}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                </div>
                {this.state.popup ? <TenderAdd
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        initiator: state.singleInitiator,
        places: state.initiatorData.places,
        initiators: state.initiatorData.initiators_arr,
        tenders: state.initiatorData.tender_purposes,
        tendersFull: state.tenders
    }
};
const InitiatorTenders = connect(mapStateToProps)(InitiatorTendersComponent);

export default InitiatorTenders;