import React from 'react';
import {connect} from 'react-redux';
import api from "../../../api";
import TabsRow from "../../../parts/tabs-row";
import {Route} from "react-router-dom";
import SingleInitiator from './single-initiator-data';
import InitiatorPlan from './initiator_plans';
import SubmitterPlan from './submitter_plans';
import InitiatorTenders from './initiator_tenders'

class InitiatorTabsRowComponent extends React.Component {
    state = {
        initiators: {},
        activeTabName : ''
    }

    constructor(props) {
        super(props);
        this.state.initiators = props.initiators
    }

    getActiveTab = (tab) => {
        this.setState = {
            activeTabName: tab,
        }
    }

    render() {
        this.getActiveTab(this.props.match.params.tabName);
        console.log(this.props)
        let typeId = this.props.match.params.id_2;
        const InitiatorTabs = [
            // {
            //     tabName: 'Selected Initiator',
            //     tabEnd: 'initiator',
            //     tabUrl: api.getRoute('initiators/type/' + typeId + '/initiator/selected/')
            // },
            {
                tabName: 'Initiator Plans',
                tabEnd: 'initiator_plans',
                tabUrl: api.getRoute('initiators/type/' + typeId + '/initiator/initiator_plans/')
            },
            {
                tabName: 'Submitter Plans',
                tabEnd: 'submitter_plans',
                tabUrl: api.getRoute('initiators/type/' + typeId + '/initiator/submitter_plans/')
            },
            {
                tabName: 'Initiator Tenders',
                tabEnd: 'initiator_tenders',
                tabUrl: api.getRoute('initiators/type/' + typeId + '/initiator/initiator_tenders/')
            },
        ];
        return (
            <div>
                <TabsRow
                    tabs={InitiatorTabs}
                    id={this.props.match.params.id}
                    path={this.props.location.pathname}
                />
                {/*<Route path={api.getRoute('initiators/type/' + typeId + '/initiator/selected/')}*/}
                {/*render={(props) => <SingleInitiator {...props} id={this.props.match.params.id}/> } />*/}
                <Route path={api.getRoute('initiators/type/' + typeId + '/initiator/initiator_plans/')}
                      render={(props) => <InitiatorPlan {...props} id={this.props.match.params.id}  _id={this.props.match.params.id} /> } />
                <Route path={api.getRoute('initiators/type/' + typeId + '/initiator/submitter_plans/')}
                render={(props) => <SubmitterPlan {...props} id={this.props.match.params.id} _id={this.props.match.params.id} /> } />
                <Route path={api.getRoute('initiators/type/' + typeId + '/initiator/initiator_tenders/')}
                       render={(props) => <InitiatorTenders {...props} id={this.props.match.params.id} _id={this.props.match.params.id} /> } />
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        initiators: state.initiatorsByType.initiators
    }
};

const InitiatorTabsRow = connect(mapStateToProps)(InitiatorTabsRowComponent);
export default InitiatorTabsRow;
