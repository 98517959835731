import React from 'react';
import {connect} from 'react-redux';
import {
    ActionDescriptorsTenderPublicationUnits,
    ActionTenderPublicationUnitTypeAdd,
    ActionTenderPublicationUnitTypeDelete,
    ActionTenderPublicationUnitTypeEdit
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import Storege from "../../../storage/storage";
import ToolButtons from "../../../parts/ToolButtons";
import Search from "../../../parts/Search";
import TenderTypesPublicationUnitType from "./TenderPublicationUnitTypePopup";
import RemoveButton from "../../../parts/removeBtn";
import CommonTab from "../../../parts/CommonTabClass";
import {doFilter} from "../../../parts/functions";
import EditBtn from "../../../parts/EditBtn";

class TenderPublicationUnitDescriptorComponent extends CommonTab {

    orderFields = {
        "Tender Publication Unit Type Name" : "tenderpublicationunittypename",
        "Tender Publication Unit Type Name Eng": "tenderpublicationunittypenameeng"
    };

    state = {
        tenderPublicationUnits: {},
        popup: false,
        popupType: undefined,
        rowIndex: -1,
        currentItemIndex: -1,
        order: null,
        searchValue: ''
    };

    constructor(props) {
        super(props);
        this.state.tenderPublicationUnits = props.tenderPublicationUnits;
    }

    changeState = (name, e, id) => {
        let temp = this.props.tenderPublicationUnits;
        temp.forEach(function (item) {
            if (item.tenderpublicationunittypeid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            tenderPublicationUnits: Object.assign({}, temp)
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    addContentType = (object) => {
        ActionTenderPublicationUnitTypeAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD TENDER PUBLICATION UNIT TYPE',
                item: data
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionTenderPublicationUnitTypeEdit(object.tenderpublicationunittypeid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE TENDER PUBLICATION UNIT TYPE',
                item: data,
                id: data.tenderpublicationunittypeid
            });
            this.changePopupState();
        });
    };

    render() {
        if (!this.props.tenderPublicationUnits.length) {
            ActionDescriptorsTenderPublicationUnits();
        }
        let {filtered, filteredItems, searchValue} = this.state;
        let {tenderPublicationUnits} = this.props;
        let list = filtered ? filteredItems : tenderPublicationUnits;
        if (!list) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        let outPutPlaces = tenderPublicationUnits;
        outPutPlaces = doFilter(
            outPutPlaces,
            [
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'tenderpublicationunittypename',
                        'tenderpublicationunittypenameeng'
                    ]
                }
            ]
        );

        this.sortResults(outPutPlaces);
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div>
                        <div className="inline-toolbuttons">
                            <h3>Tender Publication Unit Types:</h3>
                            <div className={'tool-bar'}>
                                <ToolButtons
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    id={this.state.currentItemIndex}
                                />
                                <Search
                                    change={this.search}
                                />
                            </div>
                        </div>
                        <table className='table table-striped'>
                            <thead>
                            {this.orderPanel()}
                            </thead>
                            <tbody>
                            {outPutPlaces.map((item, index) => {
                                return (
                                    <tr key={index} onClick={
                                        e => {
                                            e.preventDefault();
                                            this.setState({
                                                rowIndex: index,
                                                currentItemIndex: item.tenderpublicationunittypeid
                                            });
                                        }
                                    } className={this.state.rowIndex == index ? 'active' : ''}>
                                        <td className={'flex-row'}>
                                            <RemoveButton
                                                action={ActionTenderPublicationUnitTypeDelete}
                                                id={item.tenderpublicationunittypeid}
                                                descriptor={'REMOVE TENDER PUBLICATION UNIT TYPE'}
                                            />
                                            <EditBtn
                                                actionType={this.changePopupType}
                                                popupChange={this.changePopupState}
                                                change={this.setCurrentIndex}
                                                id={item.tenderpublicationunittypeid}
                                            />
                                        </td>
                                        <td>{item.tenderpublicationunittypename}</td>
                                        <ColumnResizer className="columnResizer" minWidth={5}/>
                                        <td>{item.tenderpublicationunittypenameeng}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {this.state.popup ? <TenderTypesPublicationUnitType
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tenderPublicationUnits: state.tenderPublicationUnits
    }
};

const DescriptorTenderPublicationUnit = connect(mapStateToProps)(TenderPublicationUnitDescriptorComponent);
export default DescriptorTenderPublicationUnit;