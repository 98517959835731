import React from 'react';
import {connect} from 'react-redux';
import {
    ActionDescriptorsPlanningPhases,
    ActionPlanPublications,
    ActionSourcePlanPublicationAdd,
    ActionSourcePlanPublicationDelete,
    ActionSourcePlanPublicationEdit,
    ActionPlanPublicationUnits
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import TendersRequest from "../../tenders/TendersRequest";
import ToolButtons from "../../../parts/ToolButtons";
import Storege from "../../../storage/storage";
import RemoveButton from "../../../parts/removeBtn";
import PlanPublicationAdd from "./PlanPublicationAdd";
import CommonTab from "../../../parts/CommonTabClass";
import EditBtn from "../../../parts/EditBtn";
import getFullDateYear, {getItemData} from "../../../parts/functions";
import Units from './unit/Units'

class PlanPublicationsComponent extends CommonTab {

    orderFields = {
        "Publication Date": 'publicationdate',
        "Planning Phase": 'planningphaseid',
        "Source": 'sourceid',
        "Source Credibility": 'sourcecredibilityid',
        "Remarks": 'remarks',
        "URL": 'url'
    };

    state = {
        planningPhases: {},
        source: {},
        sourcecredibility: {},
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        uploaded: false,
    };

    constructor(props) {
        super(props);

        this.state.planningPhases = props.planningPhases;
        this.state.source = props.source;
        this.state.sourcecredibility = props.sourcecredibility;
        this.state.plan = props.plan
    }


    selectChange = (name, e, id) => {
        let temp = this.props.plan;
        temp.publications.forEach(function (item) {
            if (item.publicationid == id) {
                item[name] = +e.value;
            }
        });
        this.setState({
            plan: Object.assign({}, temp)
        });
    };

    addContentType = (object) => {
        ActionSourcePlanPublicationAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLAN PUBLICATION',
                item: data
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionSourcePlanPublicationEdit(object.planpublicationid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLAN PUBLICATION',
                item: data,
                id: data.planpublicationid
            });
            this.changePopupState();
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    render() {

        if (!this.props.planningPhases.length) {
            ActionDescriptorsPlanningPhases();
        }

        if (!this.props.sourcecredibility.length) {
            TendersRequest('tenders');
        }

        let {plan, planningPhases, source, sourcecredibility} = this.props;

        let publications = plan.publications ? plan.publications : [];

        if (!plan.publications) {
            ActionPlanPublications(plan.planid);
        }

        this.sortResults(publications);

        if (!plan) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        let curent = null;
        if(this.state.currentItemIndex && this.state.currentItemIndex > 0){
            curent = publications.filter( (item) => {
                return item.planpublicationid == this.state.currentItemIndex;
            } )[0];
        }
        return (
            <div>
                <div className={'custom-tool-bar'}>
                    <h3>Plan Publications</h3>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <div className="scrolled ">
                    <table className={"table table-striped"} valign={"middle"}>
                        <thead>
                        {this.orderPanel()}
                        </thead>
                        <tbody>
                        {publications.map((item, index) => {
                            return (
                                <tr key={index} onClick={
                                    () => {
                                        this.setState({
                                            rowIndex: index,
                                            currentItemIndex: item.planpublicationid
                                        });
                                    }
                                } className={'list-group-item' + (this.state.rowIndex == index ? ' active' : '')}>
                                    <td  className={'flex-row'}>
                                        <RemoveButton
                                            descriptor={'REMOVE PLAN PUBLICATION'}
                                            action={ActionSourcePlanPublicationDelete}
                                            id={item.planpublicationid}
                                        />
                                        <EditBtn
                                            actionType={this.changePopupType}
                                            popupChange={this.changePopupState}
                                            change={this.setCurrentIndex}
                                            id={item.planpublicationid}
                                        />
                                    </td>
                                    <td>
                                        {getFullDateYear(item.publicationdate)}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {getItemData(planningPhases ? planningPhases : [], 'planningphaseid', item.planningphaseid, 'planningphasename')}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {getItemData(source ? source : [], 'sourceid', item.sourceid, 'sourcename')}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {getItemData(sourcecredibility ? sourcecredibility : [], 'sourcecredibilityid', item.sourcecredibilityid, 'sourcecredibilityname')}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{item.remarks}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{item.url}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    {curent ? (<Units items={curent.units} publication={this.state.currentItemIndex} planid={plan.planid} />) : ''}
                </div>
                {this.state.popup ? <PlanPublicationAdd
                    planid={plan.planid}
                    close={this.changePopupState}
                    itemList={publications}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {

    let plan = {};

    state.plans.forEach(item => {
        if (item.planid == ownProps.match.params.id) {
            plan = item
        }
    });

    return {
        plan: Object.assign({}, plan),
        source: state.source,
        planningPhases: state.planningPhases,
        sourcecredibility: state.sourcecredibility
    }
};

const PlanPublications = connect(mapStateToProps)(PlanPublicationsComponent);
export default PlanPublications;