import React from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import api from "../../api";
import DescriptorsTypesRequest from "./DescriptorsTypeRequest";
import ToolButtons from "../../parts/ToolButtons";
import {
    ActionContentTypesAdd,
    ActionContentTypesDelete, ActionContentTypesEdit,
} from "../../parts/actions";
import Search from "../../parts/Search";
import ContentTypePopup from "./ContentTypePopup";
import Storege from "../../storage/storage";
import RemoveButton from "../../parts/removeBtn";
import {doFilter} from "../../parts/functions";
import EditBtn from "../../parts/EditBtn";
import CommonList from "../../parts/CommonListClass";

class DescriptorsTypesListComponent extends CommonList {

    orderFields = {
        "Content Type Name": 'contenttypename',
        "Content Type Name Eng": 'contenttypenameeng'
    };


    state = {
        page: 1,
        popup: false,
        popupType: undefined,
        edit: {
            editable: false,
            editId: -1
        },
        order: null,
        searchValue: ''
    };

    constructor(props) {
        super(props);
    }

    getId(pathName) {
        let path = (pathName).split('/');
        let id = path[path.length - 1];
        return id;
    }

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    changeEdit = (id) => {
        this.setState(
            {
                edit: {
                    editable: true,
                    editId: id
                }
            }
        )
    }

    closeEdit = () => {
        this.setState(
            {
                edit: {
                    editable: false,
                    editId: -1
                }
            }
        )
    }

    setOrder = (type) => {
        let order = {
            type: type,
            diraction: true
        };
        if (this.state.order && this.state.order.type == type) {
            order.diraction = !this.state.order.diraction;
        }
        this.setState({
            order: order
        })
    };

    diractionSymbol(type) {
        if (this.state.order && this.state.order.type == type) {
            return (
                <span
                    className={this.state.order.diraction ? 'asc ' : 'desc'}>{this.state.order.diraction ? 'asc ' : 'desc'}</span>
            )
        }
        return null;
    }

    sortResults = (items) => {
        const {order} = this.state;
        if (order) {
            const k = order.diraction ? 1 : -1;
            items.sort((a, b) => {
                let result = 0
                if (a[order.type] === null) {
                    a[order.type] = '';
                }
                if (b[order.type] === null) {
                    b[order.type] = '';
                }
                if (a[order.type] < b[order.type]) {
                    result = -1;
                }

                if (a[order.type] > b[order.type]) {
                    result = 1;
                }
                return result * k;
            });
        }
    };


    orderPanel = () => {
        let fields = [];
        if (this.orderFields) {
            for (let label in this.orderFields) {
                fields.push((
                    <a className="col-sm-6" onClick={() => {
                        this.setOrder(this.orderFields[label])
                    }}><h4>{label}</h4>{this.diractionSymbol(this.orderFields[label])}</a>
                ))
            }
        }
        if (fields.length) {
            return (
                <li key={-1} className={'sortable-btn'}>
                    <div className="list-group-item clear">
                        {fields}
                    </div>
                </li>
            )
        }
        return null;
    };

    addContentType = (object) => {
        console.log(object);
        ActionContentTypesAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD CONTENT TYPES',
                item: data
            });
            this.changePopupState();
            this.props.history.push('/descriptors/content_types/' + data.contenttypeid);
        });
    };

    editContentType = (object) => {
        console.log(object);
        ActionContentTypesEdit(object.contenttypeid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE CONTENT TYPE',
                item: data,
                id: data.contenttypeid
            });
            this.changePopupState();
            this.props.history.push('/descriptors/content_types/' + data.contenttypeid);
        });
    };

    redirectFunction = () => {
        this.props.history.push('/descriptors/content_types/');
    };

    render() {
        const {descTypes} = this.props;
        const {searchValue} = this.state;
        if (!descTypes.length) {
            DescriptorsTypesRequest('descriptors/content_types');
        }
        let outPutPlaces = descTypes;
        outPutPlaces = doFilter(
            outPutPlaces,
            [
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'contenttypename',
                        'contenttypenameeng'
                    ]
                }
            ]
        );

        this.sortResults(outPutPlaces);
        if (!descTypes) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <h2>Content types</h2>
                <div className={'tool-bar'}>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        redirect={this.redirectFunction}
                        viewPath={'descriptors/content_types'}
                        viewPathEdit={'descriptors/content_types/edit/'}
                        url={this.props.location.pathname}
                    />
                    <Search
                        change={this.search}
                    />
                </div>
                <ul className={'list-group'}>
                    {this.orderPanel()}
                    {(outPutPlaces ? outPutPlaces : []).map((item, index) => {
                        return (
                            <li key={index}>
                                <RemoveButton
                                    id={item.contenttypeid}
                                    descriptor={'REMOVE CONTENT TYPE'}
                                    action={ActionContentTypesDelete}
                                />
                                <EditBtn
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    change={this.setCurrentIndex}
                                    id={item.contenttypeid}
                                />
                                <Link to={api.getRoute('descriptors/content_types/' + item.contenttypeid)} onClick={
                                    () => {
                                        this.setState({
                                            rowIndex: index,
                                            currentItemIndex: item.contenttypeid
                                        });
                                    }
                                } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                    <div className={'col-sm-6'}>{item.contenttypename}</div>
                                    <div className={'col-sm-6'}>{item.contenttypenameeng}</div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                {this.state.popup ? <ContentTypePopup
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        descTypes: state.descriptorsPlaceTypes
    }
};

const DescriptorsTypesList = connect(mapStateToProps)(DescriptorsTypesListComponent);
export default DescriptorsTypesList;