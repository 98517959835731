import React from 'react';
import api from "../../../api";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Pagination from "../../../parts/Pagination";
import {
    ActionDescriptorsContractor,
    ActionTenderContactorsAdd,
    ActionTenderContactorsDelete,
    ActionTenderContactorsEdit
} from "../../../parts/actions";
import Storege from "../../../storage/storage";
import ToolButtons from "../../../parts/ToolButtons";
import Search from "../../../parts/Search";
import TenderContactorPopup from './TenderContactorPopup'
import RemoveButton from "../../../parts/removeBtn";
import CommonList from "../../../parts/CommonListClass";
import {doFilter} from "../../../parts/functions";
import EditBtn from "../../../parts/EditBtn";

const perPega = 25;

class ContractorListComponent extends CommonList {

    orderFields = {
        "Contractors" : "contrarctorname",
        "Date": "updated_at"
    };

    state = {
        statusCategories: {},
        statuses: {},
        popup: false,
        popupType: undefined,
        rowIndex: -1,
        currentItemIndex: -1,
        searchValue: '',
        order: null
    };

    constructor(props) {
        super(props);
        this.state.page = 1
    }

    paginationChange = (number) => {
        this.setState({
            page: number
        });
    };

    addContentType = (object) => {
        ActionTenderContactorsAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD TENDER CONTACTORS 44',
                item: data
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionTenderContactorsEdit(object.contractorid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE TENDER CONTACTORS 44',
                item: data,
                id: data.contractorid
            });
            this.changePopupState();
        });
    };

    render() {
        if (!this.props.contractors.length) {
            ActionDescriptorsContractor();
        }
        let {searchValue} = this.state;
        let {contractors} = this.props;
        let outPutPlaces = contractors ? contractors : [];
        outPutPlaces = doFilter(
            outPutPlaces,
            [
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'contrarctorname',
                        'contrarctornameeng'
                    ]
                }
            ]
        );

        this.sortResults(outPutPlaces);

        let maxPage = Math.ceil(outPutPlaces.length / perPega);
        outPutPlaces = outPutPlaces.slice((this.state.page - 1) * perPega, (this.state.page) * perPega);
        let pages = [];
        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }
        if (!outPutPlaces) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <h3>Contractors</h3>
                <div className={'tool-bar contactors-tool-bar'}>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                    <Search
                        change={this.search}
                    />
                </div>
                <ul className={'list-group'}>
                    {this.orderPanel()}
                    {outPutPlaces.map((item, index) => {
                        return (
                            <li key={index}>
                                <RemoveButton
                                    action={ActionTenderContactorsDelete}
                                    id={item.contractorid}
                                    descriptor={'REMOVE TENDER CONTACTORS'}
                                />
                                <EditBtn
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    change={this.setCurrentIndex}
                                    id={item.contractorid}
                                />
                                <Link
                                    to={api.getRoute('tender_descriptors/tender_contractors/contractor/' + item.contractorid)}
                                    onClick={
                                        () => {
                                            this.setState({
                                                rowIndex: index,
                                                currentItemIndex: item.contractorid
                                            });
                                        }
                                    }
                                    className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                    <div className={"col-sm-6"}>{item.contrarctorname}</div>
                                    <div className={"col-sm-6"}>{item.updated_at}</div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                <Pagination currentPage={this.state.page} pages={pages} click={this.paginationChange}
                            paginationCount={5}/>
                {this.state.popup ? <TenderContactorPopup
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        contractors: state.contractors,
    }
};

const ContractorList = connect(mapStateToProps)(ContractorListComponent);
export default ContractorList;