import React from 'react';
import {connect} from 'react-redux';
import {
    ActionSetSovereignties,
    ActionPlacesSovereigntyAdd,
    ActionPlacesSovereigntyEdit,
    ActionPlacesSovereigntyDelete,
} from "../actions";
import SelectArray from "./Statuses";
import ColumnResizer from "react-column-resizer";
import CommonTab from "../CommonTabClass";
import {getItemData} from "../functions";
import RemoveButton from "../removeBtn";
import ToolButtons from "../ToolButtons";
import EditBtn from "../EditBtn";
import SovereigntiesAdd from "./SovereigntiesAdd";
import SovereigntyChanges from "./SovereigntyChanges";



class SovereigntiesComponent extends CommonTab{

    orderFields = {
        "Territory": (a, b) => {
            const {territories} = this.state;
            let result = 0;
            if (getItemData(territories, 'territoryid', a.territoryid, 'territoryname') < getItemData(territories, 'territoryid', b.territoryid, 'territoryname')) {
                result = -1;
            }

            if (getItemData(territories, 'settlementid', a.settlementid, 'territoryname') > getItemData(territories, 'territoryid', b.territoryid, 'territoryname')) {
                result = 1;
            }
            return result
        },
        "Sovereignty": (a, b) => {
            const {sovereignties} = this.state;
            let result = 0;
            if (getItemData(sovereignties, 'territoryid', a.sovereigntyid, 'sovereigntyname') < getItemData(sovereignties, 'sovereigntyid', b.sovereigntyid, 'sovereigntyname')) {
                result = -1;
            }

            if (getItemData(sovereignties, 'settlementid', a.sovereigntyid, 'sovereigntyname') > getItemData(sovereignties, 'sovereigntyid', b.sovereigntyid, 'sovereigntyname')) {
                result = 1;
            }
            return result
        }
    };

    state = {
        place: {},
        territories: [],
        sovereignties: []
    }

    constructor(props) {
        super(props);
        this.state.place = props.place;
        this.state.territories = props.territories;
        this.state.sovereignties = props.sovereignties;
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            place: newProps.place,
            territories: newProps.territories,
            sovereignties: newProps.sovereignties
        });
    }

    changeState(name, e) {
        let temp = this.state.place;
        temp[name] = e.value;
        this.setState({place: temp});
    }

    addContentType = (object) => {
        ActionPlacesSovereigntyAdd('', object).then(data => {
            this.changePopupState();
            ActionSetSovereignties(this.state.place.placeid);
        });
    };

    editContentType = (object) => {
        ActionPlacesSovereigntyEdit(object.placesovereigntyid, object).then(data => {
            this.changePopupState();
             ActionSetSovereignties(this.state.place.placeid);
        });
    };

    DeleteContentType = (id,descriptor) => {
        ActionPlacesSovereigntyDelete(id,descriptor).then(()=>{
        	ActionSetSovereignties(this.state.place.placeid);
        });
    };

    render() {
        if (!this.state.place.PlaceSovereignties || !this.state.place.PlaceSovereigntyChanges) {
            ActionSetSovereignties(this.state.place.placeid);
        }
        let soveregnities = this.state.place.PlaceSovereignties ? this.state.place.PlaceSovereignties : [];
        let soveregnitChanges = this.state.place.PlaceSovereigntyChanges ? this.state.place.PlaceSovereigntyChanges : [];
        this.sortResults(soveregnities);

        console.log(soveregnities);
        return (
            <div>
                <div className={'custom-tool-bar'}>
                    <h4>Place Sovereignties</h4>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <table className={'table table-striped'}>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {soveregnities.map((item, index)=>{
                        return (
                            <tr key={index}>
                                <td className="edit-buttons">
                                    <div className="flex-row">
                                        <RemoveButton
                                            action={this.DeleteContentType}
                                            id={item.placesovereigntyid}
                                            descriptor={'REMOVE TENDER CONTACTOR'}
                                        />
                                        <EditBtn
                                            actionType={this.changePopupType}
                                            popupChange={this.changePopupState}
                                            change={this.setCurrentIndex}
                                            id={item.placesovereigntyid}
                                        />
                                    </div>
                                </td>
                                <td>
                                    {this.state.territories.map((territory)=>{
                                        return territory.territoryid == item.territoryid ? territory.territoryname : ''
                                    })}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {this.state.sovereignties.map((sovereignty)=>{
                                        return sovereignty.sovereigntyid == item.sovereigntyid ? sovereignty.sovereigntyname : ''
                                    })}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <SovereigntyChanges soveregnitChanges={soveregnitChanges} />
                {this.state.popup ? <SovereigntiesAdd
                    close={this.changePopupState}
                    itemList={soveregnities}
                    place = {this.props.place}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let place = {};
    state.places.forEach(function (item) {
        if (item.placeid == ownProps.match.params.id) {
            place = item;
        }
    });
    return {
        place: Object.assign({}, place),
        territories: state.territories,
        sovereignties: state.sovereignties
    }
};

const Sovereignties = connect(mapStateToProps)(SovereigntiesComponent);
export default Sovereignties;