import React from 'react';
import {connect} from 'react-redux';
import Select from "../../../parts/select";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class PlanRelationshipAddComponent extends CommonTab {

    state = {
        popupItem: {
            planid: this.props.name === 'underlying' ? this.props.planid : 0,
            underlyingplanid: this.props.name === 'underlying' ? 0 : this.props.planid
        },
        errors: [],
        validateFields: [
            (this.props.name === 'underlying') ? 'underlyingplanid' : 'planid' ]
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }
    placeChange = (value) => {
        let temp = this.state.popupItem;
        temp.popupItemid = value.target.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };
    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };
    render() {
        const {popupItem, validateFields, errors} = this.state;
        const {planRelations, name} = this.props;
        let output = [];
        for (let key in planRelations) {
            let obj = {
                planid: key,
                planname: planRelations[key]
            };
            output.push(obj);
        }
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('remarks' in popupItem)) {
                            popupItem['remarks'] = ''
                        }
                        this.formValidation(popupItem, validateFields);
                        // this.props.action(popupItem);
                    }}
                >
                    <h3>{this.props.button + (this.props.button === 'Add ' ? 'New ' : '')} Plan Relationship</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>{(name === 'underlying') ? 'Underlying' : 'Overlying'} Plan:</td>
                            <td className={errors.indexOf((name === 'underlying') ? 'underlyingplanid' : 'planid') !== -1 ? 'error' : ''}>
                                <Select
                                    name={(name === 'underlying') ? 'underlyingplanid' : 'planid'}
                                    types={output}
                                    className={"form-control"}
                                    value={popupItem[(name === 'underlying') ? 'underlyingplanid' : 'planid']}
                                    optionValue={'planid'}
                                    optionName={"planname"}
                                    onChange={(e) => {
                                        this.changeState(((name === 'underlying') ? 'underlyingplanid' : 'planid'), e.target);
                                    }}
                                />
                                {errors.indexOf(name === 'underlying' ? 'underlyingplanid' : 'planid') !== -1 ?
                                    <span className="errorText">Please set Plan</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Remarks:</td>
                            <td>
                            <input
                                    type={"text"}
                                    value={popupItem.remarks ? popupItem.remarks : ''}
                                    className={'form-control'}
                                    onChange={event => {
                                        this.changeState('remarks', event.target);
                                    }}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item['planrelationshipid'] == ownProps.id) {
            popupItem = item;
        }
    });

    return {
        planRelations: state.planRelations,
        popupItem: Object.assign({}, popupItem)
    }
};

const PlanRelationshipAdd = connect(mapStateToProps)(PlanRelationshipAddComponent);
export default PlanRelationshipAdd;
