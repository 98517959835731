import React from 'react';
import {connect} from "react-redux";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class PlanPublicationPopupComponent extends CommonTab{

    state = {
        planPublication: this.props.planPublication,
        errors: [],
        validateFields: [
            'planpublicationunittypename',
            'planpublicationunittypenameeng'
        ]
    };

    changeState(name, e) {
        let temp = this.state.planPublication;
        temp[name] = e.value;
        this.setState({planPublication: temp});
    }

    render() {
        let {planPublication, errors, validateFields} = this.state;
        return (
            <Popup openClose={this.props.openClose}>
                <div className={'popup-content-types'}>
                    <form onSubmit={event => {
                        event.preventDefault();
                        this.formValidation(planPublication, validateFields);
                    }}>
                        <h2>{this.props.button} Plan Publication Unit Type</h2>
                        <table className={'table table-striped'} align={'right'}>
                            <tbody>
                            <tr>
                                <td>Plan Publication Name :</td>
                                <td className={errors.indexOf('planpublicationunittypename') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={planPublication.planpublicationunittypename}
                                        onChange={(e) => {
                                            this.changeState('planpublicationunittypename', e.target);
                                        }}
                                    />
                                    {errors.indexOf('planpublicationunittypename') !== -1 ? <span className="errorText">Please set Plan Publication Name</span> : '' }
                                </td>
                            </tr>
                            <tr>
                                <td>Plan Publication Nameeng :</td>
                                <td className={errors.indexOf('planpublicationunittypenameeng') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={planPublication.planpublicationunittypenameeng}
                                        onChange={(e) => {
                                            this.changeState('planpublicationunittypenameeng', e.target);
                                        }}
                                    />
                                    {errors.indexOf('planpublicationunittypenameeng') !== -1 ? <span className="errorText">Please set Plan Publication Nameeng</span> : '' }
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </form>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let content = {};
    state.planPublicationType.map((item) => {
        if (item.planpublicationunittypeid == ownProps.id) {
            content = item;
        }
    });
    return {
        planPublication: Object.assign({}, content)
    }
};

const PlanPublicationPopup = connect(mapStateToProps)(PlanPublicationPopupComponent);

export default PlanPublicationPopup;