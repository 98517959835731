import React from 'react';
import {connect} from 'react-redux';
import Popup from "../../../parts/Popup";
import Select from "../../../parts/select";
import DatePicker from "react-datepicker";
import CommonTab from "../../../parts/CommonTabClass";

class PlanPublicationAddComponent extends CommonTab {
    state = {
        popupItem: {
            planid: this.props.planid ? this.props.planid : this.props.popupItem.planid
        },
        errors: [],
        validateFields: [
            'publicationdate',
            'sourceid',
            'sourcecredibiltyid'
        ]
    };

    handleChange = (date) => {
        let temp = this.state.popupItem;
        temp.publicationdate = date;
        this.setState({popupItem: temp});
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }


    placeChange = (value) => {
        let temp = this.state.popupItem;
        temp.popupItemid = value.target.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        const {popupItem, validateFields, errors} = this.state;
        const {planningPhases, sourcecredibility, source} = this.props;
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('planningphaseid' in popupItem)) {
                            popupItem['planningphaseid'] = ''
                        }
                        if (!('remarks' in popupItem)) {
                            popupItem['remarks'] = ''
                        }
                        if (!('url' in popupItem)) {
                            popupItem['url'] = ''
                        }
                        this.formValidation(popupItem, validateFields);
                    }}
                >
                    <h3>{this.props.button + (this.props.button === 'Add ' ? 'New ' : '')} Plan Publication</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Publication Date:</td>
                            <td className={errors.indexOf('publicationdate') !== -1 ? 'error' : ''}>
                                <DatePicker
                                    selected={popupItem.publicationdate ? new Date(popupItem.publicationdate) : ''}
                                    onChange={this.handleChange}
                                    //showTimeSelect
                                    timeFormat="HH:mm:s"
                                    timeIntervals={60}
                                    dateFormat="dd/MM/yyyy h:mm:s"
                                    timeCaption="time"
                                />
                                {errors.indexOf('publicationdate') !== -1 ?
                                    <span className="errorText">Please set Date</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Planing Phase:</td>
                            <td>
                                <Select
                                    id={"planning_phase"}
                                    className={"form-control"}
                                    name={"planning_phase"}
                                    value={popupItem.planningphaseid ? popupItem.planningphaseid : null}
                                    types={planningPhases}
                                    optionValue={"planningphaseid"}
                                    optionName={"planningphasename"}
                                    onChange={(e) => {
                                        this.changeState('planningphaseid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Source:</td>
                            <td className={errors.indexOf('sourceid') !== -1 ? 'error' : ''}>
                                <Select
                                    id={"source"}
                                    className={"form-control"}
                                    name={"source"}
                                    value={popupItem.sourceid ? popupItem.sourceid : null}
                                    types={source}
                                    optionValue={"sourceid"}
                                    optionName={"sourcename"}
                                    onChange={(e) => {
                                        this.changeState('sourceid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('sourceid') !== -1 ?
                                    <span className="errorText">Please set Source</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Source Credibility:</td>
                            <td className={errors.indexOf('sourcecredibiltyid') !== -1 ? 'error' : ''}>
                                <Select
                                    id={"source_credability"}
                                    className={"form-control"}
                                    name={"source_credability"}
                                    value={(popupItem.sourcecredibiltyid || popupItem.sourcecredibiltyid ===0) ? popupItem.sourcecredibiltyid : null}
                                    types={sourcecredibility}
                                    optionValue={"sourcecredibilityid"}
                                    optionName={"sourcecredibilityname"}
                                    onChange={(e) => {
                                        this.changeState('sourcecredibiltyid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('sourcecredibiltyid') !== -1 ?
                                    <span className="errorText">Please set Source Credibility</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Remarks:</td>
                            <td>
                                <input
                                    type={'text'}
                                    value={popupItem.remarks}
                                    onChange={e => {
                                        this.changeState('remarks', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>URL:</td>
                            <td>
                                <input
                                    type={'text'}
                                    value={popupItem.url}
                                    onChange={e => {
                                        this.changeState('url', e.target);
                                    }}
                                />
                                <a href={"http://" + popupItem.url} target={'_blank'}>Test</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item.planpublicationid == ownProps.id) {
            popupItem = item;
        }
    });

    return {
        source: (state.source.length) ? state.source : state.sourcesArr,
        planningPhases: state.planningPhases,
        sourcecredibility: state.sourcecredibility,
        popupItem: Object.assign({}, popupItem)
    }
};

const PlanPublicationAdd = connect(mapStateToProps)(PlanPublicationAddComponent);
export default PlanPublicationAdd;
