import React from 'react';
import {connect} from 'react-redux';
import Select from "../../../parts/select";
import Popup from "../../../parts/Popup";
import DatePicker from "react-datepicker";
import CommonTab from "../../../parts/CommonTabClass";

class TenderContractorAddComponent extends CommonTab {

    state = {
        popupItem: {
            contractorid: this.props.contractorid
        },
        errors: [],
        validateFields: [
            'winnersum', 'tenderid'
        ]
    };

    constructor(props) {
        super(props);
        this.setState({
            popupItem: Object.assign(this.state.popupItem, this.props.popupItem)
        });
    }

    placeChange = (value) => {
        let temp = this.state.popupItem;
        temp.popupItemid = value.target.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    handleChange_1 = (date) => {
        let temp = this.state.popupItem;
        temp.workstartdate = date;
        this.setState({popupItem: temp});
    };

    handleChange_2 = (date) => {
        let temp = this.state.popupItem;
        temp.workenddate = date;
        this.setState({popupItem: temp});
    };

    render() {
        const {popupItem, validateFields, errors} = this.state;
        const {tenderNames,contactors} = this.props;
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('remarks' in popupItem)) {
                            popupItem['remarks'] = ''
                        }
                        if (!('additionalsum' in popupItem)) {
                            popupItem['additionalsum'] = ''
                        }
                        if (!('tendertotalsum' in popupItem)) {
                            popupItem['tendertotalsum'] = ''
                        }
                        if (!('workenddate' in popupItem)) {
                            popupItem['workenddate'] = ''
                        }
                        if (!('workstartdate' in popupItem)) {
                            popupItem['workstartdate'] = ''
                        }
                            this.formValidation(popupItem, validateFields)
                    }}
                >
                    <h3>{this.props.button} Tender Contractor</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Contractor:</td>
                            <td className={errors.indexOf('contractorid') !== -1 ? 'error' : ''}>
                                <Select
                                    name={'contractorid'}
                                    types={contactors}
                                    className={"form-control"}
                                    value={popupItem.contractorid}
                                    optionValue={"contractorid"}
                                    optionName={"contrarctorname"}
                                    onChange={(e) => {
                                        this.changeState('contractorid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('contractorid') !== -1 ?
                                    <span className="errorText">Please set Contractor</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Tender:</td>
                            <td className={errors.indexOf('tenderid') !== -1 ? 'error' : ''}>
                                <Select
                                    name={'plan_name'}
                                    types={tenderNames}
                                    className={"form-control"}
                                    value={popupItem.tenderid}
                                    optionValue={"tenderid"}
                                    optionName={"tendername"}
                                    onChange={(e) => {
                                        this.changeState('tenderid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('tenderid') !== -1 ?
                                    <span className="errorText">Please set Tender</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Work Start Date:</td>
                            <td>
                                <DatePicker
                                    selected={popupItem.workstartdate ? new Date(popupItem.workstartdate) : ''}
                                    onChange={this.handleChange_1}
                                    //showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={60}
                                    dateFormat="dd/MM/yyyy"
                                    timeCaption="time"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Work End Date:</td>
                            <td>
                                <DatePicker
                                    selected={popupItem.workenddate ? new Date(popupItem.workenddate) : ''}
                                    onChange={this.handleChange_2}
                                    //showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={60}
                                    dateFormat="dd/MM/yyyy"
                                    timeCaption="time"
                                />
                            </td>
                        </tr>


                        <tr>
                            <td>Winner Sum:</td>
                            <td className={errors.indexOf('winnersum') !== -1 ? 'error' : ''}>
                                <input
                                    type="text"
                                    value={popupItem.winnersum ? popupItem.winnersum.replace(/\$|\.|\,/g, "") : ''}
                                    onChange={event => {
                                        if (!isNaN(event.target.value)) {
                                            this.changeState('winnersum', event.target);
                                        }
                                    }}
                                />
                                {errors.indexOf('winnersum') !== -1 ?
                                    <span className="errorText">Please set Winner Sum</span> : ''}
                            </td>
                        </tr>

                        <tr>
                            <td>Tender Total Sum:</td>
                            <td>
                                <input
                                    type="text"
                                    value={popupItem.tendertotalsum ? popupItem.tendertotalsum.replace(/\$|\.|\,/g, "") : ''}
                                    onChange={event => {
                                        if (!isNaN(event.target.value)) {
                                            this.changeState('tendertotalsum', event.target);
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Tender Aditional Sum:</td>
                            <td>
                                <input
                                    type="text"
                                    value={popupItem.additionalsum ? popupItem.additionalsum.replace(/\$|\.|\,/g, "") : ''}
                                    onChange={event => {
                                        if (!isNaN(event.target.value)) {
                                            this.changeState('additionalsum', event.target);
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Remarks:</td>
                            <td>
                                <input
                                    type="text"
                                    value={popupItem.remarks}
                                    onChange={event => {
                                        this.changeState('remarks', event.target);
                                    }}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let newItem = {};

    ownProps.itemList.forEach(function (item) {
        if (item.tendercontractorid == ownProps.id) {
            newItem = item;
            console.log(newItem);
        }
    });

    return {
        contactors: state.contractorArr,
        popupItem: Object.assign({}, newItem),
        tenderNames: state.tenders
    }
};

const TenderContractorAdd = connect(mapStateToProps)(TenderContractorAddComponent);
export default TenderContractorAdd;
