import React from 'react';
import {connect} from 'react-redux';
import Popup from "../../../../parts/Popup";
import Select from "../../../../parts/select";
import CommonTab from "../../../../parts/CommonTabClass";

class addUnitComponent extends CommonTab {
    state = {
        popupItem: {
            planpublicationid: this.props.publication
        },
        errors: [],
        validateFields: [
            'planpublicationunittypeid',
            'unit',
        ]
    };

    handleChange = (date) => {
        let temp = this.state.popupItem;
        temp.publicationdate = date;
        this.setState({popupItem: temp});
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }


    placeChange = (value) => {
        let temp = this.state.popupItem;
        temp.popupItemid = value.target.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        const {popupItem, validateFields, errors} = this.state;
        const {planPublicationType} = this.props;
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('planningphaseid' in popupItem)) {
                            popupItem['planningphaseid'] = ''
                        }
                        if (!('remarks' in popupItem)) {
                            popupItem['remarks'] = ''
                        }
                        if (!('url' in popupItem)) {
                            popupItem['url'] = ''
                        }
                        this.formValidation(popupItem, validateFields);
                    }}
                >
                    <h3>{this.props.button + (this.props.button === 'Add ' ? 'New ' : '')} Unit</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr className={errors.indexOf('planpublicationunittypeid') !== -1 ? 'error' : ''}>
                            <td>Unit Type:</td>
                            <td>
                                <Select
                                    id={"planpublicationunittypeid"}
                                    className={"form-control"}
                                    name={"planpublicationunittypeid"}
                                    value={popupItem.planpublicationunittypeid ? popupItem.planpublicationunittypeid : null}
                                    types={planPublicationType}
                                    optionValue={"planpublicationunittypeid"}
                                    optionName={"planpublicationunittypename"}
                                    onChange={(e) => {
                                        this.changeState('planpublicationunittypeid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr  className={errors.indexOf('unit') !== -1 ? 'error' : ''}>
                            <td>Units:</td>
                            <td>
                                <input
                                    type={'number'}
                                    value={popupItem.unit}
                                    onChange={e => {
                                        this.changeState('unit', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state , ownProps) => {
    let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item.planpublicationunittypedetailid == ownProps.id) {
            popupItem = item;
        }
    });
    return {
        planPublicationType: state.planPublicationType,
        popupItem : popupItem
    }
};

const AddUnit = connect(mapStateToProps)(addUnitComponent);
export default AddUnit;
