import React from 'react';
import {connect} from 'react-redux';
import Select from "../../../parts/select";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";
import DatePicker from "react-datepicker";

class TenderContractorsAddComponent extends CommonTab {

    state = {
        popupItem: {
            tenderid: this.props.tenderid
        },
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        errors: [],
        validateFields: [
            'contractorid'
        ]
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }


    placeChange = (value) => {
        let temp = this.state.popupItem;
        temp.popupItemid = value.target.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    handleChange = (name, date) => {
        let temp = this.state.popupItem;
        temp[name] = date;
        this.setState({
            popupItem: temp
        });
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        console.log('dsfsdfsdfsdfsd')
        const {popupItem, errors, validateFields} = this.state;
        const {contactors} = this.props;

        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('workstartdate' in popupItem)) {
                            popupItem['workstartdate'] = ''
                        }
                        if (!('workenddate' in popupItem)) {
                            popupItem['workenddate'] = ''
                        }
                        if (!('winnersum' in popupItem)) {
                            popupItem['winnersum'] = ''
                        }
                        if (!('tendertotalsum' in popupItem)) {
                            popupItem['tendertotalsum'] = ''
                        }
                        if (!('additionalsum' in popupItem)) {
                            popupItem['additionalsum'] = ''
                        }
                        if (!('remarks' in popupItem)) {
                            popupItem['remarks'] = ''
                        }
                        this.formValidation(popupItem, validateFields);
                    }}
                >
                    <h3>{this.props.button} Tender Contractor</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Contractor:</td>
                            <td className={errors.indexOf('contractorid') !== -1 ? 'error' : ''}>
                                <Select
                                    name={'contractorid'}
                                    types={contactors}
                                    className={"form-control"}
                                    value={popupItem.contractorid}
                                    optionValue={"contractorid"}
                                    optionName={"contrarctorname"}
                                    onChange={(e) => {
                                        this.changeState('contractorid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('contractorid') !== -1 ?
                                    <span className="errorText">Please set Contractor</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Work Start Date:</td>
                            <td>
                                <DatePicker
                                    selected={popupItem.workstartdate ? new Date(popupItem.workstartdate) : ''}
                                    onChange={(data)=>{
                                        this.handleChange('workstartdate',data)
                                    }}
                                    //showTimeSelect
                                    timeFormat="HH:mm:s"
                                    timeIntervals={60}
                                    dateFormat="dd/MM/yyyy h:mm:s"
                                    timeCaption="time"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Work End Date:</td>
                            <td>
                                <DatePicker
                                    selected={popupItem.workenddate ? new Date(popupItem.workenddate) : ''}
                                    onChange={(data)=>{
                                        this.handleChange('workenddate',data)
                                    }}
                                    //showTimeSelect
                                    timeFormat="HH:mm:s"
                                    timeIntervals={60}
                                    dateFormat="dd/MM/yyyy h:mm:s"
                                    timeCaption="time"
                                />
                            </td>
                        </tr>



                        <tr>
                            <td>Winner Sum:</td>
                            <td>
                                <input
                                    className={'form-control'}
                                    type={'number'}
                                    value={popupItem.winnersum ? popupItem.winnersum.replace('$','').replace(',','') : ''}
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        this.changeState('winnersum', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Tender Total sum:</td>
                            <td>
                                <input
                                    className={'form-control'}
                                    type={'number'}
                                    value={popupItem.tendertotalsum ? popupItem.tendertotalsum.replace('$','').replace(',','')  : ''}
                                    onChange={(e) => {
                                        this.changeState('tendertotalsum', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Additional Sum:</td>
                            <td>
                                <input
                                    className={'form-control'}
                                    type={'number'}
                                    value={popupItem.additionalsum ? popupItem.additionalsum.replace('$','').replace(',','')  : ''}
                                    onChange={(e) => {
                                        this.changeState('additionalsum', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Remarks:</td>
                            <td>
                                <input
                                    className={'form-control'}
                                    type={'text'} value={popupItem.remarks ? popupItem.remarks : ''}
                                    onChange={(e) => {
                                        this.changeState('remarks', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        </tbody>

                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item.tendercontractorid == ownProps.id) {
            popupItem = item;
        }
    });

    return {
        contactors: state.contractorArr,
        popupItem: Object.assign({}, popupItem)
    }
};

const TenderContractorsAdd = connect(mapStateToProps)(TenderContractorsAddComponent);
export default TenderContractorsAdd;
