import React from 'react';
import {connect} from 'react-redux';
import api from "../../../api";
import TabsRow from "../../../parts/tabs-row";
import {Route} from "react-router-dom";
import SingleSourcePlan from './single_source_plan'
import SingleSourceTender from './single_source_tender'


class SourceTabsRowComponent extends React.Component {
    state = {
        initiators: {},
        activeTabName : ''
    }

    constructor(props) {
        super(props);
        this.state.initiators = props.initiators
    }

    getActiveTab = (tab) => {
        this.setState = {
            activeTabName: tab,
        }
    }

    render() {
        this.getActiveTab(this.props.match.params.tabName);
        let categoryId = this.props.match.params.id_category;
        let id = this.props.match.params.id;
        const SourceTabs = [
            {
                tabName: 'Plan Publication',
                tabEnd: 'plan_publications',
                tabUrl: api.getRoute('sources/category/' + categoryId +'/source/plan_publications/')
            },
            {
                tabName: 'Tender Publications',
                tabEnd: 'tender_publications',
                tabUrl: api.getRoute('sources/category/' + categoryId +'/source/tender_publications/')
            },
        ];
        return (
            <div>
                <TabsRow
                    tabs={SourceTabs}
                    id={this.props.match.params.id}
                    path={this.props.location.pathname}
                    id_category = {this.props.match.params.id_category}
                />
                <Route path={api.getRoute('sources/category/' + categoryId +'/source/plan_publications/')}
                       render={(props) => <SingleSourcePlan {...props} id={this.props.match.params.id} id_category={this.props.match.params.id_category}/> }
                />
                <Route path={api.getRoute('sources/category/' + categoryId +'/source/tender_publications/')}
                       render={(props) => <SingleSourceTender {...props} id={this.props.match.params.id} id_category={this.props.match.params.id_category}/> }
                />
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        initiators: state.initiatorsByType.initiators
    }
};

const SourceTabsRow = connect(mapStateToProps)(SourceTabsRowComponent)
export default SourceTabsRow;
