import React from 'react';
import api from "../../api";
import Storege from "../../storage/storage";

const TendersRequest = (route) => {
    fetch(api.ajaxUrl + route, {
        method: "POST",
        headers: {
            "Content-type": "application/json",
            "X-CSRF-TOKEN": api.token
        }
    }).then((response) => response.json()).then(function (data) {
        console.log(data);
        Storege.dispatch({
            type: 'SET TENDERS',
            items: data.tenders
        });
        Storege.dispatch({
            type: 'SET TENDERS PLACES',
            items: data.places
        });
        Storege.dispatch({
            type: 'SET TENDERS TYPES',
            items: data.tenderTypes
        });
        Storege.dispatch({
            type: 'SET TENDERS PURPOSES',
            items: data.tenderPurpose
        });
        Storege.dispatch({
            type: 'SET TENDERS INITIATOR',
            items: data.initiator
        });
        Storege.dispatch({
            type: 'SET TENDERS LADUSES',
            items: data.allLanduses
        });
        Storege.dispatch({
            type: 'SET TENDERS CONTACTORS',
            items: data.contractors
        });
        Storege.dispatch({
            type: 'SET TENDERS TENDER PUBLIC PURP',
            items: data.tenderPublicationPurpose
        });
        Storege.dispatch({
            type: 'SET TENDERS TENDER PUBLIC SOURCE ARR',
            items: data.sourcesArr
        });
        Storege.dispatch({
            type: 'SET TENDERS SOURCE CREDIBILITY',
            items: data.sourcecredibility
        });
        Storege.dispatch({
            type: 'SET TENDERS PLANS',
            items: data.plansArr
        });
    });
};

export default TendersRequest;