import React from 'react';
import {connect} from "react-redux";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class TenderTypesPopupComponent extends CommonTab {
    state = {
        tenderType: this.props.tenderType,
        errors: [],
        validateFields: [
            'tendertypename',
            'tendertypenameeng'
        ]
    };

    changeState(name, e) {
        let temp = this.state.tenderType;
        temp[name] = e.value;
        this.setState({tenderType: temp});
    }

    render() {
        let {tenderType, errors, validateFields} = this.state;
        let {close} = this.props;
        return (
            <Popup openClose={close}>
                <div className={'popup-content-types'}>
                    <form onSubmit={event => {
                        event.preventDefault();
                        this.formValidation(tenderType, validateFields);
                    }}>
                        <h2>{this.props.button} Tender Type</h2>
                        <table className={'table table-striped'} align={'right'}>
                            <tbody>
                            <tr>
                                <td>Tender Type Name :</td>
                                <td className={errors.indexOf('tendertypename') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={tenderType.tendertypename}
                                        onChange={(e) => {
                                            this.changeState('tendertypename', e.target);
                                        }}
                                    />
                                    {errors.indexOf('tendertypename') !== -1 ? <span className="errorText">Please set Tender Type Name</span> : '' }
                                </td>
                            </tr>

                            <tr>
                                <td>Tender Type Name Eng :</td>
                                <td className={errors.indexOf('tendertypenameeng') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={tenderType.tendertypenameeng}
                                        onChange={(e) => {
                                            this.changeState('tendertypenameeng', e.target);
                                        }}
                                    />
                                    {errors.indexOf('tendertypenameeng') !== -1 ? <span className="errorText">Please set Tender Type Name Eng</span> : '' }
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </form>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let content = {};
    state.tendersTypes.map((item) => {
        if (item.tendertypeid == ownProps.id) {
            content = item;
        }
    });
    return {
        tenderType: Object.assign({}, content)
    }
};

const TenderTypesPopup = connect(mapStateToProps)(TenderTypesPopupComponent);

export default TenderTypesPopup;