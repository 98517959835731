import React from 'react';
import ContractorList from "./contactors_list";
import {Route,Switch} from 'react-router-dom';
import api from "../../../api";
import ContractorSingle from "./contractor_single";

class ContractorDescriptor extends React.Component {

    render() {
        return (
            <div className="row">
                <div className="col-sm-4">
                    <Route path={api.getRoute('tender_descriptors/tender_contractors')}
                           component={ContractorList}/>
                </div>
                <div className="col-sm-8">
                <Switch>
	                <Route path={api.getRoute('tender_descriptors/tender_contractors/contractor/:id')}
	                       component={ContractorSingle}/>
	                <Route render={() => (<h2 className={'selectItem'}>Please select an item from the list</h2>)}/>
                </Switch>
                </div>
            </div>
        )
    }
}

// const mapStateToProps = (state) => {
//     return {
//         tenderPublicationCost: state.tenderPublicationCost
//     }
// };
//const DescriptorTenderPublicationCost = connect(mapStateToProps)(TenderPublicationCostDescriptorComponent);

export default ContractorDescriptor;