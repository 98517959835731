import React from 'react';

class ExportExcel extends React.Component {
    render() {
        return (
            <div id={'export-excel'}>
                <form
                    onSubmit={(e)=>{
                        e.preventDefault();
                    }}
                >
                    <h4>Filters for export:</h4>
                    <input
                        type={'submit'}
                        value={'Export Data'}
                        className={'btn btn-primary'}
                    />
                </form>
            </div>
        )
    }
}

export default ExportExcel;