import React from 'react';
import {connect} from "react-redux";
import Popup from "../../../parts/Popup";

class CommunityTypePopupComponent extends React.Component {
    state = {
        communityType: {}
    };

    constructor(props) {
        super(props);
        this.state = {
            communityType: this.props.communityType ? this.props.communityType : {}
        }
    }

    changeState(name, e) {
        let temp = this.state.communityType;
        temp[name] = e.value;
        this.setState({communityType: temp});
    }

    render() {
        console.log(this.props);
        let {communityType} = this.state;
        let {close} = this.props;
        console.log(close);
        return (
            <Popup openClose={close}>
                <div className={'popup-content-types'}>
                    <form onSubmit={event => {
                        event.preventDefault();
                        this.props.action(communityType);
                    }}>
                        <h2>{this.props.button + (this.props.button === 'Add' ? ' New' : '')} Place Community Type</h2>
                        <table className={'table table-striped'} align={'right'}>
                            <tbody>
                            <tr>
                                <td>Place Community Type Name :</td>
                                <td>
                                    <input
                                        type={'text'}
                                        value={communityType.placecommunitytypename}
                                        onChange={(e) => {
                                            this.changeState('placecommunitytypename', e.target);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Place Community Type Name Eng :</td>
                                <td>
                                    <input
                                        type={'text'}
                                        value={communityType.placecommunitytypenameeng}
                                        onChange={(e) => {
                                            this.changeState('placecommunitytypenameeng', e.target);
                                        }}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </form>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let content = {};
    state.communityType.map((item) => {
        if (item.placecommunitytypeid == ownProps.id) {
            content = item;
        }
    });
    return {
        communityType: Object.assign({}, content)
    }
};

const CommunityTypePopup = connect(mapStateToProps)(CommunityTypePopupComponent);

export default CommunityTypePopup;