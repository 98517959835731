import React from 'react';
import {connect} from 'react-redux';
import SelectObject from "../../parts/SelectObject";
import Popup from "../../parts/Popup";
import CommonTab from "../../parts/CommonTabClass";

class GeneralDataAddComponent extends CommonTab {
    state = {
        category: {},
        errors: [],
        validateFields: [
            'generalcategoryname',
            'generalcategorynameeng',
            'generalunitid',
            'generalsourceid'
        ]
    };

    constructor(props) {
        super(props);
        this.state.category = props.category;
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if (oldProps.category.generalcategoryid !== newProps.category.generalcategoryid) {
            this.setState({category: newProps.category})
        }
    }

    changeState = (name, e) => {
        let temp = this.props.category;
        temp[name] = e.value;
        this.setState({
            category: Object.assign({}, temp)
        });
    };

    changeValue = (name, e, id) => {
        let temp = this.props.category.values;
        temp.forEach(function (item) {
            if (item.generalvalueid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            category: Object.assign({}, temp)
        });
    };

    selectChange = (name, e) => {
        let temp = this.props.category;
        temp[name] = +e.value;
        this.setState({
            category: Object.assign({}, temp)
        });
    };

    render() {
        let {category, source, unit} = this.props;
        let {errors, validateFields} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <form onSubmit={event => {
                    event.preventDefault();
                    if (!('description' in category)) {
                        category['description'] = ''
                    }
                    this.formValidation(category, validateFields);
                    // this.props.action(this.state.category);
                }}>
                    <h2>{this.props.button} General Category</h2>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Name:</td>
                            <td>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'generalcategoryname'}
                                       value={category.generalcategoryname}
                                       onChange={(e) => {
                                           this.changeState('generalcategoryname', e.target);
                                       }}
                                />
                                {errors.indexOf('generalcategoryname') !== -1 ?
                                    <span className="errorText">Please set Category Name</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Name Eng:</td>
                            <td>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'generalcategorynameeng'}
                                       value={category.generalcategorynameeng}
                                       onChange={(e) => {
                                           this.changeState('generalcategorynameeng', e.target);
                                       }}
                                />
                                {errors.indexOf('generalcategorynameeng') !== -1 ?
                                    <span className="errorText">Please set Category Name Eng</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Unit:</td>
                            <td>
                                <SelectObject
                                    name={'generalunitid'}
                                    value={category.generalunitid}
                                    types={unit}
                                    change={(e) => {
                                        this.selectChange('generalunitid', e.target);
                                    }}
                                />
                                {errors.indexOf('generalunitid') !== -1 ?
                                    <span className="errorText">Please set Unit</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Source:</td>
                            <td>
                                <SelectObject
                                    name={'generalsourceid'}
                                    value={category.generalsourceid}
                                    types={source}
                                    change={(e) => {
                                        this.selectChange('generalsourceid', e.target);
                                    }}
                                />
                                {errors.indexOf('generalsourceid') !== -1 ?
                                    <span className="errorText">Please set Source</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Description:</td>
                            <td>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'data-category-description'}
                                       value={category.description}
                                       onChange={(e) => {
                                           this.changeState('description', e.target);
                                       }}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let category = {};

    state.generalData.forEach(function (item) {
        if (item.generalcategoryid == ownProps.id) {
            category = item;
        }
    });

    return {
        category: Object.assign({}, category),
        source: state.generalDataSources,
        unit: state.generalDataUnits
    }
};

const GeneralDataAdd = connect(mapStateToProps)(GeneralDataAddComponent);

export default GeneralDataAdd;