import React from 'react';
import {connect} from 'react-redux';
import Select from "../../../parts/select";
import {
    ActionSourceTenderPublicationAdd, ActionSourceTenderPublicationDelete, ActionSourceTenderPublicationEdit,
    ActionTenderPublications
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import CommonTab from "../../../parts/CommonTabClass";
import {getItemData} from "../../../parts/functions";
import PlansRequest from "../../plans/PlansRequest";
import TendersRequest from "../../tenders/TendersRequest";

class TenderPublicationsComponent extends CommonTab {
    orderFields = {
        "Publication Date": 'publicationdate',
        "Tender Publication Purpose": 'tenderpublicationpurposeid',
        "Source": 'sourceid',
        "Source Credibility": 'sourcecredibiltyid',
        "Plan": "planid",
        "Lot": "lot",
        "Remarks": 'remarks',
        "URL": 'url'
    };

    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            popupType: undefined,
            currentItemIndex: -1,
            rowIndex: -1,
            order: null,
            tab : 0
        }
    }

    getFullDateYear(date) {
        let newDate = new Date(date);
        let Year = newDate.getFullYear();
        let Month = newDate.getMonth();
        let Day = newDate.getDate();
        //let dateString = Year + '-' + (Month < 10 ? '0' + Month : Month) + '-' + (Day < 10 ? '0' + Day : Day);
        let dateString = (Day < 10 ? '0' + Day : Day) + '-' + (Month < 10 ? '0' + Month : Month) + '-' + Year   ;
        return dateString;
    };

    render() {
        const {plans, tenderPublicPurp, tenderSourceArr, sourcecredibility, tendersPlacesArr,items} = this.props;
        if (!plans.length) {
            PlansRequest('plans');
        }
        if (!this.props.tenderPublicPurp.length) {
            TendersRequest('tenders');
        }
        let publications = items ? items : [];
        this.sortResults(publications);

        return (
            <div>
                <div className={'custom-tool-bar'}>
                    <h3>Tender Publications</h3>
                </div>
                <div className={'scrolled'}>
                    <table className={'table table-striped publication-table'}>
                        <thead>
                        {this.orderPanel()}
                        </thead>
                        <tbody>
                        {publications.map((item, index) => {
                            return (
                                <tr key={index} onClick={
                                    () => {
                                        this.setState({
                                            rowIndex: index,
                                            currentItemIndex: item.tenderpublicationid
                                        });
                                    }
                                } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                    <td>

                                    </td>
                                    <td>{item.publicationdate ? this.getFullDateYear(item.publicationdate) : ''}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{tenderPublicPurp[item.tenderpublicationpurposeid]}</td>
                                    {/*<td>{getItemData(tenderPublicPurp ? tenderPublicPurp : [], 'tenderpurposeid', item.tenderpublicationpurposeid, 'tenderpurposename')}</td>*/}
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{getItemData(tenderSourceArr ? tenderSourceArr : [], 'sourceid', item.sourceid, 'sourcename')}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{getItemData(sourcecredibility ? sourcecredibility : [], 'sourcecredibilityid', item.sourcecredibiltyid, 'sourcecredibilityname')}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{getItemData(plans ? plans : [], 'planid', item.planid, 'planname')}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{item.lot ? item.lot : ''}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{item.remarks ? item.remarks : ''}</td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>{item.url ? item.url : ''}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        plans: state.plans,
        tenderPublicPurp: state.tenderPublicationPurpose,
        tenderSourceArr: state.sourcesArr,
        sourcecredibility: state.sourcecredibility,
        tendersPlacesArr: state.tendersPlacesArr,
    }
};

const TenderPublications = connect(mapStateToProps)(TenderPublicationsComponent);

export default TenderPublications;
