import React from 'react';
import {connect} from 'react-redux';
import {
    ActionPlacesGeometryAdd,
    ActionPlacesGeometryDelete,
    ActionPlacesGeometryEdit,
    ActionSetGeometries
} from "../actions";
import ColumnResizer from "react-column-resizer";
import CommonTab from "../CommonTabClass";
import RemoveButton from "../removeBtn";
import EditBtn from "../EditBtn";
import Storege from "../../storage/storage";
import ToolButtons from "../ToolButtons";
import GeometryAdd from "./GeometryAdd";

class GeometryComponent extends CommonTab {

    state = {
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        order: null
    };

    orderFields = {
        "Create Date": 'createdate',
        "Shape Wkt": 'shapewkt',
        "Shape WGS84 Wkt": 'shapewgs84wkt',
        "Shape Area": 'shapearea',
        "Shape Length": 'shapelength',
        "Point Wkt": 'shapepointwkt',
        "Point WGS84 Wkt": 'shapewgs84pointwkt',
        "Label Wkt": 'labelpointwkt',
        "Label WGS84 Wkt": 'labelpointwgs84wkt',
    };

    addContentType = (object) => {
        ActionPlacesGeometryAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLACE GEOMETRY',
                item: data,
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionPlacesGeometryEdit(object.placegeometryid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLACE GEOMETRY',
                item: data,
                id: data.placegeometryid,
                placeid : object.placeid
            });
            this.changePopupState();
        });
    };





    render() {
        const {place} = this.props;
        if (!place.geometriesList) {
            ActionSetGeometries(place.placeid);
        }
        let geometryList = place.geometriesList ? place.geometriesList : {};
        this.sortResults(geometryList);
        return (
            <React.Fragment>
                <div className={'inline-toolbuttons'}>
                    <h3>Geometry</h3>
                    {geometryList.placegeometryid ?
                        ''
                        :
                        <ToolButtons
                            actionType={this.changePopupType}
                            popupChange={this.changePopupState}
                            id={this.state.currentItemIndex}
                        />
                    }
                </div>
                <div className="scrolled ">
                    <table className={'table table-striped geometry-table geometry-simple'}>
                        <thead>
                        {this.orderPanel()}
                        </thead>
                        <tbody>
                        {geometryList.placegeometryid ?
                            <tr>
                            <td className={'flex-row'}>
                                <RemoveButton
                                    action={ActionPlacesGeometryDelete}
                                    id={geometryList.placegeometryid}
                                    descriptor={'REMOVE PLACE GEOMETRY'}
                                />
                                <EditBtn
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    change={this.setCurrentIndex}
                                    id={geometryList.placegeometryid}
                                />
                            </td>
                            <td>{geometryList.createdate}</td>
                            <ColumnResizer className="columnResizer" minWidth={5}/>
                            <td>{geometryList.shapewkt}</td>
                            <ColumnResizer className="columnResizer" minWidth={5}/>
                            <td>{geometryList.shapewgs84wkt}</td>
                            <ColumnResizer className="columnResizer" minWidth={5}/>
                            <td>{geometryList.shapearea}</td>
                            <ColumnResizer className="columnResizer" minWidth={5}/>
                            <td>{geometryList.shapelength}</td>
                            <ColumnResizer className="columnResizer" minWidth={5}/>
                            <td>{geometryList.shapepointwkt}</td>
                            <ColumnResizer className="columnResizer" minWidth={5}/>
                            <td>{geometryList.shapewgs84pointwkt}</td>
                            <ColumnResizer className="columnResizer" minWidth={5}/>
                            <td>{geometryList.labelpointwkt}</td>
                            <ColumnResizer className="columnResizer" minWidth={5}/>
                            <td>{geometryList.labelpointwgs84wkt}</td>
                        </tr>
                            :''}
                        </tbody>
                    </table>
                </div>

                {this.state.popup ? <GeometryAdd
                    placeid={place.placeid}
                    close={this.changePopupState}
                    itemList={place.statusList}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {}
};

const Geometry = connect(mapStateToProps)(GeometryComponent);
export default Geometry;