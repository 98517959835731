import React from 'react';
import {
    ActionSetTracking,
    ActionPlacesTrackingAdd,
    ActionPlacesTrackingEdit,
    ActionPlacesTrackingDelete,
    ActionTenderPublicationCostAdd,
    ActionTenderPublications,
    ActionTenderPublicationCostEdit, ActionTenderPublicationCostDelete,
} from "../actions";
import {connect} from "react-redux";
import getFullDateYear from "../functions";
import ColumnResizer from "react-column-resizer";
import CommonTab from "../CommonTabClass";
import {getItemData} from "../functions";
import ToolButtons from "../ToolButtons";
import TrackingAdd from "./TrackingAdd";
import RemoveButton from "../removeBtn";
import EditBtn from "../EditBtn";


class TrackingComponent extends CommonTab {

    state = {
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        order: null
    };

    orderFields = {
        "Tracking Date": 'trackingdate',
        "Tracking Type": 'trackingtypeid',
        "Tracking Value Type": 'trackingvaluetypeid',
        "Tracking Value": 'trackingvalue',
        "Tracking Is Realative": '',
        "Tracking Remarks": 'trackingid'
    };

    addContentType = (object) => {
        ActionPlacesTrackingAdd('', object).then(data => {
            this.changePopupState();
           ActionSetTracking(this.props.place.placeid);
        });
    };

    editContentType = (object) => {
       ActionPlacesTrackingEdit(object.trackingid, object).then(data => {
            this.changePopupState();
            ActionSetTracking(this.props.place.placeid);
        });
    };

    DeleteContentType = (id,descriptor) => {
       ActionPlacesTrackingDelete(id,descriptor).then(()=>{
        	ActionSetTracking(this.props.place.placeid);
        });
    };

    render() {
        const {place, trackingTypes, trackingValueTypes} = this.props;
        const {tracking} = place;
        if (!place.tracking) {
            ActionSetTracking(place.placeid);
        }

        this.sortResults(tracking);
        return (
            <div className={'scrolled'}>
                <div className="inline-toolbuttons">
                <h4>Trackings</h4>
                 <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <table className={'table table-striped'}>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {(tracking ? tracking : []).map((item, index) => {
                        return (
                            <tr key={index}>
                                <td  className={'flex-row'}>
                                    <RemoveButton
                                        descriptor={'REMOVE PLAN PUBLICATION'}
                                        action={this.DeleteContentType}
                                        id={item.trackingid}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.trackingid}
                                    />
                                </td>
                                <td>
                                    {item.trackingdate}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {getItemData(trackingTypes,'trackingtypeid', item.trackingtypeid, 'trackingtypename' )}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {getItemData(trackingValueTypes,'trackingvaluetypeid', item.trackingvaluetypeid, 'trackingvaluetypename' )}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.trackingvalue ? item.trackingvalue : ''}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {getItemData(trackingTypes,'trackingtypeid', item.trackingtypeid, 'isrelativevalue' ) ? 'yes' : 'no'}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                   {item.remarks ? item.remarks : ''}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <TrackingAdd
                    close={this.changePopupState}
                    itemList={tracking}
                    placeid = {place.placeid}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    trackingTypes: state.trackingTypes,
    trackingValueTypes: state.trackingValueTypes
});

const Tracking = connect(mapStateToProps)(TrackingComponent);

export default Tracking;

