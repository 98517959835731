import React from 'react';
import api from "../../api";
import Storege from "../../storage/storage";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Pagination from "../../parts/Pagination";
import PlansRequest from "./PlansRequest";
import Filter from "../../parts/Filters";
import Search from "../../parts/Search";
import Popup from "../../parts/Popup";
import UploadExcel from "../../parts/UploadExcel";
import ExportExcel from "../../parts/ExportExcel";
import ToolButtons from "../../parts/ToolButtons";
import getFullDateYear, {doFilter} from "../../parts/functions";
import {ActionPlanAdd, ActionPlanDelete, ActionPlanEdit} from "../../parts/actions";
import PlanAdd from "./PlansAdd";
import RemoveButton from "../../parts/removeBtn";
import CommonList from "../../parts/CommonListClass";
import EditBtn from "../../parts/EditBtn";

const perPega = 25;

class PlansListComponent extends CommonList {
    orderFields = {
        "Title": 'planname',
        "Date": 'updatedate'
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            // page: this.getCurrentPage(),
            items: nextProps.items
        });
    }

    addContentType = (object) => {
        ActionPlanAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLAN',
                item: data
            });
            this.changePopupState();
            this.props.history.push(api.getRoute('plans/plan-details/plan/' + data.planid));
        });
    };

    editContentType = (object) => {
        ActionPlanEdit(object.planid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLAN',
                item: data,
                id: data.planid
            });
            this.changePopupState();
        });
    };

    changeImportPopupState = () => {
        let currentPopupState = this.state.import_popup;
        this.setState(
            {
                import_popup: !currentPopupState
            }
        );
        if(this.state.file_loaded){
            window.location.reload();
        }
    };

    changeExportPopupState = () => {
        let currentPopupState = this.state.export_popup;
        this.setState(
            {
                export_popup: !currentPopupState
            }
        );
        if(this.state.file_loaded){
            window.location.reload();
        }
    };

    afterLoading = () => {
        this.setState(
            {
                file_loaded: true
            }
        );
    };

    render() {
        const {searchValue, items, valuePlaceTypesFilter} = this.state;
        if (!this.props.items.length) {
            PlansRequest('plans');
        }
        let outPutPlaces = items;
        outPutPlaces = doFilter(
            items,
            [
                {
                    name: 'valuePlaceTypesFilter',
                    value: valuePlaceTypesFilter,
                    searchId: 'placeid'
                },
                {
                    name: 'searchValue',
                    value: searchValue,
                    searchId: [
                        'planname'
                    ],
                    additionalFilter: (item, str) => {
                        return this.props.plansPlaces[item.placeid] && this.props.plansPlaces[item.placeid].toLowerCase().indexOf(str) != -1;
                    }
                }
            ]
        );

        this.sortResults(outPutPlaces);
        let maxPage = Math.ceil(outPutPlaces.length / perPega);
        outPutPlaces = outPutPlaces.slice((this.state.page - 1) * perPega, (this.state.page) * perPega);
        let pages = [];
        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }
        let arrayForFillters = [];
        if (!arrayForFillters.length) {
            for (let key in this.props.plansPlaces) {
                let singleObject = {};
                singleObject.placeid = key;
                singleObject.placename = this.props.plansPlaces[key];
                arrayForFillters.push(singleObject);
            }
        }
        var path = this.props.location.pathname.split('/');
        var activeTab = 'plan-details';
        if (path.length > 3) {
            activeTab = path[2]
        }
        if (!items.length) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <h2>Plans</h2>
                <button className={"import-excel-button btn btn-primary"} onClick={() => {
                    this.setState({
                        import_popup: true,
                    });
                }
                }>Import
                </button>
                {this.state.import_popup ?
                    <Popup openClose={this.changeImportPopupState}
                           key={"import_plans_publications"}
                           children={<UploadExcel
                               title={"Upload Plans Table"}
                               route={"import_plans_publications"}
                               afterLoading={this.afterLoading}
                           />}
                    />
                    : ''}
                <button className={"export-excel-button btn btn-primary"} onClick={() => {
                    this.setState({
                        export_popup: true,
                    });
                }
                }>Export
                </button>
                {this.state.export_popup ?
                    <Popup openClose={this.changeExportPopupState}
                           key={"export_plans_publications"}
                           children={<ExportExcel
                               title={"Export data"}
                               route={"export_plans_publications"}
                           />}
                    /> : ''}
                <div className={'tool-bar'}>
                    <div id={'filters-wrapper'}>
                        <Filter
                            filterElemens={arrayForFillters}
                            filterName={'filter_plans'}
                            value={this.state.valuePlaceTypesFilter}
                            optionValue={'placeid'}
                            optionName={'placename'}
                            filterValue={'valuePlaceTypesFilter'}
                            change={this.filter}
                            reset={this.resetFilter}
                            resetBtnName={'Clear'}
                            title={'Place Filter:'}
                        />
                    </div>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                    <Search
                        change={this.search}
                    />
                </div>
                <ul className={'list-group'}>
                    {this.orderPanel()}
                    {outPutPlaces.map((item, index) => {
                        return (
                            <li key={index}>
                                <RemoveButton
                                    action={ActionPlanDelete}
                                    id={item.planid}
                                    descriptor={'REMOVE PLAN'}
                                />
                                <EditBtn
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    change={this.setCurrentIndex}
                                    id={item.planid}
                                />
                                <Link to={api.getRoute('plans/' + activeTab + '/plan/' + item.planid)} onClick={
                                    () => {
                                        this.setState({
                                            rowIndex: index,
                                            currentItemIndex: item.planid
                                        });
                                    }
                                } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                    <div className="col-sm-6">
                                        {item.planname} | {this.props.plansPlaces[item.placeid]}
                                    </div>
                                    <div className="col-sm-6">
                                        <span>{item.updatedate ? getFullDateYear(item.updatedate) : '01.01.1970'}</span>
                                    </div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                <Pagination currentPage={this.state.page} pages={pages} click={this.paginationChange}
                            paginationCount={5}/>
                {this.state.popup ? <PlanAdd
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        items: state.plans,
        plansPlaces: state.plansPlaces
    }
};

const PlansList = connect(mapStateToProps)(PlansListComponent);
export default PlansList;
