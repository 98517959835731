import React from 'react';

let Tenders = function (store = [], event) {
    if (event.type == 'SET TENDERS') {
        store = event.items;
    }

    if(event.type == 'UPDATE TENDERS') {
        store.push(event.item);
    }

    if(event.type == 'REMOVE TENDER'){
        store = store.filter(item => {
            return item.tenderid != event.id
        });
    }

    if (event.type == 'ADD TENDER') {
        store.push(event.item);
    }

    if(event.type == 'UPDATE TENDER'){
        return store.map(function (item) {
            if (item.tenderid == event.id) {
                let item1 = Object.assign({}, event.item);
                return item1;
            }
            return item;
        });
    }

    if(event.type == 'UPDATE TENDER LANDUSES'){
        return store.map(function (item) {
            if (item.tenderid == event.id) {
                let item1 = Object.assign({}, item, {
                    landusArray: event.items
                });
                return item1;
            }
            return item;
        });
    }

    if(event.type == 'UPDATE TENDER PUBLICATIONS'){
        return store.map(function (item) {
            if (item.tenderid == event.id) {
                let item1 = Object.assign({}, item, {
                    tenderPublications: event.items.tenderPublications,
                    tenderPublicationsDetails: event.items.tenderPublicationsDetails
                });
                return item1;
            }
            return item;
        });
    }

    if(event.type == 'UPDATE TENDER CONTACTORS'){
        return store.map(function (item) {
            if (item.tenderid == event.id) {
                let item1 = Object.assign({}, item, {
                    contactors: event.items
                });
                return item1;
            }
            return item;
        });
    }

    if(event.type == 'UPDATE TENDER RELATIONSHIPS'){
        return store.map(function (item) {
            if (item.tenderid == event.id) {
                let item1 = Object.assign({}, item, {
                    tenderRealationships: event.items
                });
                return item1;
            }
            return item;
        });
    }


///////////////
    if (event.type === 'ADD TENDER LANDUSE') {
        return store.map(function (item) {
            if (item.tenderid == event.item.tenderid) {
                item.landusArray.push(event.item);
            }
            return item;
        });
    }

    if (event.type === 'REMOVE TENDER LANDUSE') {
        return store.map(function (item) {
            if (item.tenderid == event.id.tenderid) {
                item.landusArray = item.landusArray.filter(statusItem => {
                    return statusItem.tenderlanduseid !== event.id.tenderlanduseid;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }


    if (event.type === 'UPDATE TENDER LANDUSE') {
        return store.map(function (item) {
            if (item.tenderid == event.item.tenderid) {
                item.landusArray = item.landusArray.map(statusItem => {
                    if (statusItem.tenderlanduseid === event.item.tenderlanduseid) {
                        let editedItem = Object.assign({}, event.item);
                        return editedItem;
                    }
                    return statusItem;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }
    //////////////////

    if (event.type === 'ADD TENDER CONTACTOR') {
        console.log(store);
        console.log(event);
        return store.map(function (item) {
            if (item.tenderid == event.item.tenderid) {
                item.contactors.push(event.item);
            }
            return item;
        });
    }

    if (event.type === 'REMOVE TENDER CONTACTOR') {
        console.log(event);
        console.log(store);
        return store.map(function (item) {
            if (item.tenderid == event.id.tenderid) {
                console.log(item.contactors);
                item.contactors = item.contactors.filter(statusItem => {
                    return statusItem.tendercontractorid !== event.id.tendercontractorid;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }


    if (event.type === 'UPDATE TENDER CONTACTOR') {
        return store.map(function (item) {
            if (item.tenderid == event.item.tenderid) {
                item.contactors = item.contactors.map(statusItem => {
                    if (statusItem.tendercontractorid === event.item.tendercontractorid) {
                        let editedItem = Object.assign({}, event.item);
                        return editedItem;
                    }
                    return statusItem;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    ///////////////////////////////////////////////////////


    if (event.type === 'ADD TENDER PUBLICATION') {
        return store.map(function (item) {
            if (item.tenderid == event.item.tenderid) {
                if(!item.tenderPublications){
                    item.tenderPublications = [];
                }
                item.tenderPublications.push(event.item);
            }
            return item;
        });
    }

    if (event.type === 'REMOVE TENDER PUBLICATION') {
        return store.map(function (item) {
            if (item.tenderid == event.id.tenderid) {
                item.tenderPublications = item.tenderPublications.filter(statusItem => {
                    return statusItem.tenderpublicationid !== event.id.tenderpublicationid;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    if (event.type === 'UPDATE TENDER PUBLICATION') {
        return store.map(function (item) {
            if (item.tenderid == event.item.tenderid) {
                item.tenderPublications = item.tenderPublications ? item.tenderPublications : [];
                item.tenderPublications = item.tenderPublications.map(statusItem => {
                    if (statusItem.tenderpublicationid === event.item.tenderpublicationid) {
                        let editedItem = Object.assign({}, event.item);
                        return editedItem;
                    }
                    return statusItem;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    ////////////////////////////////////////

    if (event.type === 'ADD TENDER RELATIONSHIP' || event.type === 'ADD TENDER RELATIONSHIP OVERLYING') {
        return store.map(function (item) {
            if (item.tenderid == event.item[event.type === 'ADD TENDER RELATIONSHIP' ? 'tenderid' : 'underlyingtenderid']) {
                item.tenderRealationships[event.type === 'ADD TENDER RELATIONSHIP' ? 0 : 1].push(event.item);
            }
            return item;
        });
    }

    if (event.type === 'REMOVE TENDER RELATIONSHIP' || event.type === 'REMOVE TENDER RELATIONSHIP OVERLYING') {
        return store.map(function (item) {
            if (item.tenderid == event.id[event.type === 'REMOVE TENDER RELATIONSHIP' ? 'tenderid' : 'underlyingtenderid']) {
                console.log(item);
                item.tenderRealationships[event.type === 'REMOVE TENDER RELATIONSHIP' ? 0 : 1] = item.tenderRealationships[event.type === 'REMOVE TENDER RELATIONSHIP' ? 0 : 1].filter(statusItem => {
                    return statusItem.tenderrelationshipid !== event.id.tenderrelationshipid;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    if (event.type === 'UPDATE TENDER RELATIONSHIP' || event.type === 'UPDATE TENDER RELATIONSHIP OVERLYING') {
        return store.map(function (item) {
            if (item.tenderid == event.item[event.type === 'UPDATE TENDER RELATIONSHIP' ? 'tenderid' : 'underlyingtenderid']) {
                item.tenderRealationships[event.type === 'UPDATE TENDER RELATIONSHIP' ? 0 : 1] = item.tenderRealationships[event.type === 'UPDATE TENDER RELATIONSHIP' ? 0 : 1].map(statusItem => {
                    console.log(item);
                    if (statusItem.tenderrelationshipid === event.item.tenderrelationshipid) {
                        let editedItem = Object.assign({}, event.item);
                        return editedItem;
                    }
                    return statusItem;
                });
                let newItem = Object.assign({}, item);
                return newItem;
            }
            return item;
        });
    }

    return store;
};

export default Tenders;



