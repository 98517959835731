import React from 'react';
import ColumnResizer from "react-column-resizer";
import CommonTab from "../CommonTabClass";



class SovereigntyChanges extends CommonTab{

    orderFields = {
        "From Territory": 'fromterritoryname',
        "To Territory": 'toterritoryname',
        "From Sovereignty": 'fromsovereigntyname',
        "To Sovereignty": 'tosovereigntyname',
        "Sovereignty Change Status": 'fromterritoryname',
        "Territory": 'sovereigntychangestatus',
    };


    render() {
        let soveregnitChanges = this.props.soveregnitChanges ? this.props.soveregnitChanges : [];
        this.sortResults(soveregnitChanges);

        return (
            <div>

                <h4>Place Sovereignty Changes</h4>
                <table className={'table table-striped'}>
                    <thead>
                     {this.orderPanel()}
                    </thead>
                    <tbody>
                    {soveregnitChanges.map((item, index)=>{
                        return (
                            <tr key={index}>
	                            <td></td>
                                <td>{item.fromterritoryname}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.toterritoryname}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.fromsovereigntyname}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.tosovereigntyname}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.sovereigntychangestatus}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default SovereigntyChanges;