import React from 'react';
import Login from "../login";
import {Route, Switch, Redirect} from "react-router-dom";
import {connect} from 'react-redux'
import api from "../api";

import PlaceList from "./places/places_list";
import PlansList from "./plans/plans_list";
import TimeRanges from "./time_range/TimeRanges";
import TendersList from "./tenders/tenders_list";
import DescriptorsTypesList from "./descriptors/descriptors_list";
import TeritorriesList from "./territories/territoriesList";
import InitiatorList from "./descriptors/Initiators/initiator_list";
import InitiatorByType from "./descriptors/Initiators/initiator_by_category";
import SourceCategory from "./descriptors/Sources/source-category";
import SourceByCategory from "./descriptors/Sources/source-by-category";
import LandusesList from "./descriptors/Landuses/descriptor_landuses";
import InfoTable from "./item_list/info_table";
import SinglePlace from "./places/single_place";
import addNew from "./item_list/new_chart_add";
import PlanSingle from "./plans/plan_single";
import TenderSingle from "./tenders/tender_single";
import TenderEdit from "./tenders/TenderEdit";
import TenderAdd from "./tenders/TenderAdd";
import DescriptorContentTypes from "./descriptors/discriptor_content_type_single";
import SingleInitiator from "./descriptors/Initiators/single-initiator";
import TerritorySingle from "./territories/territoriesSingle";
import TerritorieDetailsAdd from "./territories/territoryAdd";
import SourceTabsRow from "./descriptors/Sources/single_source";
import SingleLanduse from "./descriptors/Landuses/single_landuse";
import TabsDataRow from "./general_data/tab_data_row";
import TabsPlacesRow from "./descriptors/place_descriptors_tab_row";
import TabsPlansRow from "./descriptors/plans_descriptors_tab_row";
import TabsTenderRow from "./descriptors/tenders_descriptor_tab_row";
import Header from "../header/header";
import ItemList from "./charts/charts_list";
import TimeRangeSingle from "./time_range/TimeRangeSingle";

class ContentComponent extends React.Component {
    render() {
        let {isLoged} = this.props;
        return (
            <div>
                {!isLoged ? <Login/> :
                    <div>
                        <Header/>
                        <div className={"container content"}>
                            <div className={"row"}>
                                <div className={"charts-list col-sm-4"}>
                                    <Switch>
                                    {/*<Route exact path="/" render={() => (<Redirect to="places"/>)}/>*/}
                                    <Route path={api.getRoute('/')} component={PlansList}/>
                                    <Route path={api.getRoute('charts/')} component={ItemList}/>
                                    <Route path={api.getRoute('places/')} component={PlaceList}/>
                                    <Route path={api.getRoute('plans/')} component={PlansList}/>
                                    <Route path={api.getRoute('tenders/')} component={TendersList}/>
                                    <Route path={api.getRoute('descriptors/content_types/')}
                                           component={DescriptorsTypesList}/>
                                    <Route path={api.getRoute('territories/')} component={TeritorriesList}/>
                                    <Route path={api.getRoute('initiators')} component={InitiatorList}/>
                                    <Route path={api.getRoute('sources')} component={SourceCategory}/>
                                    <Route path={api.getRoute('landuses')} component={LandusesList}/>
                                    <Route path={api.getRoute('time_ranges')} component={TimeRanges}/>
                                    {/*<Route exact path="/landuses/" render={() => (<Redirect to="/landuses/landuse/0/plans"/>)}/>*/}
                                    </Switch>
                                </div>
                                <div className={"charts-table col-sm-8"}>
                                    <Switch>
                                        <Route path={api.getRoute('time_ranges/:id')} component={TimeRangeSingle}/>
                                        <Route path={api.getRoute('charts/chart/:id')} component={InfoTable}/>
                                        <Route path={api.getRoute('places/:tabName/place/:id')} component={SinglePlace}/>
                                        <Route path={api.getRoute('charts/add_new')} component={addNew}/>
                                        <Route path={api.getRoute('plans/:tabName/plan/:id')} component={PlanSingle}/>
                                        <Route path={api.getRoute('tenders/:tabName/tender/:id')} component={TenderSingle}/>
                                        <Route path={api.getRoute('tenders/edit/:id')} component={TenderEdit}/>
                                        <Route path={api.getRoute('tenders/add/')} component={TenderAdd}/>
                                        <Route path={api.getRoute('descriptors/content_types/:id')}
                                               component={DescriptorContentTypes}/>
                                        <Route path={api .getRoute('initiators/type/:id')} component={InitiatorByType}/>
                                        <Route path={api.getRoute('territories/:tabName/territory/:id')}
                                               component={TerritorySingle}/>
                                        <Route path={api.getRoute('territories/add')} component={TerritorieDetailsAdd}/>
                                        <Route path={api.getRoute('sources/category/:id')} component={SourceByCategory}/>
                                        <Route path={api.getRoute('landuses/landuse/:id')} component={SingleLanduse}/>
                                        <Route path={api.getRoute('general/')} render={() => ('')}/>
                                        <Route path={api.getRoute('descriptors/place_types/')} render={() => ('')}/>
                                        <Route path={api.getRoute('plan_descriptors')} render={() => ('')}/>
                                        <Route path={api.getRoute('tender_descriptors')} render={() => ('')}/>
                                        <Route render={() => (<h2 className={'selectItem'}>Please select an item from the list</h2>)}/>
                                    </Switch>
                                    <Switch>
                                    <Route path={api.getRoute('initiators/type/:id_2/initiator/:tabName/:id')}
                                           component={SingleInitiator}/>
                                    <Route path={api.getRoute('sources/category/:id_category/source/:tabname/:id')}
                                           component={SourceTabsRow}/>
                                    </Switch>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={'col-sm-12'}>
                                    <Switch>
                                    <Route path={api.getRoute('general/')} component={TabsDataRow}/>
                                    <Route path={api.getRoute('descriptors/place_types/')} component={TabsPlacesRow}/>
                                    <Route path={api.getRoute('plan_descriptors')} component={TabsPlansRow}/>
                                    <Route path={api.getRoute('tender_descriptors')} component={TabsTenderRow}/>
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        isLoged: state.isLoged
    }
};

const Content = connect(mapStateToProps)(ContentComponent);

export default Content;
