import React from 'react';
import {connect} from 'react-redux';
import {ActionSingleLanduseTenders, ActionTenderDelete, ActionTenderEdit} from "../../../parts/actions";
import TendersRequest from "../../tenders/TendersRequest";
import TenderPopup from "../Tenders Description/tender_popup";
import Pagination from "../../../parts/Pagination";
import CommonTab from "../../../parts/CommonTabClass";
import Search from "../../../parts/Search";
import RemoveButton from "../../../parts/removeBtn";
import EditBtn from "../../../parts/EditBtn";
import TenderAdd from "../../tenders/TenderAdd";
import Storege from "../../../storage/storage";

class LanduseTenderComponent extends CommonTab {

    orderFields = {
        "Tender": "tender"
    };

    state = {
        landuse: {},
        tenderNames: {},
        tenderPopup: {},
        page: 1,
        searchValue: '',
        order: null
    };

    constructor(props) {
        super(props);
        this.state.landuse = props.landuse;
        this.state.tenderNames = props.tenderNames;
        this.state.tenderPopup = props.tenderPopup;
    }

    paginationChange = (number) => {
        this.setState({
            page: number
        });
    };

    changeStatePopup = (id) => {
        let temp = this.props.tenderPopup;
        temp.active = true;
        this.props.tenderNames.forEach(function (item) {
            if (item.tenderid == id) {
                temp.data = item;
            }
        })
        this.setState({
            tenderPopup: Object.assign({}, temp)
        });
    }

    editContentType = (object) => {
        ActionTenderEdit(object.tenderid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE TENDER',
                item: data,
                id: data.tenderid
            });
            ActionSingleLanduseTenders(this.props.id);
            this.changePopupState();
            this.props.history.push(data.tenderid);
        });
    };

    render() {
        const {searchValue} = this.state;
        let landuseId = this.props.id;
        let landuse = this.props.landuse;
        if (!landuse.hasOwnProperty('landuseTenders')) {
            ActionSingleLanduseTenders(landuseId);
        }
        let tenders = [];
        let tendersArr = this.props.tenders;
        //let tendersArr = landuse.landuseTenders;
        for (let key in tendersArr) {
            tenders.push({id: +key, tender: tendersArr[key]});
        }
        let tenderNames = this.props.tenderNames;
        if (!tenderNames.length) {
            TendersRequest('tenders');
        }
        let landuseTenders = this.props.landuse.landuseTenders;
        let items = [];
        let pages = [];
        const perPage = 20;

        if (!landuseTenders) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        let displayItems = tenderNames;
        let arr = [];
        displayItems.forEach(item => {
            let item2 = landuseTenders.filter(planItem => {
                return planItem.tenderid && planItem.tenderid == item.tenderid;
            });
            if(item2.length){
                 arr.push(item);
            }
        });

        if (searchValue.length) {
            arr = arr.filter(item => {
                return item.tender.toLowerCase().indexOf(searchValue) != -1;
            });
        }

        arr = (arr).slice((this.state.page - 1) * perPage, (this.state.page) * perPage);
        let maxPage = Math.ceil(arr.length / perPage);
        for (let i = 1; i <= maxPage; i++) {
            pages.push(i);
        }
        this.sortResults(arr);
        return (
            <div>
                <div className={'inline-toolbuttons'}>
                    <h3>Tenders</h3>
                    <Search
                        change={this.search}
                        id={'land-tenders'}
                    />
                </div>
                <table className='table table-striped'>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {arr.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                <EditBtn
                                    actionType={this.changePopupType}
                                    popupChange={this.changePopupState}
                                    change={this.setCurrentIndex}
                                    id={item.tenderid}
                                />
                                </td>
                                <td>{item ? item.tendername : ''}</td>
                            </tr>
                        )
                    })}
                    <tr>
                        <Pagination currentPage={this.state.page} pages={pages} click={this.paginationChange}
                                    paginationCount={5}/>
                    </tr>
                    </tbody>
                </table>
                {/*<TenderPopup active={this.props.tenderPopup.active} data={this.props.tenderPopup.data}/>*/}
                 {this.state.popup ? <TenderAdd
                    close={this.changePopupState}
                    action={this.state.popupType == 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType == 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType == 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        landuse: state.singleLanduse,
        tenders: state.landuses.tenders,
        tenderNames: state.tenders,
        tenderPopup: {
            active: false
        }
    }
};
const LanduseTender = connect(mapStateToProps)(LanduseTenderComponent);

export default LanduseTender;