import React from 'react';
import {connect} from 'react-redux';
import Select from "../../../parts/select";
import {getItemData} from "../../../parts/functions";
import ToolButtons from "../../../parts/ToolButtons";

class TenderDetailsComponent extends React.Component {
    render() {
        const {tender, change} = this.props;
        let tendersTypes = this.props.tendersTypes ? this.props.tendersTypes : [];
        let tendersPurposes = this.props.tendersPurposes ? this.props.tendersPurposes : [];
        let initiator = this.props.initiator ? this.props.initiator : [];
        return (
            <div>
                <div className={'custom-tool-bar'}>
                    <h3>Tender Details</h3>
                    {/*<ToolButtons*/}
                        {/*actionType={this.changePopupType}*/}
                        {/*popupChange={this.changePopupState}*/}
                        {/*id={this.state.currentItemIndex}*/}
                    {/*/>*/}
                </div>
                <table className={'table table-striped'}>
                    <tbody>
                    <tr>
                        <td>Tender Type: </td>
                        <td>{getItemData(tendersTypes, 'tendertypeid', tender.tendertypeid,  'tendertypename')}</td>
                    </tr>
                    <tr>
                        <td>Tender Purpose: </td>
                        <td>{getItemData(tendersPurposes, 'tenderpurposeid', tender.tenderpurposeid,  'tenderpurposename')}</td>
                    </tr>
                    <tr>
                        <td>Tender Initiator: </td>
                        <td>{getItemData(initiator, 'initiatorid', tender.initiatorid,  'initiatorname')}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tendersTypes: state.tendersTypes,
        tendersPurposes: state.tendersPurposes,
        initiator: state.tenderInitiator
    }
};

const TenderDetails = connect(mapStateToProps)(TenderDetailsComponent);

export default TenderDetails;