import React from 'react';
import {connect} from 'react-redux';
import {
    ActionPlanRelationship,
    ActionPlanRelationshipAdd, ActionPlanRelationshipDelete,
    ActionPlanRelationshipEdit,
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import ToolButtons from "../../../parts/ToolButtons";
import Storege from "../../../storage/storage";
import PlanRelationshipAdd from "./PlanRelationshipAdd";
import RemoveButton from "../../../parts/removeBtn";
import EditBtn from "../../../parts/EditBtn";
import CommonTab from "../../../parts/CommonTabClass";

class PlanRelationshipComponent extends CommonTab {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            valueRegionsFilter: -1,
            valuePlaceTypesFilter: -1,
            searchValue: '',
            popup: false,
            popupType: undefined,
            currentItemIndex: -1,
            rowIndex: -1,
            rowIndex_2: -1,
            order: null
        }
    }

    addContentType = (object) => {
        ActionPlanRelationshipAdd('', object).then(data => {
            Storege.dispatch({
                type: this.state.popupName === 'underlying' ? 'ADD RELATIONSHIP' : 'ADD RELATIONSHIP OVERLYING',
                item: data
            });
            this.changePopupState();
            this.props.history.push(data.placeid);
        });
    };

    editContentType = (object) => {
        console.log(object);
        ActionPlanRelationshipEdit(object.planrelationshipid, object).then(data => {
            Storege.dispatch({
                type: this.state.popupName === 'underlying' ? 'UPDATE RELATIONSHIP' : 'UPDATE RELATIONSHIP OVERLYING',
                item: data,
                id: data.planrelationshipid
            });
            this.changePopupState();
            //this.props.history.push(data.planid);
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    overOrUnder = (name = '') => {
        this.setState(
            {
                popupName: name
            }
        )
    };

    render() {
        let {plan, planRelations, change, changeInput} = this.props;
        let relationship = plan.relationship ? plan.relationship : [];
        let overerRel = relationship[1] ? relationship[1] : [];
        let underRel = relationship[0] ? relationship[0] : [];
        if (!plan.relationship) {
            ActionPlanRelationship(plan.planid);
        }

        if (!plan && !planRelations) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div className={'inline-toolbuttons'}>
                    <h3>Overlying Plans</h3>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                        popupName={this.overOrUnder}
                        name={'overlying'}
                    />
                </div>
                <table className={"table table-striped"} valign={"middle"}>
                    <thead>
                    <tr className={'sortable-btn'}>
                        <th></th>
                        <th>Overlying Plan</th>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <th>Remarks</th>
                    </tr>

                    </thead>
                    <tbody>
                    {overerRel.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                () => {
                                    this.setState({
                                        rowIndex: index,
                                        rowIndex_2: -1,
                                        currentItemIndex: item.planrelationshipid
                                    });
                                }
                            } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionPlanRelationshipDelete}
                                        id={item.planrelationshipid}
                                        descriptor={'REMOVE RELATIONSHIP OVERLYING'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.planrelationshipid}
                                        name={'overlying'}
                                    />
                                </td>
                                <td>{planRelations[item.planid]}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.remarks}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <div className={'inline-toolbuttons'}>
                    <h4>Underlying Plans</h4>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                        popupName={this.overOrUnder}
                        name={'underlying'}
                    />
                </div>
                <table className={"table table-striped"} valign={"middle"}>
                    <thead>
                    <tr className={'sortable-btn'}>
                        <th></th>
                        <th>Underlying Plan</th>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <th>Remarks</th>
                    </tr>

                    </thead>
                    <tbody>
                    {underRel.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                () => {
                                    this.setState({
                                        rowIndex_2: index,
                                        rowIndex: -1,
                                        currentItemIndex: item.planrelationshipid
                                    });
                                }
                            } className={'list-group-item clear' + (this.state.rowIndex_2 == index ? ' active' : '')}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionPlanRelationshipDelete}
                                        id={item.planrelationshipid}
                                        descriptor={'REMOVE RELATIONSHIP'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.planrelationshipid}
                                        name={'underlying'}
                                    />
                                </td>
                                <td>{planRelations[item.underlyingplanid]}</td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>{item.remarks}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <PlanRelationshipAdd
                    planid={plan.planid}
                    close={this.changePopupState}
                    name={this.state.popupName}
                    itemList={this.state.popupName === 'underlying' ? underRel : overerRel}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        planRelations: state.planRelations
    }
};

const PlanRelationship = connect(mapStateToProps)(PlanRelationshipComponent);
export default PlanRelationship;