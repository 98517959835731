import React from 'react';
import {connect} from "react-redux";
import SelectObject from "../../../parts/SelectObject";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class StatusPopupComponent extends CommonTab {
    state = {
        statusItem: this.props.statusItem ,
        errors: [],
        validateFields: [
            'statusname',
            'statusnameeng',
            // 'description',
            'statuscategoryid'
        ]
    };

    changeState(name, e) {
        let temp = this.state.statusItem;
        temp[name] = e.value;
        this.setState({statusItem: temp});
    }

    render() {
        let {statusItem, validateFields, errors} = this.state;
        let statusCategoriesSelectOptions = {};
        this.props.statusCategories.map(item => {
            statusCategoriesSelectOptions[item.statuscategoryid] = item.statuscategoryname;
        });
        return (
            <Popup openClose={this.props.close}>
                <div className={'popup-content-types'}>
                    <form onSubmit={event => {
                        event.preventDefault();
                        if (!('description' in statusItem)) {
                            statusItem['description'] = ''
                        }
                        this.formValidation(statusItem, validateFields);
                    }}>
                        <h2>{this.props.button + (this.props.button === 'Add' ? ' New' : '')} Status</h2>
                        <table className={'table table-striped'} align={'right'}>
                            <tbody>
                            <tr>
                                <td>Status Name :</td>
                                <td className={errors.indexOf('statusname') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={statusItem.statusname}
                                        onChange={(e) => {
                                            this.changeState('statusname', e.target);
                                        }}
                                    />
                                    {errors.indexOf('statusname') !== -1 ?
                                        <span className="errorText">Please set Status Name</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Status Name Eng :</td>
                                <td className={errors.indexOf('statusnameeng') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={statusItem.statusnameeng}
                                        onChange={(e) => {
                                            this.changeState('statusnameeng', e.target);
                                        }}
                                    />
                                    {errors.indexOf('statusnameeng') !== -1 ?
                                        <span className="errorText">Please set Status Name Eng</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Description :</td>
                                <td className={errors.indexOf('description') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={statusItem.description}
                                        onChange={(e) => {
                                            this.changeState('description', e.target);
                                        }}
                                    />
                                    {errors.indexOf('description') !== -1 ?
                                        <span className="errorText">Please set Description</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Category Status:</td>
                                <td className={errors.indexOf('statuscategoryid') !== -1 ? 'error' : ''}>
                                    <SelectObject
                                        name={'status-category'}
                                        value={statusItem.statuscategoryid}
                                        types={statusCategoriesSelectOptions}
                                        change={(e) => {
                                            this.changeState('statuscategoryid', e.target);
                                        }}
                                    />
                                    {errors.indexOf('statuscategoryid') !== -1 ?
                                        <span className="errorText">Please set Status Category</span> : ''}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </form>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let content = {};
    console.log(state.statuses);
    console.log(ownProps.id);
    state.statuses.map((item) => {
        if (item.statusid == ownProps.id) {
            content = item;
        }
    });

    return {
        statusCategories: state.statusCategories,
        statusItem: Object.assign({}, content)
    }
};

const StatusPopup = connect(mapStateToProps)(StatusPopupComponent);

export default StatusPopup;