import * as React from 'react';
import {connect} from 'react-redux';
import CommonTab from "../../../../parts/CommonTabClass";
import ToolButtons from "../../../../parts/ToolButtons";
import RemoveButton from "../../../../parts/removeBtn";
import {
	ActionDescriptorsTenderPublicationCost,
	ActionTenderPublicationCostDelete,
	ActionTenderPublicationCostAdd,
	ActionTenderPublicationCostEdit,
	ActionTenderPublications
} from "../../../../parts/actions";
import EditBtn from "../../../../parts/EditBtn";
import ColumnResizer from "react-column-resizer";
import AddCost from './addCost';
import {getItemData} from "../../../../parts/functions";

class CostsComponent extends CommonTab {

	orderFields = {
        "Plan Publication Cost Type": 'tenderpublicationcosttypeid',
        "Cost": 'cost'
    };


	addContentType = (object) => {
        ActionTenderPublicationCostAdd('', object).then(data => {
            this.changePopupState();
            ActionTenderPublications(this.props.tenderid);
        });
    };

    editContentType = (object) => {
        ActionTenderPublicationCostEdit(object.tenderpublicationcostdetailid, object).then(data => {
            this.changePopupState();
             ActionTenderPublications(this.props.tenderid);
        });
    };

    DeleteContentType = (id,descriptor) => {
        ActionTenderPublicationCostDelete(id,descriptor).then(()=>{
        	ActionTenderPublications(this.props.tenderid);
        });
    };

	render() {
		const {items,tenderPublicationCostsType,publication} = this.props
		 if (!tenderPublicationCostsType.length) {
            ActionDescriptorsTenderPublicationCost();
        }

		this.sortResults(items);
		return (
			<div>
                <div className={'custom-tool-bar'}>
                    <h3>Cost Details</h3>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <div className="scrolled ">
                    <table className={"table table-striped"} valign={"middle"}>
                        <thead>
                        {this.orderPanel()}
                        </thead>
	                    <tbody>
	                    {items.map((item, index) => {
                            return (
                                <tr key={index}  className={'list-group-item'}>
                                    <td  className={'flex-row'}>
                                        <RemoveButton
                                            descriptor={'REMOVE PLAN PUBLICATION'}
                                            action={this.DeleteContentType}
                                            id={item.tenderpublicationcostdetailid}
                                        />
                                        <EditBtn
                                            actionType={this.changePopupType}
                                            popupChange={this.changePopupState}
                                            change={this.setCurrentIndex}
                                            id={item.tenderpublicationcostdetailid}
                                        />
                                    </td>
                                    <td>
	                                    {getItemData(tenderPublicationCostsType ? tenderPublicationCostsType : [], 'tenderpublicationcosttypeid', item.tenderpublicationcosttypeid, 'tenderpublicationcosttypename')}
	                                    &nbsp;/&nbsp;
	                                    {getItemData(tenderPublicationCostsType ? tenderPublicationCostsType : [], 'tenderpublicationcosttypeid', item.tenderpublicationcosttypeid, 'tenderpublicationcosttypenameeng')}
                                    </td>
                                    <ColumnResizer className="columnResizer" minWidth={5}/>
                                    <td>
                                        {item.cost}
                                    </td>
                                </tr>
                            )
                        })}
	                    </tbody>
                    </table>
                </div>
				{this.state.popup ? <AddCost
                    close={this.changePopupState}
                    itemList={items}
                    publication = {publication}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
			</div>
		)
	}
}

const mapStateToProps = (state , ownProps) => {
    return {
        tenderPublicationCostsType: state.tenderPublicationCost,
	    items : ownProps.items ? ownProps.items : []
    }
};
const Costs = connect(mapStateToProps)(CostsComponent);

export default Costs;