import React from 'react';
import {connect} from "react-redux";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class PlaceTypesPopupComponent extends CommonTab {

    state = {
        placeType: this.props.placeType,
        errors: [],
        validateFields: [
            'placetypename',
            'placetypenameeng'
        ]
    };

    constructor(props) {
        super(props);
        this.state = Object.assign({}, this.state, {placeType: this.props.placeType});
    }

    changeState(name, e) {
        let temp = this.state.placeType;
        temp[name] = e.value;
        this.setState({placeType: temp});
    }

    render() {
        let {placeType, validateFields, errors} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <div className={'popup-content-types'}>
                    <form onSubmit={event => {
                        event.preventDefault();
                        console.log(placeType)
                        this.formValidation(placeType, validateFields);
                    }}>
                        <h2>{this.props.button + (this.props.button === 'Add' ? ' New' : '')} Place Type</h2>
                        <table className={'table table-striped'} align={'right'}>
                            <tbody>
                            {/*<tr>*/}
                                {/*<td>Place Type ID:</td>*/}
                                {/*<td className={errors.indexOf('placetypeid') !== -1 ? 'error' : ''}>*/}
                                    {/*<input*/}
                                        {/*type={'text'}*/}
                                        {/*value={placeType.placetypeid}*/}
                                        {/*onChange={(e) => {*/}
                                            {/*this.changeState('placetypeid', e.target);*/}
                                        {/*}}*/}
                                    {/*/>*/}
                                    {/*{errors.indexOf('placetypeid') !== -1 ?*/}
                                        {/*<span className="errorText">Please set ID</span> : ''}*/}
                                {/*</td>*/}
                            {/*</tr>*/}
                            <tr>
                                <td>Place Type Name :</td>
                                <td className={errors.indexOf('placetypename') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={placeType.placetypename}
                                        onChange={(e) => {
                                            this.changeState('placetypename', e.target);
                                        }}
                                    />
                                    {errors.indexOf('placetypename') !== -1 ?
                                        <span className="errorText">Please set Name</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Place Type Name Eng :</td>
                                <td className={errors.indexOf('placetypenameeng') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={placeType.placetypenameeng}
                                        onChange={(e) => {
                                            this.changeState('placetypenameeng', e.target);
                                        }}
                                    />
                                    {errors.indexOf('placetypenameeng') !== -1 ?
                                        <span className="errorText">Please set Name Eng</span> : ''}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </form>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let content = {};
    state.placeTypes.map((item) => {
        if (item.placetypeid == ownProps.id) {
            content = item;
        }
    });
    return {
        placeType: Object.assign({}, content)
    }
};

const PlaceTypesPopup = connect(mapStateToProps)(PlaceTypesPopupComponent);

export default PlaceTypesPopup;