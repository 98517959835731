import React from 'react';
import {Link} from 'react-router-dom';

export default class TabsRow extends React.Component {

    render() {
        let tabs = this.props.tabs;
        let id = this.props.id;
        let path = this.props.path;
        return(
            <ul className={"nav navbar-nav place-tabs"}>
                {tabs.map((item, index)=>{
                    let link = item.tabUrl + id;
                    return(
                        <li key={index}>
                            <Link className={link == path ? 'active' : ''} to={link} onClick={(e)=>{
                            }}>{item.tabName}</Link>
                        </li>
                    )
                })}
                {/*<li>*/}
                    {/*<Link to={'/peacenow/home/places/place/' + this.props.id + '/statuses'}>Statuses</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*<Link to={'/peacenow/home/places/place/' + this.props.id + '/synonyms'}>Synonyms</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*<Link to={'/peacenow/home/places/place/' + this.props.id + '/hierarchies'}>Hierarchies</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*<Link to={'/peacenow/home/places/place/' + this.props.id + '/geometry'}>Geometries</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*<Link to={'/peacenow/home/places/place/' + this.props.id + '/sovereignties'}>Sovereignties</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*<Link to={'/peacenow/home/places/place/' + this.props.id + '/tracking'}>Tracking</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*<Link to={'/peacenow/home/places/place/' + this.props.id + '/links'}>Links</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*<Link to={'/peacenow/home/places/place/' + this.props.id + '/ICBS'}>ICBS</Link>*/}
                {/*</li>*/}
            </ul>
        )
    }
}
