import React from 'react';
import {connect} from 'react-redux';
import getFullDateYear, {getItemData} from "../../parts/functions";
import ColumnResizer from "react-column-resizer";
import {
	ActionTimeRangeSingle
} from "../../parts/actions";
import  TenderPublications from './tabs/TenderPublications';
import  PlanPublications from './tabs/PlanPublications';
import  Tracking from './tabs/Trackings';

class TimeRangeSingleComponent extends React.Component {

    state ={
        tab : 0
    }
    constructor(props){
        super(props);
    }

    render() {
        const {timeRange} = this.props;
        if (!timeRange) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        if(!timeRange.TenderPublications){
            ActionTimeRangeSingle(timeRange.timerangeid)
        }
        console.log(timeRange);
        let  tabs = [
                {
                    'tabName' : 'Plan Publications',
                    'component' :  <PlanPublications items={timeRange.PlanPublications} publication={this.state.currentItemIndex} timeRange={timeRange.timerangeid} />
                },
                {
                    'tabName' : 'Tender Publications',
                    'component' :  <TenderPublications items={timeRange.TenderPublications ? timeRange.TenderPublications : []} timeRange={timeRange.timerangeid} />
                },
                {
                    'tabName' : 'Tracking',
                    'component' :  <Tracking  items={timeRange.Trackings ? timeRange.Trackings : []} timeRange={timeRange.timerangeid} />
                },
            ]
        return (
            <div>
                <div className={'place-top'}>
                    <h3>Selected Time Range</h3>
                    <div className="left-id">
                        Time Range ID: {timeRange.timerangeid}
                    </div>
                </div>
                <table className={"table table-striped place-top-table"} valign={"middle"}>
                    <tbody>
                    <tr>
                        <td>
                            <label htmlFor={"name"}>Name: </label>
                        </td>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <td>{timeRange.timerangename}</td>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <td><label htmlFor={"name_eng"}>Name Eng: </label></td>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <td>{timeRange.timerangenameeng}</td>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <td>
                            <label htmlFor={"endDate"}>Remarks: </label>
                        </td>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <td>{timeRange.remarks}</td>
                    </tr>
                    <tr>
                        <td>
                            <label htmlFor={"startDate"}>Start Date: </label>
                        </td>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <td>
                            {timeRange.startdate ? getFullDateYear(timeRange.startdate) : '01.01.1970'}
                        </td>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <td>
                            <label htmlFor={"endDate"}>End Date: </label>
                        </td>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <td>
                            {timeRange.enddate ? getFullDateYear(timeRange.enddate) : '01.01.1970'}
                        </td>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <td></td>
                        <ColumnResizer className="columnResizer" minWidth={5}/>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
                <div>
                    <ul className={"nav navbar-nav place-tabs"}>
                        {tabs.map((item, index) => {
                            return (
                                <li key={index}>
                                    <a className={this.state.tab == index ? 'active' : ''} href={'#'} onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({tab: index});
                                    }}>{item.tabName}</a>
                                </li>
                            )
                        })}
                    </ul>
                    {tabs[this.state.tab].component}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let timeRange = null;
    state.timeRanges.forEach(function (item) {
        if (item.timerangeid == ownProps.match.params.id) {
            timeRange = item;
        }
    });
    return {
        timeRange: timeRange
    }
};

const TimeRangeSingle = connect(mapStateToProps)(TimeRangeSingleComponent);

export default TimeRangeSingle;
