import React from 'react';
import {Link, Route} from 'react-router-dom';
import api from "../../api";
import DescriptorPlaceTypes from './Place Descriptors/descriptors_place_types';
import DescriptorRegions from './Place Descriptors/descriptor_regions';
import DescriptorStatus from './Place Descriptors/descriptor_status';
import DescriptorCommunity from "./Place Descriptors/descriptors_community";
import DescriptorPopulation from "./Place Descriptors/descriptor_population";
import DescriptorTracking from "./Place Descriptors/descriptor_tracking";
import DescriptorTrackingValue from "./Place Descriptors/descriptor_tracking_value";

export default class TabsPlacesRow extends React.Component {
    render() {
        const PlacesTabs = [
            {
                tabName: 'Places Types',
                tabEnd: 'place_types',
                tabUrl: api.getRoute('descriptors/place_types/place_type')
            },
            {
                tabName: 'Regions',
                tabEnd: 'regions',
                tabUrl: api.getRoute('descriptors/place_types/regions')
            },
            {
                tabName: 'Status',
                tabEnd: 'status_categories',
                tabUrl: api.getRoute('descriptors/place_types/status_categories')
            },
            {
                tabName: 'Place Community Types',
                tabEnd: 'community_types',
                tabUrl: api.getRoute('descriptors/place_types/community_types')
            },
            {
                tabName: 'Place Population Types',
                tabEnd: 'population_types',
                tabUrl: api.getRoute('descriptors/place_types/population_types')
            },
            {
                tabName: 'Tracking Types',
                tabEnd: 'tracking_types',
                tabUrl: api.getRoute('descriptors/place_types/tracking_types')
            },
            {
                tabName: 'Tracking Value Types',
                tabEnd: 'tracking_value_types',
                tabUrl: api.getRoute('descriptors/place_types/tracking_value_types')
            },
        ];
        let path=this.props.location.pathname;
        return(
            <div>
                <h2>Place descriptors</h2>
                <div className="row">
                    <div className="col-sm-12">
                        <ul className={"nav navbar-nav place-tabs"}>
                            {PlacesTabs.map((item, index)=>{
                                let link = item.tabUrl;
                                return(
                                    <li key={index}>
                                        <Link className={path.indexOf(link) != -1 ? 'active' : ''} to={link} onClick={(e)=>{
                                        }}>{item.tabName}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Route path={api.getRoute('descriptors/place_types/place_type')} component={DescriptorPlaceTypes}/>
                        <Route path={api.getRoute('descriptors/place_types/regions')} component={DescriptorRegions}/>
                        <Route path={api.getRoute('descriptors/place_types/status_categories')} component={DescriptorStatus}/>
                        <Route path={api.getRoute('descriptors/place_types/community_types')} component={DescriptorCommunity}/>
                        <Route path={api.getRoute('descriptors/place_types/population_types')} component={DescriptorPopulation}/>
                        <Route path={api.getRoute('descriptors/place_types/tracking_types')} component={DescriptorTracking}/>
                        <Route path={api.getRoute('descriptors/place_types/tracking_value_types')} component={DescriptorTrackingValue}/>
                    </div>
                </div>
            </div>
        )
    }
}
