import React from 'react';
import {connect} from 'react-redux';
import Select from "../../parts/select";
import Popup from "../../parts/Popup";
import DatePicker from "react-datepicker";
import CommonTab from "../../parts/CommonTabClass";

class PlaceAddComponent extends CommonTab {
    state = {
        place: this.props.place,
        errors: [],
        validateFields: [
            'name',
            'nameeng',
            'placetypeid',
            'regionid',
            // 'placecommunitytypeid',
            // 'placepopulationtypeid'
        ]
    };

    changeState = (name, e) => {
        let temp = this.state.place;
        temp[name] = e.value;
        this.setState({place: temp});
    };

    handleChange = (date) => {
        let temp = this.state.place;
        temp.yearestablisheddate = date;
        this.setState({place: temp});
    };



    render() {
        const {place, validateFields, errors} = this.state;
        console.log(this.props);
        return (
            <Popup openClose={this.props.openClose}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('yearestablisheddate' in place)) {
                            place['yearestablisheddate'] = ''
                        }
                        if (!('description' in place)) {
                            place['description'] = ''
                        }
                        if (!('url' in place)) {
                            place['url'] = ''
                        }
                        this.formValidation(place, validateFields);
                        // this.props.action(this.state.place);
                    }}
                >
                    <div>
                        <h2>{this.props.button} place</h2>
                        <table className={"table table-striped"} valign={"middle"}>
                            <tbody>
                            <tr>
                                <td>
                                    <label htmlFor={"name"}>Name: </label>
                                </td>
                                <td className={errors.indexOf('name') !== -1 ? 'error' : ''}>
                                    <input type={"text"}
                                           className={"form-control"}
                                           name={"name"}
                                           id={"name"}
                                           value={this.state.place.name ? this.state.place.name : ''}
                                           onChange={(e) => {
                                               this.changeState("name", e.target);
                                           }}
                                    />
                                    {errors.indexOf('name') !== -1 ?
                                        <span className="errorText">Please set Place Name</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td><label htmlFor={"nameeng"}>Name Eng: </label></td>
                                <td className={errors.indexOf('nameeng') !== -1 ? 'error' : ''}>
                                    <input type={"text"}
                                           className={"form-control"}
                                           name={"nameeng"}
                                           id={"nameeng"}
                                           value={this.state.place.nameeng ? this.state.place.nameeng : ''}
                                           onChange={(e) => {
                                               this.changeState("nameeng", e.target);
                                           }}
                                    />
                                    {errors.indexOf('nameeng') !== -1 ?
                                        <span className="errorText">Please set Place Name Eng</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor={"placetypeid"}>Place Type: </label>
                                </td>
                                <td className={errors.indexOf('placetypeid') !== -1 ? 'error' : ''}>
                                    <Select
                                        id={"placetypeid"}
                                        className={"form-control"}
                                        name={"placetypeid"}
                                        value={this.state.place.placetypeid ? this.state.place.placetypeid : ''}
                                        types={this.props.placeTypes}
                                        optionValue={"placetypeid"}
                                        optionName={"placetypename"}
                                        onChange={(e) => {
                                            this.changeState("placetypeid", e.target);
                                        }
                                        }
                                    />
                                    {errors.indexOf('placetypeid') !== -1 ?
                                        <span className="errorText">Please set Place Type</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor={"regionid"}>Region: </label>
                                </td>
                                <td className={errors.indexOf('regionid') !== -1 ? 'error' : ''}>
                                    <Select
                                        id={"regionid"}
                                        className={"form-control"}
                                        name={"regionid"}
                                        value={this.state.place.regionid ? this.state.place.regionid : ''}
                                        types={this.props.regions}
                                        optionValue={"regionid"}
                                        optionName={"regionname"}
                                        onChange={(e) => {
                                            this.changeState("regionid", e.target);
                                        }
                                        }
                                    />
                                    {errors.indexOf('regionid') !== -1 ?
                                        <span className="errorText">Please set Region</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor={"placecommunitytypeid"}>Community Type: </label>
                                </td>
                                <td className={errors.indexOf('placecommunitytypeid') !== -1 ? 'error' : ''}>
                                    <Select
                                        id={"placecommunitytypeid"}
                                        className={"form-control"}
                                        name={"placecommunitytypeid"}
                                        value={this.state.place.placecommunitytypeid ? this.state.place.placecommunitytypeid : ''}
                                        types={this.props.communityType}
                                        optionValue={"placecommunitytypeid"}
                                        optionName={"placecommunitytypename"}
                                        onChange={(e) => {
                                            this.changeState("placecommunitytypeid", e.target);
                                        }
                                        }
                                    />
                                    {errors.indexOf('placecommunitytypeid') !== -1 ?
                                        <span className="errorText">Please set Place Community Type</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor={"placepopulationtypeid"}>Population Type: </label>
                                </td>
                                <td className={errors.indexOf('placepopulationtypeid') !== -1 ? 'error' : ''}>
                                    <Select
                                        id={"placepopulationtypeid"}
                                        className={"form-control"}
                                        name={"placepopulationtypeid"}
                                        value={this.state.place.placepopulationtypeid ? this.state.place.placepopulationtypeid : ''}
                                        types={this.props.populationType}
                                        optionValue={"placepopulationtypeid"}
                                        optionName={"placepopulationtypename"}
                                        onChange={(e) => {
                                            this.changeState("placepopulationtypeid", e.target);
                                        }
                                        }
                                    />
                                    {errors.indexOf('placepopulationtypeid') !== -1 ?
                                        <span className="errorText">Please set Place Population Type</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor={"established_date"}>Year Established Date:</label>
                                </td>
                                <td>
                                    <DatePicker
                                        selected={this.state.place.yearestablisheddate ? new Date(this.state.place.yearestablisheddate) : ''}
                                        onChange={this.handleChange}
                                        dateFormat="dd/MM/yyyy"
                                        timeCaption="time"
                                    />
                                    {/*<input type={"date"}*/}
                                           {/*className={"form-control"}*/}
                                           {/*name={"established_date"}*/}
                                           {/*id={"established_date"}*/}
                                           {/*value={this.state.place.yearestablisheddate ? this.state.place.yearestablisheddate : ''}*/}
                                           {/*onChange={(e) => {*/}
                                               {/*this.changeState("yearestablisheddate", e.target);*/}
                                           {/*}}*/}
                                    {/*/>*/}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor={"description"}>Description: </label>
                                </td>
                                <td>
                                    <textarea className={"form-control"}
                                              name={"description"}
                                              id={"description"}
                                              value={this.state.place.description ? this.state.place.description : ''}
                                              onChange={(e) => {
                                                  this.changeState("description", e.target);
                                              }}
                                    >{''}</textarea>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor={"URL"}>URL: </label>
                                </td>
                                <td>
                                    <input type={"text"}
                                           className={"form-control"}
                                           name={"URL"}
                                           id={"URL"}
                                           value={this.state.place.url ? this.state.place.url : ''}
                                           onChange={(e) => {
                                               this.changeState("url", e.target);
                                           }}
                                    />
                                    <a href={'http://' + this.state.place.url ? this.state.place.url : '#'} target={'_blank'}>Test</a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </div>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let place = {};
    state.places.forEach(function (item) {
        if (item.placeid == ownProps.id) {
            console.log(item);
            place = item;
        }
    });

    return {
        placeTypes: state.placeTypes,
        communityType: state.communityType,
        regions: state.regions,
        populationType: state.populationType,
        place: Object.assign({}, place)
    }
};

const PlaceAdd = connect(mapStateToProps)(PlaceAddComponent);
export default PlaceAdd;
