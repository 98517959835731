import React from 'react';

class Search extends React.Component {
    render() {
        return (
            <div id={'search-holder'}>
                <form
                    onSubmit={(e)=>{
                        e.preventDefault();
                        let searchValue = document.getElementById((this.props.id ? this.props.id : 'search')).value;
                        this.props.change(searchValue);
                    }}
                >
                <input
                    type={'text'}
                    name={'search'}
                    id={(this.props.id ? this.props.id : 'search')}
                />
                <input
                    type={'submit'}
                    value={'Search'}
                    className={'btn btn-primary'}
                />
                </form>
            </div>
        )
    }
}

export default Search;