import React from 'react';
import {connect} from 'react-redux';
import SelectObject from "../../parts/SelectObject";
import {ActionGeneralCategoryAdd, ActionGeneralCategoryEdit, ActionGeneralDataValue} from "../../parts/actions";
import Storege from "../../storage/storage";

class GeneralDataEditComponent extends React.Component {
    state = {
        category: {},
    };

    constructor(props) {
        super(props);
        this.state.category = props.category;
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if (oldProps.category.generalcategoryid !== newProps.category.generalcategoryid) {
            this.setState({category: newProps.category})
        }
    }

    changeState = (name, e) => {
        let temp = this.props.category;
        temp[name] = e.value;
        this.setState({
            category: Object.assign({}, temp)
        });
    };

    changeValue = (name, e, id) => {
        let temp = this.props.category.values;
        temp.forEach(function (item) {
            if (item.generalvalueid == id) {
                item[name] = e.value;
            }
        });
        this.setState({
            category: Object.assign({}, temp)
        });
    };

    selectChange = (name, e) => {
        let temp = this.props.category;
        temp[name] = +e.value;
        this.setState({
            category: Object.assign({}, temp)
        });
    };

    render() {
        let {category, source, unit} = this.props;
        return (
            <div>
                <form onSubmit={event => {
                    event.preventDefault();
                    ActionGeneralCategoryEdit(this.state.category.generalcategoryid, this.state.category).then(data => {
                        Storege.dispatch({
                            type: 'EDIT GENERAL CATEGORY',
                            item: data,
                            id: data.generalcategoryid
                        });
                        this.props.history.push('/general/category/' + data.generalcategoryid);
                    });
                    // ActionGeneralCategoryAdd('', this.state.category).then(data => {

                    // this.props.history.push('/general/category/' + data.generalcategoryid);
                    // });
                }}>
                    <h3>Edit Category</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Name:</td>
                            <td>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'data-category-name'}
                                       value={category.generalcategoryname}
                                       onChange={(e) => {
                                           this.changeState('generalcategoryname', e.target);
                                       }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Name Eng:</td>
                            <td>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'data_category_nameeng'}
                                       value={category.generalcategorynameeng}
                                       onChange={(e) => {
                                           this.changeState('generalcategorynameeng', e.target);
                                       }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Unit:</td>
                            <td>
                                <SelectObject
                                    name={'data-category-unit'}
                                    value={category.generalunitid}
                                    types={unit}
                                    change={(e) => {
                                        this.selectChange('generalunitid', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Source:</td>
                            <td>
                                <SelectObject
                                    name={'data-category-source'}
                                    value={category.generalsourceid}
                                    types={source}
                                    change={(e) => {
                                        this.selectChange('generalsourceid', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Description:</td>
                            <td>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'data-category-description'}
                                       value={category.description}
                                       onChange={(e) => {
                                           this.changeState('description', e.target);
                                       }}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={"Edit"}/>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let category = {};
    state.generalData.forEach(function (item) {
        if (item.generalcategoryid == ownProps.match.params.id) {
            category = item;
        }
    });
    return {
        category: Object.assign({}, category),
        source: state.generalDataSources,
        unit: state.generalDataUnits
    }
};

const GeneralDataEdit = connect(mapStateToProps)(GeneralDataEditComponent);

export default GeneralDataEdit;