import React from 'react';
import {connect} from 'react-redux';
import CommonTab from "../../parts/CommonTabClass";
import Select from "../../parts/select";
import Popup from "../../parts/Popup";

class addNewComponent extends CommonTab {
    state = {
        chart: this.props.chart,
        errors: [],
        validateFields: [
            'chartname',
            'charttypeid',
            'title',
            'titleeng',
            'datasource'
        ]
    };

    placeChange = (name, e) => {
        let temp = this.state.chart;
        temp[name] = e.value;
        this.setState({
            chart: Object.assign({}, temp)
        });
    };

    //
    // componentWillReceiveProps(newProps) {
    //     const oldProps = this.props;
    //     if (oldProps.chart.chartid !== newProps.chart.chartid) {
    //         this.setState({chart: newProps.chart})
    //     }
    // }

    changeState(name, e) {
        let temp = this.state.chart;
        temp[name] = e.value;
        this.setState({chart: temp});
    }

    render() {
        const {chart, validateFields, errors} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    if (!('chartstyleid' in chart)) {
                        chart['chartstyleid'] = 0
                    }
                    if (!('yaxiseng' in chart)) {
                        chart['yaxiseng'] = ''
                    }
                    if (!('yaxis' in chart)) {
                        chart['yaxis'] = ''
                    }
                    if (!('xaxiseng' in chart)) {
                        chart['xaxiseng'] = ''
                    }
                    if (!('xaxis' in chart)) {
                        chart['xaxis'] = ''
                    }
                    if (!('subtitleeng' in chart)) {
                        chart['subtitleeng'] = ''
                    }
                    if (!('subtitle' in chart)) {
                        chart['subtitle'] = ''
                    }
                    if (!('description' in chart)) {
                        chart['description'] = ''
                    }
                    if (!('params' in chart)) {
                        chart['params'] = ''
                    }
                    this.formValidation(chart, validateFields);
                }}>
                    <h3>{this.props.button} Chart</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Chart Name</td>
                            <td className={errors.indexOf('chartname') !== -1 ? 'error' : ''}>
                                <input type={"text"} className={"form-control"} name={"chart_name"}
                                       value={chart.chartname ? chart.chartname : ""} size="50"
                                       onChange={(e) => {
                                           this.changeState("chartname", e.target);
                                       }}/>
                                {errors.indexOf('chartname') !== -1 ?
                                    <span className="errorText">Please set Chart Name</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Chart Type</td>
                            <td className={errors.indexOf('charttypeid') !== -1 ? 'error' : ''}>
                                <Select
                                    types={this.props.types}
                                    className={"form-control"}
                                    value={chart.charttypeid}
                                    optionValue={"charttypeid"}
                                    optionName={"charttypename"}
                                    onChange={(e) => {
                                        this.placeChange('charttypeid', e.target);
                                    }
                                    }
                                />
                                {errors.indexOf('charttypeid') !== -1 ?
                                    <span className="errorText">Please set Chart Type</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Chart Style</td>
                            <td>
                                <Select
                                    types={this.props.styles}
                                    className={"form-control"}
                                    value={chart.chartstyleid}
                                    optionValue={"chartstyleid"}
                                    optionName={"chartstylename"}
                                    onChange={(e) => {
                                        this.placeChange('chartstyleid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Data Source</td>
                            <td className={errors.indexOf('datasource') !== -1 ? 'error' : ''}>
                                <input type="text" className={"form-control"} name="chart_datasource"
                                       value={this.state.chart.datasource ? this.state.chart.datasource : ""}
                                       size="50" onChange={(e) => {
                                    this.changeState("datasource", e.target);
                                }}/>
                                {errors.indexOf('datasource') !== -1 ?
                                    <span className="errorText">Please set Data Source</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Params</td>
                            <td><input type="text" className={"form-control"} name="chart_params"
                                       value={this.state.chart.params ? this.state.chart.params : ""} size="50"
                                       onChange={(e) => {
                                           this.changeState("params", e.target);
                                       }}/>
                            </td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td>
                                <input type="text" className={"form-control"} name="chart_description"
                                       value={this.state.chart.description ? this.state.chart.description : ""}
                                       size="50" onChange={(e) => {
                                    this.changeState("description", e.target);
                                }}/>
                            </td>
                        </tr>
                        <tr>
                            <td>Title</td>
                            <td className={errors.indexOf('title') !== -1 ? 'error' : ''}>
                                <input type="text" className={"form-control"} name="chart_title"
                                       value={this.state.chart.title ? this.state.chart.title : ""} size="50"
                                       onChange={(e) => {
                                           this.changeState("title", e.target);
                                       }}
                                />
                                {errors.indexOf('title') !== -1 ?
                                    <span className="errorText">Please set Title</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Title Eng</td>
                            <td className={errors.indexOf('titleeng') !== -1 ? 'error' : ''}>
                                <input type="text" className={"form-control"} name="chart_title_eng"
                                       value={this.state.chart.titleeng ? this.state.chart.titleeng : ""} size="50"
                                       onChange={(e) => {
                                           this.changeState("titleeng", e.target);
                                       }}
                                />
                                {errors.indexOf('titleeng') !== -1 ?
                                    <span className="errorText">Please set Title Eng</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Subtitle</td>
                            <td><input type="text" className={"form-control"} name="chart_subtitle"
                                       value={this.state.chart.subtitle ? this.state.chart.subtitle : ""} size="50"
                                       onChange={(e) => {
                                           this.changeState("subtitle", e.target);
                                       }}/>
                            </td>
                        </tr>
                        <tr>
                            <td>Subtitle Eng</td>
                            <td><input type="text" className={"form-control"} name="chart_subtitle_eng"
                                       value={this.state.chart.subtitleeng ? this.state.chart.subtitleeng : ""}
                                       size="50"
                                       onChange={(e) => {
                                           this.changeState("subtitleeng", e.target);
                                       }}/>
                            </td>
                        </tr>
                        <tr>
                            <td>XAxis</td>
                            <td>
                                <input type="text" className={"form-control"} name="chart_xaxis"
                                       value={this.state.chart.xaxis ? this.state.chart.xaxis : ""} size="50"
                                       onChange={(e) => {
                                           this.changeState("xaxis", e.target);
                                       }}/>
                            </td>
                        </tr>
                        <tr>
                            <td>XAxis Eng</td>
                            <td>
                                <input type="text" className={"form-control"} name="chart_xaxiseng"
                                       value={this.state.chart.xaxiseng ? this.state.chart.xaxiseng : ""} size="50"
                                       onChange={(e) => {
                                           this.changeState("xaxiseng", e.target);
                                       }}/>
                            </td>
                        </tr>
                        <tr>
                            <td>YAxis</td>
                            <td><input type="text" className={"form-control"} name="chart_yaxis"
                                       value={this.state.chart.yaxis ? this.state.chart.yaxis : ""} size="50"
                                       onChange={(e) => {
                                           this.changeState("yaxis", e.target);
                                       }}
                            />
                            </td>
                        </tr>
                        <tr>
                            <td>YAxis Eng</td>
                            <td><input type="text" className={"form-control"} name="chart_yaxiseng"
                                       value={this.state.chart.yaxiseng ? this.state.chart.yaxiseng : ""} size="50"
                                       onChange={(e) => {
                                           this.changeState("yaxiseng", e.target);
                                       }}/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align="center">
                                <input className={"btn btn-primary"} type={"submit"} value={this.props.button}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let chart = {};
    state.charts.forEach(function (el) {
        if (el.chartid == ownProps.id) {
            chart = el;
        }
    });
    return {
        chart: Object.assign({}, chart),
        types: state.types,
        styles: state.styles
    }
};

const addNew = connect(mapStateToProps)(addNewComponent);
export default addNew;