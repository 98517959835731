import React from 'react';
import {ActionPlacesSynonymAdd, ActionPlacesSynonymDel, ActionPlacesSynonymEdit, ActionSetSynonyms} from "../actions";
import ColumnResizer from "react-column-resizer";
import Storege from "../../storage/storage";
import RemoveButton from "../removeBtn";
import ToolButtons from "../ToolButtons";
import SynonymAdd from "./SynonymAdd";
import CommonTab from "../CommonTabClass";
import EditBtn from "../EditBtn";

class Synonyms extends CommonTab {

    orderFields = {
        "Name": 'name',
        "Name Eng": 'nameeng'
    };

    state = {
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1,
        order: null
    };


    addContentType = (object) => {
        ActionPlacesSynonymAdd('', object).then(data => {
            Storege.dispatch({
                type: 'ADD PLACE SYNONYMS',
                item: data,
            });
            this.changePopupState();
        });
    };

    editContentType = (object) => {
        ActionPlacesSynonymEdit(object.placesynonymid, object).then(data => {
            Storege.dispatch({
                type: 'UPDATE PLACE SYNONYMS',
                item: data,
                id: data.placesynonymid
            });
            this.changePopupState();
        });
    };

    changePopupType = (actionType) => {
        this.setState({
            popupType: actionType
        })
    };

    changePopupState = () => {
        let currentPopupState = this.state.popup;
        this.setState(
            {
                popup: !currentPopupState
            }
        )
    };

    render() {
        const {place} = this.props;
        if (!place.synonymsList) {
            ActionSetSynonyms(place.placeid);
        }
        let synonyms = place.synonymsList ? place.synonymsList : [];
        this.sortResults(synonyms);
        return (
            <React.Fragment>
                <div className={'inline-toolbuttons'}>
                    <h3>Synonyms</h3>
                    <ToolButtons
                        actionType={this.changePopupType}
                        popupChange={this.changePopupState}
                        id={this.state.currentItemIndex}
                    />
                </div>
                <table className={"table table-striped"} valign={"middle"}>
                    <thead>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {synonyms.map((item, index) => {
                        return (
                            <tr key={index} onClick={
                                () => {
                                    this.setState({
                                        rowIndex: index,
                                        currentItemIndex: item.placesynonymid
                                    });
                                }
                            } className={'list-group-item clear' + (this.state.rowIndex == index ? ' active' : '')}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        action={ActionPlacesSynonymDel}
                                        id={item.placesynonymid}
                                        descriptor={'REMOVE PLACE SYNONYMS'}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.placesynonymid}
                                    />
                                </td>

                                <td>
                                    {item.name}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5}/>
                                <td>
                                    {item.nameeng}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {this.state.popup ? <SynonymAdd
                    placeid={place.placeid}
                    close={this.changePopupState}
                    itemList={place.synonymsList}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </React.Fragment>
        )
    }
}

export default Synonyms;