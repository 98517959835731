import React from 'react';
import {connect} from 'react-redux';
import Select from "../../../parts/select";
import Popup from "../../../parts/Popup";

class TenderLandusAddComponent extends React.Component {

    state = {
        popupItem: {
            tenderid: this.props.tenderid
        },
        error: false,
        error2: false
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = {
                popupItem: Object.assign({},this.props.popupItem)
            }
        }
    }

    placeChange = (value) => {
        let temp = this.state.popupItem;
        temp.popupItemid = value.target.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        const {popupItem, error, error2} = this.state;
        const {tenderLanduses, itemList} = this.props;

        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        let currentItems = itemList.filter(item => {
                            return item.landuseid == popupItem.landuseid && popupItem.landuseid!=this.props.popupItem.landuseid;
                        }).length;

                        if (currentItems === 0 && (popupItem.landuseid || popupItem.landuseid===0) ) {
                            this.props.action(popupItem);
                            this.setState({
                                error : false,
                                error2 : false
                            });
                        } else {
                             this.setState({
                                error : !(currentItems === 0),
                                error2 : !(popupItem.landuseid || popupItem.landuseid===0)
                            });
                        }
                    }}
                >
                    <h3>{this.props.button} Tender Landuse</h3>
                    {error ? <span className={'error'}>This Landuse is currently exist</span> : ''}
                    {error2 ? <span className={'error'}>Select Landuse</span> : ''}
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Landuse:</td>
                            <td>
                                <Select
                                    name={'plan_name'}
                                    types={tenderLanduses}
                                    className={"form-control"}
                                    value={popupItem.landuseid}
                                    optionValue={"landuseid"}
                                    optionName={"landusename"}
                                    onChange={(e) => {
                                        this.changeState('landuseid', e.target);
                                    }
                                    }
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item.tenderlanduseid == ownProps.id) {
            popupItem = item;
        }
    });

    return {
        tenderLanduses: state.tenderLanduses,
        popupItem: Object.assign({}, popupItem)
    }
};

const TenderLandusAdd = connect(mapStateToProps)(TenderLandusAddComponent);
export default TenderLandusAdd;
