import React from 'react';
import {connect} from 'react-redux';
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class SourceByCategoryAddComponent extends CommonTab {
    state = {
        popupItem: this.props.popupItem,
        errors: [],
        validateFields: [
            'sourcename',
            'sourcenameeng'
        ]
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: Object.assign({}, temp)
        });
    };

    render() {
        const {popupItem, errors, validateFields} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        popupItem.sourcecategoryid = this.props.categoryId;
                        console.log(popupItem);
                        this.formValidation(popupItem, validateFields);
                    }}
                >
                    <h2>{this.props.button} Source</h2>
                    <table className={'table table-striped'}>
                        <tbody>
                        {/*<tr>*/}
                        {/*    <td>Source Category:</td>*/}
                        {/*    <td>*/}
                        {/*        <Select*/}
                        {/*            name={'plan_name'}*/}
                        {/*            types={sourcesCategory}*/}
                        {/*            className={"form-control"}*/}
                        {/*            value={popupItem.sourcecategoryid}*/}
                        {/*            optionValue={"sourcecategoryid"}*/}
                        {/*            optionName={"sourcecategoryname"}*/}
                        {/*            onChange={(e) => {*/}
                        {/*                this.changeState('sourcecategoryid', e.target);*/}
                        {/*            }*/}
                        {/*            }*/}
                        {/*        />*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td>Source Name:</td>
                            <td className={errors.indexOf('sourcename') !== -1 ? 'error' : ''}>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'source-name'}
                                       value={popupItem.sourcename}
                                       onChange={(e) => {
                                           this.changeState('sourcename', e.target);
                                       }}
                                />
                                {errors.indexOf('sourcename') !== -1 ? <span className="errorText">Please set Source Name</span> : '' }
                            </td>
                        </tr>
                        <tr>
                            <td>Source Name Eng:</td>
                            <td className={errors.indexOf('sourcenameeng') !== -1 ? 'error' : ''}>
                                <input type={'text'}
                                       className={'form-control'}
                                       name={'source-nameeng'}
                                       value={popupItem.sourcenameeng}
                                       onChange={(e) => {
                                           this.changeState('sourcenameeng', e.target);
                                       }}
                                />
                                {errors.indexOf('sourcenameeng') !== -1 ? <span className="errorText">Please set Source Name Eng</span> : '' }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let newItem = {};
    state.sourceByCategory.forEach(item => {
       if(item.sourceid === ownProps.id) {
           newItem = item
       }
    });
    return {
        popupItem: Object.assign({}, newItem),
        sourcesCategory: state.sources.sourceCategories
    }
};

const SourceByCategoryAdd = connect(mapStateToProps)(SourceByCategoryAddComponent);
export default SourceByCategoryAdd;

