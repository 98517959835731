import React from 'react';
import {connect} from 'react-redux';
import Popup from "../../parts/Popup";
import DatePicker from "react-datepicker";
import CommonTab from "../../parts/CommonTabClass";

class GeneralDataAddComponent extends CommonTab {

    state = {
        popupItem: {
            generalcategoryid: this.props.generalcategoryid
        },
        errors: [],
        validateFields: [
            'valuedate',
            'value'
        ],
        popup: false,
        popupType: undefined,
        currentItemIndex: -1,
        rowIndex: -1
    };

    constructor(props) {
        super(props);
        if (Object.keys(this.props.popupItem).length) {
            this.state = Object.assign({}, this.state, {popupItem: this.props.popupItem});
        }
    }

    handleChange = (date) => {
        let temp = this.state.popupItem;
        temp.valuedate = date;
        this.setState({popupItem: temp});
    };

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        const {popupItem, errors, validateFields} = this.state;
        console.log(popupItem);
        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!('remarks' in popupItem)) {
                            popupItem['remarks'] = ''
                        }
                        this.formValidation(popupItem, validateFields);
                        // this.props.action(popupItem);
                    }}
                >
                    <h3>{this.props.button + (this.props.button === 'Add ' ? 'New ' : '')} General Value</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Value Date:</td>
                            <td className={errors.indexOf('valuedate') !== -1 ? 'error' : ''}>
                                <DatePicker
                                    selected={popupItem.valuedate ? new Date(popupItem.valuedate) : new Date().setHours(12)}
                                    onChange={this.handleChange}
                                    //showTimeSelect
                                    timeIntervals={60}
                                    dateFormat="dd/MM/yyyy"
                                    timeCaption="time"
                                />
                                {errors.indexOf('valuedate') !== -1 ?
                                    <span className="errorText">Please set Date</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Value:</td>
                            <td className={errors.indexOf('value') !== -1 ? 'error' : ''}>
                                <input
                                    className={'form-control'}
                                    type={'number'}
                                    value={popupItem.value ? popupItem.value : ''}
                                    onChange={(e) => {
                                        this.changeState('value', e.target);
                                    }}
                                />
                                {errors.indexOf('value') !== -1 ?
                                    <span className="errorText">Please set Value</span> : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Remarks:</td>
                            <td>
                                <input
                                    className={'form-control'}
                                    type={'text'} value={popupItem.remarks ? popupItem.remarks : ''}
                                    onChange={(e) => {
                                        this.changeState('remarks', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let popupItem = {};

    ownProps.itemList.forEach(function (item) {
        if (item.generalvalueid === ownProps.id) {
            popupItem = item;
            console.log(ownProps.itemList);
            console.log(ownProps.id);
        }
    });

    return {
        popupItem: Object.assign({}, popupItem)
    }
};

const GeneralDataAdd = connect(mapStateToProps)(GeneralDataAddComponent);
export default GeneralDataAdd;

