import React from 'react';
import MenuList from "./menu/menu_list/menu_list";
import {Link} from "react-router-dom";
import api from "../api";

console.log(api.getRoute('general'));

let menus = [
    {
        menuTitle: "DATA",
        items: [
            {
                title: 'Edit Places',
                path: api.getRoute('places')
            },
            {
                title: 'Edit Plans',
                path: api.getRoute('plans')
            },
            {
                title: 'Edit Tenders',
                path: api.getRoute('tenders')
            },
            {
                title: 'Edit General Data',
                path: api.getRoute('general/category/')
            }
        ]
    },

    {
        menuTitle: "DEFINITIONS",
        items: [
            {
                title: 'Edit Content Descriptors',
                path: api.getRoute('descriptors/content_types')
            },
            {
                title: 'Edit Place Descriptors',
                path: api.getRoute('descriptors/place_types/place_type')
            },
            {
                title: 'Edit Plan Descriptors',
                path: api.getRoute('plan_descriptors/planning_phases')
            },
            {
                title: 'Edit Tender Descriptors',
                path: api.getRoute('tender_descriptors/tender_types')
            },
            {
                title: 'Edit Initiators',
                path: api.getRoute('initiators')
            },
            {
                title: 'Edit Sources',
                path: api.getRoute('sources')
            },
            {
                title: 'Edit Landuses',
                path: api.getRoute('landuses')
            },
            {
                title: 'Edit Territories',
                path: api.getRoute('territories')
            },
            {
                title: 'Edit Time Ranges',
                path: api.getRoute('time_ranges')
            },
        ]
    },
    {
        menuTitle: "WEBSITE",
        items: [
            {
                title: 'Charts',
                path: api.getRoute('charts')
            }
        ]
    }
];

export default class Header extends React.Component {
    render() {
        return (
            <div className={"navbar navbar-default navbar-static-top"}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"header-top"}>
                            <Link className={"s"} to={"/"}>
                                <img src={"/public/img/logo-en.png"} alt={""}/>
                            </Link>
                        </div>
                        <nav className={"navbar"}>
                            <ul className={"nav navbar-nav"}>
                                {menus.map(function (menuItem, index) {
                                    return (
                                        <MenuList key={index} item={menuItem}/>
                                    )
                                })}
                            </ul>
                        </nav>
                        <a className={'loguot'} href={'http://test.peacenow.org.il/logout_now'}>Log out</a>
                    </div>
                </div>
            </div>
        )
    }
}
