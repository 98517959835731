import React from 'react';
import {connect} from 'react-redux';
import {
    ActionDescriptorsPlanningPhases,
    ActionSingleSource,
    ActionSingleSourcePlan, ActionSourcePlanPublicationDelete
} from "../../../parts/actions";
import ColumnResizer from "react-column-resizer";
import CommonTab from "../../../parts/CommonTabClass";
import {getItemData} from "../../../parts/functions";
import PlanPublicationAdd from "../../plans/tabs/PlanPublicationAdd";
import RemoveButton from "../../../parts/removeBtn";
import EditBtn from "../../../parts/EditBtn";
import TendersRequest from "../../tenders/TendersRequest";

class SingleSourcePlanComponent extends CommonTab {
    state = {
        singleSource: {},
    }

    orderFields = {
         "Source": 'sourceid',
        "Publication Date": 'publicationdate',
        "Plan": 'planid',
        "Plan Phase": 'sourcecredibilityid',
        "Remarks": 'remarks',
        "URL": 'url'
    };

    constructor(props) {
        super(props);
        this.state.singleSource = props.singleSource;
    }

    changeStateSource= (name, e, id) => {
        let temp = this.props.singleSource;
        temp.singleSourcePlan.forEach(function (item) {
            if (item.planpublicationid == id) {
                item[name] = e.value;
            }
        })
        this.setState({
            singleSource: Object.assign({}, temp)
        });
    };

    render() {
        let id = +this.props.id;
        let id_category = this.props.id_category;
        let singleSource = this.props.singleSource;
        if (!singleSource || (singleSource && id != singleSource.sourceid)) {
            ActionSingleSource(id_category, id);
        }
        if (!this.props.planningPhases.length) {
            ActionDescriptorsPlanningPhases();
        }

        if (!this.props.sourcecredibility.length) {
            TendersRequest('tenders');
        }
        if (singleSource && !singleSource.hasOwnProperty('singleSourcePlan')) {
           ActionSingleSourcePlan(id_category, id);
        }
        let singleSourcePlans = this.props.singleSource.singleSourcePlan;
        let sourcesArr = [];
        let sources = this.props.sourcesArr;
        for (let key in sources) {
            sourcesArr.push({id: +key, sourceName: sources[key]});
        }
        let sourcePlans = [];
        let plans = this.props.sourcePlans;
        for (let key in plans) {
            sourcePlans.push({id: +key, plan: plans[key]});
        }
        let sourcePlaningPhases = [];
        let phases = this.props.sourcePlaningPhases;
        for (let key in phases) {
            sourcePlaningPhases.push({id: +key, planningPhase: phases[key]});
        }
        // if (!singleSourcePlans) {
        //     return (
        //         <div className="overlay-loader">
        //             <div className="spinner-border" role="status">
        //                 <span className="sr-only">Loading...</span>
        //             </div>
        //         </div>
        //     );
        // }
        this.sortResults(singleSourcePlans);
        return (
            <div>
                <h3>Plan Publications</h3>
                <table className='table table-striped'>
                    <thead>
                    <td className={'flex-row'}></td>
                    {this.orderPanel()}
                    </thead>
                    <tbody>
                    {(singleSourcePlans ? singleSourcePlans : []).map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className={'flex-row'}>
                                    <RemoveButton
                                        descriptor={'REMOVE PLAN PUBLICATION'}
                                        action={(Oid,descriptor)=>{
                                            ActionSourcePlanPublicationDelete(Oid,descriptor);
                                            ActionSingleSource(id_category, id);
                                        }}
                                        id={item.planpublicationid}
                                    />
                                    <EditBtn
                                        actionType={this.changePopupType}
                                        popupChange={this.changePopupState}
                                        change={this.setCurrentIndex}
                                        id={item.planpublicationid}
                                    />
                                </td>
                                <td>
                                    {getItemData(sourcesArr ? sourcesArr : [], 'id', item.sourceid, 'sourceName')}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5} />
                                <td>
                                    {item.publicationdate}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5} />
                                <td>
                                    {(sourcePlans ? sourcePlans : []).map((unit) => {
                                        if (item.planid == unit.id) {
                                            return unit.plan;
                                        }
                                    })}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5} />
                                <td>
                                    {(sourcePlaningPhases ? sourcePlaningPhases : []).map((unit) => {
                                        if (item.planningphaseid == unit.id) {
                                            return unit.planningPhase;
                                        }
                                    })}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5} />
                                <td>
                                    {item.remarks}
                                </td>
                                <ColumnResizer className="columnResizer" minWidth={5} />
                                <td>
                                    {item.url}
                                </td>
                            </tr>
                        )
                    })

                    }
                    </tbody>
                </table>
                {this.state.popup ? <PlanPublicationAdd
                    //planid={plan.planid}
                    close={this.changePopupState}
                    itemList={singleSourcePlans}
                    action={this.state.popupType === 'add' ? this.addContentType : this.editContentType}
                    button={this.state.popupType === 'add' ? 'Add' : 'Update'}
                    id={this.state.popupType === 'edit' ? this.state.currentItemIndex : -1}
                /> : ''}
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        singleSource: state.singleSource,
        sourcesArr: state.sources.sourcesArr,
        sourcePlans: state.sources.plans,
        sourcePlaningPhases: state.sources.planningphases,
        planningPhases: state.planningPhases,
        sourcecredibility: state.sourcecredibility
    }
};
const SingleSourcePlan = connect(mapStateToProps)(SingleSourcePlanComponent);

export default SingleSourcePlan;