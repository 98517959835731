import React from 'react';
import api from "../../api";
import Storege from "../../storage/storage";


const GeneralRequest = (route) => {
    fetch(api.ajaxUrl + route, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-type": "application/json",
            "X-CSRF-TOKEN": api.token
        }
    }).then((response) => response.json()).then(function (data) {
        Storege.dispatch({
            type: 'SET GENERAL DATA',
            items: data.category
        });
        Storege.dispatch({
            type: 'SET GENERAL DATA SOURCE',
            items: data.sourceArr
        });
        Storege.dispatch({
            type: 'SET GENERAL DATA UNIT',
            items: data.unitsArr
        });
        Storege.dispatch({
            type: 'SET GENERAL DATA DESCRIPTOR SOURCES',
            items: data.sources
        });
        Storege.dispatch({
            type: 'SET GENERAL DATA DESCRIPTOR UNITS',
            items: data.units
        });
    });
};

export default GeneralRequest;