import React from 'react';
import api from "../../api";
import Storege from "../../storage/storage";


const PlansRequest = (route) => {
    fetch(api.ajaxUrl + route, {
        method: "POST",
        mode: 'cors',
        headers: {
            "Content-type": "application/json",
            "X-CSRF-TOKEN": api.token
        }
    }).then((response) => response.json()).then(function (data) {
        Storege.dispatch({
            type: 'SET PLANS',
            items: data.plans
        });
        Storege.dispatch({
            type: 'SET PLANS PLACES',
            items: data.places
        });
        Storege.dispatch({
            type: 'SET PLANS INITIATORS',
            items: data.initiator
        });
        Storege.dispatch({
            type: 'SET PLANS INITIATORS',
            items: data.initiator
        });
        Storege.dispatch({
            type: 'SET PLANS OWNER SHIP TYPE',
            items: data.ownershiptype
        });
        Storege.dispatch({
            type: 'SET PLAN TYPE',
            items: data.plantype
        });
        Storege.dispatch({
            type: 'SET PLAN LANDUSES',
            items: data.allLanduses
        });
        Storege.dispatch({
            type: 'SET PLAN RELATIONS',
            items: data.plansArr
        });
        Storege.dispatch({
            type: 'SET PLAN SOURCES',
            items: data.source
        });
        Storege.dispatch({
            type: 'SET PLAN TENDERS',
            items: data.tendersArr
        });
        Storege.dispatch({
            type: 'SET PLAN TENDER PUBLICATIONS',
            items: data.tenderPublicationPurpose
        });
    });
};

export default PlansRequest;