import React from 'react';
import {connect} from "react-redux";
import Popup from "../../../parts/Popup";
import CommonTab from "../../../parts/CommonTabClass";

class RegionsPopupComponent extends CommonTab {

    state = {
        region: this.props.region,
        errors: [],
        validateFields: [
            'regionname',
            'regionnameeng'
        ]
    };

    constructor(props) {
        super(props);
        this.state = Object.assign({}, this.state, {region: this.props.region});
    }

    changeState(name, e) {
        let temp = this.state.region;
        temp[name] = e.value;
        this.setState({region: temp});
    }

    render() {
        let {region, validateFields, errors} = this.state;
        return (
            <Popup openClose={this.props.close}>
                <div className={'popup-content-types'}>
                    <form onSubmit={event => {
                        event.preventDefault();
                        this.formValidation(region, validateFields);
                    }}>

                        <h2>{this.props.button + (this.props.button === 'Add' ? ' New' : '')} Region</h2>
                        <table className={'table table-striped'} align={'right'}>
                            <tbody>
                            <tr>
                                <td>Region Name :</td>
                                <td className={errors.indexOf('regionname') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={region.regionname}
                                        onChange={(e) => {
                                            this.changeState('regionname', e.target);
                                        }}
                                    />
                                    {errors.indexOf('regionname') !== -1 ?
                                        <span className="errorText">Please set Region Name</span> : ''}
                                </td>
                            </tr>
                            <tr>
                                <td>Region Name Eng :</td>
                                <td className={errors.indexOf('regionnameeng') !== -1 ? 'error' : ''}>
                                    <input
                                        type={'text'}
                                        value={region.regionnameeng}
                                        onChange={(e) => {
                                            this.changeState('regionnameeng', e.target);
                                        }}
                                    />
                                    {errors.indexOf('regionnameeng') !== -1 ?
                                        <span className="errorText">Please set Region Name Eng</span> : ''}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                    </form>
                </div>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let content = {};
    state.regions.map((item) => {
        if (item.regionid == ownProps.id) {
            content = item;
        }
    });
    return {
        region: Object.assign({}, content)
    }
};

const RegionsPopup = connect(mapStateToProps)(RegionsPopupComponent);

export default RegionsPopup;