import React from 'react';
import {connect} from 'react-redux';
import {ActionSingleLanduse} from "../../../parts/actions";
import api from "../../../api";
import TabsRow from "../../../parts/tabs-row";
import {Route} from 'react-router-dom';
import LandusePlan from "./landuse_plans";
import LanduseTender from "./landuse_tenders";
import ColumnResizer from "react-column-resizer";

class SingleLandusesComponent extends React.Component {
    state = {
        landuse: {}
    }

    constructor(props) {
        super(props);
        this.state.landuse = props.landuse;
    }

    changeState = (name, e) => {
        let temp = this.props.landuse;
        temp[name] = e.value;
        this.setState({
            landuse: Object.assign({}, temp)
        });
    };

    render() {
        let landuseId = this.props.match.params.id;
        let landuse = this.props.landuse;
        const LanduseTabs = [
            {
                tabName: 'Plans',
                tabEnd: 'plans',
                tabUrl: api.getRoute('landuses/landuse/' + landuseId + '/plans')
            },
            {
                tabName: 'Tenders',
                tabEnd: 'tenders',
                tabUrl: api.getRoute('landuses/landuse/' + landuseId + '/tenders')
            }
        ];
        if (landuse.length == 0 || (landuseId != landuse.landuseid)) {
            ActionSingleLanduse(landuseId);
        }
        if (!landuse) {
            return (
                <div className="overlay-loader">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <h3>Landuse Details</h3>

                <table className='table table-striped'>
                    <thead>
                    <tr>
                        <th>Landuse Name</th>
                        <th></th>
                        <th>Landuse Name Eng</th>
                        <th></th>
                        <th>Landuse Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{landuse.landusename}</td>
                        <ColumnResizer className="columnResizer" minWidth={5} />
                        <td>{landuse.landusenameeng}</td>
                        <ColumnResizer className="columnResizer" minWidth={5} />
                        <td>{landuse.landusedescription}</td>
                    </tr>
                    </tbody>
                </table>
                <TabsRow
                    tabs={LanduseTabs}
                    id=''
                    path={this.props.location.pathname}
                />
                <Route path={api.getRoute('landuses/landuse/' + landuseId + '/plans')}
                       render={(props) => <LandusePlan {...props} id={landuseId}/> }
                />
                <Route path={api.getRoute('landuses/landuse/' + landuseId + '/tenders')}
                render={(props) => <LanduseTender {...props} id={landuseId} /> } />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        landuse: state.singleLanduse,
    }
};
const SingleLanduse = connect(mapStateToProps)(SingleLandusesComponent);

export default SingleLanduse;