import React from 'react';
import {Link, Route} from 'react-router-dom';
import api from "../../api";
import DescriptorTenderTypes from "./Tenders Description/descriptor_tenders_type";
import DescriptorTenderPurposes from "./Tenders Description/descriptor_tender_purposes";
import DescriptorTenderPublicationPurposes from "./Tenders Description/descriptor_tender_publication_purposes";
import DescriptorTenderPublicationUnit from "./Tenders Description/descriptor_tender_publication_unit";
import DescriptorTenderPublicationEvent from "./Tenders Description/descriptor_tender_publication_event";
import DescriptorTenderPublicationCost from "./Tenders Description/descriptor_tender_publication_cost";
import ContractorDescriptor from "./Tenders Description/descriptor_contactors";

export default class TabsTenderRow extends React.Component {
    render() {
        const TenderTabs = [
            {
                tabName: 'Tender Types',
                tabEnd: 'tender_types',
                tabUrl: api.getRoute('tender_descriptors/tender_types')
            },
            {
                tabName: 'Tender Purposes',
                tabEnd: 'tender_purposes',
                tabUrl: api.getRoute('tender_descriptors/tender_purposes')
            },
            {
                tabName: 'Tender Publication Purposes',
                tabEnd: 'tender_publication_purposes',
                tabUrl: api.getRoute('tender_descriptors/tender_publication_purposes')
            },
            {
                tabName: 'Tender Publication Unit Types',
                tabEnd: 'tender_publication_unit_types',
                tabUrl: api.getRoute('tender_descriptors/tender_publication_unit_types')
            },
            {
                tabName: 'Tender Publication Event Types',
                tabEnd: 'tender_publication_event_types',
                tabUrl: api.getRoute('tender_descriptors/tender_publication_event_types')
            },
            {
                tabName: 'Tender Publication Cost Types',
                tabEnd: 'tender_publication_cost_types',
                tabUrl: api.getRoute('tender_descriptors/tender_publication_cost_types')
            },
            {
                tabName: 'Contractors',
                tabEnd: 'tender_contractors',
                tabUrl: api.getRoute('tender_descriptors/tender_contractors')
            },
        ];
        let path = this.props.location.pathname;
        return (
            <div>
                <h2>Tender descriptors</h2>
                <div className="row">
                    <div className="col-sm-12">
                        <ul className={"nav navbar-nav place-tabs"}>
                            {TenderTabs.map((item, index) => {
                                let link = item.tabUrl;
                                return (
                                    <li key={index}>
                                        <Link className={link == path ? 'active' : ''} to={link} onClick={(e) => {
                                        }}>{item.tabName}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <Route path={api.getRoute('tender_descriptors/tender_types')} component={DescriptorTenderTypes}/>
                <Route path={api.getRoute('tender_descriptors/tender_purposes')} component={DescriptorTenderPurposes}/>
                <Route path={api.getRoute('tender_descriptors/tender_publication_purposes')}
                       component={DescriptorTenderPublicationPurposes}/>
                <Route path={api.getRoute('tender_descriptors/tender_publication_unit_types')}
                       component={DescriptorTenderPublicationUnit}/>
                <Route path={api.getRoute('tender_descriptors/tender_publication_event_types')}
                       component={DescriptorTenderPublicationEvent}/>
                <Route path={api.getRoute('tender_descriptors/tender_publication_cost_types')}
                       component={DescriptorTenderPublicationCost}/>
                <Route path={api.getRoute('tender_descriptors/tender_contractors')}
                       component={ContractorDescriptor}/>
            </div>
        )
    }
}
