import React from 'react';
import {connect} from 'react-redux';
import Popup from "../../../parts/Popup";

class InitiatorByCategoryAddComponent extends React.Component {

    state = {
        popupItem: {
            initiatortypeid: this.props.initiatortypeid
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            popupItem: Object.assign(this.state.popupItem, this.props.popupItem)
        }
    }

    changeState = (name, e) => {
        let temp = this.state.popupItem;
        temp[name] = e.value;
        this.setState({
            popupItem: temp
        });
    };

    render() {
        const {popupItem} = this.state;
        console.log(popupItem);
        console.log(this.props);

        return (
            <Popup openClose={this.props.close}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.props.action(popupItem);
                    }}
                >
                    <h3>{this.props.button} Initiator</h3>
                    <table className={"table table-striped"} valign={"middle"}>
                        <tbody>
                        <tr>
                            <td>Initiator Name :</td>
                            <td>
                                <input
                                    className={'form-control'}
                                    type={'text'} value={popupItem.initiatorname ? popupItem.initiatorname : ''}
                                    onChange={(e) => {
                                        this.changeState('initiatorname', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Initiator Name Eng :</td>
                            <td>
                                <input
                                    className={'form-control'}
                                    type={'text'} value={popupItem.initiatornameeng ? popupItem.initiatornameeng : ''}
                                    onChange={(e) => {
                                        this.changeState('initiatornameeng', e.target);
                                    }}
                                />
                            </td>
                        </tr>
                        </tbody>

                    </table>
                    <input type={"submit"} className={"btn btn-primary"} value={this.props.button}/>
                </form>
            </Popup>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let popupItem = {};
    ownProps.itemList.forEach(function (item) {
        if (item.initiatorid == ownProps.id) {
            popupItem = item;
        }
    });

    return {
        popupItem: Object.assign({}, popupItem)
    }
};

const InitiatorByCategoryAdd = connect(mapStateToProps)(InitiatorByCategoryAddComponent);
export default InitiatorByCategoryAdd;
